import React from "react";
import landingPageArrowLeft from "../../../../resources/logo/landing-page-arrow-left.svg";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import f5x7o4x6no1 from "../../../../resources/precut-mat-options/5x7/4x6no1-min.jpg";
import f5x7o4x6no3 from "../../../../resources/precut-mat-options/5x7/4x6no3-min.jpg";
// import singleMat from "../../../../resources/images/diplomaMats/1.SingleMat.png";
// import doubleMat from "../../../../resources/images/diplomaMats/2.Doublemat.png";
// import singleMatWithWriting from "../../../../resources/images/diplomaMats/3.SingleMatWithWriting.png";
// import doubleMatWithWriting from "../../../../resources/images/diplomaMats/4.DoubleMatwithWriting.png";

import colourOptions1 from "../../../../resources/precut-mat-options/colourOptions01-min.JPG";
import colourOptions2 from "../../../../resources/precut-mat-options/colourOptions2-min.JPG";
import colourOptions3 from "../../../../resources/precut-mat-options/colourOptions3-min.JPG";
import colourOptions4 from "../../../../resources/precut-mat-options/colourOptions4.JPG";
import colourOptions5 from "../../../../resources/precut-mat-options/colourOptions5.PNG";
import colourOptions6 from "../../../../resources/precut-mat-options/colourOptions6.JPG";
import colourOptions7 from "../../../../resources/precut-mat-options/colourOptions7.JPG";
import colourOptions8 from "../../../../resources/precut-mat-options/colourOptions8.JPG";
import colourOptions9 from "../../../../resources/precut-mat-options/colourOptions9.JPG";
import colourOptions10 from "../../../../resources/precut-mat-options/colourOptions10.JPG";

import singleMatPlain from "../../../../resources/images/diplomaMats/Single Matting.png";
import singleMatText from "../../../../resources/images/diplomaMats/Single Matting Text.png";
import singleMatLogo from "../../../../resources/images/diplomaMats/Single Matting with Logo.png";
import singleMatLogoAndText from "../../../../resources/images/diplomaMats/Single Matting Text and Logo.png";

import singleMatVerticalPlain from "../../../../resources/images/diplomaMats/Single Matting Verticial.png";
import singleMatVerticalText from "../../../../resources/images/diplomaMats/Single Matting Vertical Text.png";
import singleMatVerticalLogo from "../../../../resources/images/diplomaMats/Single Matting Vertical Logo.png";
import singleMatVerticalLogoAndText from "../../../../resources/images/diplomaMats/Single Matting Vertical Text and Logo.png";

import doubleMatPlain from "../../../../resources/images/diplomaMats/Double Matting.png";
import doubleMatText from "../../../../resources/images/diplomaMats/Double Matting Text.png";
import doubleMatLogo from "../../../../resources/images/diplomaMats/Double Matting Logo Only.png";
import doubleMatLogoAndText from "../../../../resources/images/diplomaMats/Double Matting Logo and Text.png";

import doubleMatVerticalPlain from "../../../../resources/images/diplomaMats/Double Matting Vertical.png";
import doubleMatVerticalText from "../../../../resources/images/diplomaMats/Double Matting Vertical Text.png";
import doubleMatVerticalLogo from "../../../../resources/images/diplomaMats/Double Matting Logo Only.png";
import doubleMatVerticalLogoAndText from "../../../../resources/images/diplomaMats/Double Matting Logo and Text.png";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {MenuItem} from "@material-ui/core";
import displayInputFields from "../../app-matsize-rendering/components/displayInputFields";
import arrowUp from "../../../../resources/svgIcons/select-mat-arrow-up.svg";
import arrowDown from "../../../../resources/svgIcons/select-mat-arrow-down.svg";
import localStorageFunctions from "../../comp-localStorage/localStroage";
import {Helmet} from "react-helmet";
import LazyLoad from "react-lazyload";
import LandingPageReviewStars from "../../page-landing/landingPageReviewStars";
import InstagramLoader from "../../page-landing/instagramLoader";
import ReviewComponent from "../../app-review/reviewComponent";
import LandingPageFAQ from "../../page-landing/landingPageFAQ";
import SatisfactionTransition from "../../page-landing/satisfactionTransition";

export default class DiplomaMats extends React.Component {

    constructor() {
        super();
        this.state = {
            diplomaMatType: {
                "singleMat": {
                    img: f5x7o4x6no3,
                    img2: f5x7o4x6no1,
                    default: "4x6",
                    width: 5,
                    height: 7,
                    desc: '5x7" Matboard',
                    descDetail: '5x7" ',
                    priceCAD: 7.95,
                },
                "doubleMat": {
                    img: f5x7o4x6no3,
                    img2: f5x7o4x6no1,
                    default: "4x6",
                    width: 5,
                    height: 7,
                    desc: '5x7" Matboard',
                    descDetail: '5x7" Matboards for 5x7" Frames with choice of interior dimensions',
                    priceCAD: 16.85,
                },
                "singleMatWriting": {
                    img: f5x7o4x6no3,
                    img2: f5x7o4x6no1,
                    default: "4x6",
                    width: 5,
                    height: 7,
                    desc: '5x7" Matboard',
                    descDetail: '5x7" Matboards for 5x7" Frames with choice of interior dimensions',
                    priceCAD: 7.95,
                },
                "doubleMatWithWriting": {
                    img: f5x7o4x6no3,
                    img2: f5x7o4x6no1,
                    default: "4x6",
                    width: 5,
                    height: 7,
                    desc: '5x7" Matboard',
                    descDetail: '5x7" Matboards for 5x7" Frames with choice of interior dimensions',
                    priceCAD: 7.95,
                },
            },


            orientation: "portrait",

            frameSize: {
                "singleMat": {
                    img: singleMatPlain,
                    img2: singleMatVerticalPlain,
                    default: "4x6",
                    width: 5,
                    height: 7,
                    desc: 'Single Mat',
                    descDetail: 'Single colour option with choice of your colour.',
                    descFr : 'Passe-partout simple',
                    descDetailFr : 'Option de couleur unique avec choix de votre couleur.',
                    priceCAD: 11.90,
                    href: "/single-diploma-certificate-frame-matboard",
                },
                "doubleMat": {
                    img: doubleMatPlain,
                    img2: doubleMatVerticalPlain,
                    default: "4x6",
                    width: 5,
                    height: 7,
                    desc: 'Double Mat (2 Colours)',
                    descDetail: 'Double matting option with your choice of two colours.',
                    descFr : 'Passe-partout double (2 couleurs)',
                    descDetailFr : 'Option de double passe-partout avec le choix de deux couleurs.',
                    priceCAD: 16.85,
                    href: "/double-diploma-certificate-frame-matboard",
                },
                "singleMatWriting": {
                    img: singleMatText,
                    img2: singleMatVerticalText,
                    default: "4x6",
                    width: 5,
                    height: 7,
                    desc: 'Single Mat + Text',
                    descDetail: 'Single colour option with custom text engraving.',
                    descFr : 'Passe-partout simple + Texte',
                    descDetailFr : 'Option de couleur unique avec gravure de texte personnalisé.',
                    priceCAD: 18.85,
                    href: "/single-diploma-certificate-frame-matboard-with-custom-text-print",
                },
                "doubleMatWriting": {
                    img: doubleMatText,
                    img2: doubleMatVerticalText,
                    default: "4x6",
                    width: 5,
                    height: 7,
                    desc: 'Double Mat (2 Colours) + Text',
                    descDetail: 'Double matting option with custom text engraving.',
                    descFr : 'Passe-partout double (2 couleurs) + Texte',
                    descDetailFr : 'Option de double passe-partout avec gravure de texte personnalisé.',
                    priceCAD: 23.80,
                    href: "/double-diploma-certificate-frame-matboard-with-custom-text-print",
                },
                "singleMatLogo": {
                    img: singleMatLogo,
                    img2: singleMatVerticalLogo,
                    default: "4x6",
                    width: 5,
                    height: 7,
                    desc: 'Single Mat + Text',
                    descDetail: 'Single matting option with custom logo engraving.',
                    descFr : 'Passe-partout simple + Texte',
                    descDetailFr : 'Option de passe-partout simple avec gravure de logo personnalisé.',
                    priceCAD: 21.85,
                    href: "/single-diploma-certificate-frame-matboard-with-custom-logo-print",
                },
                "doubleMatLogo": {
                    img: doubleMatLogo,
                    img2: doubleMatVerticalLogo,
                    default: "4x6",
                    width: 5,
                    height: 7,
                    desc: 'Double Mat (2 Colours) + Text',
                    descDetail: 'Double matting option with custom logo engraving.',
                    descFr : 'Passe-partout double (2 couleurs) + Texte',
                    descDetailFr : 'Option de double passe-partout avec gravure de logo personnalisé.',
                    priceCAD: 26.80,
                    href: "/double-diploma-certificate-frame-matboard-with-custom-logo-print",
                },
                "singleMatLogoWriting": {
                    img: singleMatLogoAndText,
                    img2: singleMatVerticalLogoAndText,
                    default: "4x6",
                    width: 5,
                    height: 7,
                    desc: 'Single Mat + Text',
                    descDetail: 'Single matting option with custom text & logo engraving.',
                    descFr : 'Passe-partout simple + Texte',
                    descDetailFr : 'Option de passe-partout simple avec gravure personnalisée de texte et de logo.',
                    priceCAD: 28.80,
                    href: "/single-diploma-certificate-frame-matboard-with-custom-text-and-logo-print",
                },
                "DoubleMatLogoWriting": {
                    img: doubleMatLogoAndText,
                    img2: doubleMatVerticalLogoAndText,
                    default: "4x6",
                    width: 5,
                    height: 7,
                    desc: 'Double Mat (2 Colours) + Text',
                    descDetail: 'Double matting option with custom text & logo engraving.',
                    descFr : 'Double passe-partout (2 couleurs) + Texte',
                    descDetailFr : 'Option de double passe-partout avec gravure personnalisée de texte et de logo.',
                    priceCAD: 33.75,
                    href: "/double-diploma-certificate-frame-matboard-with-custom-text-and-logo-print",
                },
                // "multipleOpening": {
                //     img: multipleOpening,
                //     img2: multipleOpening,
                //     default: "4x6",
                //     width: 5,
                //     height: 7,
                //     desc: 'Multiple Opening // Double Matting // Text & Logo',
                //     descDetail: '5x7" Matboards for 5x7" Frames with choice of interior dimensions',
                //     priceCAD: 7.95,
                //     href: "/single-mat-diploa-frame-matboard",
                // },
                // "multipleOpeningDoubleWriting": {
                //     img: multipleOpeningWithWriting,
                //     img2: multipleOpeningWithWriting,
                //     default: "4x6",
                //     width: 5,
                //     height: 7,
                //     desc: 'Multiple Opening // Double Matting // Text & Logo',
                //     descDetail: '5x7" Matboards for 5x7" Frames with choice of interior dimensions',
                //     priceCAD: 7.95,
                //     href: "/single-mat-diploa-frame-matboard",
                // },
            },
            selectedFrameSize: false,
            selectedOpeningSize: "4x6",
            selectedMatColour: "navyBlue",
            selectedQuantity: 1,
            fetchProductDetail: false,
            fetchDiscountDetail: false,
            fetchShippingInfo: false,
            ikeaFrameNames: {
                "5x7": ["Ribba", "Fiskbo", "Silverhojden", "Edsbruk", "Knoppang", "Vireserum"],
                "8x10": ["Ribba", "Fiskbo", "Himmelsby", "Edsbruk", "Knoppang", "Vireserum"],
                "9x9": ["Ribba", "Knoppang", "Sannahed", "Hovsta"],
                "12x16": ["Ribba", "Knoppang", "Lomviken", "Silverhojden", "Dalskarr", "Virserum", "Edsbruk", "Fiskbo"],
                "16x20": ["Ribba", "Knoppang", "Lomviken", "Edsburk", "Silverhojden", "Fiskbo"],
                "19.75x19.75": ["Ribba"],
                "19.75x27.5": ["Ribba", "Lomviken", "Hovsta", "Fiskbo", "Silverhojden", "Edsbruk", "Knoppang", "Ramsborg"],
                "24x35.75": ["Ribba", "Lomviken", "Hovsta", "Fiskbo", "Silverhojden", "Edsbruk", "Knoppang", "Skatteby"],
            }

        };
    };

    componentDidMount() {
        // windowaddEventListener("scroll", this.setState({showNotificationBanner: false})

        if (this.props.selectedMatSize === false) {
            var pathname = window.location.pathname;
            pathname.lastIndexOf("-inch-matboard")
            // var matboardSize = pathname.substring(
            //     // pathname.indexOf("-inch") + 1,
            //
            // );
        }

        if (this.props.selectedMatSize) {
            this.setState({selectedFrameSize: this.props.selectedMatSize})

            if (this.props.selectedOpeningSize) {
                this.setState({selectedOpeningSize: this.props.selectedOpeningSize})

            } else if (this.props.selectedMatSize === "9x9" || this.props.selectedMatSize === "19.75x19.75" || this.props.selectedMatSize === "20x20") {
                this.setState({selectedOpeningSize: "4x4"})
            }

        }



        this.setState({
            french: (window.location.pathname.slice(-2) === "fr") ? true : false
        })
    };

    fetchIKEAFrameNames() {
        var frameNames = `For IKEA frames, our ${this.state.selectedFrameSize} inch matboards are designed to fit `;

        if (this.state.ikeaFrameNames[this.state.selectedFrameSize]) {
            this.state.ikeaFrameNames[this.state.selectedFrameSize].map((frame, key) => {
                if (key > 0) {
                    frameNames = frameNames + ", " + frame;
                } else {
                    frameNames = frameNames + " " + frame;
                }
            });
            frameNames = frameNames + " frames."
            return frameNames
        }

    }

    clickAddToCartButton() {

        var selectedProductData = this.state.frameSize[this.state.selectedFrameSize];
        var frameWidth = selectedProductData.width;
        var frameHeight = selectedProductData.height;
        var windowWidth = selectedProductData.openSize[this.state.selectedOpeningSize].width;
        var windowHeight = selectedProductData.openSize[this.state.selectedOpeningSize].height;
        var price = selectedProductData.priceCAD;

        var item = {
            prodType: "precutMat",
            frameType: "Not Applicable -- Matboard Only",
            frameSize: "Not Applicable -- Matboard Only",
            frameWidth: frameWidth,
            frameHeight: frameHeight,
            matColour: this.state.selectedMatColour,
            window: {
                rowCount: 1,
                columnCount: 1,
                window1: {
                    width: windowWidth,
                    height: windowHeight,
                    marginTop: (frameHeight - windowHeight)/2,
                    marginBottom: (frameHeight - windowHeight)/2,
                    marginLeft: (frameWidth - windowWidth)/2,
                    marginRight: (frameWidth - windowWidth)/2,
                    showModal: false,
                },
            },
            secondMatPrice: 0,
            secondMatColour: "none",
            secondMatWidth: 0,
            orderQuantity: parseFloat(this.state.selectedQuantity),
            // //for matboard price, we will have to run a pricing mechanism for matboard pricing. We might borrow this from our legacy system.
            price: price,
            shipping: [9.95, 9.95],
            canvasDrawing: null,
            additionalNoteContent: "",
            backingMatboard: false,
            isWindowOval: false,
        };

        this.addProductToLocalStorageCart(item);
        this.props.showShoppingCartModal("open");

    };


    addProductToLocalStorageCart(item) {
        //check if a shopper has already shopped something on before this item
        var shoppingCart = [];

        if (localStorageFunctions("shoppingCart", null) !== null ) {
            //this part will have to be parse json

            shoppingCart = JSON.parse(localStorageFunctions("shoppingCart", null));

            shoppingCart.push(item);
        } else {
            shoppingCart.push(item);
        };

        localStorageFunctions("shoppingCart", JSON.stringify(shoppingCart));
    };

    toggleModal(stateToToggle) {
        this.setState({[stateToToggle]: !this.state[stateToToggle]})
    };

    clickPrecutModal(sortSize) {
        window.location.href = this.props.french ? `${this.state.frameSize[sortSize].href}-fr` : `${this.state.frameSize[sortSize].href}`
    };

    fetchPreCutMats(FrameOrArtworkSize) {

        var array = Object.keys(this.state.frameSize);

        return array.map((sortSize, key) => {
            return <div className={"diploma-mat-box"} onClick={() => {this.clickPrecutModal(sortSize)}}>
                <img style={{width: "100%"}} src={this.state.frameSize[sortSize].img}
                     alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                />
                <div className={"fetch-precut-mats-title-container"}>
                    <div className={"fetch-precut-mats-title"}>
                        {this.props.french ? this.state.frameSize[sortSize].descFr : this.state.frameSize[sortSize].desc}
                    </div>
                    <div className={"fetch-precut-mats-subtitle"}>
                        {this.props.currency} {this.props.fxConversion(this.state.frameSize[sortSize].priceCAD).toFixed(2)}+
                    </div>
                    <div className={"fetch-precut-mats-subtitle"}>
                        {this.props.french ? this.state.frameSize[sortSize].descDetailFr : this.state.frameSize[sortSize].descDetail}
                    </div>
                </div>
            </div>
        })
    };

    fetchWindowSizeDropdown(dropDownList, title, stateToUpdate) {
        // var list = Object.keys(productDB.taxRates[this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw]);

        var list = Object.keys(dropDownList)

        return <FormControl
            variant="outlined" className={"precut-window-selector"}>
            <div className={"add-text-fields-text-field-title"}>
                {title}
            </div>
            <Select value={this.state[stateToUpdate]}
                    onChange={(evt) => {
                        // this.setState({[stateToUpdate]: evt.target.value})
                        window.location.pathname = `${this.props.selectedMatSize}-inch-matboard-${evt.target.value}-inch-opening`
                    }}
            >
                <MenuItem value={"Select"}>
                    Select
                </MenuItem>
                {
                    list.map((item, key) => {
                        return <MenuItem value={item} key={`select-province-state-${key}`} >
                            {item}" - {dropDownList[item].desc}
                        </MenuItem>
                    })
                }
            </Select>
        </FormControl>
    };

    fetchListDropDown(dropDownList, title, stateToUpdate) {
        var list = dropDownList;

        return <FormControl
            variant="outlined" className={"precut-window-selector"}>
            <div className={"add-text-fields-text-field-title"}>
                {title}
            </div>
            <Select value={this.state[stateToUpdate]}
                    onChange={(evt) => {
                        this.setState({[stateToUpdate]: evt.target.value})
                    }}
            >
                <MenuItem value={"Select"}>
                    Select
                </MenuItem>
                {
                    list.map((item, key) => {
                        return <MenuItem value={item} key={`select-province-state-${key}`} >
                            {this.state.matboardColourOptions[item].colourName}
                        </MenuItem>
                    })
                }
            </Select>
        </FormControl>
    };

    reduceQuantity() {
        if (this.state.selectedQuantity > 1) {
            this.setState({selectedQuantity: this.state.selectedQuantity - 1})
        } else {
            alert("Order quantity cannot be less than 1.")
        }

    };

    fetchAddToBagButton() {
        return <div className="add-to-bag-button" style={{width: "auto"}} onClick={() => {this.clickAddToCartButton()}}>
            {this.props.french ? "Ajouter au sac" : "Add to bag"}
        </div>
    }

    fetchPreCutMatDetails() {

        var selectedFrame = this.state.frameSize[this.state.selectedFrameSize];

        if (this.props.french) {
            return <div className={"precut-container"}>
                <div className={"precut-sub-container"}>
                    <div className={"precut-container-left"}>

                        <Carousel>
                            <div className={"carousel-image-item"}>
                                <img src={selectedFrame.openSize[this.state.selectedOpeningSize].photos[0]} className={"precut-container-left-photo"}
                                     alt={"Diploma and certificate matboar - Navy Blue Color Size 1"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={selectedFrame.openSize[this.state.selectedOpeningSize].photos[2]} className={"precut-container-left-photo"}
                                     alt={"Diploma and certificate matboar - Navy Blue Color Size 2"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={selectedFrame.openSize[this.state.selectedOpeningSize].photos[1]} className={"precut-container-left-photo"}
                                     alt={"Diploma and certificate matboar - Navy Blue Color Size 3"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions1}
                                     alt={"Diploma and certificate matboar - Navy Blue Color Size 1"}/>

                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions2}
                                     alt={"Diploma and certificate matboar - Red Primary Mat, Gold Double Matting, Gold Writing and Logo"}/>

                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions3}
                                     alt={"Diploma and certificate matboar - Red Primary Mat, Gold Double Matting, Gold Logo"}/>

                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions4}
                                     alt={"Diploma and certificate matboar - Red Primary Mat, Gold Double Matting, Gold Writing"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions5}
                                     alt={"Diploma and certificate matboar - Navy Primary Single mat, Gold Logo and Writing"}/>

                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions6}
                                     alt={"Diploma and certificate matboar - Red Primary Mat, Gold Double Matting, Gold Logo"}/>

                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions7}
                                     alt={"Diploma and certificate matboar - Red Primary Mat, Gold Double Matting, Gold Writing"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions8}
                                     alt={"Diploma and certificate matboar - Red Primary Mat, Gold Double Matting"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions9}
                                     alt={"Diploma and certificate matboar - Red Single Mat"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions10}
                                     alt={"Diploma and certificate matboar - Red Single Mat version 2"}/>
                            </div>

                        </Carousel>

                    </div>

                    <div className={"precut-container-right"}>

                        <div style={{width: "auto", overflow: "auto", paddingBottom: "16px"}}>

                            {this.fetchAddToBagButton()}

                            <div className="select-mat-container-row" style={{width: "auto"}}>
                                Currency: <b>CAD</b><br/>
                                Unit Price: <b>$<span>{this.state.frameSize[this.state.selectedFrameSize].priceCAD}</span></b>
                            </div>
                        </div>

                        <div className={"precut-size-header"}>
                            {this.state.selectedFrameSize}" Pre-cut Matboard <br/> with {this.props.selectedOpeningSize}" opening
                        </div>


                        <div style={{width: "100%", overflow: "auto", paddingBottom: "16px"}}>
                            {this.fetchWindowSizeDropdown(this.state.frameSize[this.state.selectedFrameSize].openSize, "Window Size", "selectedOpeningSize")}
                            <div className={"select-mat-input-field-direction-long"}>
                                Your actual window opening size will be <b>{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height}"</b>.
                                The window opening should be smaller than your artwork.<br/><br/>
                                Don't have the opening you are looking for? <a href={"/customize-matboards"}>Click here to get a custom opening.</a>
                            </div>
                        </div>

                        <div style={{width: "100%", overflow: "auto", paddingBottom: "16px"}}>
                            {this.fetchListDropDown(Object.keys(this.state.matboardColourOptions), `Matboard ${this.props.location === "us" ? "Color" : "Colour"}`, "selectedMatColour")}
                            <div className={"select-mat-input-field-direction-long"}>
                                More {this.props.location === "us" ? " Color " : " Colour "} options available  <a href={"/customize-matboards"}>with custom matting option.</a>
                            </div>
                        </div>


                        <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b"}}>
                            Quantity

                        </div>

                        <div className={"cart-slider-shopped-item-quantity"} style={{margin: 0, paddingBottom: "12px", width: "100%", borderBottom: "solid 1px #828282"}}>
                            <div className={"cart-slider-shopped-item-quantity-button"} style={{marginTop: "10px"}} onClick={() => {this.setState({selectedQuantity: this.state.selectedQuantity + 1})}}>+</div>
                            <div className={"select-window-multiple-window-dimensions"}>
                                {/*{this.state.selectedQuantity}*/}
                                {displayInputFields("", this.state.selectedQuantity, (event) => this.setState({selectedQuantity: event.target.value}))}
                            </div>
                            <div className={"cart-slider-shopped-item-quantity-button"} style={{marginTop: "10px", marginLeft: "10px"}} onClick={() => {this.reduceQuantity()}}>-</div>
                        </div>

                        <div style={{width: "100%", overflow: "auto", paddingBottom: "16px", cursor: "pointer"}}>

                            <div className="select-mat-row" style={{paddingBottom: "4px", marginTop: "12px", width: "100%", borderBottom: "solid 1px #828282"}} onClick={() => this.setState({fetchDiscountDetail: !this.state.fetchDiscountDetail})}>
                                <img className="select-matboard-window-caption-arrow"
                                     style={{marginTop: "-2px", paddingTop: 0}}
                                     src={this.state.fetchDiscountDetail ? arrowUp : arrowDown} alt="Click to add a note." />
                                <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b", fontSize: "13px", overflow: "auto"}}>
                                    Quantity Discounts
                                </div>
                                <div className={this.state.fetchDiscountDetail ? "display" : "no-display"} style={{marginLeft: "40px"}}>
                                    <li>
                                        2+ items - 20% off
                                    </li>
                                    <li>
                                        4+ items - 30% off
                                    </li>
                                    <li>
                                        10+ items - 40% off
                                    </li>
                                </div>
                            </div>


                            <div className="select-mat-row" style={{paddingBottom: "4px", marginTop: "12px", width: "100%", borderBottom: "solid 1px #828282"}} onClick={() => this.setState({fetchProductDetail: !this.state.fetchProductDetail})}>
                                <img className="select-matboard-window-caption-arrow"
                                     style={{marginTop: "-2px", paddingTop: 0}}
                                     src={this.state.fetchProductDetail ? arrowUp : arrowDown} alt="Click to add a note." />
                                <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b", fontSize: "13px", overflow: "auto"}}>
                                    Product Details
                                </div>
                                <div className={this.state.fetchProductDetail ? "display" : "no-display"} style={{marginLeft: "40px" }}>
                                    <li>
                                        Matboard Size: {this.state.selectedFrameSize}
                                    </li>
                                    <li>
                                        Designed for {this.state.selectedOpeningSize} inch Prints
                                    </li>
                                    <li>
                                        Actual window cutout: {this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height} inch**<br/>
                                    </li>
                                    <li>
                                        Standard 4-ply (1/16 inch) thick
                                    </li>
                                    <li>
                                        45 degree, crisp, white-core bevel
                                    </li>
                                    <li>
                                        Acid-free (acid-neutralized) surface
                                    </li>
                                    <li>
                                        Fade and bleed resistant
                                    </li>
                                    <li>
                                        Made and shipped from Canada
                                    </li>
                                    <br/>
                                    ** Please note that our window cutout is smaller than your artwork. Without the overlap, the artwork is going to fall through the opening.
                                </div>
                            </div>


                            <div className="select-mat-row" style={{paddingBottom: "4px", marginTop: "12px", width: "100%", borderBottom: "solid 1px #828282"}} onClick={() => this.setState({fetchShippingInfo: !this.state.fetchShippingInfo})}>
                                <img className="select-matboard-window-caption-arrow"
                                     style={{marginTop: "-2px", paddingTop: 0}}
                                     src={this.state.fetchShippingInfo ? arrowUp : arrowDown} alt="Click to add a note." />
                                <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b", fontSize: "13px", overflow: "auto"}}>
                                    Shipping & Returns
                                </div>
                                <div className={this.state.fetchShippingInfo ? "display" : "no-display"} style={{marginLeft: "40px"}}>
                                    <li>
                                        Ship to Canada and the USA
                                    </li>
                                    <li>
                                        Free shipping across Canada & the USA.
                                    </li>
                                    <li>
                                        We accept returns
                                    </li>
                                    <li>
                                        Dispatches within 1 business day
                                    </li>
                                    <li>
                                        Damage-resistant packaging
                                    </li>
                                    <li>
                                        If damaged while in transit, replacements mats will be sent
                                    </li>

                                </div>
                            </div>

                        </div>

                        <div style={{width: "auto", overflow: "auto", paddingBottom: "16px"}}>
                            {this.fetchAddToBagButton()}
                            <div className="select-mat-container-row" style={{width: "auto"}}>
                                Currency: <b>CAD</b><br/>
                                Unit Price: <b>$<span>{this.state.frameSize[this.state.selectedFrameSize].priceCAD}</span></b>
                            </div>
                        </div>


                    </div>
                </div>
                <div>
                    <div className={"product-desc-dividers"}>
                        <div className={"product-desc-dividers-header"}>Product Summary:<br/><br/></div>
                        <div>
                            {this.state.selectedFrameSize} inch white core photo mat in {this.state.matboardColourOptions[this.state.selectedMatColour].colourName} colour. This photo mat is cut with a single window to fit {this.state.selectedOpeningSize} inch prints.
                            Your actual window opening size will be <b>{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height}"</b>. The window opening should be smaller than your artwork. <br/><br/>
                            There is no minimum order quantity. Free shipping across Canada & the USA. <br/><br/>
                            Quantity discounts are available for your order. Discounts will be reflected automatically as you order 2+, 4+, and 10+ orders. If you need more than 50+ sheets, please contact us for pricing. We ship to anywhere in Canada and the United States.<br/><br/>
                        </div>
                        <div className={"product-desc-dividers-header"}>
                            Product Specifications:
                        </div>
                        <div>
                            <ul>
                                <li>Overall Dimension: {this.state.selectedFrameSize} inch</li>
                                <li>Opening Dimension: {this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height} inch opening designed to fit {this.state.selectedOpeningSize} inch prints</li>
                                <li>Acid-free matboard. Acid neutralized surface, core, and backing papers for your preservation needs</li>
                                <li>4 PLY, 1/16 inch thick</li>
                                <li>All our window openings are cut with 45 degree bevels</li>
                                <li>Clean and crisp cut</li>
                                <li>Made and processed in Canada</li>
                            </ul>
                        </div>
                    </div>

                    <div className={"product-desc-dividers"}>
                        <div className={"product-desc-dividers-header"}>
                            Other Notes:<br/><br/>
                        </div>
                        <div>
                            Our {this.state.selectedFrameSize} inch matboards are designed to fit {this.state.selectedFrameSize} industry standard sized frames that are sold by Amazon, Costco, IKEA, Target, Michael’s and other retail stores. {this.fetchIKEAFrameNames()}<br/><br/>
                        </div>
                    </div>

                    <div className={"product-desc-dividers"}>
                        <div className={"product-desc-dividers-header"}>
                            100% Satisfaction Policy:<br/><br/>
                        </div>
                        <div>
                            100% Happiness Guaranteed. If you are not happy with your order, please let us know. Contact us through hi@custommat.com and we will make it right.<br/><br/>
                        </div>
                    </div>

                    <div className={"product-desc-dividers"}>
                        <div className={"product-desc-dividers-header"}>
                            Need further customization or more {this.props.location === "us" ? " Color " : " Colour "} options?<br/><br/>
                        </div>
                        <div>
                            Try our custom matboards. They are 100% customizable – your {this.props.location === "us" ? " Color " : " Colour "}, your shape, and your design. They come in 20+ {this.props.location === "us" ? " Color " : " Colour "} options.<br/><br/>
                        </div>
                    </div>


                    <div className={"product-desc-dividers"}>
                        <div className={"product-desc-dividers-header"}>
                            Any Questions?<br/><br/>
                        </div>
                        <div>
                            If you have any questions, please email us hi@CustomMat.com or text us at 604-345-8428.<br/><br/>
                        </div>
                    </div>



                </div>
            </div>
        } else {
            return <div className={"precut-container"}>
                <div className={"precut-sub-container"}>

                    <div className={"precut-container-left"}>

                        <Carousel>
                            <div className={"carousel-image-item"}>
                                <img src={selectedFrame.openSize[this.state.selectedOpeningSize].photos[0]} className={"precut-container-left-photo"}
                                     alt={"Diploma and certificate matboar - Navy Blue Color Size 1"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={selectedFrame.openSize[this.state.selectedOpeningSize].photos[2]} className={"precut-container-left-photo"}
                                     alt={"Diploma and certificate matboar - Navy Blue Color Size 2"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={selectedFrame.openSize[this.state.selectedOpeningSize].photos[1]} className={"precut-container-left-photo"}
                                     alt={"Diploma and certificate matboar - Navy Blue Color Size 3"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions1}
                                     alt={"Diploma and certificate matboar - Navy Blue Color Size 1"}/>

                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions2}
                                     alt={"Diploma and certificate matboar - Red Primary Mat, Gold Double Matting, Gold Writing and Logo"}/>

                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions3}
                                     alt={"Diploma and certificate matboar - Red Primary Mat, Gold Double Matting, Gold Logo"}/>

                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions4}
                                     alt={"Diploma and certificate matboar - Red Primary Mat, Gold Double Matting, Gold Writing"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions5}
                                     alt={"Diploma and certificate matboar - Navy Primary Single mat, Gold Logo and Writing"}/>

                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions6}
                                     alt={"Diploma and certificate matboar - Red Primary Mat, Gold Double Matting, Gold Logo"}/>

                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions7}
                                     alt={"Diploma and certificate matboar - Red Primary Mat, Gold Double Matting, Gold Writing"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions8}
                                     alt={"Diploma and certificate matboar - Red Primary Mat, Gold Double Matting"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions9}
                                     alt={"Diploma and certificate matboar - Red Single Mat"}/>
                            </div>
                            <div className={"carousel-image-item"}>
                                <img src={colourOptions10}
                                     alt={"Diploma and certificate matboar - Red Single Mat version 2"}/>
                            </div>

                        </Carousel>

                    </div>

                    <div className={"precut-container-right"}>

                        <div style={{width: "auto", overflow: "auto", paddingBottom: "16px"}}>

                            {this.fetchAddToBagButton()}

                            <div className="select-mat-container-row" style={{width: "auto"}}>
                                Currency: <b>CAD</b><br/>
                                Unit Price: <b>$<span>{this.state.frameSize[this.state.selectedFrameSize].priceCAD}</span></b>
                            </div>
                        </div>

                        <div className={"precut-size-header"}>
                            {this.state.selectedFrameSize}" Pre-cut Matboard <br/> with {this.props.selectedOpeningSize}" opening
                        </div>


                        <div style={{width: "100%", overflow: "auto", paddingBottom: "16px"}}>
                            {this.fetchWindowSizeDropdown(this.state.frameSize[this.state.selectedFrameSize].openSize, "Window Size", "selectedOpeningSize")}
                            <div className={"select-mat-input-field-direction-long"}>
                                Your actual window opening size will be <b>{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height}"</b>.
                                The window opening should be smaller than your artwork.<br/><br/>
                                Don't have the opening you are looking for? <a href={"/customize-matboards"}>Click here to get a custom opening.</a>
                            </div>
                        </div>

                        <div style={{width: "100%", overflow: "auto", paddingBottom: "16px"}}>
                            {this.fetchListDropDown(Object.keys(this.state.matboardColourOptions), `Matboard ${this.props.location === "us" ? "Color" : "Colour"}`, "selectedMatColour")}
                            <div className={"select-mat-input-field-direction-long"}>
                                More {this.props.location === "us" ? " Color " : " Colour "} options available  <a href={"/customize-matboards"}>with custom matting option.</a>
                            </div>
                        </div>


                        <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b"}}>
                            Quantity

                        </div>

                        <div className={"cart-slider-shopped-item-quantity"} style={{margin: 0, paddingBottom: "12px", width: "100%", borderBottom: "solid 1px #828282"}}>
                            <div className={"cart-slider-shopped-item-quantity-button"} style={{marginTop: "10px"}} onClick={() => {this.setState({selectedQuantity: this.state.selectedQuantity + 1})}}>+</div>
                            <div className={"select-window-multiple-window-dimensions"}>
                                {/*{this.state.selectedQuantity}*/}
                                {displayInputFields("", this.state.selectedQuantity, (event) => this.setState({selectedQuantity: event.target.value}))}
                            </div>
                            <div className={"cart-slider-shopped-item-quantity-button"} style={{marginTop: "10px", marginLeft: "10px"}} onClick={() => {this.reduceQuantity()}}>-</div>
                        </div>

                        <div style={{width: "100%", overflow: "auto", paddingBottom: "16px", cursor: "pointer"}}>

                            <div className="select-mat-row" style={{paddingBottom: "4px", marginTop: "12px", width: "100%", borderBottom: "solid 1px #828282"}} onClick={() => this.setState({fetchDiscountDetail: !this.state.fetchDiscountDetail})}>
                                <img className="select-matboard-window-caption-arrow"
                                     style={{marginTop: "-2px", paddingTop: 0}}
                                     src={this.state.fetchDiscountDetail ? arrowUp : arrowDown} alt="Click to add a note." />
                                <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b", fontSize: "13px", overflow: "auto"}}>
                                    Quantity Discounts
                                </div>
                                <div className={this.state.fetchDiscountDetail ? "display" : "no-display"} style={{marginLeft: "40px"}}>
                                    <li>
                                        2+ items - 20% off
                                    </li>
                                    <li>
                                        4+ items - 30% off
                                    </li>
                                    <li>
                                        10+ items - 40% off
                                    </li>
                                </div>
                            </div>


                            <div className="select-mat-row" style={{paddingBottom: "4px", marginTop: "12px", width: "100%", borderBottom: "solid 1px #828282"}} onClick={() => this.setState({fetchProductDetail: !this.state.fetchProductDetail})}>
                                <img className="select-matboard-window-caption-arrow"
                                     style={{marginTop: "-2px", paddingTop: 0}}
                                     src={this.state.fetchProductDetail ? arrowUp : arrowDown} alt="Click to add a note." />
                                <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b", fontSize: "13px", overflow: "auto"}}>
                                    Product Details
                                </div>
                                <div className={this.state.fetchProductDetail ? "display" : "no-display"} style={{marginLeft: "40px" }}>
                                    <li>
                                        Matboard Size: {this.state.selectedFrameSize}
                                    </li>
                                    <li>
                                        Designed for {this.state.selectedOpeningSize} inch Prints
                                    </li>
                                    <li>
                                        Actual window cutout: {this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height} inch**<br/>
                                    </li>
                                    <li>
                                        Standard 4-ply (1/16 inch) thick
                                    </li>
                                    <li>
                                        45 degree, crisp, white-core bevel
                                    </li>
                                    <li>
                                        Acid-free (acid-neutralized) surface
                                    </li>
                                    <li>
                                        Fade and bleed resistant
                                    </li>
                                    <li>
                                        Made and shipped from Canada
                                    </li>
                                    <br/>
                                    ** Please note that our window cutout is smaller than your artwork. Without the overlap, the artwork is going to fall through the opening.
                                </div>
                            </div>


                            <div className="select-mat-row" style={{paddingBottom: "4px", marginTop: "12px", width: "100%", borderBottom: "solid 1px #828282"}} onClick={() => this.setState({fetchShippingInfo: !this.state.fetchShippingInfo})}>
                                <img className="select-matboard-window-caption-arrow"
                                     style={{marginTop: "-2px", paddingTop: 0}}
                                     src={this.state.fetchShippingInfo ? arrowUp : arrowDown} alt="Click to add a note." />
                                <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b", fontSize: "13px", overflow: "auto"}}>
                                    Shipping & Returns
                                </div>
                                <div className={this.state.fetchShippingInfo ? "display" : "no-display"} style={{marginLeft: "40px"}}>
                                    <li>
                                        Ship to Canada and the USA
                                    </li>
                                    <li>
                                        Free shipping across Canada & the USA.
                                    </li>
                                    <li>
                                        We accept returns
                                    </li>
                                    <li>
                                        Dispatches within 1 business day
                                    </li>
                                    <li>
                                        Damage-resistant packaging
                                    </li>
                                    <li>
                                        If damaged while in transit, replacements mats will be sent
                                    </li>

                                </div>
                            </div>

                        </div>

                        <div style={{width: "auto", overflow: "auto", paddingBottom: "16px"}}>
                            {this.fetchAddToBagButton()}
                            <div className="select-mat-container-row" style={{width: "auto"}}>
                                Currency: <b>CAD</b><br/>
                                Unit Price: <b>$<span>{this.state.frameSize[this.state.selectedFrameSize].priceCAD}</span></b>
                            </div>
                        </div>


                    </div>
                </div>
                <div>
                    <div className={"product-desc-dividers"}>
                        <div className={"product-desc-dividers-header"}>Product Summary:<br/><br/></div>
                        <div>
                            {this.state.selectedFrameSize} inch white core photo mat in {this.state.matboardColourOptions[this.state.selectedMatColour].colourName} colour. This photo mat is cut with a single window to fit {this.state.selectedOpeningSize} inch prints.
                            Your actual window opening size will be <b>{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height}"</b>. The window opening should be smaller than your artwork. <br/><br/>
                            There is no minimum order quantity. Free shipping across Canada & the USA.<br/><br/>
                            Quantity discounts are available for your order. Discounts will be reflected automatically as you order 2+, 4+, and 10+ orders. If you need more than 50+ sheets, please contact us for pricing. We ship to anywhere in Canada and the United States.<br/><br/>
                        </div>
                        <div className={"product-desc-dividers-header"}>
                            Product Specifications:
                        </div>
                        <div>
                            <ul>
                                <li>Overall Dimension: {this.state.selectedFrameSize} inch</li>
                                <li>Opening Dimension: {this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height} inch opening designed to fit {this.state.selectedOpeningSize} inch prints</li>
                                <li>Acid-free matboard. Acid neutralized surface, core, and backing papers for your preservation needs</li>
                                <li>4 PLY, 1/16 inch thick</li>
                                <li>All our window openings are cut with 45 degree bevels</li>
                                <li>Clean and crisp cut</li>
                                <li>Made and processed in Canada</li>
                            </ul>
                        </div>
                    </div>

                    <div className={"product-desc-dividers"}>
                        <div className={"product-desc-dividers-header"}>
                            Other Notes:<br/><br/>
                        </div>
                        <div>
                            Our {this.state.selectedFrameSize} inch matboards are designed to fit {this.state.selectedFrameSize} industry standard sized frames that are sold by Amazon, Costco, IKEA, Target, Michael’s and other retail stores. {this.fetchIKEAFrameNames()}<br/><br/>
                        </div>
                    </div>

                    <div className={"product-desc-dividers"}>
                        <div className={"product-desc-dividers-header"}>
                            100% Satisfaction Policy:<br/><br/>
                        </div>
                        <div>
                            100% Happiness Guaranteed. If you are not happy with your order, please let us know. Contact us through hi@custommat.com and we will make it right.<br/><br/>
                        </div>
                    </div>

                    <div className={"product-desc-dividers"}>
                        <div className={"product-desc-dividers-header"}>
                            Need further customization or more {this.props.location === "us" ? " Color " : " Colour "} options?<br/><br/>
                        </div>
                        <div>
                            Try our custom matboards. They are 100% customizable – your {this.props.location === "us" ? " Color " : " Colour "}, your shape, and your design. They come in 20+ {this.props.location === "us" ? " Color " : " Colour "} options.<br/><br/>
                        </div>
                    </div>


                    <div className={"product-desc-dividers"}>
                        <div className={"product-desc-dividers-header"}>
                            Any Questions?<br/><br/>
                        </div>
                        <div>
                            If you have any questions, please email us hi@CustomMat.com or text us at 604-345-8428.<br/><br/>
                        </div>
                    </div>



                </div>
            </div>
        }
    };

    render() {
        return <div>
            <Helmet>
                <title>
                    {this.props.french ? `Passe-partout pour Certificats et Diplômes Personnalisés | Alternative Abordable à l'Encadrement` : `Custom Certificate & Diploma Matboards | Affordable Framing Alternative`}
                </title>
                <meta name="description" content={this.props.french ? `Célébrez vos réalisations avec des passe-partout personnalisés pour certificats et diplômes. Options 100% personnalisables : couleur, mise en page, logo et gravure de texte à partir de 7,95 $. Double passe-partout disponible.` : `Celebrate your achievements with custom certificate & diploma matboards. 100% customizable options: color, layout, logo, and text engraving starting at $7.95. Double matting available.`} />
            </Helmet>

            <div className={`precut-mat-container cert-mat-container`}>

                <div className={this.state.selectedFrameSize ? "no-display" : "display what-is-mat-page-v2"}>

                    <div className={"landing-page-standard-left-v2"}>
                        <div className={"what-is-mat-header-v2"}>
                            {this.props.french ? "PASSE-PARTOUTS POUR CERTIFICATS ET DIPLÔMES" : "CERTIFICATE & DIPLOMA MATBOARDS"}
                        </div>
                        <div className={"what-is-mat-desc-v2"}>
                            <i>
                                {this.props.french ? "Célébrez vos réalisations avec" : "Celebrate your accomplishments with"}<br className={"mobile-no-display"}/>
                                {this.props.french ? "notre alternative économique à l'encadrement sur mesure.\n" : "our budget friendly alternative to custom framing."}
                            </i>
                        </div>
                        <div className="what-is-mat-line-break-v2">—</div>
                        <div className="what-is-mat-desc-v2">
                            {this.props.french ? "Passe-partout 100% sur mesure à partir de 7,95$" : "100% custom cut matboards starting at $7.95."}
                        </div>
                    </div>

                    <div className={"landing-page-standard-right-v2"}>
                        <LazyLoad>
                            <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                                   src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                        </LazyLoad>
                    </div>


                    <div className={"inspiration-listing-header"} style={{width: "100%", overflow: "auto"}}>
                        {this.props.french ? "PASSE-PARTOUT PERSONNALISÉ + " : "Custom Matboard + "}
                         <br/>
                        {this.props.french ? "N'IMPORTE QUEL CADRE PHOTO = CADRE PERSONNALISÉ" : "Any Picture Frame = Custom Frame"}
                    </div>
                    <div className={"inspiration-listing-desc"}>
                        {this.props.french ? "PASSE-PARTOUTS POUR CERTIFICATS ET DIPLÔMES 100 % PERSONNALISABLES." : "100% Customizable certificate & diploma matboards."}
                        <br className={"mobile-no-display"}/>
                        {this.props.french ? `CHOIX DE VOTRE COULEUR, MISE EN PAGE, LOGO & GRAVURE DE TEXTE.` : `Choice of your ${this.props.location === "us" ? " Color " : " Colour "}, layout, logo & text engraving.`}

                        <br className={"mobile-no-display"}/>
                        {this.props.french ? "À PARTIR DE 7,95 $ | LIVRÉ CHEZ VOUS AU CANADA ET AUX ÉTATS-UNIS." : "Starting at $7.95 | Deliver to your door in Canada & the USA."}
                    </div>

                    <div style={{width: "100%", overflow: "auto", margin: "32px 0"}}>
                        {this.state.selectedFrameSize ? null : this.fetchPreCutMats()}
                    </div>
                </div>

                <div className={this.state.selectedFrameSize ? "display" : "no-display"}>
                    {this.state.selectedFrameSize ? this.fetchPreCutMatDetails() : null}
                </div>

            </div>

            <LandingPageReviewStars/>

            <InstagramLoader
                french={this.state.french}
            />

            <ReviewComponent
                french={this.state.french}
            />

            <SatisfactionTransition
                french={this.state.french}
            />


            <LandingPageFAQ
                location = {this.props.location}
            />


        </div>
    }
}