import React from 'react';
import photo1 from "../../../resources/images/blog/frameMat/frame-mat-photo 1.PNG"

import photo2 from "../../../resources/images/blog/matboard/Capture.PNG";
import photo3 from "../../../resources/images/blog/photoset2/photo1.jpg";
import photo4 from "../../../resources/images/blog/photoset2/photo8.png";
import photo5 from "../../../resources/images/blog/photoset2/photo9.jpg";
import photo6 from "../../../resources/images/blog/photoSet1/IMG_4253.JPG";
import photo7 from "../../../resources/images/blog/ikeaSeries/oversized-mat-photo-frame-3.jpg";
import photo8 from "../../../resources/images/blog/photoset2/photo1.jpg";
import photo9 from "../../../resources/images/blog/vintageFrames/IMG_5124.JPG";
import photo10 from "../../../resources/images/blog/diploma/Background Photo.png";
import photo11 from "../../../resources/images/blog/jerseys/3.jpeg";



import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";

export default class FrameMat extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>How to get my artwork framed perfectly in budget?</title>
                <meta name="description" content="You can order your standard sized frames from your favorite retail stores or Amazon. You can order your customized matboards from us and get them delivered to your door. It’s much easier than you think. :)"/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                How to get my artwork <br className={"mobile-no-display tablet-no-display"}/> framed perfectly in budget?
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Picture Frame Mats
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Felix H<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-content-photo"}/>
            </LazyLoad>


            <div className={"blog-article-content"}>
                <b>How to get my artwork framed perfectly in budget?</b>

                <br/><br/>

                How to find the right size of the picture frame? How do we frame your artwork in a budget? We can always bring our artwork to a custom framing shop over $200 dollars.
                <br/><br/>

                <b>Here are 3 simple steps.</b>

                <ol>
                    <li>Get a standard sized picture frame from Amazon or a retail store*.</li>
                    <li>Get our custom matboard to bridge the gap between your artwork and your frame.</li>
                    <li>Put them together — it’s a rather simple DIY project!</li>
                </ol>

                <br/>

                **Amazon, Michael’s, Hobby Lobby, Walmart, Costco, HomeSense, and Target carry a lot of standard sized frames.

                <br/>

                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <br/><br/>

                That’s why we started our business. As an industry expert, we have been thinking about way to reduce your framing bills. And we came up with ways to frame your artwork less than $50 per frame.

                <br/><br/>

                That’s what we do. You can order your standard sized frames from Amazon or retail stores and get your custom matboards from us. We get the matboards delivered to your door. It’s much easier than you think.

                <br/><br/>

                This way, you get to frame your artwork perfectly at a fraction of custom picture framing store’s price. Here are some looks you can go for

                <br/>

                <b>Let's get into detail.</b>

                <br/><br/>

                How to find the right size of the picture frame and matboard that fits your artwork in a budget?
                We can always bring our artwork to a custom framing shop for a fortune, but those costs at least $300 dollars every time.

                <br/><br/>

                That’s why we started our business. As an industry expert, we have been thinking about way to reduce your framing bills.
                And we came up with ways to frame your artwork less than $50 per frame.

                <br/><br/>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className="blog-article-photo-description">
                    L & R: Examples of bottom weighted gallery style matting | Center: Grid Style Matting<br/>
                    These are photos from our actual customers.
                </div>

                <br/>

                That’s what we do. You can order your standard sized frames from your favorite retail stores or Amazon. You can order your customized matboards from us and get them delivered to your door. It’s much easier than you think. :)

                <br/><br/>

                This way, you get to frame your artwork at a fraction of picture framing store’s prices. If you want to do the gallery wall art, cost saving would be exponential with our customized picture frame mats.

                <br/><br/>

                <b>Here are some of the looks you can go for:</b>


                <ol>
                    <li>Single & Double Piece Framing</li>
                    <li>Off-Centre Matting Gallery Wall</li>
                    <li>Grid Style Gallery Wall</li>
                    <li>Mix & Match Gallery Wall</li>
                    <li>Diploma & Certificate Framing</li>
                    <li>Sports & Jersey Framing</li>
                </ol>

                Let's take a look.

                <br/><br/>

                <b>1. Single & Double Piece Framing</b>

                <br/><br/>

                <LazyLoad>
                    <img src={photo6} className={"blog-content-photo"}/>
                </LazyLoad>

                <a href={"/save-huge-on-photo-frames-with-passe-partout-photo-mats"}>Click here to learn more about Single & Double Piece Framing</a>

                <br/><br/>

                <b>2. Off-Centre Matting Gallery Wall</b>

                <br/><br/>

                <LazyLoad>
                    <img src={photo7} className={"blog-content-photo"}/>
                </LazyLoad>

                <a href={"https://somethingturquoise.com/2020/05/20/diy-oversized-mat-gallery-wall-frames/"}>Click here to learn more about Off-Centre Matting Gallery Wall</a>

                <br/><br/>

                <b>3. Grid Style Gallery Wall</b>

                <br/><br/>

                <LazyLoad>
                    <LazyLoad>
                        <img src={photo8} className={"blog-content-photo"}/>
                    </LazyLoad>
                </LazyLoad>

                <a href={"/creating-home-office"}>Click here to learn more about Grid Style Gallery Wall</a>

                <br/><br/>

                <b>4. Mix & Match Gallery Wall</b>

                <br/><br/>

                <LazyLoad>
                    <LazyLoad>
                        <img src={photo9} className={"blog-content-photo"}/>
                    </LazyLoad>
                </LazyLoad>

                <a href={"/gallery-wall-with-repurposed-gallery-wall-frames"}>Click here to learn more about Mix & Match Gallery Wall</a>

                <br/><br/>

                <b>5. Diploma & Certificate Framing</b>

                <br/><br/>

                    <LazyLoad>
                        <img src={photo10} className={"blog-content-photo"}/>
                    </LazyLoad>

                    <a href={"/diploma-frame-matboard-and-more"}>Click here to learn more about Diploma & Certificate Framing</a>


                    <br/><br/>

                <b>6. Sports & Jersey Framing</b>

                <br/><br/>

                <LazyLoad>
                    <LazyLoad>
                        <img src={photo11} className={"blog-content-photo"}/>
                    </LazyLoad>
                </LazyLoad>

                <a href={"/jersey-sports-shadow-box-memorabilia-mattinge"}>Click here to learn more about Sports & Jersey Framing</a>

                <br/><br/>

                <LazyLoad>
                    <img src={photo2} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className="blog-article-photo-description">Our matboards with 45 degree bevel cuts </div>

                <br/><br/>

                All our acid-free (acid-neutralized acid-free mat board), high quality, 4-ply, white-core custom matboards come in 20+ mat coloring options. We do have single mat and double mat options. For your double matting options, we can adhere or not adhere top mats and the bottom mats.

                <br/><br/>

                It’s not just custom matboards that we do. We also do photo frame matboard artist presentation kits, uncut matboards, foamcore foamboards, black core matboards, foam core backing boards, rag mats, archival mats, backing boards, rag mats, acrylic glazing, and additional art supplies needed for your picture frames.

                <br/><br/>

                By bypassing the middlemen, with our direct factory to customer business model, we source directly from industry leading suppliers, including Canadian Peterboro matboards, American Crescent matboards, and British Larson Juhl matboards.

                <br/><br/>

                We also do have other smaller suppliers — so if you are looking for your other favourite suppliers, please contact us via email.

                <br/><br/>

                CustomMat runs with 100% Happiness Guaranteed Policy – if you are not happy with your order, let us know and we will make it right.

                <br/><br/>






                If you have any questions, please email us at hi@custommat.com


                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>


            </div>

            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>

        </div>
    }
}