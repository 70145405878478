import React from 'react';
import {productDB} from "../../comp-pricing/productDB";
import Tooltip from "@material-ui/core/Tooltip";
import displayInputFields from "./displayInputFields";
import Select from "@material-ui/core/Select";
import warningIcon from "../../../../resources/svgIcons/warning-icon.svg";
import ideaIcon from "../../../../resources/svgIcons/lightbulb-regular.svg";

export default class SecondaryMat extends React.Component {

  constructor() {
    super();
    this.state = {
      openSecondMattingModal: false,
      userSelectedFractionalValue: "b",
      userSelectedStandardValue: "b",
      doubleMattingWidthCm: 0.7,
    }
  }

  doubleMattingCmConversion(value) {
    this.setState({doubleMattingWidthCm: value})
    this.props.parentSetStateCallBack("secondMatWidth", (value/2.54).toFixed(2));
  }

  showSelectedSecondMatColourPicture() {
    return <picture>
      <source type="image/webp" srcSet={productDB.matboards[this.props.secondMatColour] ? productDB.matboards[this.props.secondMatColour].webp : ""}/>
      <source type="image/png" srcSet={productDB.matboards[this.props.secondMatColour] ? productDB.matboards[this.props.secondMatColour].img : ""}/>
      <Tooltip title={productDB.matboards[this.props.secondMatColour] ? productDB.matboards[this.props.secondMatColour].color : ""} placement={"top"}>
        <img alt={"Click to add window."} className={"matboard-selected-colour"} src={productDB.matboards[this.props.secondMatColour] ? productDB.matboards[this.props.secondMatColour].webp : ""} />
      </Tooltip>
    </picture>
  }

  fetchUnitConversionDirection(fraction) {
    return <div className="select-mat-input-field-direction-long">
      {this.props.french ? `Vos dimensions seront converties avec une partie apparente de ${fraction} pouce à la caisse.` :
          `Your dimensions will be converted to ${fraction} inch reveal at checkout.`}
    </div>
  }

  activateOrDeactivateSecondMatting() {
    if (this.props.secondMatWidth > 0) {
      this.props.parentSetStateCallBack("secondMatWidth", 0); this.props.parentSetStateCallBack("secondMatColour", "none")
    } else {
      this.props.parentSetStateCallBack("secondMatWidth", 0.3); this.props.parentSetStateCallBack("secondMatColour", "smoothBlack")
    }
  }

  fetchSelectedMatColor() {
    if (productDB.matboards[this.props.matColour]) {
      return this.props.french ? productDB.matboards[this.props.matColour].colorFr : productDB.matboards[this.props.matColour].color;
    } else {
      return "";
    }
  }

  fetchSecondMatColourDesc() {
    if (productDB.matboards[this.props.secondMatColour]) {
      return `${this.props.french ? productDB.matboards[this.props.secondMatColour].colorFr: productDB.matboards[this.props.secondMatColour].color}: ${this.props.french ? productDB.matboards[this.props.secondMatColour].descFr: productDB.matboards[this.props.secondMatColour].desc}`

    }
  }

  showSelectedSecondMatColour() {
    if (productDB.matboards[this.props.secondMatColour]) {
      return this.props.french ? productDB.matboards[this.props.secondMatColour].colorFr : productDB.matboards[this.props.secondMatColour].color;
    } else {
      return "";
    }

  }

  render() {

    return <div className={`select-mat-section-frame-buttons`}  style={{"borderTop": "none"}}>

      <div className={"select-mat-row"} id={"select-mat-row-mobile-margin-left-2"}>

        <div className={"select-matboard-window-caption-arrow add-margin-buffer-5px select-box-outside"}
             onClick={() => this.setState({openSecondMattingModal: !this.state.openSecondMattingModal}, () => {this.activateOrDeactivateSecondMatting()})}>
          <div className={this.props.secondMatWidth > 0 ? "box-selected" : "box-unselected"}></div>
        </div>

        <div className={"select-mat-row-divider"} id={"double-mat-mobile-float-right"}>
          <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"select-matboard-window-caption-text-double-matting"}
               onClick={() => this.setState({openSecondMattingModal: !this.state.openSecondMattingModal}, () => {this.activateOrDeactivateSecondMatting()})}>
            <span>
              {this.props.fetchLanguage("Passe-partout double", "Double Matting")}
            </span>
            <span> </span>
            <div className={this.state.openSecondMattingModal ? "display second-mat-price" : "mobile-display-only double-mat-price-indicator-mobile"} >+${(this.props.secondMatPrice).toFixed(2)}</div>
          </div>

          <div className="select-matboard-window-caption-selected-colour-text" id={"double-mat-mobile-float-right-price"}
               onClick={() => this.setState({openSecondMattingModal: !this.state.openSecondMattingModal}, () => {this.activateOrDeactivateSecondMatting()})}
          >
            <div className={"matboard-colour-name matboard-colour-name-desc"} id={"double-mat-mobile-float-right-price-2"}>
              {(this.props.secondMatColour !== "none" && this.props.secondMatColour !== "not selected") ? this.showSelectedSecondMatColour() : null}
              <span className={this.state.openSecondMattingModal ? "no-display" : "mobile-no-display"}> +${this.props.secondMatPrice.toFixed(2)}</span>
            </div>
            {(this.props.secondMatColour !== "none" && this.props.secondMatColour !== "not selected") ? this.showSelectedSecondMatColourPicture() : null}
          </div>

            <div style={{width: "100%", overflow: "auto"}}
                 onClick={() => this.setState({openSecondMattingModal: !this.state.openSecondMattingModal}, () => {this.activateOrDeactivateSecondMatting()})}
            >
                <img className={"select-mat-guidance-icon mobile-no-display"} src={ideaIcon}
                     alt={this.props.french ? "Veuillez consulter le guide pour plus de détails sur les couleurs" : `Please see guide for ${this.props.location === "us" ? " color " : " colour "} details.`}
                />
                <div className={"select-mat-guidance-desc-long"}>
                  <div className={this.state.openSecondMattingModal ? "no-display" : "display"}>
                    <b>{this.props.fetchLanguage(`Ajoutez un passe-partout pour faire ressortir votre œuvre d'art. Cela ne coûtera que ${this.props.secondMatPrice.toFixed(2)}$.`, `Add an accent mat to make your artwork stand out. It will look much nicer for only $${this.props.secondMatPrice.toFixed(2)}.`)}</b>
                  </div>
                  <div className={this.state.openSecondMattingModal ? "display" : "no-display"}>
                    {this.props.french ? "Il y a deux façons de faire ça:" : "There are two ways to do this:"}<br/>
                    {this.props.french ? "1. Utilisez une couleur différente pour faire ressortir l'illustration." : `1. Use a different ${this.props.location === "us" ? " color " : " colour "} to make the artwork stand out.`}<br/>
                    {this.props.french ? `2. Utilisez une couleur similaire pour créer un léger accent sans trop de distraction.` : `2. Use a similar ${this.props.location === "us" ? " color " : " colour "} to create slight emphasis without too much distraction.`}<br/>
                  </div>
                </div>
            </div>

        </div>

      </div>

      <div className={"select-mat-sub-row"}>

        <div className={this.state.openSecondMattingModal ? "select-mat-size-option-toggle" : "no-display"} id={"choose-double-matting-option"}>
          <div className={"show-secondary-mat-colour-container"}>
            {this.props.fetchMatboardColours("secondMatColour")}
          </div>

          <div className="select-mat-input-field-direction-long" id={"colour-description-container"}>
            {this.fetchSecondMatColourDesc()}
          </div>

          <div className={"select-mat-sub-row"}>

            <div
                className={this.props.doubleMattingValueType === "decimals" ? "value-type-selector value-type-selected" : "value-type-selector"}
                id={"matsize-decimals"}
                onClick={() => {this.props.parentSetStateCallBack("doubleMattingValueType", "decimals")}}
            >
              {this.props.fetchLanguage("Décimales", "Decimals (in)")}
            </div>
            <div className={"value-type-selector"}> | </div>
            <div
                className={this.props.doubleMattingValueType === "fractions" ? "value-type-selector value-type-selected" : "value-type-selector"}
                id={"matsize-fractions"}
                onClick={() => {this.props.parentSetStateCallBack("doubleMattingValueType", "fractions")}}
            >
              {this.props.fetchLanguage("Fractions", "Fractions (in)")}
            </div>
            <div className={"value-type-selector"}> | </div>
            <div
                className={this.props.doubleMattingValueType === "standard" ? "value-type-selector value-type-selected" : "value-type-selector"}
                id={"matsize-fractions"}
                onClick={() => {this.props.parentSetStateCallBack("doubleMattingValueType", "standard")}}
            >
              {this.props.fetchLanguage("Tailles Populaires", "Popular Sizes (in)")}
            </div>
            <div className={"value-type-selector"}> | </div>
            <div
                className={this.props.doubleMattingValueType === "centimeters" ? "value-type-selector value-type-selected" : "value-type-selector"}
                id={"matsize-fractions"}
                onClick={() => {this.props.parentSetStateCallBack("doubleMattingValueType", "centimeters")}}
            >
              Centimeters (cm)
            </div>
          </div>

          <div className={this.state.openSecondMattingModal ? "select-mat-product-order-quantity" : "no-display"}>


            <div className={this.props.doubleMattingValueType === "decimals" ? "display" : "no-display"}>
              <div className={"select-mat-input-field-direction"} >
                {this.props.fetchLanguage("portion apparente (largeur du passe-partout)", "reveal (matting width)")}
              </div>
              {displayInputFields("", this.props.secondMatWidth, (event) => this.props.parentSetStateCallBack("secondMatWidth", event.target.value), "outlined")}
            </div>

            <div className={this.props.doubleMattingValueType === "centimeters" ? "display" : "no-display"}>
              <div className={"select-mat-input-field-direction"} >
                {this.props.fetchLanguage("portion apparente (largeur du passe-partout)", "reveal (matting width) in cms")}
              </div>
              {displayInputFields("", this.state.doubleMattingWidthCm, (event) => this.doubleMattingCmConversion(event.target.value), "outlined")}
              <div className="select-mat-input-field-direction-long">
                <br/><br/>
                At checkout, dimensions are shown in <u>inches.</u><br/>
                <b>
                  Double Matting reveal of {this.state.doubleMattingWidthCm} cm is equivalent to {this.props.secondMatWidth} inch at checkout.
                </b>
              </div>
            </div>

            <div className={this.props.doubleMattingValueType === "fractions" ? "display" : "no-display"}>

              <div className="matboard-size-popular-values-input">
                <div className={"select-mat-input-field-direction"} >
                  {this.props.fetchLanguage("portion apparente (largeur du passe-partout)", "reveal (matting width)")}
                </div>
                <Select
                    native
                    className={"matboard-size-popular-values-input"}
                    value={this.state.userSelectedFractionalValue}
                    onChange={(event) => {this.props.parentSetStateCallBack("secondMatWidth", this.props.doubleMattingFractionalObject[event.target.value].decimalValue); this.setState({userSelectedFractionalValue: event.target.value})}}
                    variant={"outlined"}
                >
                  {
                    Object.keys(this.props.doubleMattingFractionalObject).map((matSize, key) => {
                      return <option value={matSize} key={`select-province-state-${key}`} >
                        {this.props.doubleMattingFractionalObject[matSize].desc}
                      </option >
                    })
                  }
                </Select>
              </div>

              {this.fetchUnitConversionDirection(this.props.secondMatWidth)}
            </div>





            <div className={this.props.doubleMattingValueType === "standard" ? "display" : "no-display"}>

              <div className="matboard-size-popular-values-input">
                <div className={"select-mat-input-field-direction"} >
                  {this.props.fetchLanguage("portion apparente (largeur du passe-partout)", "reveal (matting width)")}
                </div>
                <Select
                    native
                    className={"matboard-size-popular-values-input"}
                    value={this.state.userSelectedStandardValue}
                    onChange={(event) => {this.props.parentSetStateCallBack("secondMatWidth", this.props.doubleMattingPopularObject[event.target.value].decimalValue); this.setState({userSelectedStandardValue: event.target.value})}}
                    variant={"outlined"}
                >
                  {
                    Object.keys(this.props.doubleMattingPopularObject).map((matSize, key) => {
                      return <option value={matSize} key={`select-province-state-${key}`} >
                        {this.props.doubleMattingPopularObject[matSize].desc}
                      </option >
                    })
                  }
                </Select>
              </div>
              {this.fetchUnitConversionDirection(this.props.secondMatWidth)}
            </div>



          </div>
        </div>

      </div>

    </div>
  }
}