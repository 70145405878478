import React from 'react';
import photo1 from "../../../resources/images/blog/frameMat/matCutter.PNG"
import photo2 from "../../../resources/images/blog/frameMat/loganMatCutter.jpg";
import photo3 from "../../../resources/images/blog/frameMat/Luna.webp";
import photo4 from "../../../resources/images/blog/matboard/Capture.PNG";
import photo5 from "../../../resources/images/blog/photoset2/photo9.jpg";



import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";
import backgorundPhoto from "../../../resources/images/blog/oversizedMatRamsborgFrames/IMG_3419.JPG";
import photo11 from "../../../resources/images/blog/jerseys/11.jpg";

export default class MatboardCutter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>Looking for a Matboard Cutter?</title>
                <meta name="description" content="We are one of Canada’s biggest art suppliers specializing in matboards and picture framing supplies. We specialize in picture frame matboards."/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Looking for a Matboard Cutter?
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Matboard 101
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Felix H<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-content-photo"}/>
            </LazyLoad>


            <div className={"blog-article-content"}>

                It may seem attractive to purchase a handheld matboard cutter — for example the ever-popular Logan handheld mat cutter. However, do not underestimate the precision required with level, bevel, and overall, a perfectly cantered piece.

                Professional pieces deserve professional elevation. Your average scrapbooker or hobby artist may see the convenience in this tiny stationary tool, however, when considering material cost vs chance of the error it may be safer to trust experts.

                <br/><br/>


                <LazyLoad>
                    <img src={photo2} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className="blog-article-photo-description">
                    Logan Matboard Cutter -- Elite Mat Cutter cost around $300 - $400 on Amazon
                </div>

                <br/><br/>

                A single matboard from an art supply store normally hovers around $20 dollars per sheet — which exceeds the price of a single professionally cut matboard.
                Professional mat cutter facilities, like us, have the orders that they can scale and order inventory at a manufacturer’s prices.
                We bring down the prices compared to the self-sourced uncut retail matboards.
                In addition to that, getting the colours that you want is a bonus with ordering custom matboards from us.

                <br/><br/>

                Here at Custom Mat, our industrial, computerized mat cutters cut bevels at a high precision.
                All our motorized guide rails are tuned to cut the matboards at a such a high precision down to a millimeter.
                The laser precision of the matboard cutting software and the machines cannot be compared to the home-use matboard cutters.

                <br/><br/>

                <LazyLoad>
                    <iframe width="100%" height={"400px"}
                            src="https://www.youtube.com/embed/Kb59DNjhV24?controls=0&amp;start=125"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </LazyLoad>

                <br/><br/>

                The precision of our bevel cutter makes precise straight cuts. Our blade depths are tuned to the exact depth of the matboard so that there will be zero bleeding bevel edges. At your request, we can get the v-groove cuts with our precise cuts.

                <br/><br/>

                <LazyLoad>
                    <img src={photo3} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className="blog-article-photo-description">
                    Example of our Circle Mat -- Photo Coutesy of CustomMat.ca Customer Luna
                </div>

                <br/><br/>

                Circle mats are possible as well. Leave your DIY mat cutting to us, and focus on your artwork and presentation. If you are looking to order our matboard at a high volume, please check out our quantity discount. Once you update your quantity, at the checkout, your 20 - 60% quantity discount will be applied automatically.

                <br/><br/>


                <LazyLoad>
                    <img src={photo4} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className="blog-article-photo-description">
                    45 Degree Bevel Examples -- Photo Credit: Custom Mat
                </div>

                <br/><br/>

                Our high-quality, acid-free, 4ply standard matboards come in 20+ colours. Our heavy duty mat cutting systems create 45 degree bevel cuts by default settings, but it can be changed to anywhere between 45 to 90 degrees. Our system has checks to ensure the straight edge and eliminated the common overcuts. Through our experience of cutting mats that amount to hundreds of thousands, our framers have mastered the art of mat cutting.

                <br/><br/>

                Looking for more than matboard cutting? We also supply other picture framing supplies, including artist presentation kits, foamboards, self-healing cutting boards, baseboards, and acrylics.

                <br/><br/>

                <LazyLoad>
                    <img src={photo5} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className="blog-article-photo-description">
                    Examples of gallery wall done with IKEA's frame and our custom matboards<br/>
                    For your comparison, bottom right is the frame without our custom matboard.
                </div>

                You can order a standard sized frames from Amazon or IKEA and get a customized matboards from us.

                That way, you can bypass the custom framing and get the perfect matboards at a competitive price.

                We have supplied hundreds of thousands of matboards to our customers with our 100% Customer Satisfaction Guarantee. Check our reviews at the bottom right corner. :)

                <br/><br/>

                Please feel free to reach out to us at hi@custommat.com if you have any questions. We are here to help.


                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>



            </div>

            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>



        </div>
    }
}