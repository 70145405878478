import React from 'react';
import axios from 'axios';
import localStorageFunctions from "../comp-localStorage/localStroage";
import connectToDB from "../database/connectDB";
import {Helmet} from "react-helmet";
import ReactPixel from 'react-facebook-pixel';
import FetchItemList from "../comp-cart/fetchItemList";
import {productDB} from "../comp-pricing/productDB";
import landingPageArrowLeft from "../../../resources/logo/landing-page-arrow-left.svg";
import {cadAudRate, cadEurRate, cadGbpRate, cadNzdRate, cadUsdRate} from "../comp-pricing/forExRate";

export default class ConfirmationApp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      savedToDB: false,
      showShoppingCartItems: false,
      french: false,
      dataLoaded: false,
    };
    this.fetchPaymentSectionHeader = this.fetchPaymentSectionHeader.bind(this);
    this.dispatchSectionHeader = this.dispatchSectionHeader.bind(this);
    this.fetchLanguage = this.fetchLanguage.bind(this);

  };


  componentDidMount() {
    this.loadLocalStorage();


    if (this.state.itemsSubtotal) {
      this.loadAdConversionTrackers()
    }

    this.setState({
      french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
    })

  }

  loadAdConversionTrackers() {
    if (typeof window.rdt === "function") {
      window.rdt("track", "Purchase");
    }

    if (typeof window.pintrk === "function" && this.state.itemsSubtotal) {
      window.pintrk('track', 'checkout', {
        order_id: `${new Date()}`,
        value: this.state.itemsSubtotal,
        order_quantity: 1,
        currency: 'CAD'
      });;
    }

    ReactPixel.track('Purchase', {currency: "CAD", value: this.state.itemsSubtotal});

    if (typeof window.ttq.track === "function") {
      window.ttq.track("CompletePayment", {
        content_id: '301',
        content_type: "product",
        quantity: 1,
        price: parseFloat(this.props.calculateItemSubtotal()),
        value: parseFloat(this.props.calculateItemSubtotal()),
        currency: 'CAD',
      });
    }
  }

  loadLocalStorage() {
    var items = { ...localStorage };
    var prices = this.props.updatePrices();

    if (items.customerEmail) {
      this.setState({
        lastOrderConfirmNumber: items.lastOrderConfirmNumber,
        country: items.country,
        customerEmail: items.customerEmail,
        customerPhoneNumber: items.customerPhoneNumber,
        orderConfirmationNumber: items.orderConfirmationNumber,
        promoCodeApplied: items.promoCodeApplied,
        shippingAddressCity: items.shippingAddressCity,
        shippingAddressPostalCode: items.shippingAddressPostalCode,
        shippingAddressProvince: items.shippingAddressProvince,
        shippingAddressRecipientName: items.shippingAddressRecipientName,
        shippingAddressStreet: items.shippingAddressStreet,
        shippingAddressSuite: items.shippingAddressSuite,
        currency: this.props.currency,
        preDiscountPrice: prices.preDiscountPrice,
        itemsSubtotal: prices.itemsSubtotal,
        shippingCharges: prices.shippingCharges,
        taxes: prices.taxes,
        grandTotal: prices.grandTotal,
      }, () => this.returnToLandingPage());
    } else {
      window.location.href="/"
    }
  }

  returnToLandingPage() {
    //if there's no shipment info data or empty cart, then return to the appropriate page.
    if (isNaN(this.state.grandTotal) || this.shippingAddressStreet === null) {
      window.location.href="/"
    } else if (this.state.grandTotal < 1) {
      window.location.href="/"
    } else {
      this.setState({dataLoaded: true})
      this.callonlyonce()
      return null
    }
  }



  sendConfirmationEmail() {
    //if the last order confirm number equals to current order confirm number, it means that the confirm email has been fired.
    var lastOrderConfirmNumber = localStorage.getItem("lastOrderConfirmNumber");

    if (lastOrderConfirmNumber !== this.state.orderConfirmationNumber) {

      this.saveDataToDB()

      localStorage.setItem("lastOrderConfirmNumber", this.state.orderConfirmationNumber)

      var parseUserName = this.state.shippingAddressRecipientName.split(" ");
      let promoCodeRate = 1;

      if (this.state.promoCodeApplied === "true") {
        promoCodeRate = 1 - this.props.promoDecimals;
      } else {
        promoCodeRate = 1;
      }

      let quantityDiscountMultiplier = this.props.getDiscountMultiplier();
      let shoppingCartJson =  JSON.stringify(this.props.shoppingCartItems);

      let preDiscountPrice = this.props.fxConversion(this.state.preDiscountPrice);
      let itemsSubtotal = this.props.fxConversion(this.state.itemsSubtotal * promoCodeRate);
      let itemsShipping = this.props.fxConversion(this.state.shippingCharges * promoCodeRate);
      let itemsTaxes =  this.props.fxConversion(this.state.taxes * promoCodeRate);
      let totalAmount =  this.props.fxConversion(this.state.grandTotal * promoCodeRate);


      //we have to send the emailer converted price.

      let deliveryAddress = `${this.state.shippingAddressStreet} ${this.state.shippingAddressSuite === "undefined" ? "" : this.state.shippingAddressSuite}`;
      let deliveryAddressLine2 = `${this.state.shippingAddressCity}, ${this.state.shippingAddressProvince}, ${this.state.shippingAddressPostalCode}`;

      let forExRate;

      if (this.props.currency === "AUD") {
        forExRate = cadAudRate;
      } else if (this.props.currency === "CAD") {
        forExRate = 1;
      } else if (this.props.currency === "EUR") {
        forExRate = cadEurRate;
      } else if (this.props.currency === "GBP") {
        forExRate = cadGbpRate;
      } else if (this.props.currency === "USD") {
        forExRate = cadUsdRate;
      } else if (this.props.currency === "NZD") {
        forExRate = cadNzdRate;
      } else {
        forExRate = 1;
      }

      axios.post(
          `https://us-central1-sendgridmailer-91a7a.cloudfunctions.net/sendMail?dest=${this.state.customerEmail}&name=${parseUserName[0]}&shipping=1&orderReferenceNumber=${this.state.orderConfirmationNumber}`,
          {
            // discountCodeUsed: this.state.promoCodeApplied,
            quantityDiscountFactor: quantityDiscountMultiplier,
            discountCodeDiscountFactor: promoCodeRate,
            preDiscountPrice: preDiscountPrice,
            itemsSubtotal: itemsSubtotal,
            itemsShipping: itemsShipping,
            itemsTaxes: itemsTaxes,
            totalAmount: totalAmount,
            deliveryName: this.state.shippingAddressRecipientName,
            deliveryAddress: deliveryAddress,
            deliveryAddressLine2: deliveryAddressLine2,
            shoppingCartItems: shoppingCartJson,
            cadUsdRate: forExRate,
            forExRate: forExRate,
            currency: this.props.currency,
          })


    } else {
      // means email already sent. don't do anything
      return null
    }

  }

  callonlyonce() {
    var executed = false;

    if (executed === false) {
      this.sendConfirmationEmail();
    }
  }

  fetchLanguage(fr, en) {
    return this.state.french ? `${fr}` : `${en}`;
  }

  saveDataToDB() {

    var dataToSaveToDB = {
      customerEmail: this.state.customerEmail,
      orderConfirmationNumber: this.state.orderConfirmationNumber,
      shoppingCart: JSON.stringify(this.props.shoppingCartItems),
      grandTotal: this.state.grandTotal,
    };

    connectToDB("w","paid-prod", this.state.orderConfirmationNumber, dataToSaveToDB);

  }


  fetchPaymentSectionHeader(text) {
    return <div className={"payment-section-container-header"}>
      {text}
    </div>
  };

  dispatchSectionHeader(text) {
    return <div className={`shipping-form-container-section-header`}>
      {text}
    </div>
  };

  getOrderConfirmTimestamp() {
    var timestamp = new Date();
    timestamp = timestamp.toString().substring(0,24);
    this.setState({orderConfirmationTime: timestamp});
  };

  calculateShippingCosts(subtotalAmount, shippingItems, country, shippingOrPickup) {
    if (subtotalAmount >= 49 || shippingOrPickup === "pickup") {
      return 0;
    } else {
      var maxShippingCost = 0;
      var countryCode = (country === "CA") ? 0 : 1;
      if (shippingItems) {
        var shippingItemList = Object.keys(shippingItems);
        shippingItemList.map((item, key) => {
          if (shippingItems[item].shipping[countryCode] > maxShippingCost) {
            maxShippingCost = shippingItems[item].shipping[countryCode];
          };
        });
        return maxShippingCost.toFixed(2)
      } else {
        return null;
      };
    };
  };

  taxesCalculator(amount, countryCode, provincialCode) {
    //if country code is US, there will be no taxes
    var taxRate = productDB.taxRates[countryCode][provincialCode];
    this.setLocalStorage("taxes", (amount * taxRate).toFixed(2));
    return (amount * taxRate).toFixed(2);
    //if else (canada), the tax rates will depend on provincial code, which comes from pricingDB
  };

  fetchShippingChargesPrompt(shippingCost, shippingOrPickup, shoppingSubtotal) {
    if (shoppingSubtotal >= 49 && shippingOrPickup === "shipping") {
      return this.state.french ? "Livraison  Gratuite" : "Free Shipping";
    } else if (shippingCost === 0 && shippingOrPickup === "pickup") {
      return "Free Pickup";
    } else {
      return `$${shippingCost.toFixed(2)}`;
    }
  };

  abandonDecimals(number) {
    return (Math.trunc(number * 100)/100).toFixed(2);
  }

  renderPriceSummary() {

    var promoCodeRate = 1;

    if (this.state.promoCodeApplied === "true") {
      promoCodeRate = 1 - this.props.promoDecimals;
    } else {
      promoCodeRate = 1;
    }

    var quantityDiscountMultiplier = this.props.getDiscountMultiplier();
    var preDiscountPrice = this.props.fxConversion(this.state.preDiscountPrice).toFixed(2);
    var itemsSubtotal = this.props.fxConversion(this.state.itemsSubtotal * promoCodeRate).toFixed(2);
    var itemsShipping = this.props.fxConversion(this.state.shippingCharges * promoCodeRate).toFixed(2);
    var taxes =  this.props.fxConversion(this.state.taxes * promoCodeRate).toFixed(2);
    var grandTotal =  this.props.fxConversion(this.state.grandTotal * promoCodeRate).toFixed(2);

    return <div>

      <div className={"checkout-progress-bar"}>

        <div className={"checkout-progress-option"}>
          {this.state.french ? "Adresse" : "Address"}
        </div>

        <div className={"checkout-progress-option"}>
          {this.state.french ? "Paiement + Révision" : "Payment + Review"}
        </div>

        <div className={"checkout-progress-option checkout-progress-option-selected"}>
          {this.state.french ? "Confirmation" : "Confirmation"}
        </div>

      </div>

      <div className={"order-confirmation-header"} style={{borderBottom: "none"}}>

        <div className={"delivery-info-header-emphasis"}>
          {this.state.french ? "Commande Passée" : "Order Placed!"}

        </div>

        <div className={"delivery-info-header-regular"}>
          {this.state.french ? "Commande# " : "Order# "}
          {this.state.orderConfirmationNumber}
        </div>

        <div className={"delivery-info-wrapper"}>
          <div>
            {this.state.french ? "Vous devriez recevoir un courriel de confirmation de commande de " : "You'll get a confirmation email from us."}
          </div>

          <div>
            {this.state.french ? "Veuillez cocher " : "If you don't see it, check your "}
            <i>
              {this.state.french ? "la case Spam ou promotion  " : "Spam or Promotion "}
            </i>
            {this.state.french ? "si vous ne le voyez pas." : "folder."}

          </div>
        </div>

      </div>

      <div className={"review-section-container-header"}>
        <div className={"summary-review-button-left"}>
          {this.props.french ? "Révision" : "Review"}
          <img
              className={this.state.showShoppingCartItems ? "support-arrow-arrow-v2-inverse" : "support-arrow-arrow-v2"}
              src={landingPageArrowLeft}
              onClick={() => this.setState({showShoppingCartItems: !this.state.showShoppingCartItems}, () => {})}
              alt={this.props.french ? "Cliquez pour afficher les détails du panier." : "Click to view shopping cart details."}
          />
        </div>

        <div className={"summary-review-button-right"}>
          {this.props.getTotalItemCount()} {this.props.getTotalItemCount() > 1 ?
            this.props.french ? "Articles" : "Items" :
            this.props.french ? "Article" : "Item"}
        </div>

      </div>

      <div className={this.state.showShoppingCartItems ? "display" : "no-display"}>
        <FetchItemList
            shoppingCartItems={this.props.shoppingCartItems}
            updateItemList={this.props.updateItemList}
            getDiscountMultiplier={this.props.getDiscountMultiplier}
            fetchLanguage={this.fetchLanguage}
            french={this.props.french}
            fxConversion={this.props.fxConversion}
            location={this.props.location}
        />
        <div className={this.props.getDiscountMultiplier() === 1 ? "no-display" : "shipping-summary-header"} style={{width: "100%", textAlign: "right"}}>
          The unit price above reflects {((1-this.props.getDiscountMultiplier())*100).toFixed(0)}% Quantity Discount.
        </div>
      </div>



      <div className={"subtotal-calculator-container"}>

        <div className={"shipping-cost-container"}>


            <div className={promoCodeRate === 1 ? "no-display" : "display"} >
                <div className={quantityDiscountMultiplier === 1 ? "no-display" : "shipping-summary-header"}>{this.props.french ? "Prix d'origine" : "Original Price"}</div>
                <div className={"shipping-summary-amount"} style={{textDecoration: "line-through"}}>
                  {this.state.currency} {preDiscountPrice}
                </div>
                <div style={{width: "100%", textAlign: "right"}}>
                    {((1-promoCodeRate)*100).toFixed(0)}% Promotion Discount
                </div>
            </div>

          <div className={"shipping-summary-header"}>{this.props.french ? "Sous-total" : "Subtotal"}</div>
          <div className={"shipping-summary-amount"}>
            {this.state.currency} {itemsSubtotal}
          </div>
        </div>

        <div className={"shipping-and-taxes-container"}>

          <div className={"shipping-cost-container"}>
            <div className={"shipping-summary-header"}>{this.props.french ? "Frais d’expédition" : "Shipping Charges"}</div>
            <div className={"shipping-summary-amount"}>
              {this.state.currency} {itemsShipping}
            </div>
          </div>

          <div className={"shipping-cost-container shipping-summary-add-bottom-line"}>
            <div className={"shipping-summary-header"}>Taxes</div>
            <div className={"shipping-summary-amount"}>
              {this.state.currency} {taxes}
            </div>
          </div>

          <div className={"shipping-cost-container"}>
            <div className={"shipping-summary-header-total"}>Total</div>
            <div className={"shipping-summary-amount-total"}>
              {this.state.currency}
              {grandTotal}
            </div>
          </div>

        </div>

      </div>
    </div>
  }

  render() {



    return <div className={`payments-container`} id={"confirmation-container-web"}>

      <Helmet>
        <title>
          {this.state.french ? "PERSONNALISEZ LE PASSE-PARTOUT • Livraison gratuite au Canada • Livraison gratuite au Québec •  Pas de quantité minimum de commande" :
              "Custom Matboards • Photo Mats • Picture Mats • Matboard Supplies • Free Shipping in Canada • No Minimum Order Quantity "}
        </title>
        <meta name="description" content={
          this.state.french ? "Personnalisez vos passe-partout en ligne. Nous vous les expédions sous 48h. Pas de quantité minimum de commande. Des dizaines d'options de couleurs. Livraison gratuite au Canada et aux États-Unis à partir de 49 $." :
              "Customize your matboards online. We ship them to you within 48 hours. No minimum order quantity. Dozens of colour options. Free shipping to Canada and the USA over $49."
        } />
      </Helmet>

      <div onClick={this.props.turnOffErrorChecker === false ? () => {this.setState({focusedModal: "summary"})} : null}>

        {this.state.dataLoaded === true ? this.renderPriceSummary() : null}

        <div className={"review-page-reminder"}>
          <b>Production & Shipping Details</b>
          <ul>
            <li>Ships in 2 business days (Dispatch from New York & Ontario Warehouses).</li><br/>
            <li>US & Canada Delivery: 2-6 business days.</li><br/>
            <li>Europe & ANZ: 5-20 business days.</li><br/>
            <li>Free replacements for damaged/unsatisfactory/misfitting items.</li><br/>
            <li>During sales periods, please allow 1-3 extra days for delivery.</li><br/>
            <li>Please email us hi@custommat.com for any questions, including delays, damages, and missing item. We will help you locate your item.</li>
          </ul>
        </div>

      </div>


      <a href={this.state.french ? "/inspiration-fr" : "/inspiration"}>
        <div className={"future-inspirations-button"}>
          {this.state.french ? "Trouvez de futures inspirations" : "Find Future Inspirations"}
        </div>
      </a>


    </div>
  }
}