import React from 'react';
import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";

import photo1 from "../../../resources/images/gallery/matboards/Colors.jpg";
import photo2 from "../../../resources/images/gallery/matboards/Whites.jpg";
import photo3 from "../../../resources/images/gallery/matboards/Shade1.jpg";
import photo4 from "../../../resources/images/gallery/matboards/Shade2.jpg";
import photo5 from "../../../resources/images/gallery/matboards/Shade3.jpg";
import photo6 from "../../../resources/images/gallery/matboards/Shade4.jpg";
import photo7 from "../../../resources/images/gallery/matboards/Shade5.jpg";
import photo8 from "../../../resources/images/gallery/matboards/Shade6.jpg";


export default class HowAreMatboardsMade extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>How are the matboards made?</title>
                <meta name="description" content="Matboards, also known as matting or mounting boards, are commonly used in the picture framing industry to enhance and protect artworks, photographs, or other items. Matboards are made through a manufacturing process that involves several steps. Here's a general overview of how matboards are typically produced."/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                How are the matboards made?
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Production Process
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Paris K<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img className="blog-article-photo-two-image" src={photo1}/>
                <img className="blog-article-photo-two-image" src={photo3}/>
            </LazyLoad>


            <div className={"blog-article-content"}>

                <b>
                    How are the matboards made? What kind of processes do they go through to make a customized matboard?
                </b>

                <br/><br/>

                Matboards, also known as matting or mounting boards, are commonly used in the picture framing industry to enhance and protect artworks, photographs, or other items. Matboards are made through a manufacturing process that involves several steps. Here's a general overview of how matboards are typically produced:

                <ol>
                    <li>
                        <b>Material selection: </b>

                        <br/><br/>

                        Matboards are usually made from high-quality, acid-neutralized paper or a combination of wood pulp and other materials. Acid-free matboards are preferred because they help preserve the artwork by preventing acid damage over time.

                        <br/><br/>

                        Most of the matboards available in the market these days are acid-neutralized. The majority of the matboards have been treated and buffered to remove all acidic components, meeting your basic archival needs. The same applies to pre-cut mats, photo mats, backing boards, show kits, and foam boards.

                        <br/><br/>

                        Our matboards are produced with high-quality materials for everyday framing. Please note that our matboards are not museum-level archival, as they are more expensive and niche market products. For those, you will have to opt for museum-grade conservation matboards. All of our matboards are composed of the best-selling matboards in the market.
                    </li>

                    <br/><br/>


                    <li>
                        <b>Sizing and cutting: </b>

                        <br/><br/>

                        The chosen materials are cut into standard-sized, uncut matboard sheets or rolls to meet the desired dimensions of the matboard. The thickness of the matboard can vary depending on its intended use and the preferences of the manufacturer.

                        <br/><br/>

                        Most of the matboards we use are manufactured by Peterboro Matboard Company, located in Ontario, Canada. Some also come from reputable companies in the United States and the United Kingdom. We source the high quality materials from the best sellers beloved by the picture framers.
                    </li>

                    <br/><br/>


                    <li>
                        <b>Coloring: </b>
                        <br/><br/>

                        Matboards are often available in a range of color palettes to complement or enhance the artwork they will frame. The sheets or rolls of matboard material are typically dyed using water-based or pigment-based colorants. This process ensures consistent color throughout the matboard.
                    </li>

                    <br/><br/>

                    <li>
                        <b>Surface treatment: </b>

                        <br/><br/>

                        To enhance the appearance and durability of the matboard, various surface treatments may be applied (for example, a suede layer). Please note that all of our matboards are 4-ply matboards. Except for Gold and Silver, all our matboards have a matte finish. Gold and silver matboards have a shiny, foiled surface suitable for certificates, degrees, diplomas, watercolors, acrylic paints, and other mixed media.
                    </li>

                    <br/><br/>

                    <li>
                        <b>Cutting the opening: </b>

                        <br/><br/>

                        If the photo mat is designed to frame artwork or photographs, an opening is cut into the center of the mat. This opening is typically slightly smaller than the size of the artwork, creating a border or "window" around the piece.

                        <br/><br/>

                        Here at Custom Mat, we use high-precision, industry-grade mat cutter machines to cut the matboards specifically catering to your dimensions. With our custom matboards, we can create a custom framing look on standard-sized picture frames or mass-market poster frames.

                        <br/><br/>

                        For double matting/accented matboards, we automatically adjust the window size to meet your custom matting needs for your artwork.
                    </li>

                </ol>


                <br/><br/>

                Please feel free to reach out to us at hi@custommat.com if you have any questions. We are here to help.


                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                    <img src={photo6} className={"blog-content-photo"}/>
                    <img src={photo7} className={"blog-content-photo"}/>
                    <img src={photo8} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>



            </div>

            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>



        </div>
    }
}