import React from "react";
import {Helmet} from "react-helmet";
import {marketList} from "../articleDB/articleCityNames";
import {productDB} from "../comp-pricing/productDB";


export default class ShippingInfoPage extends React.Component {
  render() {
    return <div className={`user-policies-container`}>
      <Helmet>
        <title>Order Custom Mat Board & Frames Online • Delivered to your door - Our Policy </title>
        <meta name="description" content="We custom cut your mat boards. Upgrade your retail picture frames. Make them look custom! Ship anywhere in Canada and US." />
      </Helmet>

      <div className={`user-policy-header`}>
        We ship to anywhere in Canada & USA free of charge. We have affordable shipping to Europe and Australia as well.
      </div>
      <div className={`user-policy-paragraph`}>
        We offer faster shipping to following areas:

        {
          Object.keys(marketList).map((city) => {
            return marketList[city].neighbourList.map((neighbourhood) => {
              var neighbourhoodStringProcessed = neighbourhood.replace(' ', "");
              var cityStringProcessed = city.replace(' ', "");
              return <div style={{"color": "#72696A", "marginLeft":  "1rem", "marginBottom": "0.5rem"}}>
                •
                <a href={`/custom-framing-matboard-${neighbourhoodStringProcessed}-${cityStringProcessed}`}
                   alt={`Shipping information for ${neighbourhood}, ${marketList[city].cityName}`}
                   style={{"color": "#72696A"}}>{neighbourhood}, {marketList[city].cityName}
                </a>
              </div>
            })
          })
        }
      </div>
    </div>

  }
}