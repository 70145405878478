import React from "react";

export default class ErrPage extends React.Component {

  render() {
    return <div className={`how-it-works-container`} id={"what-we-do-container"}>
      <div className={`section-topic-header`} id={`transaction-topic-header-reduced-margin`}>
        {this.props.french ? "Erreur de Transaction" : "Transaction Error"}
      </div>
      <div className={`what-we-do-description`} id={`what-we-do-description-margin`} style={{textAlign: "left"}}>
        {this.props.french ? "Nous sommes désolés. Votre transaction n'a pas pu être complétée pour le moment." : "We are sorry. Your transaction cannot be completed at this time. "}
        {this.props.french ? " Les raisons les plus courpxantes de l'erreur sont :" : "Most common reasons are:"}
        <br/>
        {this.props.french ? "1. Numéro de vérification de carte de crédit incorrect" : "1. Incorrect credit card verification number"}
        <br/>
        {this.props.french ? "(numéro à 3 chiffres au dos de votre carte de crédit, ou numéro à 4 chiffres devant votre numéro de carte de crédit pour American Express)" : "(3 digit number on the back of your credit card, or 4 digit number in front of your credit card number for American Express)"}
        <br/>
        {this.props.french ? "2. Votre banque a refusé notre paiement." : "2. Your bank declined our payment."}
        <br/><br/>
        {this.props.french ? "Vos commandes sont enregistrées. Veuillez réessayer."  : "Your orders are saved. Please try one more time."}
        <br/>
        {this.props.french ? "Si ce problème persiste, veuillez essayer une autre carte ou contactez-nous à hi@custommat.com" : "If this issue continues, please try different card, or contact us at hi@custommat.com"}
        <br/><br/>
        {this.props.french ? "*Veuillez noter que votre carte de crédit n'a pas été débitée." : "*Please note that your credit card was not charged."}
        <br/>
        {this.props.french ? "*S'il y a des frais en attente, ils seront supprimés dans un délai de 1 à 2 jours ouvrables." : "*If there are pending charges, they will be removed within 1-2 business days."}
        <br/>
      </div>
      <a href={this.props.french ? "/payments-fr" : "/payments"}>
        <div className={`return-to-payment-page-button`}>
          {this.props.french ? "Aller à la page de paiement" : "Go to Payment Page"}
        </div>
      </a>
    </div>
  }
}