import React from "react";
import companyLogoIcon from "../../../resources/logo/logo-color.png";
import mobileMenu from "../../../resources/svgIcons/mobile-menu.png";
import closeOutIcon from "../../../resources/svgIcons/close-icon.svg";
import CartSlider from "../comp-cart/cartSlider";
import logoV2 from '../../../resources/logo/logo-v2.svg';
import bag from '../../../resources/logo/bag.png';
import mobileMenuButtonV2 from "../../../resources/logo/navbar-top-menu.svg";
import landingPageArrowLeft from "../../../resources/logo/landing-page-arrow-left.svg";
import canadaFlag from "../../../resources/svgIcons/can.png";
import chevRight from "../../../resources/svgIcons/chevron-right.svg";
import chevLeft from "../../../resources/svgIcons/chevron-left.svg";
import caFlag from "../../../resources/images/navbar/caFlag.png";
import usFlag from "../../../resources/images/navbar/usFlag.png";
import arrowDown from "../../../resources/svgIcons/select-mat-arrow-down.svg";

import Countdown from "react-countdown";


export default class NavBarTop extends React.Component {

  constructor() {
    super();
    this.state = {
      openMobileNavBar: false,
      currentUrlParam: (window.location.pathname + window.location.search),
      onGalleryPage: false,
      french: false,
      openCartModal: false,
      openSupportDropdown: false,
      navbarTopFixed: true,
      fetchStandardMenuButtons: true,
      fetchOurMatboardsButton: false,
      fetchInspirationButton: false,
      fetchSupportButton: false,
      openMatboardsSection: false,
    };
    this.navbarTopSetState = this.navbarTopSetState.bind(this);
    this.totalItemCounter = this.totalItemCounter.bind(this);
  };

  componentDidMount() {
    this.executeQueryParams();
    // windowaddEventListener("scroll", this.setState({showNotificationBanner: false})
    this.setState({
      french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
    })

    localStorage.getItem("unit") === "cm" ? this.setState({unit: "cm"}) : this.setState({unit: "in"})
};

  goToPage(url) {
    window.location.href = `${url}`
  }

  navbarTopSetState(dictKey, dictValue) {
    this.setState({[dictKey]: dictValue});
  }

  showCompanyLogo(url) {
    return <a href={this.state.french ? "/fr" : "/"}>
      <div className={"navbar-top-company-name-logo"}>
        <img className={"navbar-top-company-name-logo-image"}
             alt={"Small maple leaf to decorate CustomMat.ca"}
             src={companyLogoIcon}/>
      </div>
    </a>
  };

  fetchIntroHeading() {

    var urlIsGlobal = window.location.hostname.slice(4) === "custommat.com" || window.location.hostname === "custom-mat.com" ? true : false;
    var location = this.props.location;
    var localized = window.location.hostname.slice(0,2) === "ww" ? false : true;
    var country = "Worldwide";
    // var urlIsGlobal = true;
    // var location = "ca";

    if (location === "ca") {
      country = "Canada"
      localStorage.setItem("currency", "CAD");
    } else if (location === "us") {
      country = "USA";
      localStorage.setItem("currency", "USD");
    } else if (location === "gb") {
      country = "United Kingdom";
      localStorage.setItem("currency", "GBP");
    } else if (location === "au") {
      country = "Australia";
      localStorage.setItem("currency", "AUD");
    } else if (location === "nz") {
      country = "New Zealand";
      localStorage.setItem("currency", "NZD");
    } else if (location === "fr") {
      country = "France";
      localStorage.setItem("currency", "EUR");
    } else if (location === "es") {
      country = "Spain";
      localStorage.setItem("currency", "EUR");
    } else if (location === "it") {
      country = "Italy";
      localStorage.setItem("currency", "EUR");
    } else if (location === "de") {
      country = "Germany";
      localStorage.setItem("currency", "EUR");
    } else if (location === "nl") {
      country = "Netherlands";
      localStorage.setItem("currency", "EUR");
    } else {
      country = "Worldwide";
      localStorage.setItem("currency", "USD");
    }

    if (location === "ca") {
      return "Free Shipping // Made in Canada"
    } else if (location === "us" && urlIsGlobal === false) {
      return <div>
        <div className={"event-message-version2"}>Free Shipping across the USA</div>
        <a className={"event-message-version2"} href="https://www.custommat.com">Click here to visit our US Website</a>
      </div>
    } else if (location === "us" && urlIsGlobal === true) {
      return <div className={"event-message-version2"}>Free Shipping across the USA</div>
    } else if (location === "gb" && urlIsGlobal === false) {
      return <div>
        <div className={"event-message-version2"}>Free Shipping across the UK</div>
        <a className={"event-message-version2"} href="https://www.custommat.com">Click here to visit our UK Website</a>
      </div>
    } else if (location === "fr" && localized === false) {
      return <div>
        <div className={"event-message-version2"}>La livraison gratuite vers la France est disponible</div>
        <a className={"event-message-version2"} href="https://fr.custommat.com">Cliquez ici pour visiter notre site français</a>
      </div>
    } else if (location === "de" && localized === false) {
      return <div>
        <div className={"event-message-version2"}>Kostenloser Versand nach Deutschland ist verfügbar</div>
        <a className={"event-message-version2"} href="https://de.custommat.com">Klicken Sie hier, um unsere deutsche Website zu besuchen</a>
      </div>
    } else if (location === "it" && localized === false) {
      return <div>
        <div className={"event-message-version2"}>La spedizione gratuita verso l'Italia è disponibile</div>
        <a className={"event-message-version2"} href="https://it.custommat.com">Clicca qui per visitare il nostro sito italiano</a>
      </div>
    } else if (location === "es" && localized === false) {
      return <div>
        <div className={"event-message-version2"}>Envío gratis en toda España disponible</div>
        <a className={"event-message-version2"} href="https://it.custommat.com">Haz clic aquí para visitar nuestro sitio web en español</a>
      </div>
    } else if (location === "nl" && localized === false) {
      return <div>
        <div className={"event-message-version2"}>Gratis verzending naar Nederland is beschikbaar</div>
        <a className={"event-message-version2"} href="https://nl.custommat.com">Klik hier om onze Nederlandse website te bezoeken</a>
      </div>
    } else if (location !== "ca" && location !== "us" && location !== "gb" && urlIsGlobal === false && localized === false) {
      return <div>
        <div className={"event-message-version2"}>Worldwide Free Shipping Available</div>
        <a className={"event-message-version2"} href="https://www.custommat.com">Click here to visit our Global Website</a>
      </div>
    } else {
      return <div className={"event-message-version2"}>{country} Free Shipping Available</div>
    }
  }

  fetchEventHeadline() {
    if (this.props.onGoingPromotion) {
      return <div className={this.state.french ? "event-message-version2-fr" : "event-message-version2"}>
        {/*Black Friday Code Below*/}
        {this.state.french ? "Vente de fin d'année // 20 % de réduction supplémentaire à la caisse" : `Happy Holidays // Year-End Sale // Extra 20% Off @ Checkout`}<br/>
        {/*{this.props.location === "us" ? "" : "Our deliveries are NOT affected by the Canada Post disruption"}*/}
      </div>
    } else {
      return <div className={this.state.french ? "event-message-version2-fr" : "event-message-version2"}>
        {this.fetchIntroHeading()}
      </div>
    }

  }

  totalItemCounter(shoppingCartItems) {
    var totalItemCount = 0;
    var shippingSize = "small"

    if (shoppingCartItems) {


      shoppingCartItems.map((item, key) => {

        var longside = item.frameWidth >= item.frameHeight ? item.frameWidth : item.frameHeight;

        if (longside > 30.1) {
          shippingSize = "xLarge"
        } else if (longside > 24.1 ) {
          shippingSize = "large"
        } else if (longside > 16.1) {
          shippingSize = "medium"
        } else {
          shippingSize = "small"
        }
            totalItemCount  = parseFloat(totalItemCount) + parseFloat(item.orderQuantity);
      })
    }

    if (isNaN(totalItemCount)) {
      totalItemCount = ""
    }

    localStorage.setItem("shippingSize", shippingSize)
    // this.setState({shippingSize: shippingSize})
    // this.props.parentSetStateCallback("shippingSize", shippingSize);

    return totalItemCount;

  }

  executeQueryParams() {
    var urlQueryParams = (window.location.pathname + window.location.search).substr(1);
    if (urlQueryParams === "?whatWeDo") {
      document.getElementById('what-we-do-container').scrollIntoView({behavior: 'smooth'});
    } else if (urlQueryParams === "?howItWorks") {
      document.getElementById('how-it-works-container').scrollIntoView({behavior: 'smooth'});
    }
  };

  fetchCurrency() {
    return <div className={"mobile-language-selection-bar"}>
      <div style={{width: "100%", overflow: "auto"}}>
        <div className={"mobile-language-selection-flag-text"}>Selected Currency: {this.props.currency}</div>
      </div>
      <div className={this.props.currency==="AUD" ? "mobile-language-selection-flag-text underline" : "mobile-language-selection-flag-text"} onClick={() => this.props.changeCurrency("AUD")}>AUD</div>
      <div className={this.props.currency==="CAD" ? "mobile-language-selection-flag-text underline" : "mobile-language-selection-flag-text"} onClick={() => this.props.changeCurrency("CAD")}>CAD</div>
      <div className={this.props.currency==="EUR" ? "mobile-language-selection-flag-text underline" : "mobile-language-selection-flag-text"} onClick={() => this.props.changeCurrency("EUR")}>EUR</div>
      <div className={this.props.currency==="GBP" ? "mobile-language-selection-flag-text underline" : "mobile-language-selection-flag-text"} onClick={() => this.props.changeCurrency("GBP")}>GBP</div>
      <div className={this.props.currency==="NZD" ? "mobile-language-selection-flag-text underline" : "mobile-language-selection-flag-text"} onClick={() => this.props.changeCurrency("NZD")}>NZD</div>
      <div className={this.props.currency==="USD" ? "mobile-language-selection-flag-text underline" : "mobile-language-selection-flag-text"} onClick={() => this.props.changeCurrency("USD")}>USD</div>
    </div>

    // if (this.props.currency === "USD") {
    //   return <div className={"mobile-language-selection-bar"}>
    //     <div className={"mobile-language-selection-flag-text"}>Currency: US Dollars</div>
    //   </div>
    // } else if (this.props.currency === "CAD") {
    //   return <div className={"mobile-language-selection-bar"}>
    //     <div className={"mobile-language-selection-flag-text"}>Currency: Canadian Dollars</div>
    //   </div>
    // } else {
    //  return <div className={"mobile-language-selection-bar"}>
    //     <div className={"mobile-language-selection-flag-text"}>Currency: US Dollars</div>
    //   </div>
    // }
  }

  fetchMobileHeaderIndicator() {
    if (this.state.openMatboardsSection) {
      return <div className={"navbar-mobile-header-indicator-text"}>
        Our Matboards
      </div>
    }
    else if (this.state.fetchInspirationButton) {
      return <div className={"navbar-mobile-header-indicator-text"}>
        Inspiration
      </div>
    }
    else if (this.state.fetchSupportButton) {
      return <div className={"navbar-mobile-header-indicator-text"}>
        Support
      </div>
    }
  }

  clickCloseModalButton() {
    this.setState({openMobileNavBar: false, fetchStandardMenuButtons: true, openMatboardsSection: false, fetchInspirationButton: false, fetchSupportButton: false})
  }

  openMatboardsSection() {
    return <div className={this.state.openMatboardsSection ? "display" : "no-display"}>
      <div className={"navbar-top-button-mobile-menu"}>
        <div className={"navbar-top-button-header"}>
          {this.state.french ? "Passe-Partout" : "Matboards"}
        </div>
        <a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Passe-Partout Personnalisé" : "Fully Custom Matboards"}
          </div>
        </a>

        <a href={this.state.french ? "/precut-mats-fr" : "/precut-mats"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Passe-Partout de Taille Standard" : "Standard Sized, Pre-cut Matboards"}
          </div>
        </a>

        <a href={this.state.french ? "/backing-mat-boards-fr" : "/backing-mat-boards"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Support / Montage Passe-partout" : "Backing & Mounting Matboards"}
          </div>
        </a>

        <div className={"navbar-top-button-header"}>
          {this.state.french ? "Trier par Taille" : "Sort By Size"}
        </div>
        <a href={this.state.french ? "/shop-by-frame-size-fr" : "/shop-by-frame-size"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Acheter par taille de cadre" : "Matboards based on Frame Size"}
          </div>
        </a>

        <div className={"navbar-top-button-header"}>
          {this.state.french ? "Passe-partout Spécialisé" : "Specialty Mats"}
        </div>
        <a href={this.state.french ? "/diploma-frame-matboards-fr" : "/diploma-frame-matboards"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Passe-partout pour Diplômes et Certificats" : "Diploma & Certificate Matboard"}
          </div>
        </a>
      </div>


    </div>
  }

  openInspirationSection() {
    return <div className={this.state.fetchInspirationButton ? "display" : "no-display"}>
      <div className={"navbar-top-button-mobile-menu"}>
        <a href={this.state.french ? "/shop-by-looks-fr" : "/shop-by-looks"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Looks tendance" : "Trending Looks"}
          </div>
        </a>

        <a href={this.state.french ? "/inspiration-fr" : "/inspiration"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Photos des clients" : "Customer Photos"}
          </div>
        </a>

        <a href={this.state.french ? "/design-blog-main-fr" : "/design-blog-main"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Blog de design" : "Design Blog"}
          </div>
        </a>

        <a href={this.state.french ? "https://www.pinterest.ca/custommatcanada" : "https://www.pinterest.ca/custommatcanada"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Notre page Pinterest" : "Our Pinterest Page"}
          </div>
        </a>
      </div>


    </div>
  }




  openSupportSection() {
    return <div className={this.state.fetchSupportButton ? "display" : "no-display"}>
      <div className={"navbar-top-button-mobile-menu"}>
        <div className={"navbar-top-button-header"}>
          {this.state.french ? "Prix" : "Pricing"}
        </div>
        <a href={this.state.french ? "/pricing-and-discounts-fr" : "/pricing-and-discounts"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Prix et Rabais" : "Pricing & Discounts"}
          </div>
        </a>

        <div className={"navbar-top-button-header"}>
          {this.state.french ? "Assistance" : "Support"}
        </div>
        <a href={this.state.french ? "/returns-fr" : "/returns"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Retours" : "Returns"}
          </div>
        </a>

        <a href={this.state.french ? "/shipping-fr" : "/shipping"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Expédition" : "Shipping"}
          </div>
        </a>

        <a href={this.state.french ? "/policies-fr" : "/policies"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "Politiques" : "Policies"}
          </div>
        </a>

        <a href={this.state.french ? "/faq-fr" : "/faq"}>
          <div className={"navbar-top-button-subheader"}>
            {this.state.french ? "FAQ" : "FAQ"}
          </div>
        </a>
      </div>

      <div className={"navbar-top-button-header"}>
        {this.state.french ? "Guides" : "Guides"}
      </div>

      <a href={this.state.french ? "/colour-guide-fr" : "/colour-guide"}>
        <div className={"navbar-top-button-subheader"}>
          {this.state.french ? "Guide de Couleur" : `${this.props.location === "us" ? "Color Guide" : "Colour Guide"}`}
        </div>
      </a>
      <a href={this.state.french ? "/design-guide-fr" : "/design-guide"}>
        <div className={"navbar-top-button-subheader"}>
          {this.state.french ? "Guide de Conception" : "Design Guide"}
        </div>
      </a>

      <div className={"navbar-top-button-header"}>
        {this.state.french ? "Prix" : "Reviews"}
      </div>
      <a href={this.state.french ? "/customer-reviews" : "/customer-reviews"}>
        <div className={"navbar-top-button-subheader"}>
          {this.state.french ? "Lisez nos avis" : "Read Our Reviews"}
        </div>
      </a>
      <a href={this.state.french ? "/customer-reviews" : "/customer-reviews"}>
        <div className={"navbar-top-button-subheader"}>
          {this.state.french ? "Publier un avis" : "Post a Review"}
        </div>
      </a>


    </div>
  }

  openLangSection() {
    return <div className={this.state.fetchLanguage ? "display" : "no-display"}>
      <div className={"navbar-top-button-mobile-menu"}>
        <div className={"navbar-top-button-header"}>
          Available Languages
        </div>
        <a href={this.state.french ? "/pricing-and-discounts-fr" : "/pricing-and-discounts"}>
          <div className={"navbar-top-button-subheader"}>
            English
          </div>
        </a>

        <a href={this.state.french ? "/pricing-and-discounts-fr" : "/pricing-and-discounts"}>
          <div className={"navbar-top-button-subheader"}>
            Francais
          </div>
        </a>


      </div>

      <div className={"navbar-top-button-header"}>
        {this.state.french ? "Guides" : "Guides"}
      </div>

      <a href={this.state.french ? "/colour-guide-fr" : "/colour-guide"}>
        <div className={"navbar-top-button-subheader"}>
          {this.state.french ? "Guide de Couleur" : `${this.props.location === "us" ? "Color Guide" : "Colour Guide"}`}
        </div>
      </a>
      <a href={this.state.french ? "/design-guide-fr" : "/design-guide"}>
        <div className={"navbar-top-button-subheader"}>
          {this.state.french ? "Guide de Conception" : "Design Guide"}
        </div>
      </a>

      <div className={"navbar-top-button-header"}>
        {this.state.french ? "Prix" : "Reviews"}
      </div>
      <a href={this.state.french ? "/customer-reviews" : "/customer-reviews"}>
        <div className={"navbar-top-button-subheader"}>
          {this.state.french ? "Lisez nos avis" : "Read Our Reviews"}
        </div>
      </a>
      <a href={this.state.french ? "/customer-reviews" : "/customer-reviews"}>
        <div className={"navbar-top-button-subheader"}>
          {this.state.french ? "Publier un avis" : "Post a Review"}
        </div>
      </a>


    </div>
  }

  fetchMobileNavBar() {
    return <div className={"mobile-navbar-options-container"}>

      <div className={"mobile-nav-head-section"}>
        <div className={this.state.fetchStandardMenuButtons ? "no-display" : "navbar-mobile-header-indicator"}>
          <img className={"navbar-mobile-header-indicator-img"} src={chevLeft} onClick={() => this.setState({fetchStandardMenuButtons: true, openMatboardsSection: false, fetchInspirationButton: false, fetchSupportButton: false})} />
          {this.fetchMobileHeaderIndicator()}
        </div>
        <img className={"mobile-nav-close-icon"} src={closeOutIcon} onClick={() => {this.clickCloseModalButton()}}/>

      </div>

      <div className={this.state.fetchStandardMenuButtons ? "display" : "no-display"}>
        <div className={"navbar-top-button-mobile-menu"} onClick={() => this.setState({openMatboardsSection: true, fetchStandardMenuButtons: false})}>
          {/*<a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}>*/}
          {/*  <div className={"navbar-top-button-mobile"}>*/}
          {/*    {this.state.french ? "Passe-Partout Personnalisé" : "Our Matboards"}*/}
          {/*  </div>*/}
          {/*  <img className={"navbar-top-button-chevron"} src={chevRight} />*/}
          {/*</a>*/}

          <div className={"navbar-top-button-mobile"}>
            {this.state.french ? "Passe-Partout Personnalisé" : "Our Matboards"}
          </div>
          <img className={"navbar-top-button-chevron"} src={chevRight} />
        </div>

        <div className={"navbar-top-button-mobile-menu"}>
          <a href={this.state.french ? "/how-it-works-fr" : "/how-it-works"}>
            <div className={"navbar-top-button-mobile"}>
              {this.state.french ? "Comment cela fonctionne" : "How it works"}
            </div>
          </a>
        </div>

        <div className={"navbar-top-button-mobile-menu"} onClick={() => this.setState({fetchInspirationButton: true, fetchStandardMenuButtons: false})}>
          <div className={"navbar-top-button-mobile"}>
            {this.state.french ? "Inspiration" : "Inspiration"}
          </div>
          <img className={"navbar-top-button-chevron"} src={chevRight} />
        </div>

        <div className={"navbar-top-button-mobile-menu"} onClick={() => this.setState({fetchSupportButton: true, fetchStandardMenuButtons: false})}>
          <div className={"navbar-top-button-mobile"}>
            {this.state.french ? "Assistance" : "Support"}
          </div>
          <img className={"navbar-top-button-chevron"} src={chevRight} />
        </div>
      </div>


      {this.openMatboardsSection()}
      {this.openInspirationSection()}

      {this.openSupportSection()}

      {this.openLangSection()}

      {this.fetchCurrency()}

      <div className={"select-currency-options"}>
        <div className={"select-currency-row"} >
          <a className={"currency-description"} href={"https://www.custommat.com"}>
            English
          </a>
        </div>
        <div className={"select-currency-row"} >
          <a className={"currency-description"} href={"https://fr.custommat.com"}>
            Français
          </a>
        </div>
        <div className={"select-currency-row"} >
          <a className={"currency-description"} href={"https://es.custommat.com"}>
            Español
          </a>
        </div>
        <div className={"select-currency-row"} >
          <a className={"currency-description"} href={"https://de.custommat.com"}>
            Deutsch
          </a>
        </div>
        <div className={"select-currency-row"} >
          <a className={"currency-description"} href={"https://it.custommat.com"}>
            Italiano
          </a>
        </div>
        <div className={"select-currency-row"} >
          <a className={"currency-description"} href={"https://nl.custommat.com"}>
            Nederlands
          </a>
        </div>
      </div>
    </div>
  }

  showMobileNavPanel() {
    this.setState({openMobileNavBar: !this.state.openMobileNavBar});
  };

  fetchSupportDropdown() {
    return <div className={this.state.fetchSupport ? "support-v2-dropdown" : "no-display"}>


      <a href={this.state.french ? "/design-blog-main" : "/design-blog-main"} >
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Blog" : "Design Blog"}
        </div>
      </a>

      <a href={this.state.french ? "/design-guide-fr" : "/design-guide"} >
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Guide de Conception" : "Design Guide"}
        </div>
      </a>

      <a href={this.state.french ? "/colour-guide-fr" : "/colour-guide"} >
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Guide de Couleur" : `${this.props.location === "us" ? "Color Guide" : "Colour Guide"}`}
        </div>
      </a>

      <a href={this.state.french ? "/shipping-fr" : "/shipping"} >
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Livraison" : "Shipping"}
        </div>
      </a>

      <a href={this.state.french ? "/returns-fr" : "/returns"} className={"support-v2-dropdown-button"}>
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Retours" : "Returns"}
        </div>
      </a>

      <a href={this.state.french ? "/faq-fr" : "/faq"} className={"support-v2-dropdown-button"}>
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "FAQ" : "FAQ"}
        </div>
      </a>

      <a href={this.state.french ? "/pricing-and-discounts-fr" : "/pricing-and-discounts"} className={"support-v2-dropdown-button"}>
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Prix et Remises" : "Pricing & Discounts"}
        </div>
      </a>

      <a href={this.state.french ? "/custom-design-guide-fr" : "/custom-design-guide"} className={"support-v2-dropdown-button"}>
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Personnalisée" : "Custom Design"}
        </div>
      </a>


      <a href={this.state.french ? "/review-fr" : "/review"} className={"support-v2-dropdown-button"}>
        <div className={"support-v2-dropdown-button"}>
          {this.state.french ? "Publier une évaluation" : "Post a Review"}
        </div>
      </a>
    </div>
  }

  changeMeasurementUnit(unit) {
    this.setState({unit: unit})

    localStorage.setItem("unit", unit)
  }

  fetchNavBarHovering(hoveredButton) {

    if (hoveredButton === "ourmatboard") {
      return <div className={this.state.fetchOurMatboardsButton ? "navbar-web-hover" : "no-display"}>
        <div className={"navbar-web-hover-column"}>
          <div className={"navbar-web-hover-title"}>
            {this.state.french ? "Par type de produit" : "By Product Type"}
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={ this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}>{this.state.french ? "Passe-partout Personnalisé" : "Custom Matboard"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/precut-mats-fr" :"/precut-mats"}>{this.state.french ? "Passe-partout de taille standard" : "Standard Size & Pre-cut Matboards"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/backing-mat-boards-fr" : "/backing-mat-boards"}>{this.state.french ? "Montage Passe-partout" : "Backing & Mounting Matboards"}</a>
          </div>
        </div>

        <div className={"navbar-web-hover-column"}>
          <div className={"navbar-web-hover-title"}>
            {this.state.french ? "Par taille" : "By Size"}
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/shop-by-frame-size-fr" : "/shop-by-frame-size"}>{this.state.french ? "basé sur la taille du cadre" : "Matboards Based on Frame Size"}</a>
          </div>
        </div>

        <div className={"navbar-web-hover-column"}>
          <div className={"navbar-web-hover-title"}>
            {this.state.french ? "Produits spécialisés" : "Specialty Matboards"}
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/diploma-frame-matboards-fr" : "/diploma-frame-matboards"}>{this.state.french ? "Passe-Partout de Diplôme" : "Graduation & Certificate Mats"}</a>
          </div>
        </div>
      </div>
    }
    else if (hoveredButton === "inspiration") {
      return <div className={this.state.fetchInspirationButton ? "navbar-web-hover" : "no-display"}>
        <div className={"navbar-web-hover-column"} id={"inspiration-menu-expand"}>
          <div className={"navbar-web-hover-title"}>
            Inspiration
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/inspiration-fr" : "/inspiration"}>{this.state.french ? "Galerie de Photos Clients" : "Customer Photo Gallery"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/design-blog-main-fr" : "/design-blog-main"}>{this.state.french ? "Blog de Conception" : "Design Blog"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={ this.state.french ? "/shop-by-looks-fr" : "/shop-by-looks"}>{this.state.french ? "Design à la Mode" : "Trending Looks"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={"https://www.pinterest.ca/custommatcanada/"}>{this.state.french ? "Nos Tableaux Pinterest" : "Our Pinterest Boards"}</a>
          </div>
        </div>
      </div>
    }
    else if (hoveredButton === "support") {
      return <div className={this.state.fetchSupport ? "navbar-web-hover" : "no-display"}>

        <div className={"navbar-web-hover-column-narrow"}>
          <div className={"navbar-web-hover-title"}>
            {this.state.french ? "Guide" : "Guides"}
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/how-it-work-fr" : "/how-it-works"}>{this.state.french ? "Comment cela fonctionne" : "How It Works"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/colour-guide-fr" : "/colour-guide"}>{this.state.french ? `Guide des Couleurs` : `${this.props.location === "us" ? " Color" : " Colour"} Guide`}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/design-guide-fr" : "/design-guide"}>{this.state.french ? "Guide de Mesure" : "Measuring Guide"}</a>
          </div>
        </div>

        <div className={"navbar-web-hover-column-narrow"}>
          <div className={"navbar-web-hover-title"}>
            {this.state.french ? "Assistance" : "Support"}
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/returns-fr" : "/returns"}>{this.state.french ? "Retour" : "Returns"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/shipping-fr" : "/shipping"}>{this.state.french ? "Expédition" : "Shipping"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/policies-fr" : "/policies"}>{this.state.french ? "Politique" : "Policies"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/faq-fr" : "/faq"}>{this.state.french ? "FAQ" : "FAQ"}</a>
          </div>
        </div>



        <div className={"navbar-web-hover-column-narrow"}>
          <div className={"navbar-web-hover-title"}>
            {this.state.french ? "Avis" : "Reviews"}
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/review-fr" : "/review"}>{this.state.french ? "Poster un Avis" : "Post a Review"}</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href={this.state.french ? "/customer-reviews" : "/customer-reviews"}>{this.state.french ? "Lisez nos Avis" : "Read our Reviews"}</a>
          </div>
        </div>

        <div className={"navbar-web-hover-column-narrow"}>
          <div className={"navbar-web-hover-title"}>
            {this.state.french ? "Tarifs" : "Pricing"}
          </div>
            <div className={"navbar-web-hover-button"}>
                <a href={this.state.french ? "/pricing-and-discounts-fr" : "/pricing-and-discounts"}>{this.state.french ? "Prix et Rabais" : "Pricing and Discounts"}</a>
            </div>
          {/*<div className={"navbar-web-hover-button"}>*/}
          {/*  <a href={this.state.french ? "/get-quote-fr" : "/get-quote"}>{this.state.french ? "Obtenir un Devis" : "Get a Quote"}</a>*/}
          {/*</div>*/}
          {/*  <div className={"navbar-web-hover-button"}>*/}
          {/*      <a href={this.state.french ? "/quantity-quote-fr" : "/quantity-quote"}>{this.state.french ?  "Devis quantité" : "Quantity Order Quote"}</a>*/}
          {/*  </div>*/}
          {/*  <div className={"navbar-web-hover-button"}>*/}
          {/*      <a href={this.state.french ? "/price-estimate-fr" : "/price-estimate"}>{this.state.french ? "Estimation de Prix" : "Price Estimate Calculator"}</a>*/}
          {/*  </div>*/}
        </div>

      </div>
    } else if (hoveredButton === "languages") {return <div className={this.state.fetchLanguage ? "navbar-web-hover" : "no-display"}>

        <div className={"navbar-web-hover-column-narrow"}>
          <div className={"navbar-web-hover-title"}>
            Languages
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href="https://www.custommat.com">English</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href="https://fr.custommat.com">Français</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href="https://es.custommat.com">Español</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href="https://de.custommat.com">Deutsch</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href="https://it.custommat.com">Italiano</a>
          </div>
          <div className={"navbar-web-hover-button"}>
            <a href="https://nl.custommat.com">Nederlands</a>
          </div>
        </div>

        <div className={"navbar-web-hover-column-narrow"}>
          <div className={"navbar-web-hover-title"}>
            Currency
          </div>
          {/*<div className={"navbar-web-hover-button"}>*/}
          {/*  <a>Australian Dollars (AUD)</a>*/}
          {/*</div>*/}
          {/*<div className={"navbar-web-hover-button"}>*/}
          {/*  <a>*/}
          {/*    British Pounds (GBP)*/}
          {/*  </a>*/}
          {/*</div>*/}
          <div className={this.props.currency === "AUD" ? "navbar-web-hover-button bold" : "navbar-web-hover-button"} onClick={() => this.props.changeCurrency("AUD")}>
            <a>
              Australian Dollars (AUD)
            </a>
          </div>
          <div className={this.props.currency === "GBP" ? "navbar-web-hover-button bold" : "navbar-web-hover-button"} onClick={() => this.props.changeCurrency("GBP")}>
            <a>
              British Pounds (GBP)
            </a>
          </div>
          <div className={this.props.currency === "CAD" ? "navbar-web-hover-button bold" : "navbar-web-hover-button"} onClick={() => this.props.changeCurrency("CAD")}>
            <a>
              Canadian Dollars (CAD)
            </a>
          </div>
          <div className={this.props.currency === "EUR" ? "navbar-web-hover-button bold" : "navbar-web-hover-button"} onClick={() => this.props.changeCurrency("EUR")}>
            <a>
              Euro (EUR)
            </a>
          </div>
          <div className={this.props.currency === "NZD" ? "navbar-web-hover-button bold" : "navbar-web-hover-button"} onClick={() => this.props.changeCurrency("NZD")}>
            <a>
              New Zealand Dollars (NZD)
            </a>
          </div>
          <div className={this.props.currency === "USD" ? "navbar-web-hover-button bold" : "navbar-web-hover-button"} onClick={() => this.props.changeCurrency("USD")}>
            <a>
              US Dollars (USD)
            </a>
          </div>
        </div>

      <div className={"navbar-web-hover-column-narrow"}>
        <div className={"navbar-web-hover-title"}>
          Units of Measurement
        </div>
        {/*<div className={"navbar-web-hover-button"}>*/}
        {/*  <a>Australian Dollars (AUD)</a>*/}
        {/*</div>*/}
        {/*<div className={"navbar-web-hover-button"}>*/}
        {/*  <a>*/}
        {/*    British Pounds (GBP)*/}
        {/*  </a>*/}
        {/*</div>*/}
        <div className={this.state.unit === "in" ? "navbar-web-hover-button bold" : "navbar-web-hover-button"} onClick={() => this.changeMeasurementUnit("in")}>
          <a>
            Inches (in)
          </a>
        </div>
        {/*<div className={"navbar-web-hover-button"}>*/}
        {/*  <a>*/}
        {/*    Euro (EUR)*/}
        {/*  </a>*/}
        {/*</div>*/}
        <div className={this.state.unit === "cm" ? "navbar-web-hover-button bold" : "navbar-web-hover-button"} onClick={() => this.changeMeasurementUnit("cm")}>
          <a>
            Centimeters (cm)
          </a>
        </div>
      </div>




      </div>
    }

  }

  fetchNavbarTop(fixedOrNotFixed) {

    if (fixedOrNotFixed) {
      return <div>
        <div className={`display navbar-v2`}>
          <div className={"navbar-top-mobile-button"} onClick={() => {
            this.showMobileNavPanel()
          }}>
            <img src={mobileMenu}
                 alt={this.state.french ? "Cliquez pour développer le menu mobile." : `Click to expand mobile menu.`}/>
          </div>

          <div className={"navbar-top-button-groups"}>

            {/*<div className={this.props.onGoingPromotion ? "notice-bar-version2-event" : "notice-bar-version2"}>*/}
            <div className={"notice-bar-version2"}>

            {this.fetchEventHeadline()}

              <a href={this.state.french ? "/faq-fr" : "/faq"} className={"notice-bar-contact-us mobile-no-display tablet-no-display"}>
                {this.state.french ? "Contactez-nous" : "Contact Us"}
              </a>

              {/*<div className={"notice-bar-region mobile-no-display tablet-no-display"}>*/}
              {/*  <img className={"notice-bar-region-icon"}*/}
              {/*       src={this.props.location === "us" ? usFlag : canadaFlag}*/}
              {/*  />*/}
              {/*  <div className={"notice-bar-region-text mobile-no-display tablet-no-display"}>*/}
              {/*    {this.props.location === "us" ? `USA (USD)` : `Canada (CAD)`}*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>


            <div className={"logo-vertical-v2"}>
              <a href={this.state.french ? "/-fr" : "/"}>
                <img src={logoV2}
                     alt={this.state.french ? "Retour à la page principale." : "Return to the main page."}
                />
              </a>
            </div>

            <div href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}
                 className={"navbar-button-v2 how-it-works-v2"}
                 onMouseEnter={() => this.setState({fetchOurMatboardsButton: true})}
                 onMouseLeave={() => this.setState({fetchOurMatboardsButton: false})}
            >
              {this.state.french ? "Notre Passe-Partout" : "Our Matboards"}
              {this.fetchNavBarHovering("ourmatboard")}

            </div>


            <a href={this.state.french ? "/how-it-works-fr" : "/how-it-works"}
               className={"navbar-button-v2 how-it-works-v2"}>
              {this.state.french ? "Comment ça Marche" : "How it works"}
            </a>

            <a href={this.state.french ? "/inspiration" : "/inspiration"} className={"navbar-button-v2 inspiration-v2"}
               onMouseEnter={() => this.setState({fetchInspirationButton: true})}
               onMouseLeave={() => this.setState({fetchInspirationButton: false})}
            >
              {this.state.french ? "Inspiration" : "Inspiration"}
              {this.fetchNavBarHovering("inspiration")}
            </a>

            <div className={"navbar-button-v2 support-v2"}
                 onMouseOver={() => this.setState({fetchSupport: true})}
                 onMouseLeave={() => this.setState({fetchSupport: false})}
            >
              {this.state.french ? "Assistance" : "Support"}

              {this.fetchNavBarHovering("support")}
              {/*{this.fetchSupportDropdown()}*/}
            </div>

            <div className={"navbar-button-v2 support-v2"}
                 onMouseOver={() => this.setState({fetchLanguage: true})}
                 onMouseLeave={() => this.setState({fetchLanguage: false})}
            >
              Languages & Settings
              {this.fetchNavBarHovering("languages")}
            </div>


            <div className={"bag-v2 mobile-menu-button"} id={"mobile-menu-button-tablet"}
                 style={{paddingTop: "3px"}}
                 onClick={() => {
                   this.showMobileNavPanel()
                 }}>

              <img className={this.state.openMobileNavBar ? "no-display" : "bag-icon-v2"} src={mobileMenuButtonV2}
                   alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
              />
              <img className={this.state.openMobileNavBar ? "bag-icon-v2" : "no-display"} alt={"Click here for menu."}
                   alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
                   onClick={() => {
                     this.showMobileNavPanel()
                   }} src={closeOutIcon}/>
            </div>

            <div className={"bag-v2"} onClick={() => {
              this.props.showShoppingCartModal("open")
            }}>
              <img className={"bag-icon-v2"} src={bag}
                   alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
              />
              <div className={"cart-item-count-container"}>
                <div className={"cart-item-count"}>
                  {this.totalItemCounter(this.props.shoppingCartItems)}
                </div>
              </div>
            </div>

            <div className={"navbar-start-button mobile-no-display"}>
              <a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}>
                <div className={"landing-page-button-standard-v2"}>
                  {this.state.french ? "Je crée " : "START DESIGNING"}
                </div>
              </a>
            </div>
          </div>

          <div className={"language-switcher"}>

          </div>

          <div className={this.state.openMobileNavBar ? "mobile-navbar-open-wide" : "no-display"}>
            {this.fetchMobileNavBar()}
            <div style={{width: "100%", height: "100vh", zIndex: 10}} onClick={() => {this.clickCloseModalButton()}}>

            </div>
          </div>

        </div>
      </div>
    } else {
      return <div>
        <div className={`display navbar-v2-non-fixed`}>
          <div className={"navbar-top-mobile-button"} onClick={() => {
            this.showMobileNavPanel()
          }}>
            <img src={mobileMenu}
                 alt={this.state.french ? "Cliquez pour développer le menu mobile." : `Click to expand mobile menu.`}/>
          </div>

          <div className={"navbar-top-button-groups"}>

            <div className={this.props.onGoingPromotion ? "notice-bar-version2-event" : "notice-bar-version2"}>
              {this.fetchEventHeadline()}

              <a href={this.state.french ? "/faq-fr" : "/faq"} className={"notice-bar-contact-us mobile-no-display tablet-no-display"}>
                {this.state.french ? "Contactez-nous" : "Contact Us"}
              </a>

              <div className={"notice-bar-region mobile-no-display tablet-no-display"}>
                <img className={"notice-bar-region-icon"}
                     src={this.props.location === "us" ? usFlag : canadaFlag}
                />
                <div className={"notice-bar-region-text mobile-no-display"}>
                  {this.props.location === "us" ? `USA (USD)` : `Canada (CAD)`}
                </div>
              </div>
            </div>


            <div className={"logo-vertical-v2"}>
              <a href={this.state.french ? "/-fr" : "/"}>
                <img src={logoV2}
                     alt={this.state.french ? "Retour à la page principale." : "Return to the main page."}
                />
              </a>
            </div>

            <div href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}
                 className={"navbar-button-v2 how-it-works-v2"}
                 onMouseEnter={() => this.setState({fetchOurMatboardsButton: true})}
                 onMouseLeave={() => this.setState({fetchOurMatboardsButton: false})}
            >
              {this.state.french ? "Notre Passe-Partout" : "Our Matboards"}
              {this.fetchNavBarHovering("ourmatboard")}

            </div>




            <a href={this.state.french ? "/inspiration" : "/inspiration"} className={"navbar-button-v2 inspiration-v2"}
               onMouseEnter={() => this.setState({fetchInspirationButton: true})}
               onMouseLeave={() => this.setState({fetchInspirationButton: false})}
            >
              {this.state.french ? "Inspiration" : "Inspiration"}
              {this.fetchNavBarHovering("inspiration")}
            </a>

            <div className={"navbar-button-v2 support-v2"}
                 onMouseOver={() => this.setState({fetchSupport: true})}
                 onMouseLeave={() => this.setState({fetchSupport: false})}
            >
              {this.state.french ? "Assistance" : "Guide & Support"}

              {this.fetchNavBarHovering("support")}
              {/*{this.fetchSupportDropdown()}*/}
            </div>



            <div className={"bag-v2 mobile-menu-button"} id={"mobile-menu-button-tablet"}
                 style={{paddingTop: "3px"}}
                 onClick={() => {
                   this.showMobileNavPanel()
                 }}>

              <img className={this.state.openMobileNavBar ? "no-display" : "bag-icon-v2"} src={mobileMenuButtonV2}
                   alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
              />
              <img className={this.state.openMobileNavBar ? "bag-icon-v2" : "no-display"} alt={"Click here for menu."}
                   alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
                   onClick={() => {
                     this.showMobileNavPanel()
                   }} src={closeOutIcon}/>
            </div>

            <div className={"bag-v2"} onClick={() => {
              this.props.showShoppingCartModal("open")
            }}>
              <img className={"bag-icon-v2"} src={bag}
                   alt={this.state.french ? "Cliquez pour voir le panier." : "Click to view the shopping cart."}
              />
              <div className={"cart-item-count-container"}>
                <div className={"cart-item-count"}>
                  {this.totalItemCounter(this.props.shoppingCartItems)}
                </div>
              </div>
            </div>

            <div className={"navbar-start-button mobile-no-display"}>
              <a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}>
                <div className={"landing-page-button-standard-v2"}>
                  {this.state.french ? "Je crée " : "START DESIGNING"}
                </div>
              </a>
            </div>
          </div>


          <div className={this.state.openMobileNavBar ? "mobile-navbar-open-wide" : "no-display"}>
            {this.fetchMobileNavBar()}
            <div style={{width: "100%", height: "100vh", zIndex: 10}} onClick={() => {this.clickCloseModalButton()}}>

            </div>
          </div>

        </div>
      </div>

    }
  }

  render(props) {
    return <div className={"navbar-top-container"}>

      {this.fetchNavbarTop(true)}

      <CartSlider
          openShoppingCartModal={this.props.openShoppingCartModal}
          navbarTopSetState={this.navbarTopSetState}
          showShoppingCartModal={this.props.showShoppingCartModal}
          shoppingCartItems={this.props.shoppingCartItems}
          parentSetStateCallback={this.props.parentSetStateCallback}
          updateItemList={this.props.updateItemList}
          calculateItemSubtotal={this.props.calculateItemSubtotal}
          totalItemCounter={this.totalItemCounter}
          getDiscountMultiplier={this.props.getDiscountMultiplier}
          french={this.state.french}
          location={this.props.location}
          currency={this.props.currency}
          fxConversion={this.props.fxConversion}
          onGoingPromotion={this.props.onGoingPromotion}
          promotionRate={this.props.promotionRate}
      />

      <div className={(this.state.fetchInspirationButton || this.state.fetchOurMatboardsButton || this.state.fetchSupport) ? "display-bottom-blur" : "no-display"}>


      </div>

    </div>
  }
}