import {productDB} from "../comp-pricing/productDB";

export const faqContents = {
  products: {
    headerTitle: "How it works",
    questions: {
      question1: {
        question: "How does your service work?",
        answers: [
            "1. Select the product you like and design your own matboard, picture frame, or polaroid frame.",
            `2. Once you place an order, we typically ship within 1-2 business days. You should receive your order within 2-8 business days.`,
        ],
      },
      question6: {
        question: "We have 100% customer satisfaction policy",
        answers: [
          "We care about your happiness, and we take pride in our work.",
          "If you are not satisfied, please email us and let us know. We will work with you until we make it right.",
        ],
      },
      question2: {
        question: "How do you source your matboards and frames?",
        answers: [`We carry dozens of matboards from several suppliers, including industry leader Larson Juhl. We only partner with suppliers with a proven record of high-quality production.`],
      },
      question3: {
        question: "Where & how do you process your matboard?",
          answers: [
            `Our matboards are manufactured in three different locations -- Canada, United Kingdom, and the United States.`,
            `Our office, production facility, and shipping facility are all located in Vancouver, Canada. Our matboards are cut with industry-standard equipment designed to cut precisely at 45 degrees (bevel cutting).`,
          ]
      },
      question4: {
        question: "Can I try a sample mat boards",
        answers: [`We offer a set of sample matboards for free of charge. Please email us for more information.`]
      },
      question5: {
        question: "Can you tell me a little bit more about your matboard quality?",
        answers: [
          `CustomMat matboards:`,
          `
            <li>Acid-free (acid-neutralized) surface, core, and backing for artwork preservation.</li>
            <li>4-ply, coherent colouring, and high quality board. </li>
            <li>Alkaline - buffered to a pH of 7.5 - 9.5 for preservation needs.</li>
            <li>Smooth and consistent surface and paper colours. Bleed & fade resistant.</li>
            <li>Produced and processed in Canada, the United States, and the United Kingdom. They are all compliant with environmental standards.</li>
            <li>Ideal for general decorative framing applications. (Not recommended for museum / conservation framing).</li>
            <li>Cut on a 45-degree angel with a bevel cutter.</li>
          `,
        ]
      },
    },
  },

  shipping: {
    headerTitle: "Delivery & Shipping",
    questions: {
      question1: {
        question: "What countries do you ship to?",
        answers: ["We ship our products anywhere within Canada and the United States."],
      },
      question2: {
        question: "How much is shipping?",
        answers: [
          `Free Shipping across Canada & the USA`
        ],
      },
      question3: {
        question: "How can I track my order?",
        answers: ["Please check your shipment notification emaill. Once your order is shipped, we send you with the tracking details. If you don't have in your inbox, please check promotions or spam folder."],
      },
      question4: {
        question: "Do you offer express/overnight shipping?",
        answers: [
            `To optimize customer shipping costs, we do not offer overnight shipping. If you are based in Vancouver, please feel free to select “Vancouver pickup” when you check out to collect your order faster.`
        ],
      },
      question5: {
        question: "Where is your Vancouver pick-up location?",
        answers: [`We are located in Strathcona, Vancouver (1147 Grant Street, 1ST FL, Vancouver).`]
      },
      question6: {
        question: "I’m ordering from the US, do I have to worry about taxes?",
        answers: [
            "US CBP (Customs and Border Protection) indicates following:",
            `"Packages whose declared value is under $800 ($100 if being sent as a gift to someone other than the purchaser) will generally be cleared without any additional paperwork prepared by CBP."`,
            `You won't be charged any state, local, municipality, or Canada/US federal taxes for your orders.`
        ],
      },
    }
  },

  refunds: {
    headerTitle: "Returns & Changes",
    questions: {
      question6: {
        question: "We have 100% customer satisfaction policy",
        answers: [
          "We care about your happiness, and we take pride in our work.",
          "If you are not satisfied, please email us and let us know. We will work with you until we make it right.",
        ],
      },
      question1: {
        question: "I want to change or cancel my order.",
        answers: ["Please refer to our <a href='returns'>returns</a> section.",
          "Did you place an order within last few hours?",
          "Please go to <a href='/tracking' >tracking</a> tab above and enter your email and zip code used for your order. If your order is not fully processed, you can change or cancel your order.",
          "If we cancel your order, your credit card payments will be refunded usually within in several business days.",
          "Did you place your order more than 24 hours ago?",
          "If your order has been completed and ready to be shipped, we cannot change or cancel your order.",
          "If your need more assistance, please do not hesitate to contact us via hi@CustomMat.com. We usually respond within hours.",
        ],
      },
      question2: {
        question: "I received a damaged product.",
        answers: [
          "First, we are very sorry. We do our best to prevent any damage. But during shipping, small damages may happen.",
          "If you receive a mat boards with small bending at the corners, first, try on your picture frame. As the corners sit inside the frame, they may not show up on your picture frame.",
          "However, if you received a mat boards with a bigger damage, please let us know. We will prioritize and replace your order."
        ],
      },
      question3: {
        question: "I accidentally provided wrong measurement.",
        answers: [
          "If you provided an incorrect measurement, please send us an email with your order information at your earliest convenience. We will try our best to accommodate your changes.",
          "However, if we already cut your matboard (typically within 24 hours of cutting the mat boards), we cannot issue a refund.",
        ],
      },
      question4: {
        question: "Changed mind or no longer needed.",
        answers: [
          "We are more than happy to issue a refund if your work has not been fully processed.",
          "However, if we already cut your matboard (typically within 24 hours of cutting the mat boards), we cannot issue a refund.",
        ],
      },
      question5: {
        question: "I need to change my shipping address. What shall I do?",
        answers: [
          "Please send us an email at your earliest convenience. We will try to resolve as much as we can.",
        ],
      },
    },
  },

  // pricing: {
  //   headerTitle: "Pricing",
  //   questions: {
  //
  //   },
  // },

  others: {
    headerTitle: "Other Questions",
    questions: {
      question4: {
        question: "Don't have a ruler. Any alternatives?",
        answers: [
          "Please see following page for ruler alternatives. It's much easier than you think! :)",
          `<a href="/how-to-measure-mat-board-without-a-ruler" target="_blank" rel="noopener noreferrer">Our ruler alternatives</a>`
        ]
      },
      question1: {
        question: "What is your phone number?",
        answers: [
          "You can reach us via email. We typically respond in hours, if not minutes. In case of urgent matters, you can reach us at +1 226-828-1705.",
        ]
      },
      question2: {
        question: "Is my account secure? Do you store my credit card information?",
        answers: [
          "Your Customer Data:",
          "At CustomMat.ca, we store all your client information on Google’s secured Firebase database. Of course, for maximum security, all your data points will be ENCRYPTED with highly complex ciphering algorithms. We do our best to store your order information safely. ",
          "Your Payments Data:",
          "We DO NOT save your credit card information. We don’t even see your credit card number. All of our payments are linked to Stripe, our third-party financial services provider. Your credit card information and handling will be done through Stripe.",
          "Stripe is one of the most favored payment processors among tech companies. It’s security has been proved by years of experience and thousands of companies, using Stripe’s services.",
        ]
      },
      question3: {
        question: "When is my credit card charged?",
        answers: [
          "Your credit card will be charged when you place an order. It will be shown up as “Custom Mats” on your credit card statement.",
          "If you place an order in Canada dollars, you will be charged with Canada dollars. If you place an order in US dollars, you will be charged in US dollars."],
      },

    }
  }
};