import React from "react";
import homeinoak from "../../../resources/images/inspirationsPhoto/homeinoak-IG.PNG";
import IGLogo from "../../../resources/logo/IG-Logo.svg";
import kristinadapaah1 from "../../../resources/images/inspirationsPhoto/kristinadapaah-IG.jpg";
import julesInteriors5 from "../../../resources/images/inspirationsPhoto/jules_interiors-IG-5.PNG";
import emilyKLewis from "../../../resources/images/inspirationsPhoto/emily.k.lewis-IG.PNG";
import LazyLoad from 'react-lazyload';
import save from '../../../resources/svgIcons/money-bill-1-solid.svg';
import crop from '../../../resources/svgIcons/crop-simple-solid.svg';
import change from '../../../resources/svgIcons/arrow-right-arrow-left-solid.svg';
import online from '../../../resources/svgIcons/wifi-solid.svg';

export default class InstagramLoader extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      // testValue: 1,
      scrolled: false,
      currencySelected: "CAD",
      displayType: "type1",

    };

  };

  fetchEnglish() {
    return <div className={"instagram-controller-container"}>


      <div className="instagram-scroller">
        <div className={"instagrom-scrollerpost-container"}>

          <LazyLoad>
            <img className={"inspiration-post-col-photo-landing-page"} src={kristinadapaah1}
                 alt={"Actual image from our customers."}
            />
          </LazyLoad>

          <div className={"inspiration-post"}>
            <div className={"inspiration-logo-name"}>
              Customer Image
            </div>
            <div className={"inspiration-logo-name-subscript"}>
              <a href={"https://www.instagram.com/p/CNVhgwDFuCf/"}>
                @kristina_pah
              </a>
            </div>
          </div>
        </div>

      </div>

      <div className="instagram-scroller-right">
        <div className="instagram-scroller-right-title">
          100% Satisfaction Guaranteed
        </div>
        <div className="instagram-scroller-right-subtitle">
          If you are not 100% satisfied with your matboard, we will send you a <b>replacement matboard</b> or <b>refund</b> you right away.
        </div>
      </div>

    </div>
  }

  fetchFrench() {
    return <div className={"instagram-controller-container"}>


      <div className="instagram-scroller">
        <div className={"instagrom-scrollerpost-container"}>

          <LazyLoad>
            <img className={"inspiration-post-col-photo-landing-page"} src={kristinadapaah1}
                 alt={"Actual image from our customers."}
            />
          </LazyLoad>

          <div className={"inspiration-post"}>
            <div className={"inspiration-logo-name"}>
              Image réelle de nos clients.
            </div>
            <div className={"inspiration-logo-name-subscript"}>
              <a href={"https://www.instagram.com/p/CNVhgwDFuCf/"}>
                @kristina_pah
              </a>
            </div>
          </div>
        </div>

      </div>

      <div className="instagram-scroller-right">
        <div className="instagram-scroller-right-title">
         Satisfaction 100 % garantie.
        </div>
        <div className="instagram-scroller-right-subtitle">
          Si vous n'êtes pas entièrement satisfait de votre passe-partout, nous vous enverrons un <b>remplacement</b> ou vous <b>rembourserons</b> immédiatement.
        </div>
      </div>

    </div>
  }

  fetchGerman() {
    return <div className={"instagram-controller-container"}>


      <div className="instagram-scroller">
        <div className={"instagrom-scrollerpost-container"}>

          <LazyLoad>
            <img className={"inspiration-post-col-photo-landing-page"} src={kristinadapaah1}
                 alt={"Echtes Bild von unseren Kunden."}
            />
          </LazyLoad>

          <div className={"inspiration-post"}>
            <div className={"inspiration-logo-name"}>
              Reales Bild von unseren Kunden.
            </div>
            <div className={"inspiration-logo-name-subscript"}>
              <a href={"https://www.instagram.com/p/CNVhgwDFuCf/"}>
                @kristina_pah
              </a>
            </div>
          </div>
        </div>

      </div>

      <div className="instagram-scroller-right">
        <div className="instagram-scroller-right-title">
          100 % Zufriedenheit garantiert.
        </div>
        <div className="instagram-scroller-right-subtitle">
          Wenn Sie mit Ihrem Passepartout nicht zu 100 % zufrieden sind, senden wir Ihnen umgehend ein <b>Ersatzpassepartout</b> oder erstatten Ihnen den <b>Vollbetrag</b>.
        </div>
      </div>

    </div>
  }

  fetchSpanish() {
    return <div className={"instagram-controller-container"}>


      <div className="instagram-scroller">
        <div className={"instagrom-scrollerpost-container"}>

          <LazyLoad>
            <img className={"inspiration-post-col-photo-landing-page"} src={kristinadapaah1}
                 alt={"Imagen real de nuestros clientes."} />
          </LazyLoad>

          <div className={"inspiration-post"}>
            <div className={"inspiration-logo-name"}>
              Imagen real de nuestros clientes.
            </div>
            <div className={"inspiration-logo-name-subscript"}>
              <a href={"https://www.instagram.com/p/CNVhgwDFuCf/"}>
                @kristina_pah
              </a>
            </div>
          </div>
        </div>

      </div>

      <div className="instagram-scroller-right">
        <div className="instagram-scroller-right-title">
          100 % satisfacción garantizada.
        </div>
        <div className="instagram-scroller-right-subtitle">
          Si no está 100 % satisfecho con su passepartout, le enviaremos inmediatamente un <b>passepartout de reemplazo</b> o le reembolsaremos el <b>monto completo</b>.
        </div>
      </div>

    </div>
  }

  fetchItalian() {
    return <div className={"instagram-controller-container"}>

      <div className="instagram-scroller">
        <div className={"instagrom-scrollerpost-container"}>

          <LazyLoad>
            <img className={"inspiration-post-col-photo-landing-page"} src={kristinadapaah1}
                 alt={"Immagine reale dei nostri clienti."} />
          </LazyLoad>

          <div className={"inspiration-post"}>
            <div className={"inspiration-logo-name"}>
              Immagine reale dei nostri clienti.
            </div>
            <div className={"inspiration-logo-name-subscript"}>
              <a href={"https://www.instagram.com/p/CNVhgwDFuCf/"}>
                @kristina_pah
              </a>
            </div>
          </div>
        </div>

      </div>

      <div className="instagram-scroller-right">
        <div className="instagram-scroller-right-title">
          Soddisfazione garantita al 100%.
        </div>
        <div className="instagram-scroller-right-subtitle">
          Se non sei soddisfatto al 100% del tuo passepartout, ti invieremo immediatamente un <b>passepartout sostitutivo</b> o ti rimborseremo l'<b>importo totale</b>.
        </div>
      </div>

    </div>
  }

  fetchDutch() {
    return <div className={"instagram-controller-container"}>

      <div className="instagram-scroller">
        <div className={"instagrom-scrollerpost-container"}>

          <LazyLoad>
            <img className={"inspiration-post-col-photo-landing-page"} src={kristinadapaah1}
                 alt={"Echte afbeelding van onze klanten."} />
          </LazyLoad>

          <div className={"inspiration-post"}>
            <div className={"inspiration-logo-name"}>
              Echte afbeelding van onze klanten.
            </div>
            <div className={"inspiration-logo-name-subscript"}>
              <a href={"https://www.instagram.com/p/CNVhgwDFuCf/"}>
                @kristina_pah
              </a>
            </div>
          </div>
        </div>

      </div>

      <div className="instagram-scroller-right">
        <div className="instagram-scroller-right-title">
          100% Tevredenheid gegarandeerd.
        </div>
        <div className="instagram-scroller-right-subtitle">
          Als je niet voor 100% tevreden bent met je passepartout, sturen we je direct een <b>vervangend passepartout</b> of we betalen je het <b>volledige bedrag</b> terug.
        </div>
      </div>

    </div>
  }


  render() {
    if (this.props.country === "France") {
      return this.fetchFrench()
    } else if (this.props.country === "Germany") {
      return this.fetchGerman()
    } else if (this.props.country === "Spain") {
      return this.fetchSpanish()
    } else if (this.props.country === "Italy") {
      return this.fetchItalian()
    } else if (this.props.country === "Netherlands") {
      return this.fetchDutch()
    }

    else {
      return this.fetchEnglish()
    }
  }
}