import React from 'react';
import photo1 from "../../../resources/images/blog/howAndWhereToFindMatboards/Capture4.PNG"
import photo2 from "../../../resources/images/blog/howAndWhereToFindMatboards/Capture3.PNG";
import photo3 from "../../../resources/images/blog/howAndWhereToFindMatboards/Capture5.PNG";
import photo4 from "../../../resources/images/blog/howAndWhereToFindMatboards/Capture.PNG";
import photo5 from "../../../resources/images/blog/howAndWhereToFindMatboards/Capture2.PNG";
import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";

export default class HowToFindARightFrameForYourArtwork extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>How to Find a Right Picture Frame for Your Artwork</title>
                <meta name="description" content="It’s much easier than it seems. Tens of thousands of our customers have approached tackling their own framing DIYs with pleasing professional results. It’s so simple."/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                How to Find a Right Frame for Your Artwork
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Bargain Hunting
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Daniel S<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo3} className={"blog-content-photo"}/>
            </LazyLoad>


            <div className={"blog-article-content"}>

                Assembling framing components may sound like an artisanal realm. However, it’s much easier than it seems. Tens of thousands of our customers have approached tackling their own framing DIYs with pleasing professional results. It’s so simple.

                <br/><br/>

                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <b>Here's how you do it:</b>
                <ol>
                    <li>Find the width and height of your artwork.</li>
                    <li>Find a picture frame that is bigger than your artwork.</li>
                    <li>Get a custom matboard that bridges the gap.</li>
                </ol>

                <br/>

                In theory, these are the trade secrets and trips of the pros. As well as the general 101 of how to correctly measure your desired matboard while choosing the appropriate frame. Here are some articles we have that are useful and provide answers to many FAQs or continue reading below to explore our advanced tutorial before tackling this DIY.

                <ol>
                    <li><a href={"/design-guide"}>Click here to learn more about how to get the dimensions for your matboard.</a></li>
                    <li><a href={"/ikea-frame-matboard-sizing-guide"}>Click here to learn more about the popular standard sized frames.</a></li>
                    <li><a href={"/inspiration"}>Click here to see the examples of our customer's’ work.</a></li>
                </ol>

                <br/>

                <LazyLoad>
                    <img src={photo2} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className="blog-article-photo-description">
                    Alexa used our custom matboard to fit her artwork into a standard-sized frame.<br/>
                    Our custom matboards are designed to bridge the gap between your frame and yours artwork perfectly.
                </div>

                <LazyLoad>
                    <img src={photo4} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className="blog-article-photo-description">
                    How to place a customized matboard order on CustomMat
                </div>

                <br/><br/>

                <b>Advanced Tutorial:</b><br/><br/>
                <b>How to measure Matboard Dimensions:</b>
                <br/>

                If you are unsure about the size of your actual frame (this happens when you are changing the artwork of a custom framed piece), you should measure the overall size of the existing mat (if there is one) or size of the backing board or glazing (size of the glass or acrylic) of the frame that you are trying to repurpose.

                <br/><br/>

                If the acrylic or glass that is inside the frame doesn’t shift around too much (meaning the glazing fits the frame well), then the acrylic or glass would be a good indicator to get matboard. If this is the situation, your matboard size should be same as the width and height of the acrylic or the glass of your existing frame.

                <br/><br/>

                For your artwork window dimensions (the size of the cut out where your fine art or art print will be displayed), a good rule of thumb is that the opening should be SMALLER than the print or the photo. For instance, if your photo print is 8 x 10 inches (standard size photo print), then your opening should a quarter to half inch smaller. So, it should be either 7.75 x 9.75 inches or 7.5 x 9.5 inches.


                <br/><br/>

                Cutting a matte board to the exact dimensions of the outer border of a picture or artwork is never recommended. Your components will not be lined up seamlessly from the back of the mat board — they will fall through the matboard’s opening.

                <br/><br/>

                Just for reference, typical off the shelf, standard sized frames that are purchased from the online, retail stores, or art supply stores usually have the pre-cut matboard opening that is about a half inch smaller than the intended picture size.


                <br/><br/>

                <b>How big should matting be?</b>
                <br/>

                Great question! This is an area where your artistic preferences create a custom touch to suit your desired look. Typically, we recommend at least 1 inch matting for a smaller piece of art or 2 or more inches for a larger piece.

                <br/><br/>

                A quick rule of thumb would be the photo mat should be about 1.5 - 2 times the size of the frame to keep the picture frame from taking too much attention from your artwork. However, this is often decided by the size of the standard frames that you will be purchasing.

                <br/><br/>

                If your picture frame is bigger than your artwork, this usually works out as intended. <a href={"/customize-matboards"}>Click here and play with our visualization tool.</a>

                <br/><br/>

                Most of the custom frame shop professionals would recommend the mat boarder to be somewhere between 1 - 4 inches. Professional framers usually recommend traditional the method that is designed not to overwhelm the art. It also creates photo depth against the frame.

                <br/><br/>

                For the coloring of the matboard, your artistic preferences are involved here too. Typically, most of our customers use neutral coloured matboards — Smooth White and China White. They are the two most popular colours. However, depending on what you are looking to frame, you can choose different colours and color layering options.

                <br/><br/>

                For example, if you are framing the signed photographs of your favorite sports team player, then your team color can come in.  The same option is quite popular for certificates and diplomas.

                <br/><br/>

                <b>How to add the Depth to your Painting without Increasing the Size?</b>

                <br/>

                If you want to add the depth to your artwork, a good way to go is adding layers of matboards. Instead of single mat, you can opt for a double or triple mat. You can do so simple by checking the double matting option.
                <br/><br/>

                You can use same or different colours — our customers often choose different colours for double matting options. For the multiple layers of the matting, you will see mostly the top layers, and small layers. Typically, they reveal increments of multiple quarter inch layers. Quarter and half inch reveals are the most popular options.

                <br/><br/>

                The double matting technique enables you to add depth to your picture framing finish. It adds the accent of colored mat to make your artwork or watercolor/prints etc., pop. Typically, the top layer is the neutral color that accentuates the overall background. Then you will introduce a colour that makes your artwork pop

                <br/><br/>

                The bold or bright second layer of your double matting instantly draws the attention of your intended audience. The best way to use this is to choose a colour that is highlighted in your artwork. <a href={"/customize-matboards"}>Click here and play with Double Matting options with our visualization tool.</a>

                <br/><br/>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className="blog-article-photo-description">
                    Artists and galleries mats their artworks for presentation most of the time. Matboards serves to give your artwork a balanced look by preventing your picture frame from taking too much of audience attention.
                </div>


                <b>Final Words on Matting</b>

                <br/>

                Picture mats are important component of professional picture framing. A simple white mat that is cut perfectly bridges the gap between your picture frame and artwork. It gives your artwork a balanced look while also preventing your picture frame moulding from taking too much attention from your piece.

                <br/><br/>

                Professionally and perfectly cutting mats is an integral component of gallery wall framing. Most importantly, mats critically ensure the framed image remains flat, and provide that crucial buffer layer between the art or photo and the frame glazing. Aesthetically, mats provide elevation guaranteeing the finishing accents for the framed item. Mats establish presentation your art at its best while creating a unique but professionally handled look. Depending on the mat’s composition, it will even help preserve your artwork for a substantial period of time.

                <br/><br/>

                In other words, getting matting right is a quick trick and it’s always worth it.

                <br/><br/>

                <b>Do we need an Acid-Free Mat?</b>
                <br/>

                Getting an acid-free mat is not required, but it is generally recommended for archival purposes. For instance, acid-free matboards are much better for your artwork’s preservations. Fortunately, all our matboards here at Custom Matboard are acid-free (acid-neutralized) matboards.

                <br/><br/>

                <b>What is the Backing Board? Do I need a Backing Board or Foam Boards?</b>

                <br/>

                A mat board backing is a matboard without an opening. The backing board will have the same exterior dimensions as your primary matboard and is the exact same board material. Matboard backings can be used to sandwich your matboard and art snugly in the frame. They can also be used for float mounting or to adhere a print to if you are using fragile or thin printing material or do not want to crop your image.

                <br/><br/>

                Most of the frames do come with backing boards, and they can act as backing for your artwork. In most cases, you do not need a mat board backing. They are not always necessary. Mat board backings are typically used when your artwork or print are on the thin side and need to be stretched or affixed on top of another layer for support.

                <br/><br/>

                <b>What is Double Matting?</b>
                <br/>

                Double matting is an extra layer of matting that sits under your first mat board. It is situated between your primary mat and your artwork. Double matting’s bottom mat creates another layer of depth and an additional border to your framed work. It helps to create more focus on what is framed.

                <br/><br/>

                Double matting can be in the same colour as your primary mat, or it can be in a different colour to make your artwork stand out. Often, a dominant colour of your artwork is chosen for the double matting to emphasize the accent colour.

                <br/><br/>

                <b>What is a Bevel Cut?</b>

                <br/>

                A bevel cut is an angled cut. The default cut that we use at Custom Matboard (CustomMat.com) is beveled at 45 degrees. You can also request the cuts to be made at a 90-degree angle. This will make it so the machine will cut straight down when cutting the window, instead of making the cuts on an angle. Having a beveled cut allows the white interior core of the matboard to show through, which adds depth to the work.

                <br/><br/>

                <b>Can I have a Bevel Cut in a Different Angle?</b>

                <br/>

                Yes, we have different options — anywhere between 45 to 90 degrees. To have the 90-degree cut option, please select the 90-degree cut option on the menu. This will make it so that the interior white of the matboard is less or not visible.

                <br/><br/>

                To have the white visible with the 45-degree bevel cut, please select the 45-degree bevel option on the menu. This option is preselected as it is our default.

                <br/><br/>

                <b>How thick is your Matboard?</b>

                <br/>

                All our mat boards are standard 4 ply matboards. They are about 1.5 mm thick or about 1/16 inch thick. The majority of the standard sized frames that you can get online, or retail stores can accommodate our 4-ply matte boards.

                <br/><br/>

                <b>Do your coloured matboards have the same colour inside as on the surface?</b>
                <br/>

                No. Our coloured mat boards are only coloured on the front of the board. The back and the inside of our mat boards are white. Our mat boards’ windows are cut on a 45-degree bevel allowing the white core of the mat board to be displayed. If you would prefer your window to be cut on a 90-degree angle, please specify in the “Additional Notes” section of the Design page.

                <br/><br/>

                *Please note that all our mat boards are standard white core mats cut by default at a 45-degree bevel. We do not offer production core or solid core mats. We only offer 45-degree angle cutting or if specified, 90-degree angle cutting however we also offer almost any standard interior shape for the window opening (oval, rectangle, square, heart, etc.).

                <br/><br/>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className="blog-article-photo-description">
                    Customer Photos | Left: Custom Matboard + IKEA Ribba Black 24 x 36 (in) | Right Custom Matboard + IKEA Ribba Black 19.75 x 27.5 (in)
                </div>

                <br/>

                Here at Custom Matboard (CustomMat.com), we take pride in our high-quality products.

                We are very proud to say we provide quality mat and paper crafts at the frame industries’ competitive prices.

                In addition to that, we do have the 100% customer satisfaction policy. If you are not happy with your order, let us know and we will make it right.

                We are very proud to say that tens of thousands of customers who have received our mats have been happy with our products.

                <br/><br/>

                Please feel free to reach out to us at hi@custommat.com if you have any questions. We are here to help.


                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>



            </div>

            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>



        </div>
    }
}