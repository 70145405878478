import React from "react";
import landingPageArrowLeft from "../../../resources/logo/landing-page-arrow-left.svg";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import f5x7o4x6no1 from "../../../resources/precut-mat-options/5x7/4x6no1-min.jpg";
import f5x7o4x6no2 from "../../../resources/precut-mat-options/5x7/4x6no2-min.jpg";
import f5x7o4x6no3 from "../../../resources/precut-mat-options/5x7/4x6no3-min.jpg";

import f8x10o4x6no1 from "../../../resources/precut-mat-options/8x10/4x6no1-min.jpg";
import f8x10o4x6no2 from "../../../resources/precut-mat-options/8x10/4x6no2-min.jpg";
import f8x10o4x6no3 from "../../../resources/precut-mat-options/8x10/4x6no3-min.jpg";

import f8x10o5x7no1 from "../../../resources/precut-mat-options/8x10/5x7no1-min.jpg";
import f8x10o5x7no2 from "../../../resources/precut-mat-options/8x10/5x7no2-min.jpg";
import f8x10o5x7no3 from "../../../resources/precut-mat-options/8x10/5x7no3-min.jpg";

import f9x9o4x4no1 from "../../../resources/precut-mat-options/9x9/4x4no1-min.jpg";
import f9x9o4x4no2 from "../../../resources/precut-mat-options/9x9/4x4no2-min.jpg";
import f9x9o4x4no3 from "../../../resources/precut-mat-options/9x9/4x4no3-min.jpg";

import f9x9o5x5no1 from "../../../resources/precut-mat-options/9x9/5x5no1-min.jpg";
import f9x9o5x5no2 from "../../../resources/precut-mat-options/9x9/5x5no2-min.jpg";
import f9x9o5x5no3 from "../../../resources/precut-mat-options/9x9/5x5no3-min.jpg";

import f11x14o4x6no1 from "../../../resources/precut-mat-options/11x14/4x6no1-min.jpg";
import f11x14o4x6no2 from "../../../resources/precut-mat-options/11x14/4x6no2-min.jpg";
import f11x14o4x6no3 from "../../../resources/precut-mat-options/11x14/4x6no3-min.jpg";

import f11x14o5x7no1 from "../../../resources/precut-mat-options/11x14/5x7no1-min.jpg";
import f11x14o5x7no2 from "../../../resources/precut-mat-options/11x14/5x7no2-min.jpg";
import f11x14o5x7no3 from "../../../resources/precut-mat-options/11x14/5x7no3-min.jpg";

import f11x14o85x11no1 from "../../../resources/precut-mat-options/11x14/8.5x11no1-min.jpg";
import f11x14o85x11no2 from "../../../resources/precut-mat-options/11x14/8.5x11no2-min.jpg";
import f11x14o85x11no3 from "../../../resources/precut-mat-options/11x14/8.5x11no3-min.jpg";

import f11x14o8x10no1 from "../../../resources/precut-mat-options/11x14/8x10no1-min.jpg";
import f11x14o8x10no2 from "../../../resources/precut-mat-options/11x14/8x10no2-min.jpg";
import f11x14o8x10no3 from "../../../resources/precut-mat-options/11x14/8x10no3-min.jpg";

import f11x14o9x12no1 from "../../../resources/precut-mat-options/11x14/9x12no1-min.jpg";
import f11x14o9x12no2 from "../../../resources/precut-mat-options/11x14/9x12no2-min.jpg";
import f11x14o9x12no3 from "../../../resources/precut-mat-options/11x14/9x12no3-min.jpg";

import f12x16o4x6no1 from "../../../resources/precut-mat-options/12x16/4x6no1-min.jpg";
import f12x16o4x6no2 from "../../../resources/precut-mat-options/12x16/4x6no2-min.jpg";
import f12x16o4x6no3 from "../../../resources/precut-mat-options/12x16/4x6no3-min.jpg";

import f12x16o5x7no1 from "../../../resources/precut-mat-options/12x16/5x7no1-min.jpg";
import f12x16o85x11no1 from "../../../resources/precut-mat-options/12x16/8.5x11no1-min.jpg";
import f12x16o8x10no1 from "../../../resources/precut-mat-options/12x16/8x10no1-min.jpg";
import f12x16o9x12no1 from "../../../resources/precut-mat-options/12x16/9x12no1-min.jpg";

import f12x16o5x7no2 from "../../../resources/precut-mat-options/12x16/5x7no2-min.jpg";
import f12x16o85x11no2 from "../../../resources/precut-mat-options/12x16/8.5x11no2-min.jpg";
import f12x16o8x10no2 from "../../../resources/precut-mat-options/12x16/8x10no2-min.jpg";
import f12x16o9x12no2 from "../../../resources/precut-mat-options/12x16/9x12no2-min.jpg";

import f12x16o5x7no3 from "../../../resources/precut-mat-options/12x16/5x7no3-min.jpg";
import f12x16o85x11no3 from "../../../resources/precut-mat-options/12x16/8.5x11no3-min.jpg";
import f12x16o8x10no3 from "../../../resources/precut-mat-options/12x16/8x10no3-min.jpg";
import f12x16o9x12no3 from "../../../resources/precut-mat-options/12x16/9x12no3-min.jpg";

import f14x18o4x6no1 from "../../../resources/precut-mat-options/14x18/4x6no1-min.jpg";
import f14x18o5x7no1 from "../../../resources/precut-mat-options/14x18/5x7no1-min.jpg";
import f14x18o85x11no1 from "../../../resources/precut-mat-options/14x18/8.5x11no1-min.jpg";
import f14x18o8x10no1 from "../../../resources/precut-mat-options/14x18/8x10no1-min.jpg";
import f14x18o9x12no1 from "../../../resources/precut-mat-options/14x18/9x12no1-min.jpg";

import f14x18o4x6no2 from "../../../resources/precut-mat-options/14x18/4x6no2-min.jpg";
import f14x18o5x7no2 from "../../../resources/precut-mat-options/14x18/5x7no2-min.jpg";
import f14x18o85x11no2 from "../../../resources/precut-mat-options/14x18/8.5x11no2-min.jpg";
import f14x18o8x10no2 from "../../../resources/precut-mat-options/14x18/8x10no2-min.jpg";
import f14x18o9x12no2 from "../../../resources/precut-mat-options/14x18/9x12no2-min.jpg";

import f14x18o4x6no3 from "../../../resources/precut-mat-options/14x18/4x6no3-min.jpg";
import f14x18o5x7no3 from "../../../resources/precut-mat-options/14x18/5x7no3-min.jpg";
import f14x18o85x11no3 from "../../../resources/precut-mat-options/14x18/8.5x11no3-min.jpg";
import f14x18o8x10no3 from "../../../resources/precut-mat-options/14x18/8x10no3-min.jpg";
import f14x18o9x12no3 from "../../../resources/precut-mat-options/14x18/9x12no3-min.jpg";

import f16x20o4x6no1 from "../../../resources/precut-mat-options/16x20/4x6no1.jpg";
import f16x20o5x7no1 from "../../../resources/precut-mat-options/16x20/5x7no1.jpg";
import f16x20o85x11no1 from "../../../resources/precut-mat-options/16x20/85x11no1.jpg";
import f16x20o8x10no1 from "../../../resources/precut-mat-options/16x20/8x10no1.jpg";
import f16x20o9x12no1 from "../../../resources/precut-mat-options/16x20/9x12no1.jpg";
import f16x20o11x14no1 from "../../../resources/precut-mat-options/16x20/11x14no1.jpg";
import f16x20o12x16no1 from "../../../resources/precut-mat-options/16x20/12x16no1.jpg";

import f16x20o4x6no2 from "../../../resources/precut-mat-options/16x20/4x6no2.jpg";
import f16x20o5x7no2 from "../../../resources/precut-mat-options/16x20/5x7no2.jpg";
import f16x20o85x11no2 from "../../../resources/precut-mat-options/16x20/85x11no2.jpg";
import f16x20o8x10no2 from "../../../resources/precut-mat-options/16x20/8x10no2.jpg";
import f16x20o9x12no2 from "../../../resources/precut-mat-options/16x20/9x12no2.jpg";
import f16x20o11x14no2 from "../../../resources/precut-mat-options/16x20/11x14no2.jpg";
import f16x20o12x16no2 from "../../../resources/precut-mat-options/16x20/12x16no2.jpg";

import f16x20o4x6no3 from "../../../resources/precut-mat-options/16x20/4x6no3.jpg";
import f16x20o5x7no3 from "../../../resources/precut-mat-options/16x20/5x7no3.jpg";
import f16x20o85x11no3 from "../../../resources/precut-mat-options/16x20/85x11no3.jpg";
import f16x20o8x10no3 from "../../../resources/precut-mat-options/16x20/8x10no3.jpg";
import f16x20o9x12no3 from "../../../resources/precut-mat-options/16x20/9x12no3.jpg";
import f16x20o11x14no3 from "../../../resources/precut-mat-options/16x20/11x14no3.jpg";
import f16x20o12x16no3 from "../../../resources/precut-mat-options/16x20/12x16no3.jpg";

import f18x24o4x6no1 from "../../../resources/precut-mat-options/18x24/4x6no1.jpg";
import f18x24o5x7no1 from "../../../resources/precut-mat-options/18x24/5x7no1.jpg";
import f18x24o85x11no1 from "../../../resources/precut-mat-options/18x24/85x11no1.jpg";
import f18x24o8x10no1 from "../../../resources/precut-mat-options/18x24/8x10no1.jpg";
import f18x24o9x12no1 from "../../../resources/precut-mat-options/18x24/9x12no1.jpg";
import f18x24o11x14no1 from "../../../resources/precut-mat-options/18x24/11x14no1.jpg";
import f18x24o11x17no1 from "../../../resources/precut-mat-options/18x24/11x17no1.jpg";
import f18x24o12x16no1 from "../../../resources/precut-mat-options/18x24/12x16no1.jpg";
import f18x24o14x18no1 from "../../../resources/precut-mat-options/18x24/12x18no1.jpg";
import f18x24o16x20no1 from "../../../resources/precut-mat-options/18x24/16x20no1.jpg";

import f18x24o4x6no2 from "../../../resources/precut-mat-options/18x24/4x6no2.jpg";
import f18x24o5x7no2 from "../../../resources/precut-mat-options/18x24/5x7no2.jpg";
import f18x24o85x11no2 from "../../../resources/precut-mat-options/18x24/85x11no2.jpg";
import f18x24o8x10no2 from "../../../resources/precut-mat-options/18x24/8x10no2.jpg";
import f18x24o9x12no2 from "../../../resources/precut-mat-options/18x24/9x12no2.jpg";
import f18x24o11x14no2 from "../../../resources/precut-mat-options/18x24/11x14no2.jpg";
import f18x24o11x17no2 from "../../../resources/precut-mat-options/18x24/11x17no2.jpg";
import f18x24o12x16no2 from "../../../resources/precut-mat-options/18x24/12x16no2.jpg";
import f18x24o12x18no2 from "../../../resources/precut-mat-options/18x24/12x18no2.jpg";
import f18x24o16x20no2 from "../../../resources/precut-mat-options/18x24/16x20no2.jpg";

import f18x24o4x6no3 from "../../../resources/precut-mat-options/18x24/4x6no3.jpg";
import f18x24o5x7no3 from "../../../resources/precut-mat-options/18x24/5x7no3.jpg";
import f18x24o85x11no3 from "../../../resources/precut-mat-options/18x24/85x11no3.jpg";
import f18x24o8x10no3 from "../../../resources/precut-mat-options/18x24/8x10no3.jpg";
import f18x24o9x12no3 from "../../../resources/precut-mat-options/18x24/9x12no3.jpg";
import f18x24o11x14no3 from "../../../resources/precut-mat-options/18x24/11x14no3.jpg";
import f18x24o11x17no3 from "../../../resources/precut-mat-options/18x24/11x17no3.jpg";
import f18x24o12x16no3 from "../../../resources/precut-mat-options/18x24/12x16no3.jpg";
import f18x24o12x18no3 from "../../../resources/precut-mat-options/18x24/12x18no3.jpg";
import f18x24o16x20no3 from "../../../resources/precut-mat-options/18x24/16x20no3.jpg";

import f20x20o4x4no1 from "../../../resources/precut-mat-options/20x20/4x4no1.jpg";
import f20x20o5x5no1 from "../../../resources/precut-mat-options/20x20/5x5no1.jpg";
import f20x20o8x8no1 from "../../../resources/precut-mat-options/20x20/8x8no1.jpg";
import f20x20o10x10no1 from "../../../resources/precut-mat-options/20x20/10x10no1.jpg";
import f20x20o12x12no1 from "../../../resources/precut-mat-options/20x20/12x12no1.jpg";
import f20x20o16x16no1 from "../../../resources/precut-mat-options/20x20/16x16no1.jpg";

import f20x20o4x4no2 from "../../../resources/precut-mat-options/20x20/4x4no2.jpg";
import f20x20o5x5no2 from "../../../resources/precut-mat-options/20x20/5x5no2.jpg";
import f20x20o8x8no2 from "../../../resources/precut-mat-options/20x20/8x8no2.jpg";
import f20x20o10x10no2 from "../../../resources/precut-mat-options/20x20/10x10no2.jpg";
import f20x20o12x12no2 from "../../../resources/precut-mat-options/20x20/12x12no2.jpg";
import f20x20o16x16no2 from "../../../resources/precut-mat-options/20x20/16x16no2.jpg";

import f20x20o4x4no3 from "../../../resources/precut-mat-options/20x20/4x4no3.jpg";
import f20x20o5x5no3 from "../../../resources/precut-mat-options/20x20/5x5no3.jpg";
import f20x20o8x8no3 from "../../../resources/precut-mat-options/20x20/8x8no3.jpg";
import f20x20o10x10no3 from "../../../resources/precut-mat-options/20x20/10x10no3.jpg";
import f20x20o12x12no3 from "../../../resources/precut-mat-options/20x20/12x12no3.jpg";
import f20x20o16x16no3 from "../../../resources/precut-mat-options/20x20/16x16no3.jpg";

import f20x28o4x6no1 from "../../../resources/precut-mat-options/20x28/4x6no1.jpg";
import f20x28o5x7no1 from "../../../resources/precut-mat-options/20x28/5x7no1.jpg";
import f20x28o85x11no1 from "../../../resources/precut-mat-options/20x28/85x11no1.jpg";
import f20x28o8x10no1 from "../../../resources/precut-mat-options/20x28/8x10no1.jpg";
import f20x28o9x12no1 from "../../../resources/precut-mat-options/20x28/9x12no1.jpg";
import f20x28o11x14no1 from "../../../resources/precut-mat-options/20x28/11x14no1.jpg";
import f20x28o11x17no1 from "../../../resources/precut-mat-options/20x28/11x17no1.jpg";
import f20x28o12x16no1 from "../../../resources/precut-mat-options/20x28/12x16no1.jpg";
import f20x28o14x18no1 from "../../../resources/precut-mat-options/20x28/14x18no1.jpg";
import f20x28o16x20no1 from "../../../resources/precut-mat-options/20x28/16x20no1.jpg";

import f20x28o4x6no2 from "../../../resources/precut-mat-options/20x28/4x6no2.jpg";
import f20x28o5x7no2 from "../../../resources/precut-mat-options/20x28/5x7no2.jpg";
import f20x28o85x11no2 from "../../../resources/precut-mat-options/20x28/85x11no2.jpg";
import f20x28o8x10no2 from "../../../resources/precut-mat-options/20x28/8x10no2.jpg";
import f20x28o9x12no2 from "../../../resources/precut-mat-options/20x28/9x12no2.jpg";
import f20x28o11x14no2 from "../../../resources/precut-mat-options/20x28/11x14no2.jpg";
import f20x28o11x17no2 from "../../../resources/precut-mat-options/20x28/11x17no2.jpg";
import f20x28o12x16no2 from "../../../resources/precut-mat-options/20x28/12x16no2.jpg";
import f20x28o14x18no2 from "../../../resources/precut-mat-options/20x28/14x18no2.jpg";
import f20x28o16x20no2 from "../../../resources/precut-mat-options/20x28/16x20no2.jpg";

import f20x28o4x6no3 from "../../../resources/precut-mat-options/20x28/4x6no3.jpg";
import f20x28o5x7no3 from "../../../resources/precut-mat-options/20x28/5x7no3.jpg";
import f20x28o85x11no3 from "../../../resources/precut-mat-options/20x28/85x11no3.jpg";
import f20x28o8x10no3 from "../../../resources/precut-mat-options/20x28/8x10no3.jpg";
import f20x28o9x12no3 from "../../../resources/precut-mat-options/20x28/9x12no3.jpg";
import f20x28o11x14no3 from "../../../resources/precut-mat-options/20x28/11x14no3.jpg";
import f20x28o11x17no3 from "../../../resources/precut-mat-options/20x28/11x17no3.jpg";
import f20x28o12x16no3 from "../../../resources/precut-mat-options/20x28/12x16no3.jpg";
import f20x28o14x18no3 from "../../../resources/precut-mat-options/20x28/14x18no3.jpg";
import f20x28o16x20no3 from "../../../resources/precut-mat-options/20x28/16x20no3.jpg";

import f24x36o4x6no1 from "../../../resources/precut-mat-options/24x36/4x6no1.jpg";
import f24x36o5x7no1 from "../../../resources/precut-mat-options/24x36/5x7no1.jpg";
import f24x36o85x11no1 from "../../../resources/precut-mat-options/24x36/85x11no1.jpg";
import f24x36o8x10no1 from "../../../resources/precut-mat-options/24x36/8x10no1.jpg";
import f24x36o9x12no1 from "../../../resources/precut-mat-options/24x36/9x12no1.jpg";
import f24x36o11x14no1 from "../../../resources/precut-mat-options/24x36/11x14no1.jpg";
import f24x36o11x17no1 from "../../../resources/precut-mat-options/24x36/11x17no1.jpg";
import f24x36o12x16no1 from "../../../resources/precut-mat-options/24x36/12x16no1.jpg";
import f24x36o14x18no1 from "../../../resources/precut-mat-options/24x36/14x18no1.jpg";
import f24x36o16x20no1 from "../../../resources/precut-mat-options/24x36/16x20no1.jpg";
import f24x36o16x24no1 from "../../../resources/precut-mat-options/24x36/16x24no1.jpg";
import f24x36o18x24no1 from "../../../resources/precut-mat-options/24x36/18x24no1.jpg";
import f24x36o20x24no1 from "../../../resources/precut-mat-options/24x36/20x24no1.jpg";
import f24x36o20x28no1 from "../../../resources/precut-mat-options/24x36/20x28no1.jpg";
import f24x36o20x30no1 from "../../../resources/precut-mat-options/24x36/20x30no1.jpg";
import f24x36o22x28no1 from "../../../resources/precut-mat-options/24x36/22x28no1.jpg";

import f24x36o4x6no2 from "../../../resources/precut-mat-options/24x36/4x6no2.jpg";
import f24x36o5x7no2 from "../../../resources/precut-mat-options/24x36/5x7no2.jpg";
import f24x36o85x11no2 from "../../../resources/precut-mat-options/24x36/85x11no2.jpg";
import f24x36o8x10no2 from "../../../resources/precut-mat-options/24x36/8x10no2.jpg";
import f24x36o9x12no2 from "../../../resources/precut-mat-options/24x36/9x12no2.jpg";
import f24x36o11x14no2 from "../../../resources/precut-mat-options/24x36/11x14no2.jpg";
import f24x36o11x17no2 from "../../../resources/precut-mat-options/24x36/11x17no2.jpg";
import f24x36o12x16no2 from "../../../resources/precut-mat-options/24x36/12x16no2.jpg";
import f24x36o14x18no2 from "../../../resources/precut-mat-options/24x36/14x18no2.jpg";
import f24x36o16x20no2 from "../../../resources/precut-mat-options/24x36/16x20no2.jpg";
import f24x36o16x24no2 from "../../../resources/precut-mat-options/24x36/16x24no2.jpg";
import f24x36o18x24no2 from "../../../resources/precut-mat-options/24x36/18x24no2.jpg";
import f24x36o20x24no2 from "../../../resources/precut-mat-options/24x36/20x24no2.jpg";
import f24x36o20x28no2 from "../../../resources/precut-mat-options/24x36/20x28no2.jpg";
import f24x36o20x30no2 from "../../../resources/precut-mat-options/24x36/20x30no2.jpg";
import f24x36o22x28no2 from "../../../resources/precut-mat-options/24x36/22x28no2.jpg";

import f24x36o4x6no3 from "../../../resources/precut-mat-options/24x36/4x6no3.jpg";
import f24x36o5x7no3 from "../../../resources/precut-mat-options/24x36/5x7no3.jpg";
import f24x36o85x11no3 from "../../../resources/precut-mat-options/24x36/85x11no3.jpg";
import f24x36o8x10no3 from "../../../resources/precut-mat-options/24x36/8x10no3.jpg";
import f24x36o9x12no3 from "../../../resources/precut-mat-options/24x36/9x12no3.jpg";
import f24x36o11x14no3 from "../../../resources/precut-mat-options/24x36/11x14no3.jpg";
import f24x36o11x17no3 from "../../../resources/precut-mat-options/24x36/11x17no3.jpg";
import f24x36o12x16no3 from "../../../resources/precut-mat-options/24x36/12x16no3.jpg";
import f24x36o14x18no3 from "../../../resources/precut-mat-options/24x36/14x18no3.jpg";
import f24x36o16x20no3 from "../../../resources/precut-mat-options/24x36/16x20no3.jpg";
import f24x36o16x24no3 from "../../../resources/precut-mat-options/24x36/16x24no3.jpg";
import f24x36o18x24no3 from "../../../resources/precut-mat-options/24x36/18x24no3.jpg";
import f24x36o20x24no3 from "../../../resources/precut-mat-options/24x36/20x24no3.jpg";
import f24x36o20x28no3 from "../../../resources/precut-mat-options/24x36/20x28no3.jpg";
import f24x36o20x30no3 from "../../../resources/precut-mat-options/24x36/20x30no3.jpg";
import f24x36o22x28no3 from "../../../resources/precut-mat-options/24x36/22x28no3.jpg";

import colourOptions1 from "../../../resources/precut-mat-options/colourOptions01-min.JPG";
import colourOptions2 from "../../../resources/precut-mat-options/colourOptions2-min.JPG";
import colourOptions3 from "../../../resources/precut-mat-options/colourOptions3-min.JPG";
import colourOptions4 from "../../../resources/precut-mat-options/colourOptions4.JPG";
import colourOptions5 from "../../../resources/precut-mat-options/colourOptions5.PNG";
import colourOptions6 from "../../../resources/precut-mat-options/colourOptions6.JPG";
import colourOptions7 from "../../../resources/precut-mat-options/colourOptions7.JPG";
import colourOptions8 from "../../../resources/precut-mat-options/colourOptions8.JPG";
import colourOptions9 from "../../../resources/precut-mat-options/colourOptions9.JPG";
import colourOptions10 from "../../../resources/precut-mat-options/colourOptions10.JPG";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {MenuItem} from "@material-ui/core";
import displayInputFields from "../app-matsize-rendering/components/displayInputFields";
import arrowUp from "../../../resources/svgIcons/select-mat-arrow-up.svg";
import arrowDown from "../../../resources/svgIcons/select-mat-arrow-down.svg";
import localStorageFunctions from "../comp-localStorage/localStroage";
import {Helmet} from "react-helmet";
import WhatIsMatboard from "../page-howItWorks/whatIsMatboard";
import InstagramLoader from "../page-landing/instagramLoader";
import ReviewComponent from "../app-review/reviewComponent";
import LandingPageReviewStars from "../page-landing/landingPageReviewStars";
import LandingPageFAQ from "../page-landing/landingPageFAQ";
import SatisfactionTransition from "../page-landing/satisfactionTransition";

export default class PreCutMats extends React.Component {

    constructor() {
        super();
        this.state = {
            orientation: "portrait",
            frameSize: {
                "5x7": {
                    img: f5x7o4x6no3,
                    img2: f5x7o4x6no1,
                    default: "4x6",
                    width: 5,
                    height: 7,
                    desc: '5x7" Matboard',
                    descDetail: '5x7" Matboards for 5x7" Frames with choice of interior dimensions',
                    descFr: '5x7" Passe-partout',
                    descDetailFr: 'Passepartout 5x7 pouces pour cadres 5x7 pouces avec choix de dimensions intérieures.',
                    priceCAD: 11.95,
                    openSize: {
                        '4x6': {
                            width: 3.75,
                            height: 5.75,
                            desc: '4x6 standard prints',
                            descFr: 'Imprimés standards 4x6',
                            photos: [f5x7o4x6no1, f5x7o4x6no2, f5x7o4x6no3]
                        },
                    },
                },
                "9x9": {
                    img: f9x9o4x4no3,
                    img2: f8x10o4x6no1,
                    width: 9,
                    height: 9,
                    desc: '9x9" Matboard',
                    descDetail: '9x9" Matboards for 9x9" Frames with choice of interior dimensions',
                    descFr: '9x9" Passe-partout',
                    descDetailFr: 'Passepartout 9x9 pouces pour cadres 9x9 pouces avec choix de dimensions intérieures.',
                    priceCAD: 13.95,
                    default: "4x4",
                    openSize: {
                        '4x4': {
                            width: 3.75,
                            height: 3.75,
                            desc: '4x4 standard prints',
                            descFr: 'Imprimés standards 4x4',
                            photos: [f9x9o4x4no1, f9x9o4x4no2, f9x9o4x4no3]
                        },
                        '5x5': {
                            width: 4.75,
                            height: 4.75,
                            desc: '5x5 standard prints',
                            descFr: 'Imprimés standards 5x5',
                            photos: [f9x9o5x5no1, f9x9o5x5no2, f9x9o5x5no3]
                        },
                    },
                },
                "8x10": {
                    img: f8x10o4x6no3,
                    img2: f8x10o4x6no1,
                    width: 8,
                    height: 10,
                    desc: '8x10" Matboard',
                    descDetail: '8x10" Matboards for 8x10" Frames with choice of interior dimensions',
                    descFr: '8x10" Passe-partout',
                    descDetailFr: 'Passepartout 8x10 pouces pour cadres 8x10 pouces avec choix de dimensions intérieures.',
                    priceCAD: 13.95,
                    default: "4x6",
                    openSize: {
                        '4x6': {
                            width: 3.75,
                            height: 5.75,
                            desc: '4x6 standard prints',
                            descFr: 'Imprimés standards 4x6',
                            photos: [f8x10o4x6no1, f8x10o4x6no2, f8x10o4x6no3]
                        },
                        '5x7': {
                            width: 4.75,
                            height: 6.75,
                            desc: '5x7 standard prints',
                            descFr: 'Imprimés standards 5x7',
                            photos: [f8x10o5x7no1, f8x10o5x7no2, f8x10o5x7no3]
                        },
                    },
                },
                "11x14": {
                    img: f11x14o4x6no3,
                    img2: f11x14o4x6no1,
                    width: 11,
                    height: 14,
                    default: "4x6",
                    desc: '11x14" Matboard',
                    descDetail: '11x14" Matboards for 11x14" Frames with choice of interior dimensions',
                    descFr: '11x14" Passe-partout',
                    descDetailFr: 'Passepartout 11x14 pouces pour cadres 11x14 pouces avec choix de dimensions intérieures.',
                    priceCAD: 15.95,
                    openSize: {
                        '4x6': {
                            width: 3.75,
                            height: 5.75,
                            desc: '4x6 standard prints',
                            descFr: 'Imprimés standards 4x6',
                            photos: [f11x14o4x6no1, f11x14o4x6no2, f11x14o4x6no3],
                        },
                        '5x7': {
                            width: 4.75,
                            height: 6.75,
                            desc: '5x7 standard prints',
                            descFr: 'Imprimés standards 5x7',
                            photos: [f11x14o5x7no1, f11x14o5x7no2, f11x14o5x7no3]
                        },
                        '8x10': {
                            width: 7.75,
                            height: 9.75,
                            desc: '8x10 standard prints',
                            descFr: 'Imprimés standards 8x10',
                            photos: [f11x14o8x10no1, f11x14o8x10no2, f11x14o8x10no3],
                        },
                        '8.5x11': {
                            width: 8.25,
                            height: 10.75,
                            desc: "Letter, ANSI-A",
                            descFr: "Papier Lettre, ANSI-A",
                            photos: [f11x14o85x11no1, f11x14o85x11no2, f11x14o85x11no3],
                        },
                        '9x12': {
                            width: 8.75,
                            height: 11.75,
                            desc: 'ARCH-A prints',
                            descFr: 'Imprimés format ARCH-A',
                            photos: [f11x14o9x12no1, f11x14o9x12no2, f11x14o9x12no3],
                        },
                    },
                },
                "12x16": {
                    img: f12x16o4x6no3,
                    img2: f12x16o4x6no1,
                    width: 12,
                    height: 16,
                    desc: '12x16" Matboard',
                    descDetail: '12x16" Matboards for 12x16" Frames with choice of interior dimensions',
                    descFr: '12x16" Passe-partout',
                    descDetailFr: 'Passepartout 12x16 pouces pour cadres 12x16 pouces avec choix de dimensions intérieures.',
                    priceCAD: 17.95,
                    default: "4x6",
                    openSize: {
                        '4x6': {
                            width: 3.75,
                            height: 5.75,
                            desc: '4x6 standard prints',
                            descFr: 'Imprimés standards 4x6',
                            photos: [f12x16o4x6no1, f12x16o4x6no2, f12x16o4x6no3]
                        },
                        '5x7': {
                            width: 4.75,
                            height: 6.75,
                            desc: '5x7 standard prints',
                            descFr: 'Imprimés standards 5x7',
                            photos: [f12x16o5x7no1, f12x16o5x7no2, f12x16o5x7no3],
                        },
                        '8x10': {
                            width: 7.75,
                            height: 9.75,
                            desc: '8x10 standard prints',
                            descFr: 'Imprimés standards 8x10',
                            photos: [f12x16o8x10no1, f12x16o8x10no2, f12x16o8x10no3],
                        },
                        '8.5x11': {
                            width: 8.25,
                            height: 10.75,
                            desc: "Letter and ANSI-A prints",
                            descFr: 'Papier Lettre, ANSI-A',
                            photos: [f12x16o85x11no1, f12x16o85x11no2, f12x16o85x11no3],
                        },
                        '9x12': {
                            width: 8.75,
                            height: 11.75,
                            desc: 'ARCH-A prints',
                            descFr: 'Imprimés format ARCH-A',
                            photos: [f12x16o9x12no1, f12x16o9x12no2, f12x16o9x12no3],
                        },
                    },
                },
                "14x18": {
                    img: f14x18o4x6no3,
                    img2: f14x18o4x6no1,
                    width: 14,
                    height: 18,
                    desc: '14x18" Matboard',
                    descDetail: '14x18" Matboards for 14x18" Frames with choice of interior dimensions',
                    descFr: '14x18" Passe-partout',
                    descDetailFr: 'Passepartout 14x18 pouces pour cadres 14x18 pouces avec choix de dimensions intérieures.',
                    priceCAD: 21.95,
                    default: "4x6",
                    openSize: {
                        '4x6': {
                            width: 3.75,
                            height: 5.75,
                            desc: '4x6 standard prints',
                            descFr: 'Imprimés standards 4x6',
                            photos: [f14x18o4x6no1, f14x18o4x6no2, f14x18o4x6no3]
                        },
                        '5x7': {
                            width: 4.75,
                            height: 6.75,
                            desc: '5x7 standard prints',
                            descFr: 'Imprimés standards 5x7',
                            photos: [f14x18o5x7no1, f14x18o5x7no2, f14x18o5x7no3]
                        },
                        '8x10': {
                            width: 7.75,
                            height: 9.75,
                            desc: '8x10 standard prints',
                            descFr: 'Imprimés standards 8x10',
                            photos: [f14x18o8x10no1, f14x18o8x10no2, f14x18o8x10no3]

                        },
                        '8.27x11.69': {
                            width: 8,
                            height: 11.5,
                            desc: 'A4 prints',
                            descFr: 'Imprimés au Format A4',
                            photos: [f14x18o85x11no1, f14x18o85x11no2, f14x18o85x11no3]
                        },
                        '8.5x11': {
                            width: 8.25,
                            height: 10.75,
                            descFr: 'Imprimés standards 8.5x11',
                            desc: "Papier Lettre, ANSI-A",
                            photos: [f14x18o85x11no1, f14x18o85x11no2, f14x18o85x11no3]
                        },
                        '9x12': {
                            width: 8.75,
                            height: 11.75,
                            descFr: 'Imprimés format ARCH-A',
                            desc: 'ARCH-A prints',
                            photos: [f14x18o9x12no1, f14x18o9x12no2, f14x18o9x12no3],
                        },
                    },
                },
                "16x20": {
                    img: f16x20o4x6no3,
                    img2: f16x20o4x6no1,
                    width: 16,
                    height: 20,
                    desc: '16x20" Matboard',
                    descDetail: '16x20" Matboards for 16x20" Frames with choice of interior dimensions',
                    descFr: '16x20" Passe-partout',
                    descDetailFr: 'Passepartout 16x20 pouces pour cadres 16x20 pouces avec choix de dimensions intérieures.',
                    priceCAD: 25.95,
                    default: "4x6",
                    openSize: {
                        '4x6': {
                            width: 3.75,
                            height: 5.75,
                            desc: '4x6 standard prints',
                            descFr: 'Imprimés standards 4x6',
                            photos: [f16x20o4x6no1, f16x20o4x6no2, f16x20o4x6no3],
                        },
                        '5x7': {
                            width: 4.75,
                            height: 6.75,
                            desc: '5x7 standard prints',
                            descFr: 'Imprimés standards 5x7',
                            photos: [f16x20o5x7no1, f16x20o5x7no2, f16x20o5x7no3]
                        },
                        '8x10': {
                            width: 7.75,
                            height: 9.75,
                            desc: '8x10 standard prints',
                            descFr: 'Imprimés standards 8x10',
                            photos: [f16x20o8x10no1, f16x20o8x10no2, f16x20o8x10no3]

                        },
                        '8.27x11.69': {
                            width: 8,
                            height: 11.5,
                            desc: 'A4 prints',
                            descFr: 'Imprimés au Format A4',
                            photos: [f16x20o85x11no1, f16x20o85x11no2, f16x20o85x11no3]
                        },
                        '8.5x11': {
                            width: 8.25,
                            height: 10.75,
                            desc: "Letter and ANSI-A prints",
                            descFr: 'Papier Lettre, ANSI-A',
                            photos: [f16x20o85x11no1, f16x20o85x11no2, f16x20o85x11no3]
                        },
                        '9x12': {
                            width: 8.75,
                            height: 11.75,
                            desc: 'ARCH-A prints',
                            descFr: 'Imprimés format ARCH-A',
                            photos: [f16x20o9x12no1, f16x20o9x12no2, f16x20o9x12no3],
                        },
                        '11x14': {
                            width: 10.75,
                            height: 13.75,
                            desc: '11x14 standard prints',
                            descFr: 'Imprimés standards 11x14',
                            photos: [f16x20o11x14no1, f16x20o11x14no2, f16x20o11x14no3]
                        },
                        '11x17': {
                            width: 10.75,
                            height: 16.75,
                            desc: 'ANSI-B, Tabloid, and Ledger prints',
                            descFr: 'Imprimés ANSI-B, Tabloïd et Grand Livre',
                            photos: [f24x36o11x17no1, f24x36o11x17no2, f24x36o11x17no3]
                        },
                        '11.69x16.54': {
                            width: 11.5,
                            height: 16.25,
                            desc: 'A3 prints',
                            descFr: 'Imprimés au Format A3',
                            photos: [f16x20o12x16no1, f16x20o12x16no2, f16x20o12x16no3]
                        },
                        '12x18': {
                            width: 11.75,
                            height: 17.75,
                            desc: 'Mini-Poster prints',
                            descFr: 'Impressions de Mini-Poster',
                            photos: [f16x20o12x16no1, f16x20o12x16no2, f16x20o12x16no3]
                        },
                    },
                },
                "18x24": {
                    img: f18x24o4x6no3,
                    img2: f18x24o4x6no1,
                    width: 18,
                    height: 24,
                    desc: '18x24" Matboard',
                    descDetail: '18x24" Matboards for 18x24" Frames with choice of interior dimensions',
                    descFr: '18x24" Passe-partout',
                    descDetailFr: 'Passepartout 18x24 pouces pour cadres 18x24 pouces avec choix de dimensions intérieures.',
                    priceCAD: 29.95,
                    default: "4x6",
                    openSize: {
                        '4x6': {
                            width: 3.75,
                            height: 5.75,
                            desc: '4x6 standard prints',
                            descFr: 'Imprimés standards 4x6',
                            photos: [f18x24o4x6no1, f18x24o4x6no2, f18x24o4x6no3]
                        },
                        '5x7': {
                            width: 4.75,
                            height: 6.75,
                            desc: '5x7 standard prints',
                            descFr: 'Imprimés standards 5x7',
                            photos: [f18x24o5x7no1, f18x24o5x7no2, f18x24o5x7no3]
                        },
                        '8x10': {
                            width: 7.75,
                            height: 9.75,
                            desc: '8x10 standard prints',
                            descFr: 'Imprimés standards 8x10',
                            photos: [f18x24o8x10no1, f18x24o8x10no2, f18x24o8x10no3]
                        },
                        '8.27x11.69': {
                            width: 8,
                            height: 11.5,
                            desc: 'A4 prints',
                            descFr: 'Imprimés au Format A4',
                            photos: [f18x24o85x11no1, f18x24o85x11no2, f18x24o85x11no3]
                        },
                        '8.5x11': {
                            width: 8.25,
                            height: 10.75,
                            desc: "Letter and ANSI-A prints",
                            descFr: 'Papier Lettre, ANSI-A',
                            photos: [f18x24o85x11no1, f18x24o85x11no2, f18x24o85x11no3]
                        },
                        '9x12': {
                            width: 8.75,
                            height: 11.75,
                            descFr: 'Imprimés format ARCH-A',
                            desc: 'ARCH-A prints',
                            photos: [f18x24o9x12no1, f18x24o9x12no2, f18x24o9x12no3],
                        },
                        '11x14': {
                            width: 10.75,
                            height: 13.75,
                            descFr: 'Imprimés standards 11x14',
                            desc: '11x14 standard prints',
                            photos: [f18x24o11x14no1, f18x24o11x14no2, f18x24o11x14no3]
                        },
                        '11x17': {
                            width: 10.75,
                            height: 16.75,
                            desc: 'ANSI-B, Tabloid, and Ledger prints',
                            descFr: 'Imprimés ANSI-B, Tabloïd et Grand Livre',
                            photos: [f18x24o11x17no1, f18x24o11x17no2, f18x24o11x17no3]
                        },
                        '11.69x16.54': {
                            width: 11.5,
                            height: 16.25,
                            desc: 'A3 prints',
                            descFr: 'Imprimés au Format A3',
                            photos: [f18x24o11x17no1, f18x24o11x17no2, f18x24o11x17no3]
                        },
                        '12x18': {
                            width: 11.75,
                            height: 17.75,
                            desc: 'Mini-Poster prints',
                            descFr: 'Impressions de Mini-Poster',
                            photos: [f18x24o12x16no1, f18x24o12x16no2, f18x24o12x16no3]
                        },
                        '16x20': {
                            width: 15.75,
                            height: 19.75,
                            desc: 'Portrait Pictures (digital camera picture) prints',
                            descFr: 'Impressions de Photos de Portrait',
                            photos: [f18x24o16x20no1, f18x24o16x20no2, f18x24o16x20no3]

                        },
                    },
                },
                "19.75x19.75": {
                    img: f20x20o4x4no3,
                    img2: f20x20o4x4no1,
                    width: 19.75,
                    height: 19.75,
                    desc: '19.75x19.75" Matboard',
                    descDetail: '19.75x19.75" Matboards for 19.75x19.75" Frames with choice of interior dimensions',
                    descFr: '19.75x19.75" Passe-partout',
                    descDetailFr: 'Passepartout 19.75x19.75 pouces pour cadres 19.75x19.75 pouces avec choix de dimensions intérieures.',
                    priceCAD: 29.95,
                    default: "4x4",
                    openSize: {
                        '4x4': {
                            width: 3.75,
                            height: 3.75,
                            desc: '4x4 standard prints',
                            descFr: 'Imprimés standards 4x4',
                            photos: [f20x20o4x4no1, f20x20o4x4no2, f20x20o4x4no3]
                        },
                        '5x5': {
                            width: 4.75,
                            height: 4.75,
                            desc: '5x5 standard prints',
                            descFr: 'Imprimés standards 5x5',
                            photos: [f20x20o5x5no1, f20x20o5x5no2, f20x20o5x5no3]
                        },
                        '8x8': {
                            width: 7.75,
                            height: 7.75,
                            desc: '8x8 standard prints',
                            descFr: 'Imprimés standards 8x8',
                            photos: [f20x20o8x8no1, f20x20o8x8no2, f20x20o8x8no3]
                        },
                        '10x10': {
                            width: 9.75,
                            height: 9.75,
                            desc: '10x10 standard prints',
                            descFr: 'Imprimés standards 10x10',
                            photos: [f20x20o10x10no1, f20x20o10x10no2, f20x20o10x10no3]
                        },
                        '12x12': {
                            width: 11.75,
                            height: 11.75,
                            desc: '12x12 standard prints',
                            descFr: 'Imprimés standards 12x12',
                            photos: [f20x20o12x12no1, f20x20o12x12no2, f20x20o12x12no3]
                        },
                        '16x16': {
                            width: 15.75,
                            height: 15.75,
                            desc: '16x16 standard prints',
                            descFr: 'Imprimés standards 16x16',
                            photos: [f20x20o16x16no1, f20x20o16x16no2, f20x20o16x16no3]
                        },
                    },
                },
                "20x20": {
                    img: f20x20o4x4no3,
                    img2: f20x20o4x4no1,
                    width: 20,
                    height: 20,
                    desc: '20x20" Matboard',
                    descDetail: '20x20" Matboards for 20x20" Frames with choice of interior dimensions',
                    descFr: '20x20" Passe-partout',
                    descDetailFr: 'Passepartout 20x20 pouces pour cadres 20x20 pouces avec choix de dimensions intérieures.',
                    priceCAD: 29.95,
                    default: "4x4",
                    openSize: {
                        '4x4': {
                            width: 3.75,
                            height: 3.75,
                            desc: '4x4 standard prints',
                            descFr: 'Imprimés standards 4x4',
                            photos: [f20x20o4x4no1, f20x20o4x4no2, f20x20o4x4no3]
                        },
                        '5x5': {
                            width: 4.75,
                            height: 4.75,
                            desc: '5x5 standard prints',
                            descFr: 'Imprimés standards 5x5',
                            photos: [f20x20o5x5no1, f20x20o5x5no2, f20x20o5x5no3]
                        },
                        '8x8': {
                            width: 7.75,
                            height: 7.75,
                            desc: '8x8 standard prints',
                            descFr: 'Imprimés standards 8x8',
                            photos: [f20x20o8x8no1, f20x20o8x8no2, f20x20o8x8no3]
                        },
                        '10x10': {
                            width: 9.75,
                            height: 9.75,
                            desc: '10x10 standard prints',
                            descFr: 'Imprimés standards 10x10',
                            photos: [f20x20o10x10no1, f20x20o10x10no2, f20x20o10x10no3]
                        },
                        '12x12': {
                            width: 11.75,
                            height: 11.75,
                            desc: '12x12 standard prints',
                            descFr: 'Imprimés standards 12x12',
                            photos: [f20x20o12x12no1, f20x20o12x12no2, f20x20o12x12no3]
                        },
                        '16x16': {
                            width: 15.75,
                            height: 15.75,
                            desc: '16x16 standard prints',
                            descFr: 'Imprimés standards 16x16',
                            photos: [f20x20o16x16no1, f20x20o16x16no2, f20x20o16x16no3]
                        },
                    },
                },
                "19.75x27.5": {
                    img: f20x28o4x6no3,
                    img2: f20x28o4x6no1,
                    width: 19.75,
                    height: 27.5,
                    desc: '19.75x27.5" Matboard',
                    descDetail: '19.75x27.5" Matboards for 19.75x27.5" Frames with choice of interior dimensions',
                    descFr: '19.75x27.5" Passe-partout',
                    descDetailFr: 'Passepartout 19.75x27.5 pouces pour cadres 19.75x27.5 pouces avec choix de dimensions intérieures.',
                    priceCAD: 34.95,
                    default: "4x6",
                    openSize: {
                        '4x6': {
                            width: 3.75,
                            height: 5.75,
                            desc: '4x6 standard prints',
                            descFr: 'Imprimés standards 4x6',
                            photos: [f20x28o4x6no1, f20x28o4x6no2, f20x28o4x6no3]
                        },
                        '5x7': {
                            width: 4.75,
                            height: 6.75,
                            desc: '5x7 standard prints',
                            descFr: 'Imprimés standards 5x7',
                            photos: [f20x28o5x7no1, f20x28o5x7no2, f20x28o5x7no3]
                        },
                        '8x10': {
                            width: 7.75,
                            height: 9.75,
                            desc: '8x10 standard prints',
                            descFr: 'Imprimés standards 8x10',
                            photos: [f20x28o8x10no1, f20x28o8x10no2, f20x28o8x10no3]
                        },
                        '8.27x11.69': {
                            width: 8,
                            height: 11.5,
                            desc: 'A4 prints',
                            descFr: 'Imprimés au Format A4',
                            photos: [f20x28o8x10no1, f20x28o8x10no2, f20x28o8x10no3]
                        },
                        '8.5x11': {
                            width: 8.25,
                            height: 10.75,
                            desc: "Letter and ANSI-A prints",
                            descFr: 'Papier Lettre, ANSI-A',
                            photos: [f20x28o85x11no1, f20x28o85x11no2, f20x28o85x11no3]
                        },
                        '9x12': {
                            width: 8.75,
                            height: 11.75,
                            desc: 'ARCH-A prints',
                            descFr: 'Imprimés format ARCH-A',
                            photos: [f20x28o9x12no1, f20x28o9x12no2, f20x28o9x12no3]
                        },
                        '11x14': {
                            width: 10.75,
                            height: 13.75,
                            desc: '11x14 standard prints',
                            descFr: 'Imprimés standards 11x14',
                            photos: [f20x28o11x14no1, f20x28o11x14no2, f20x28o11x14no3]
                        },
                        '11x17': {
                            width: 10.75,
                            height: 16.75,
                            desc: 'ANSI-B, Tabloid, and Ledger prints',
                            descFr: 'Imprimés ANSI-B, Tabloïd et Grand Livre',
                            photos: [f20x28o11x17no1, f20x28o11x17no2, f20x28o11x17no3]
                        },
                        '11.69x16.54': {
                            width: 11.5,
                            height: 16.25,
                            desc: 'A3 prints',
                            descFr: 'Imprimés au Format A3',
                            photos: [f20x28o11x17no1, f20x28o11x17no2, f20x28o11x17no3]
                        },
                        '12x18': {
                            width: 11.75,
                            height: 17.75,
                            desc: 'Mini-Poster prints',
                            descFr: 'Impressions de Mini-Poster',
                            photos: [f20x28o12x16no1, f20x28o12x16no2, f20x28o12x16no3]
                        },
                        '14x18': {
                            width: 13.75,
                            height: 17.75,
                            desc: '14x18 Standard Prints',
                            descFr: 'Imprimés standards 14x18',
                            photos: [f20x28o14x18no1, f20x28o14x18no2, f20x28o14x18no3]
                        },
                        '16x20': {
                            width: 15.75,
                            height: 19.75,
                            desc: 'Portrait Pictures (digital camera picture) prints',
                            descFr: 'Impressions de Photos de Portrait',
                            photos: [f20x28o16x20no1, f20x28o16x20no2, f20x28o16x20no3]
                        },

                    },
                },
                "20x28": {
                    img: f20x28o4x6no3,
                    img2: f20x28o4x6no1,
                    width: 20,
                    height: 28,
                    desc: '20x28" Matboard',
                    descDetail: '20x28" Matboards for 20x28" Frames with choice of interior dimensions',
                    descFr: '20x28" Passe-partout',
                    descDetailFr: 'Passepartout 20x28 pouces pour cadres 20x28 pouces avec choix de dimensions intérieures.',
                    priceCAD: 34.95,
                    default: "4x6",
                    openSize: {
                        '4x6': {
                            width: 3.75,
                            height: 5.75,
                            desc: '4x6 standard prints',
                            descFr: 'Imprimés standards 4x6',
                            photos: [f20x28o4x6no1, f20x28o4x6no2, f20x28o4x6no3]
                        },
                        '5x7': {
                            width: 4.75,
                            height: 6.75,
                            desc: '5x7 standard prints',
                            descFr: 'Imprimés standards 5x7',
                            photos: [f20x28o5x7no1, f20x28o5x7no2, f20x28o5x7no3]
                        },
                        '8x10': {
                            width: 7.75,
                            height: 9.75,
                            desc: '8x10 standard prints',
                            descFr: 'Imprimés standards 8x10',
                            photos: [f20x28o8x10no1, f20x28o8x10no2, f20x28o8x10no3]
                        },
                        '8.27x11.69': {
                            width: 8,
                            height: 11.5,
                            desc: 'A4 prints',
                            descFr: 'Imprimés au Format A4',
                            photos: [f20x28o8x10no1, f20x28o8x10no2, f20x28o8x10no3]
                        },
                        '8.5x11': {
                            width: 8.25,
                            height: 10.75,
                            desc: "Letter and ANSI-A prints",
                            descFr: 'Papier Lettre, ANSI-A',
                            photos: [f20x28o85x11no1, f20x28o85x11no2, f20x28o85x11no3]
                        },
                        '9x12': {
                            width: 8.75,
                            height: 11.75,
                            desc: 'ARCH-A prints',
                            descFr: 'Imprimés format ARCH-A',
                            photos: [f20x28o9x12no1, f20x28o9x12no2, f20x28o9x12no3]
                        },
                        '11x14': {
                            width: 10.75,
                            height: 13.75,
                            desc: '11x14 standard prints',
                            descFr: 'Imprimés standards 11x14',
                            photos: [f20x28o11x14no1, f20x28o11x14no2, f20x28o11x14no3]
                        },
                        '11x17': {
                            width: 10.75,
                            height: 16.75,
                            desc: 'ANSI-B, Tabloid, and Ledger prints',
                            descFr: 'Imprimés ANSI-B, Tabloïd et Grand Livre',
                            photos: [f20x28o11x17no1, f20x28o11x17no2, f20x28o11x17no3]
                        },
                        '11.69x16.54': {
                            width: 11.5,
                            height: 16.25,
                            desc: 'A3 prints prints',
                            descFr: 'Imprimés au Format A3',
                            photos: [f20x28o11x17no1, f20x28o11x17no2, f20x28o11x17no3]
                        },
                        '12x18': {
                            width: 11.75,
                            height: 17.75,
                            desc: 'Mini-Poster prints',
                            descFr: 'Impressions de Mini-Poster',
                            photos: [f20x28o12x16no1, f20x28o12x16no2, f20x28o12x16no3]
                        },
                        '14x18': {
                            width: 13.75,
                            height: 17.75,
                            desc: '14x18 Standard Prints',
                            descFr: 'Imprimés standards 14x18',
                            photos: [f20x28o14x18no1, f20x28o14x18no2, f20x28o14x18no3]
                        },
                        '16x20': {
                            width: 15.75,
                            height: 19.75,
                            desc: 'Portrait Pictures (digital camera picture) prints',
                            descFr: 'Impressions de Photos de Portrait',
                            photos: [f20x28o16x20no1, f20x28o16x20no2, f20x28o16x20no3]
                        },

                    },
                },
                "24x35.75": {
                    img: f24x36o4x6no3,
                    img2: f24x36o4x6no1,
                    width: 24,
                    height: 35.75,
                    desc: '24x35.75" Matboard',
                    descDetail: '24x35.75" Matboards for 24x35.75" Frames with choice of interior dimensions',
                    descFr: '24x35.75" Passe-partout',
                    descDetailFr: 'Passepartout 24x35.75 pouces pour cadres 24x35.75 pouces avec choix de dimensions intérieures.',
                    priceCAD: 49.95,
                    default: "4x6",
                    openSize: {
                        '4x6': {
                            width: 3.75,
                            height: 5.75,
                            desc: '4x6 standard prints',
                            descFr: 'Imprimés standards 4x6',
                            photos: [f24x36o4x6no1, f24x36o4x6no2, f24x36o4x6no3]
                        },
                        '5x7': {
                            width: 4.75,
                            height: 6.75,
                            desc: '5x7 standard prints',
                            descFr: 'Imprimés standards 5x7',
                            photos: [f24x36o5x7no1, f24x36o5x7no2, f24x36o5x7no3]
                        },
                        '8x10': {
                            width: 7.75,
                            height: 9.75,
                            desc: '8x10 standard prints',
                            descFr: 'Imprimés standards 8x10',
                            photos: [f24x36o8x10no1, f24x36o8x10no2, f24x36o8x10no3]
                        },
                        '8.27x11.69': {
                            width: 8,
                            height: 11.5,
                            desc: 'A4 prints',
                            descFr: 'Imprimés au Format A4',
                            photos: [f24x36o85x11no1, f24x36o85x11no2, f24x36o85x11no3]
                        },
                        '8.5x11': {
                            width: 8.25,
                            height: 10.75,
                            desc: "Letter and ANSI-A prints",
                            descFr: 'Papier Lettre, ANSI-A',
                            photos: [f24x36o85x11no1, f24x36o85x11no2, f24x36o85x11no3]
                        },
                        '11x14': {
                            width: 10.75,
                            height: 13.75,
                            desc: '11x14 standard prints',
                            descFr: 'Imprimés standards 11x14',
                            photos: [f24x36o11x14no1, f24x36o11x14no2, f24x36o11x14no3]
                        },
                        '11x17': {
                            width: 10.75,
                            height: 16.75,
                            desc: 'ANSI-B, Tabloid, and Ledger prints',
                            descFr: 'Imprimés ANSI-B, Tabloïd et Grand Livre',
                            photos: [f24x36o11x17no1, f24x36o11x17no2, f24x36o11x17no3]
                        },
                        '11.69x16.54': {
                            width: 11.5,
                            height: 16.25,
                            desc: 'A3 prints',
                            descFr: 'Imprimés au Format A3',
                            photos: [f24x36o11x17no1, f24x36o11x17no2, f24x36o11x17no3]
                        },
                        '12x18': {
                            width: 11.75,
                            height: 17.75,
                            desc: 'Mini-Poster prints',
                            descFr: 'Impressions de Mini-Poster',
                            photos: [f24x36o12x16no1, f24x36o12x16no2, f24x36o12x16no3]
                        },
                        '16x20': {
                            width: 15.75,
                            height: 19.75,
                            descFr: 'Imprimés standards 16x20',
                            desc: 'Portrait Pictures (digital camera picture) prints',
                            photos: [f24x36o16x20no1, f24x36o16x20no2, f24x36o16x20no3]
                        },
                        '16x24': {
                            width: 15.75,
                            height: 23.75,
                            desc: '16x24 standard prints',
                            descFr: 'Imprimés standards 16x24',
                            photos: [f24x36o16x24no1, f24x36o16x24no2, f24x36o16x24no3]
                        },
                        '18x24': {
                            width: 17.75,
                            height: 23.75,
                            desc: 'Architectural C, Medium Poster, and ½ size prints',
                            descFr: 'Architectural C, Affiches De Taille Moyenne Et Imprimés À Moitié De Taille',
                            photos: [f24x36o18x24no1, f24x36o18x24no2, f24x36o18x24no3]
                        },
                        '20x24': {
                            width: 19.75,
                            height: 23.75,
                            desc: '20x24 standard prints',
                            descFr: 'Imprimés standards 20x24',
                            photos: [f24x36o20x24no1, f24x36o20x24no2, f24x36o20x24no3]
                        },
                        '20x28': {
                            width: 19.75,
                            height: 27.75,
                            desc: '20x28 standard prints',
                            descFr: 'Imprimés standards 20x28',
                            photos: [f24x36o20x28no1, f24x36o20x28no2, f24x36o20x28no3]
                        },
                        '20x30': {
                            width: 19.75,
                            height: 29.75,
                            desc: '20x30 standard prints.',
                            descFr: 'Imprimés standards 20x30',
                            photos: [f24x36o20x30no1, f24x36o20x30no2, f24x36o20x30no3]
                        }
                    },
                },
                "24x36": {
                    img: f24x36o4x6no3,
                    img2: f24x36o4x6no1,
                    width: 24,
                    height: 36,
                    desc: '24x36" Matboard',
                    descDetail: '24x36" Matboards for 24x36" Frames with choice of interior dimensions',
                    descFr: '24x36" Passe-partout',
                    descDetailFr: 'Passepartout 24x36 pouces pour cadres 24x36 pouces avec choix de dimensions intérieures.',
                    priceCAD: 49.95,
                    default: "4x6",
                    openSize: {
                        '4x6': {
                            width: 3.75,
                            height: 5.75,
                            desc: '4x6 standard prints',
                            descFr: 'Imprimés standards 4x6',
                            photos: [f24x36o4x6no1, f24x36o4x6no2, f24x36o4x6no3]
                        },
                        '5x7': {
                            width: 4.75,
                            height: 6.75,
                            desc: '5x7 standard prints',
                            descFr: 'Imprimés standards 5x7',
                            photos: [f24x36o5x7no1, f24x36o5x7no2, f24x36o5x7no3]
                        },
                        '8x10': {
                            width: 7.75,
                            height: 9.75,
                            desc: '8x10 standard prints',
                            descFr: 'Imprimés standards 8x10',
                            photos: [f24x36o8x10no1, f24x36o8x10no2, f24x36o8x10no3]
                        },
                        '8.27x11.69': {
                            width: 8,
                            height: 11.5,
                            desc: 'A4 prints',
                            descFr: 'Imprimés au Format A4',
                            photos: [f24x36o85x11no1, f24x36o85x11no2, f24x36o85x11no3]
                        },
                        '8.5x11': {
                            width: 8.25,
                            height: 10.75,
                            desc: "Letter and ANSI-A prints",
                            descFr: 'Papier Lettre, ANSI-A',
                            photos: [f24x36o85x11no1, f24x36o85x11no2, f24x36o85x11no3]
                        },
                        '11x14': {
                            width: 10.75,
                            height: 13.75,
                            desc: '11x14 standard prints',
                            descFr: 'Imprimés standards 11x14',
                            photos: [f24x36o11x14no1, f24x36o11x14no2, f24x36o11x14no3]
                        },
                        '11x17': {
                            width: 10.75,
                            height: 16.75,
                            desc: 'ANSI-B, Tabloid, and Ledger prints',
                            descFr: 'Imprimés ANSI-B, Tabloïd et Grand Livre',
                            photos: [f24x36o11x17no1, f24x36o11x17no2, f24x36o11x17no3]
                        },
                        '11.69x16.54': {
                            width: 11.5,
                            height: 16.25,
                            desc: 'A3 prints',
                            descFr: 'Imprimés au Format A3',
                            photos: [f24x36o11x17no1, f24x36o11x17no2, f24x36o11x17no3]
                        },
                        '12x18': {
                            width: 11.75,
                            height: 17.75,
                            desc: 'Mini-Poster prints',
                            descFr: 'Impressions de Mini-Poster',
                            photos: [f24x36o12x16no1, f24x36o12x16no2, f24x36o12x16no3]
                        },
                        '16x20': {
                            width: 15.75,
                            height: 19.75,
                            descFr: 'Impressions de Photos de Portrait',
                            desc: 'Portrait Pictures (digital camera picture) prints',
                            photos: [f24x36o16x20no1, f24x36o16x20no2, f24x36o16x20no3]
                        },
                        '16x24': {
                            width: 15.75,
                            height: 23.75,
                            desc: '16x24 standard prints',
                            descFr: 'Imprimés standards 16x24',
                            photos: [f24x36o16x24no1, f24x36o16x24no2, f24x36o16x24no3]
                        },
                        '18x24': {
                            width: 17.75,
                            height: 23.75,
                            descFr: 'Architectural C, Affiches De Taille Moyenne Et Imprimés À Moitié De Taille6',
                            desc: 'Architectural C, Medium Poster, and ½ size prints',
                            photos: [f24x36o18x24no1, f24x36o18x24no2, f24x36o18x24no3]
                        },
                        '20x24': {
                            width: 19.75,
                            height: 23.75,
                            desc: '20x24 standard prints',
                            descFr: 'Imprimés standards 20x24',
                            photos: [f24x36o20x24no1, f24x36o20x24no2, f24x36o20x24no3]
                        },
                        '20x28': {
                            width: 19.75,
                            height: 27.75,
                            desc: '20x28 standard prints',
                            descFr: 'Imprimés standards 20x28',
                            photos: [f24x36o20x28no1, f24x36o20x28no2, f24x36o20x28no3]
                        },
                        '20x30': {
                            width: 19.75,
                            height: 29.75,
                            desc: '20x30 standard prints.',
                            descFr: 'Imprimés standards 20x30',
                            photos: [f24x36o20x30no1, f24x36o20x30no2, f24x36o20x30no3]
                        }
                    },
                },
            },
            selectedFrameSize: false,
            selectedOpeningSize: "4x6",
            selectedMatColour: "smoothWhite",
            orderQuantity: 1,
            fetchProductDetail: false,
            fetchDiscountDetail: false,
            fetchShippingInfo: false,
            matboardColourOptions: {
                smoothWhite: {
                    colourName: "Smooth White (Bright White)",
                },
                chinaWhite: {
                    colourName: "China White (Off-White)",
                },
                smoothBlack: {
                    colourName: "Black",
                },
            },
            ikeaFrameNames: {
                "5x7": ["Ribba", "Fiskbo", "Silverhojden", "Edsbruk", "Knoppang", "Vireserum"],
                "8x10": ["Ribba", "Fiskbo", "Himmelsby", "Edsbruk", "Knoppang", "Vireserum"],
                "9x9": ["Ribba", "Knoppang", "Sannahed", "Hovsta"],
                "12x16": ["Ribba", "Knoppang", "Lomviken", "Silverhojden", "Dalskarr", "Virserum", "Edsbruk", "Fiskbo"],
                "16x20": ["Ribba", "Knoppang", "Lomviken", "Edsburk", "Silverhojden", "Fiskbo"],
                "19.75x19.75": ["Ribba"],
                "19.75x27.5": ["Ribba", "Lomviken", "Hovsta", "Fiskbo", "Silverhojden", "Edsbruk", "Knoppang", "Ramsborg"],
                "24x35.75": ["Ribba", "Lomviken", "Hovsta", "Fiskbo", "Silverhojden", "Edsbruk", "Knoppang", "Skatteby"],
            }

        };
    };

    componentDidMount() {
        // windowaddEventListener("scroll", this.setState({showNotificationBanner: false})

        if (this.props.selectedMatSize === false) {
            var pathname = window.location.pathname;
            var matboardSize = pathname.substring(
                // pathname.indexOf("-inch") + 1,
                pathname.lastIndexOf("-inch-matboard")
            );
        }

        if (this.props.selectedMatSize) {
            this.setState({selectedFrameSize: this.props.selectedMatSize})

            if (this.props.selectedOpeningSize) {
                this.setState({selectedOpeningSize: this.props.selectedOpeningSize})

            } else if (this.props.selectedMatSize === "9x9" || this.props.selectedMatSize === "19.75x19.75" || this.props.selectedMatSize === "20x20") {
                this.setState({selectedOpeningSize: "4x4"})
            }

        }



        this.setState({
            french: (window.location.pathname.slice(-2) === "fr") ? true : false
        })
    };

    validateQuantity(value) {
        if (Number.isInteger(parseInt(value)) === false || parseInt(value) <= 0) {
            alert("Please enter a valid quantity.")
        } else if (parseInt(value) > 0) {
            this.setState({orderQuantity: parseInt(value)})
        }
    }

    fetchIKEAFrameNames() {
        var frameNames = this.props.french ? `Pour les cadres IKEA, nos passe-partout de ${this.state.selectedFrameSize} pouces sont conçus pour s'adapter aux cadres Ribba, Knoppang, Sannahed et Hovsta.` : `For IKEA frames, our ${this.state.selectedFrameSize} inch matboards are designed to fit `;

        if (this.state.ikeaFrameNames[this.state.selectedFrameSize]) {
            this.state.ikeaFrameNames[this.state.selectedFrameSize].map((frame, key) => {
                if (key > 0) {
                    frameNames = frameNames + ", " + frame;
                } else {
                    frameNames = frameNames + " " + frame;
                }
            });
            frameNames = frameNames + `${this.props.french ? " cadres." : " frames."}`
            return frameNames
        }

    }

    clickAddToCartButton() {

        var selectedProductData = this.state.frameSize[this.state.selectedFrameSize];
        var frameWidth = selectedProductData.width;
        var frameHeight = selectedProductData.height;
        var windowWidth = selectedProductData.openSize[this.state.selectedOpeningSize].width;
        var windowHeight = selectedProductData.openSize[this.state.selectedOpeningSize].height;
        var price = selectedProductData.priceCAD;


        var item = {
            prodType: "precutMat",
            frameType: "Not Applicable -- Matboard Only",
            frameSize: "Not Applicable -- Matboard Only",
            frameWidth: frameWidth,
            frameHeight: frameHeight,
            matColour: this.state.selectedMatColour,
            window: {
                rowCount: 1,
                columnCount: 1,
                window1: {
                    width: windowWidth,
                    height: windowHeight,
                    marginTop: (frameHeight - windowHeight)/2,
                    marginBottom: (frameHeight - windowHeight)/2,
                    marginLeft: (frameWidth - windowWidth)/2,
                    marginRight: (frameWidth - windowWidth)/2,
                    showModal: false,
                },
            },
            secondMatPrice: 0,
            secondMatColour: "none",
            secondMatWidth: 0,
            orderQuantity: parseInt(this.state.orderQuantity),
            // //for matboard price, we will have to run a pricing mechanism for matboard pricing. We might borrow this from our legacy system.
            price: price,
            shipping: [9.95, 9.95],
            canvasDrawing: null,
            additionalNoteContent: "",
            backingMatboard: false,
            isWindowOval: false,
        };

        this.submitToGTag(price);

        this.addProductToLocalStorageCart(item);
        this.props.showShoppingCartModal("open");
    };

    submitToGTag(price) {
        window.gtag('event', 'add_to_cart', {
            'send_to': 'AW-732793253/RjgUCLrI2qcBEKWTtt0C',
            'value': parseFloat(price),
            'currency': "CAD",
            'transaction_id': ''
        })
    }

    addProductToLocalStorageCart(item) {
        //check if a shopper has already shopped something on before this item
        var shoppingCart = [];

        if (localStorageFunctions("shoppingCart", null) !== null ) {
            //this part will have to be parse json

            shoppingCart = JSON.parse(localStorageFunctions("shoppingCart", null));

            shoppingCart.push(item);
        } else {
            shoppingCart.push(item);
        };

        localStorageFunctions("shoppingCart", JSON.stringify(shoppingCart));
    };

    toggleModal(stateToToggle) {
        this.setState({[stateToToggle]: !this.state[stateToToggle]})
    };

    clickPrecutModal(sortSize) {
        if (this.props.french) {
            window.location.href = `${sortSize}-inch-matboard-fr`;
        } else {
            window.location.href = `${sortSize}-inch-matboard`;
        }
        // this.setState({selectedFrameSize: sortSize});
        //
        // if (sortSize === "9x9") {
        //     this.setState({selectedOpeningSize: "4x4"});
        // } else if (sortSize === "19.75x19.75") {
        //     this.setState({selectedOpeningSize: "4x4"});
        // } else if (sortSize === "20x20") {
        //     this.setState({selectedOpeningSize: "4x4"});
        // }
    };


    fetchPreCutMats(FrameOrArtworkSize) {

        var array = Object.keys(this.state.frameSize);

        return array.map((sortSize, key) => {
            return <div className={"pre-cut-mat-box"} onClick={() => {this.clickPrecutModal(sortSize)}}>
                <img style={{width: "100%"}} src={this.state.frameSize[sortSize].img} className={"pre-cut-mat-box-img"}
                     alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                />
                <div className={"fetch-precut-mats-title-container"}>
                    <div className={"fetch-precut-mats-title"}>
                        {`${sortSize}"${this.props.french ? " Passe-partout" : " Matboards"}`}
                    </div>
                    <div className={"fetch-precut-mats-subtitle"}>
                        {this.props.currency}{this.props.fxConversion(this.state.frameSize[sortSize].priceCAD * this.props.getDiscountMultiplier(1)).toFixed(2)}
                    </div>
                </div>
            </div>
        })
    };

    fetchWindowSizeDropdown(dropDownList, title, stateToUpdate) {
        // var list = Object.keys(productDB.taxRates[this.state.MJqZAX6AVWn4sIXbGOUCDPGCFU2WpgFGnoK8uagWmYw]);

        var list = Object.keys(dropDownList)

        return <FormControl
            variant="outlined" className={"precut-window-selector"}>
            <div className={"add-text-fields-text-field-title"}>
                {title}
            </div>
            <Select value={this.state[stateToUpdate]}
                    onChange={(evt) => {
                        // this.setState({[stateToUpdate]: evt.target.value})
                        window.location.pathname = this.props.french ? `${this.props.selectedMatSize}-inch-matboard-${evt.target.value}-inch-opening-fr` : `${this.props.selectedMatSize}-inch-matboard-${evt.target.value}-inch-opening`
                    }}
            >
                <MenuItem value={"Select"}>
                    {this.props.french ? "Sélectionner" : "Select"}
                </MenuItem>
                {
                    list.map((item, key) => {
                        return <MenuItem value={item} key={`select-province-state-${key}`} >
                            {item}" - {this.props.french ? dropDownList[item].descFr : dropDownList[item].desc}
                        </MenuItem>
                    })
                }
            </Select>
        </FormControl>
    };

    fetchListDropDown(dropDownList, title, stateToUpdate) {
        var list = dropDownList;

        return <FormControl
            variant="outlined" className={"precut-window-selector"}>
            <div className={"add-text-fields-text-field-title"}>
                {title}
            </div>
            <Select value={this.state[stateToUpdate]}
                    onChange={(evt) => {
                        this.setState({[stateToUpdate]: evt.target.value})
                    }}
            >
                <MenuItem value={"Select"}>
                    Select
                </MenuItem>
                {
                    list.map((item, key) => {
                        return <MenuItem value={item} key={`select-province-state-${key}`} >
                            {this.state.matboardColourOptions[item].colourName}
                        </MenuItem>
                    })
                }
            </Select>
        </FormControl>
    };

    reduceQuantity() {
        if (this.state.orderQuantity > 1) {
            this.setState({orderQuantity: this.state.orderQuantity - 1})
        } else {
            alert("Order quantity cannot be less than 1.")
        }

    };

    fetchAddToBagButton() {
        return <div className="add-to-bag-button" style={{width: "auto"}} onClick={() => {this.clickAddToCartButton()}}>
            {this.props.french ? "Adjouter au sac" : "Add to bag"}
        </div>
    }

    loadProductSummary() {
        if (this.props.french) {
            return <div className={"product-desc-dividers"}>
                <div className={"product-desc-dividers-header"}>
                    Résumé du produit:<br/><br/>
                </div>
                <div>
                    Passe-partout photo avec cœur blanc de {this.state.selectedFrameSize} pouces, de couleur{this.state.matboardColourOptions[this.state.selectedMatColour].colourNameFr}.  Ce passe-partout photo est découpé avec une seule fenêtre pour s'adapter aux impressions de {this.state.selectedOpeningSize} pouces.
                    La taille réelle de l'ouverture de la fenêtre sera de <b>{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height} pouces</b>.  L'ouverture de la fenêtre doit être plus petite que votre œuvre.<br/><br/>
                    Il n'y a pas de quantité minimale de commande. La livraison est gratuite pour les commandes de plus de 49 $ ou à un tarif fixe de 9,95 $ pour les commandes de moins de ce montant.<br/><br/>
                    Des remises quantitatives sont disponibles pour votre commande. Les remises seront automatiquement appliquées lorsque vous commandez 2+, 4+ et 10+ articles. Si vous avez besoin de plus de 50 feuilles, veuillez nous contacter pour obtenir un devis. Nous livrons partout au Canada et aux États-Unis.
                    <br/><br/>
                </div>
                <div className={"product-desc-dividers-header"}>
                    Spécifications du produit:
                </div>
                <div>
                    <ul>
                        <li>Dimensions totales: {this.state.selectedFrameSize} pouces</li>
                        <li>Dimensions de l'ouverture: {this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height}  pouces, conçues pour les impressions de {this.state.selectedOpeningSize} pouces</li>
                        <li>Passe-partout sans acide. Surface, cœur et papiers de support neutralisés à l'acide pour répondre à vos besoins de préservation</li>
                        <li>4 plis, d'une épaisseur de 1/16 de pouce</li>
                        <li>Toutes nos fenêtres sont découpées avec un biseau de 45 degrés</li>
                        <li>Découpes nettes et précises</li>
                        <li>Fabriqué et traité au Canada.</li>
                    </ul>
                </div>
            </div>
        } else {
            return <div className={"product-desc-dividers"}>
                <div className={"product-desc-dividers-header"}>
                    Product Summary:<br/><br/>
                </div>
                <div>
                    {this.state.selectedFrameSize} inch white core photo mat in {this.state.matboardColourOptions[this.state.selectedMatColour].colourName} {this.props.location === "us" ? " color " : " colour"}. This photo mat is cut with a single window to fit {this.state.selectedOpeningSize} inch prints.
                    Your actual window opening size will be <b>{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height}"</b>. The window opening should be smaller than your artwork. <br/><br/>
                    There is no minimum order quantity. Free shipping across Canada & the USA. <br/><br/>
                    Quantity discounts are available for your order. Discounts will be reflected automatically as you order 2+, 4+, and 10+ orders. If you need more than 50+ sheets, please contact us for pricing. We ship to anywhere in Canada and the United States.<br/><br/>
                </div>
                <div className={"product-desc-dividers-header"}>
                    Product Specifications:
                </div>
                <div>
                    <ul>
                        <li>Overall Dimension: {this.state.selectedFrameSize} inch</li>
                        <li>Opening Dimension: {this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height} inch opening designed to fit {this.state.selectedOpeningSize} inch prints</li>
                        <li>Acid-free matboard. Acid neutralized surface, core, and backing papers for your preservation needs</li>
                        <li>4 PLY, 1/16 inch thick</li>
                        <li>All our window openings are cut with 45 degree bevels</li>
                        <li>Clean and crisp cut</li>
                        <li>Made and processed in Canada</li>
                    </ul>
                </div>
            </div>
        }
    };

    loadShippingSummary() {
        if (this.props.french) {
            return <div>
                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        Autres informations:<br/><br/>
                    </div>
                    <div>
                        Nos passe-partout de {this.state.selectedFrameSize} pouces sont conçus pour s'adapter aux cadres de format standard de {this.state.selectedFrameSize}  pouces vendus par Amazon, Costco, IKEA, Target, Michael's et d'autres magasins de détail. {this.fetchIKEAFrameNames()}<br/><br/>
                    </div>
                </div>

                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        Politique de satisfaction à 100%:<br/><br/>
                    </div>
                    <div>
                        100 % de bonheur garanti. Si vous n'êtes pas satisfait de votre commande, veuillez nous en informer. Contactez-nous à l'adresse hi@custommat.com et nous rectifierons la situation.<br/><br/>
                    </div>
                </div>

                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        Besoin de personnalisation supplémentaire ou d'options de couleur?<br/><br/>
                    </div>
                    <div>
                        Essayez nos passe-partout personnalisés. Ils sont entièrement personnalisables - votre couleur, votre forme et votre design. Ils sont disponibles en plus de 20 options de couleur.<br/><br/>
                    </div>
                </div>


                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        Des questions?<br/><br/>
                    </div>
                    <div>
                        Si vous avez des questions, veuillez nous envoyer un e-mail à hi@CustomMat.com ou nous envoyer un SMS au 604•345•8428.<br/><br/>
                    </div>
                </div>
            </div>
        } else {
            return <div>
                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        Other Notes:<br/><br/>
                    </div>
                    <div>
                        Our {this.state.selectedFrameSize} inch matboards are designed to fit {this.state.selectedFrameSize} industry standard sized frames that are sold by Amazon, Costco, IKEA, Target, Michael’s and other retail stores. {this.fetchIKEAFrameNames()}<br/><br/>
                    </div>
                </div>

                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        100% Satisfaction Policy:<br/><br/>
                    </div>
                    <div>
                        100% Happiness Guaranteed. If you are not happy with your order, please let us know. Contact us through hi@custommat.com and we will make it right.<br/><br/>
                    </div>
                </div>

                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        Need further customization or more {this.props.location === "us" ? " color " : " colour "} options?<br/><br/>
                    </div>
                    <div>
                        Try our custom matboards. They are 100% customizable – your {this.props.location === "us" ? " color " : " colour "}, your shape, and your design. They come in 20+ {this.props.location === "us" ? " color " : " colour "} options.<br/><br/>
                    </div>
                </div>


                <div className={"product-desc-dividers"}>
                    <div className={"product-desc-dividers-header"}>
                        Any Questions?<br/><br/>
                    </div>
                    <div>
                        If you have any questions, please email us hi@CustomMat.com or text us at 604•345•8428.<br/><br/>
                    </div>
                </div>
            </div>
        }
    }

    fetchPreCutMatDetails() {

        var selectedFrame = this.state.frameSize[this.state.selectedFrameSize];

        return <div className={"precut-container"}>
            <div className={"precut-sub-container"}>

                <div className={"precut-container-left"}>

                    <Carousel>
                        <div className={"carousel-image-item"}>
                            <img src={selectedFrame.openSize[this.state.selectedOpeningSize].photos[0]} className={"precut-container-left-photo"}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={selectedFrame.openSize[this.state.selectedOpeningSize].photos[2]} className={"precut-container-left-photo"}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={selectedFrame.openSize[this.state.selectedOpeningSize].photos[1]} className={"precut-container-left-photo"}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions1}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions2}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions3}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions4}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions5}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions6}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions7}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions8}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions9}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions10}/>
                        </div>

                    </Carousel>

                </div>

                <div className={"precut-container-right"}>

                    <div style={{width: "auto", overflow: "auto", paddingBottom: "16px"}}>

                        {this.fetchAddToBagButton()}

                        <div className="select-mat-container-row" style={{width: "auto", cursor: "pointer"}}>
                            {this.props.french ? "Devise" : "Currency"}:
                            <span className={this.props.currency === "CAD" ? "bold" : ""} onClick={() => {this.props.changeCurrency("CAD")}}> CAD </span>
                            |
                            <span className={this.props.currency === "USD" ? "bold" : ""} onClick={() => {this.props.changeCurrency("USD")}}> USD </span>
                            <br/>
                            {this.props.french ? "Prix Unitaire" : "Unit Price"}: <b>$<span>{this.props.fxConversion(this.state.frameSize[this.state.selectedFrameSize].priceCAD * this.props.getDiscountMultiplier(this.state.orderQuantity)).toFixed(2)}</span></b>
                        </div>

                        {/*//Black Friday*/}
                        <div className={this.props.onGoingPromotion ? "promotion-messages" : "no-display"}>
                            {this.props.french ? `Réduction extra ${this.props.promotionRate} à la caisse` : `Additional ${this.props.promotionRate} off at checkout`}
                        </div>
                    </div>

                    <h1 className={"precut-size-header"}>
                        {this.state.selectedFrameSize}" {this.props.french ? "Passe-partout prédécoupé" : "Pre-cut Matboard"} <br/>
                        {this.props.french ? `avec une DÉCOUPE DE FENÊTRE DE ${this.props.selectedOpeningSize}"` : `with  ${this.props.selectedOpeningSize}" Window Cutout`}
                    </h1>

                    <div className="add-text-fields-text-field-title" style={{width: "100%", borderBottom: "1px solid #828282", paddingBottom: "12px", marginBottom: "12px", fontWeight: "500", color: "#3b3b3b"}}>
                        {this.props.french ? "Quantité" : "Quantity"}<br/>
                    </div>

                    <div style={{width: "100%", overflow: "auto", fontWeight: 400}}>
                        <div className={"quantity-change-div"} style={{float: "left"}}>
                            <span style={{textTransform: "none", color: "#3B3B3B"}}>{this.props.fetchDiscountTier(this.state.orderQuantity)}</span>
                            <a target={"_blank"} href={"/pricing-and-discounts"} className={"select-mat-request-samples"}>
                                {this.props.french ? "Remise quantitative" : "Quantity Discount"}
                            </a>
                        </div>

                        <div className={"quantity-change-div"}  style={{float: "right"}}>
                            <div className={"cart-slider-shopped-item-quantity-button quantity-change-div-button"}  style={{width: "auto", margin: "10px", marginRight: 0, float: "right"}}  onClick={() => {this.reduceQuantity()}}>-</div>
                            <div className={"order-quantity-input-box"} style={{float: "right"}}>
                                {displayInputFields("", this.state.orderQuantity, (event) => this.validateQuantity(event.target.value), "outlined")}
                            </div>
                            <div className={"cart-slider-shopped-item-quantity-button quantity-change-div-button"} style={{width: "auto", margin: "10px", float: "right"}} onClick={() => {this.setState({orderQuantity: this.state.orderQuantity + 1})}}>+</div>
                        </div>
                    </div>


                    <div style={{width: "100%", overflow: "auto", paddingBottom: "16px"}}>
                        {this.fetchWindowSizeDropdown(this.state.frameSize[this.state.selectedFrameSize].openSize, this.props.french ? "Taille de la fenêtre" : "Window Size", "selectedOpeningSize")}
                        <div className={"select-mat-input-field-direction-long"}>
                            {this.props.french ? "La taille réelle de l'ouverture de votre fenêtre sera de " : "Your actual window opening size will be "}
                            <b>{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height}"</b>.
                            {this.props.french ? " L'ouverture de la fenêtre devrait être plus petite que votre œuvre." : "The window opening should be smaller than your artwork."}<br/><br/>
                            {this.props.french ? "Vous n'avez pas la découpe que vous cherchez?" : "Don't have the opening you are looking for? Click here to get a custom opening."}
                            <a href={"/customize-matboards"}>{this.props.french ? "Cliquez ici pour obtenir une découpe personnalisée." : "Click here to get a custom opening."}</a>
                        </div>
                    </div>

                    <div style={{width: "100%", overflow: "auto", paddingBottom: "16px"}}>
                        {this.fetchListDropDown(Object.keys(this.state.matboardColourOptions), this.props.french ? "La couleur du passe-partout" : `Matboard ${this.props.location === "us" ? "Color" : "Colour"}`, "selectedMatColour")}
                        <div className={"select-mat-input-field-direction-long"}>
                            {this.props.french ? "Plus d'options de couleur disponibles avec " : `More ${this.props.location === "us" ? " color " : " colour "} options available with `}
                            <a href={"/customize-matboards"}>
                                {this.props.french ? "l'option de passe-partout personnalisé." : `custom matting option.`}
                            </a>
                        </div>
                    </div>


                    {/*<div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b"}}>*/}
                    {/*        Quantity*/}

                    {/*</div>*/}

                    {/*<div className={"cart-slider-shopped-item-quantity"} style={{margin: 0, paddingBottom: "12px", width: "100%", borderBottom: "solid 1px #828282"}}>*/}
                    {/*    <div className={"cart-slider-shopped-item-quantity-button"} style={{marginTop: "10px"}} onClick={() => {this.setState({selectedQuantity: this.state.selectedQuantity + 1})}}>+</div>*/}
                    {/*    <div className={"select-window-multiple-window-dimensions"}>*/}
                    {/*        /!*{this.state.selectedQuantity}*!/*/}
                    {/*        {displayInputFields("", this.state.selectedQuantity, (event) => this.setState({selectedQuantity: event.target.value}))}*/}
                    {/*    </div>*/}
                    {/*    <div className={"cart-slider-shopped-item-quantity-button"} style={{marginTop: "10px", marginLeft: "10px"}} onClick={() => {this.reduceQuantity()}}>-</div>*/}
                    {/*</div>*/}

                    <div style={{width: "100%", overflow: "auto", paddingBottom: "16px", cursor: "pointer"}}>

                        <div className="select-mat-row" style={{paddingBottom: "4px", marginTop: "12px", width: "100%", borderBottom: "solid 1px #828282"}} onClick={() => this.setState({fetchDiscountDetail: !this.state.fetchDiscountDetail})}>
                            <img className="select-matboard-window-caption-arrow"
                                 style={{marginTop: "-2px", paddingTop: 0}}
                                 src={this.state.fetchDiscountDetail ? arrowUp : arrowDown} alt="Click to add a note." />
                            <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b", fontSize: "13px", overflow: "auto"}}>
                                {this.props.french ? "Remises Quantitatives" : "Quantity Discounts"}
                            </div>
                            <div className={this.state.fetchDiscountDetail ? "display" : "no-display"} style={{marginLeft: "40px"}}>
                                <li>
                                    {this.props.french ? "2 articles ou plus - 20% de réduction" : " 2+ items - 20% off"}
                                </li>
                                <li>
                                    {this.props.french ? "4 articles ou plus - 30% de réduction" : "4+ items - 30% off"}
                                </li>
                                <li>
                                    {this.props.french ? "10 articles ou plus - 40% de réduction" : "10+ items - 40% off"}
                                </li>
                                <li>
                                    {this.props.french ? "20 articles ou plus - 50% de réduction" : "20+ items - 50% off"}
                                </li>
                                <li>
                                    {this.props.french ? "50 articles ou plus - 60% de réduction" : "50+ items - 60% off"}
                                </li>
                                <li>
                                    {this.props.french ? "100 articles ou plus - 64% de réduction" : "100 items - 64% off"}
                                </li>
                                <li>
                                    {this.props.french ? "250 articles ou plus - 66% de réduction" : "250+ items - 66% off"}
                                </li>
                                <li>
                                    {this.props.french ? "500 articles ou plus - 68% de réduction" : "500+ items - 68% off"}
                                </li>
                                <li>
                                    {this.props.french ? "1000 articles ou plus - 70% de réduction" : "1000+ items - 70% off"}

                                </li>
                            </div>
                        </div>


                        <div className="select-mat-row" style={{paddingBottom: "4px", marginTop: "12px", width: "100%", borderBottom: "solid 1px #828282"}} onClick={() => this.setState({fetchProductDetail: !this.state.fetchProductDetail})}>
                            <img className="select-matboard-window-caption-arrow"
                                 style={{marginTop: "-2px", paddingTop: 0}}
                                 src={this.state.fetchProductDetail ? arrowUp : arrowDown} alt="Click to add a note." />
                            <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b", fontSize: "13px", overflow: "auto"}}>
                                {this.props.french ? "Détails du produit" : "Product Details"}
                            </div>
                            <div className={this.state.fetchProductDetail ? "display" : "no-display"} style={{marginLeft: "40px" }}>
                                <li>
                                    {this.props.french ? "Taille du passe-partout :" : "Matboard Size: "}  {this.state.selectedFrameSize}
                                </li>
                                <li>
                                    {this.props.french ? "Conçu pour des impressions de " : "Designed for "}
                                    {this.state.selectedOpeningSize}
                                    {this.props.french ? "pouces" : "inch Prints"}

                                </li>
                                <li>
                                    {this.props.french ? "Découpe de fenêtre réelle: " : "Actual window cutout: "}
                                    {this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].width}x{this.state.frameSize[this.state.selectedFrameSize].openSize[this.state.selectedOpeningSize].height}"
                                    {this.props.french ? "pouces" : "inch"}**<br/>
                                </li>
                                <li>
                                    {this.props.french ? "Standard 4 plis (1/16 de pouce) d'épaisseur" : " Standard 4-ply (1/16 inch) thick"}

                                </li>
                                <li>
                                    {this.props.french ? "Biseau blanc de 45 degrés avec un cœur blanc net" : "  45 degree, crisp, white-core bevel"}
                                </li>
                                <li>
                                    {this.props.french ? "Acid-free (acid-neutralized) buffered surface to meet your preservation needs" : "Sans acide (surface tamponnée pour neutraliser l'acidité) pour répondre à vos besoins de préservation"}
                                </li>
                                <li>
                                    {this.props.french ? "Résistant à la décoloration et à l'écoulement de l'encre" : " Fade and bleed resistant"}
                                </li>
                                <li>
                                    {this.props.french ? "Fabriqué et expédié depuis le Canada" : "Made and shipped from Canada"}
                                </li>
                                <br/>
                                    {this.props.french ? "** Veuillez noter que notre découpe de fenêtre est plus petite que votre œuvre. Sans le recouvrement, l'œuvre risque de passer à travers l'ouverture." : "** Please note that our window cutout is smaller than your artwork. Without the overlap, the artwork is going to fall through the opening."}

                            </div>
                        </div>


                        <div className="select-mat-row" style={{paddingBottom: "4px", marginTop: "12px", width: "100%", borderBottom: "solid 1px #828282"}} onClick={() => this.setState({fetchShippingInfo: !this.state.fetchShippingInfo})}>
                            <img className="select-matboard-window-caption-arrow"
                                 style={{marginTop: "-2px", paddingTop: 0}}
                                 src={this.state.fetchShippingInfo ? arrowUp : arrowDown} alt="Click to add a note." />
                            <div className={"add-text-fields-text-field-title"} style={{color: "#3b3b3b", fontSize: "13px", overflow: "auto"}}>
                                {this.props.french ? "Livraison et retours" : "Shipping & Returns"}
                            </div>
                            <div className={this.state.fetchShippingInfo ? "display" : "no-display"} style={{marginLeft: "40px"}}>
                                <li>
                                    {this.props.french ? "Expédition au Canada et aux États-Unis" : "Ship to Canada and the USA"}
                                </li>
                                <li>
                                    {this.props.french ? "Livraison gratuite pour les commandes de plus de 49$" : "Free shipping"}
                                </li>
                                <li>
                                    {this.props.french ? "Livraison gratuite partout au Canada et aux États-Unis" : "Free shipping across Canada & the USA."}
                                </li>
                                <li>
                                    {this.props.french ? "Nous acceptons les retours" : "We accept returns"}
                                </li>
                                <li>
                                    {this.props.french ? "Expédié dans un délai d'un jour ouvrable" : "Dispatches within 1 business day"}
                                </li>
                                <li>
                                    {this.props.french ? "Emballage résistant aux dommages" : "Damage-resistant packaging"}
                                </li>
                                <li>
                                    {this.props.french ? "En cas de dommages pendant le transport, des passe-partout de remplacement seront envoyés." : " If damaged while in transit, replacements mats will be sent"}
                                </li>
                            </div>
                        </div>

                    </div>

                    <div style={{width: "auto", overflow: "auto", paddingBottom: "16px"}}>
                        {this.fetchAddToBagButton()}
                        {/*<div className="select-mat-container-row" style={{width: "auto"}}>*/}
                        {/*    Currency: <b>CAD</b><br/>*/}
                        {/*    Unit Price: <b>$<span>{this.state.frameSize[this.state.selectedFrameSize].priceCAD}</span></b>*/}
                        {/*</div>*/}
                    </div>


                </div>
            </div>
            <div>

                {this.loadProductSummary()}

                {this.loadShippingSummary()}

            </div>
        </div>
    };

    render() {
        return <div>
            <Helmet>
                <title>
                    {this.state.french ? `Passe-partout pré-découpé de ${this.state.selectedFrameSize ? this.state.selectedFrameSize : ""} pouces avec ouverture de ${this.props.selectedOpeningSize ? this.props.selectedOpeningSize : "différentes tailles standard"}" pouces pour cadre de taille standard de ${this.state.selectedFrameSize ? this.state.selectedFrameSize : ""} pouces` :
                        `${this.state.selectedFrameSize ? this.state.selectedFrameSize : ""} inch precut matboard with ${this.props.selectedOpeningSize ? this.props.selectedOpeningSize : "différentes tailles standard"} inch opening for standard sized ${this.state.selectedFrameSize ? this.state.selectedFrameSize : ""} inch frame`}
                </title>
                <meta name="description" content={
                    this.state.french ? "Découvrez nos passe-partouts pré-découpés de taille standard pour cadres populaires et estampes artistiques. Expédition le prochain jour ouvrable avec livraison gratuite et retours faciles. Livraison au Canada et aux États-Unis. Prêts à être utilisés avec des cadres d'Amazon, IKEA et plus encore." :
                        `Explore our pre-cut standard-sized matboards for popular frames and art prints. Ships next business day with free shipping and easy returns. Delivering to Canada & the USA. Ready for use with frames from Amazon, IKEA, and more.`
                } />
            </Helmet>

            <div className={`precut-mat-container`}>

                <div className={this.state.selectedFrameSize ? "no-display" : "display"} style={{margin: "16px 0"}}>
                    <h1 className={"inspiration-listing-header"}>
                        {this.props.french ? "DIMENSIONS STANDARD" : "STANDARD SIZED"}<br/>
                        {this.props.french ? "PASSE-PARTOUTS" : "MATBOARDS"}
                    </h1>
                    <h2 className={"inspiration-listing-desc"}>

                        {this.props.french ? "PASSE-PARTOUTS PRÉ-DÉCOUPÉS AUX DIMENSIONS STANDARD" : "PRE-CUT, STANDARD-SIZED MATBOARDS"} <br className={"mobile-no-display"}/>
                        {this.props.french ? "POUR LES CADRES ET LES REPRODUCTIONS D'ART LES PLUS POPULAIRES." : "for the most popular frames and art prints."} <br className={"mobile-no-display"}/>
                        {this.props.french ? "EXPÉDIÉS LE PROCHAIN JOUR OUVRABLE." : "Ships next business day."} <br/>

                        {this.props.french ? "LIVRÉS CHEZ VOUS AU CANADA ET AUX ÉTATS-UNIS." : "Delivered to your door in Canada & USA."}  <br className={"mobile-no-display"}/>
                        {this.props.french ? "LIVRAISON RAPIDE ET RETOURS FACILES." : "Fast Shipping & Easy Returns."}  <br className={"mobile-no-display"}/>
                    </h2>
                    <div style={{width: "100%", overflow: "auto", margin: "32px 0"}}>
                        {this.state.selectedFrameSize ? null : this.fetchPreCutMats()}
                    </div>
                </div>

                <div className={this.state.selectedFrameSize ? "display" : "no-display"}>
                    {this.state.selectedFrameSize ? this.fetchPreCutMatDetails() : null}
                </div>
            </div>

            <LandingPageReviewStars
                french={this.props.french}
                location = {this.props.location}
            />

            <InstagramLoader
                french={this.props.french}
                location = {this.props.location}
            />

            <SatisfactionTransition
                french={this.props.french}
                location = {this.props.location}
            />


            <LandingPageFAQ
                french={this.props.french}
                location = {this.props.location}
            />



        </div>
    }
}