import React from "react";
import {Helmet} from "react-helmet";
import WhatIsMatboard from "../page-howItWorks/whatIsMatboard";
import Explainer from "../page-howItWorks/explainer";
import InstagramLoader from "../page-landing/instagramLoader";
import ReviewComponent from "../app-review/reviewComponent";


export default class RegionalDelivery extends React.Component {

    constructor() {
        super();
        this.state = {

        };
    };

    componentDidMount() {

    };

    fetchAdditionalComponents() {
        return <div>
            <WhatIsMatboard
                language={this.props.language}
                country={this.props.country}r
            />
            <Explainer
                language={this.props.language}
                country={this.props.country}
            />
            <InstagramLoader
                language={this.props.language}
                country={this.props.country}
            />
            <ReviewComponent
                language={this.props.language}
                country={this.props.country}
            />
        </div>
    }

    fetchEnglishContent() {
        return <div className={"blog-app-container"}>
            <div className="blog-article-content">

                <Helmet>
                    <title>
                        100% Custom Matboard Delivered to UK now. Free Shipping Available for most of the sizes.
                    </title>
                    <meta name="description" content="Custom Mat now ships 100% custom matboards to United Kingdom and Ireland. Your color, design, and dimesnions. Free shipping available for most sizes." />
                </Helmet>

                <h1 className={"carousel-subject-head"}>
                    100% Custom Matboard Delivered to UK and Ireland now.
                </h1>

                <h3>
                    100% Custom Matboard Delivered to UK and Ireland now. Free Shipping Available for most of the sizes.
                </h3>

                <div>
                    Shipping Info for UK and Ireland.
                </div>

                <ul>
                    <li>We ship to UK and Ireland free of charge on eligible orders. Additional charges apply for larger items.</li>
                    <li>UK - Free shipping on all orders. Including the larger items. </li>
                    <li>Ireland - Free shipping on orders, smaller than 24 inches.</li>
                    <li>Ireland - Items over 24 inches (one side) incur a shipping fee of approximately $10 USD (around €9).</li>
                    <li>Ireland - Items over 28 inches (one side) incur a shipping fee of approximately $15 USD (around €13.50).</li>
                    <li>UK & Ireland - Complete handling of tax formalities: customs, VAT, import duties, and provincial/state sales taxes.</li>
                    <li>Delivery partners: APC, POST NL, and National Carriers (6–15 business days).</li>
                    <i>Courier selection depends on package size, weight, and shipping address.</i>
                </ul>

                <div className="visit-custom-mat-button">
                    <a href="https://www.custommat.com" className="carousel-read-more-button">
                        Visit Custom Mat
                    </a>
                </div>

                {this.fetchAdditionalComponents()}

            </div>
        </div>
    }


    fetchFrenchContent() {
        return <div className={"blog-app-container"}>
            <div className="blog-article-content">

                <Helmet>
                    <title>
                        Passe-partout sur mesure en France avec livraison en ligne, également disponible en Belgique, Suisse et Luxembourg.
                    </title>
                    <meta name="description" content={"Custom Mat expédie désormais des passe-partouts 100 % personnalisés en France, Belgique, Suisse et Luxembourg. Livraison gratuite disponible pour la plupart des tailles."} />
                </Helmet>

                <h1 className={"carousel-subject-head"}>
                    Passe-partout sur mesure en France avec livraison en ligne
                </h1>

                <h3>
                    Custom Mat expédie désormais des passe-partouts 100 % personnalisés en France, Belgique, Suisse et Luxembourg. Livraison gratuite disponible pour la plupart des tailles.
                </h3>

                <div>
                    Commandez en ligne dès aujourd'hui pour des passe-partouts sur mesure livrés rapidement et en toute sécurité !
                </div>

                <div>
                    Informations sur la livraison pour la France, la Belgique, le Luxembourg et la Suisse
                </div>

                <ul>
                    <li>Livraison gratuite vers les pays de l'UE, la Norvège et la Suisse pour les commandes éligibles. Des frais supplémentaires s'appliquent aux articles plus volumineux.</li>
                    <li>Livraison gratuite pour les commandes de moins de 24 pouces (60 cm).</li>
                    <li>Articles de plus de 24 pouces (un côté) : frais d'expédition de ~10 USD (environ 9 €).</li>
                    <li>Articles de plus de 28 pouces (un côté) : frais d'expédition de ~15 USD (environ 13,50 €).</li>
                    <li>Gestion complète des formalités fiscales : douanes, TVA, droits d'importation et taxes de vente provinciales/étatiques.</li>
                    <li>Livraison en Europe : APC, POST NL et transporteurs nationaux (6 à 15 jours ouvrables).</li>
                    <i>Le choix du transporteur dépend de la taille, du poids et de l'adresse de livraison.</i>
                </ul>

                <div className="visit-custom-mat-button">
                    <a href="https://fr.custommat.com" className="carousel-read-more-button">
                        Visitez Custom Mat
                    </a>
                </div>

                {this.fetchAdditionalComponents()}

            </div>
        </div>
    }

    fetchGermanContent() {
        return <div className={"blog-app-container"}>
            <div className="blog-article-content">

                <Helmet>
                    <title>
                        Passepartout nach Maß Deutschland, Österreich und der Schweiz Online-Lieferung
                    </title>
                    <meta name="description" content={"100 % maßgefertigte Passepartouts jetzt mit Lieferung nach Deutschland. Kostenloser Versand für die meisten Größen verfügbar. Custom Mat liefert jetzt nach Europa, einschließlich Deutschland, Österreich und der Schweiz. Kostenloser Versand für die meisten Größen, mit geringen Gebühren für größere Pakete."} />
                </Helmet>

                <h1 className={"carousel-subject-head"}>
                    Passepartout nach Maß Deutschland, Österreich und der Schweiz Online-Lieferung
                </h1>

                <h3>
                    100 % maßgefertigte Passepartouts jetzt mit Lieferung nach Deutschland, Österreich und Schweiz. Kostenloser Versand für die meisten Größen verfügbar.
                </h3>

                <div>
                    Versandinformationen für Deutschland, Österreich und die Schweiz
                </div>

                <ul>
                    <li>Wir liefern in die EU-Länder, nach Norwegen und in die Schweiz kostenlos bei berechtigten Bestellungen. Zusätzliche Gebühren fallen für größere Artikel an.</li>
                    <li>Kostenloser Versand für Bestellungen unter 24 Zoll.</li>
                    <li>Artikel größer als 24 Zoll (eine Seite) kosten ca. 10 USD Versandgebühren.</li>
                    <li>Artikel größer als 28 Zoll (eine Seite) kosten ca. 15 USD Versandgebühren.</li>
                    <li>Gestion complète des formalités fiscales : douanes, TVA, droits d'importation et taxes de vente provinciales/étatiques.</li>
                    <li>Wir kümmern uns um alle steuerlichen Angelegenheiten, einschließlich Zoll, Mehrwertsteuer, Einfuhrzölle und staatliche/provinciale Verkaufssteuern.</li>
                    <li> Europa-Lieferungen: APC, POST NL und nationale Spediteure (6–15 Werktage). Die Auswahl des Kuriers hängt von der Größe, dem Gewicht und der Lieferadresse des Pakets ab.</li>
                    <i>Die Auswahl des Kuriers hängt von der Größe, dem Gewicht und der Lieferadresse des Pakets ab.</i>
                </ul>

                <div className="visit-custom-mat-button">
                    <a href="https://de.custommat.com" className="carousel-read-more-button">
                        Besuchen Sie Custom Mat
                    </a>
                </div>

                {this.fetchAdditionalComponents()}
            </div>
        </div>
    }

    fetchSpanishContent() {
        return <div className={"blog-app-container"}>
            <div className="blog-article-content">

                <Helmet>
                    <title>
                        Passepartout a medida España entrega online
                    </title>
                    <meta name="description" content="Passepartout 100 % a medida ahora con entrega en España. Envío gratuito disponible para la mayoría de los tamaños. Custom Mat ahora envía a Europa, incluyendo España. Envío gratuito para la mayoría de los tamaños, con tarifas reducidas para paquetes más grandes." />
                </Helmet>

                <h1 className={"carousel-subject-head"}>
                    Passepartout a medida España entrega online
                </h1>

                <h3>
                    Passepartout 100 % a medida ahora con entrega en España. Envío gratuito disponible para la mayoría de los tamaños.
                </h3>

                <div>
                    Información de envío para España
                </div>

                <ul>
                    <li>Realizamos envíos gratuitos a los países de la UE, Noruega y Suiza en pedidos elegibles. Se aplican cargos adicionales para artículos más grandes.</li>
                    <li>Envío gratuito para pedidos de menos de 24 pulgadas.</li>
                    <li>Los artículos más grandes de 24 pulgadas (un lado) tienen un cargo de envío de aproximadamente 10 USD.</li>
                    <li>Los artículos más grandes de 28 pulgadas (un lado) tienen un cargo de envío de aproximadamente 15 USD.</li>
                    <li>Gestión completa de los trámites fiscales: aduanas, IVA, aranceles de importación e impuestos sobre ventas provinciales/estatales.</li>
                    <li>Nos encargamos de todos los asuntos fiscales, incluidos aduanas, IVA, derechos de importación e impuestos de ventas estatales/provinciales.</li>
                    <li>Envíos a Europa: APC, POST NL y transportistas nacionales (6–15 días hábiles). La elección del mensajero depende del tamaño, el peso y la dirección de entrega del paquete.</li>
                    <i>La elección del mensajero depende del tamaño, el peso y la dirección de entrega del paquete.</i>
                </ul>

                <div className="visit-custom-mat-button">
                    <a href="https://es.custommat.com" className="carousel-read-more-button">
                        Visite Custom Mat
                    </a>
                </div>

                {this.fetchAdditionalComponents()}
            </div>
        </div>
    }

    fetchItalianContent() {
        return <div className={"blog-app-container"}>
            <div className="blog-article-content">

                <Helmet>
                    <title>
                        Passepartout su misura Italia consegna online
                    </title>
                    <meta name="description" content="Passepartout 100% su misura ora con consegna in Spagna. Spedizione gratuita disponibile per la maggior parte delle dimensioni. Custom Mat ora spedisce in Europa, inclusa la Spagna. Spedizione gratuita per la maggior parte delle dimensioni, con tariffe ridotte per pacchi più grandi." />
                </Helmet>

                <h1 className={"carousel-subject-head"}>
                    Passepartout su misura Italia consegna online
                </h1>

                <h3>
                    Passepartout 100% su misura ora con consegna in Italia. Spedizione gratuita disponibile per la maggior parte delle dimensioni.
                </h3>

                <div>
                    Informazioni sulla spedizione per la Italia
                </div>

                <ul>
                    <li>Effettuiamo spedizioni gratuite nei paesi UE, in Norvegia e in Svizzera per ordini idonei. Si applicano costi aggiuntivi per articoli di dimensioni maggiori.</li>
                    <li>Spedizione gratuita per ordini sotto i 24 pollici.</li>
                    <li>Articoli più grandi di 24 pollici (un lato) hanno una tassa di spedizione di circa 10 USD.</li>
                    <li>Articoli più grandi di 28 pollici (un lato) hanno una tassa di spedizione di circa 15 USD.</li>
                    <li>Gestione completa delle pratiche fiscali: dogane, IVA, dazi di importazione e tasse sulle vendite provinciali/statali.</li>
                    <li>Ci occupiamo di tutte le questioni fiscali, comprese dogane, IVA, diritti di importazione e tasse sulle vendite statali/provinciali.</li>
                    <li>Spedizioni in Europa: APC, POST NL e corrieri nazionali (6–15 giorni lavorativi). La scelta del corriere dipende dalle dimensioni, dal peso e dall'indirizzo di consegna del pacco.</li>
                    <i>La scelta del corriere dipende dalle dimensioni, dal peso e dall'indirizzo di consegna del pacco.</i>
                </ul>

                <div className="visit-custom-mat-button">
                    <a href="https://it.custommat.com" className="carousel-read-more-button">
                        Visita Custom Mat
                    </a>
                </div>

                {this.fetchAdditionalComponents()}
            </div>
        </div>
    }

    fetchDutchContent() {
        return <div className={"blog-app-container"}>
            <div className="blog-article-content">
                <Helmet>
                    <title>
                        Maatwerk Passepartout Nederland en België online levering
                    </title>
                    <meta name="description" content="100% maatwerk passepartouts nu met levering in Nederland. Gratis verzending beschikbaar voor de meeste formaten. Custom Mat levert nu ook in Europa, inclusief Nederland. Gratis verzending voor de meeste formaten, met verlaagde tarieven voor grotere pakketten." />
                </Helmet>

                <h1 className={"carousel-subject-head"}>
                    Maatwerk Passepartout Nederland en België online levering
                </h1>

                <h3>
                    100% maatwerk passepartout nu met levering in Nederland en België. Gratis verzending beschikbaar voor de meeste formaten.
                </h3>

                <div>
                    Verzendinginformatie voor Nederland
                </div>

                <ul>
                    <li>Gratis verzending naar EU-landen, Noorwegen en Zwitserland voor in aanmerking komende bestellingen. Extra kosten voor grotere artikelen.</li>
                    <li>Gratis verzending voor bestellingen onder de 24 inch.</li>
                    <li>Grotere artikelen van 24 inch (één kant) hebben een verzendkost van ongeveer 10 USD.</li>
                    <li>Grotere artikelen van 28 inch (één kant) hebben een verzendkost van ongeveer 15 USD.</li>
                    <li>Volledige belastingverwerking: douane, btw, invoerrechten en provinciale/staat belastingheffingen.</li>
                    <li>Wij regelen alle belastingaangelegenheden, inclusief douane, btw, invoerrechten en staats-/provinciale belastingheffingen.</li>
                    <li>Verzendingen naar Europa: APC, POST NL en nationale koeriers (6-15 werkdagen). De keuze van de koerier hangt af van de grootte, het gewicht en het afleveradres van het pakket.</li>
                    <i>De keuze van de koerier hangt af van de grootte, het gewicht en het afleveradres van het pakket.</i>
                </ul>

                <div className="visit-custom-mat-button">
                    <a href="https://nl.custommat.com" className="carousel-read-more-button">
                        Bezoek Custom Mat
                    </a>
                </div>

                {this.fetchAdditionalComponents()}
            </div>
        </div>
    }

    render() {
        if (this.props.country === "France") {
            return this.fetchFrenchContent()
        } else if (this.props.country === "Germany") {
            return this.fetchGermanContent()
        } else if (this.props.country === "Spain") {
            return this.fetchSpanishContent()
        } else if (this.props.country === "Italy") {
            return this.fetchItalianContent()
        } else if (this.props.country === "Netherlands") {
            return this.fetchDutchContent()
        }

        else {
            return this.fetchEnglishContent()
        }
    }
}