import React from "react";
import logoSmallV2 from '../../../resources/logo/Brandmark.svg';
import leafVector from '../../../resources/logo/leaf-vector-white.svg';
import InstagramIcon from '../../../resources/svgIcons/instagram.svg';
import PinterestIcon from '../../../resources/svgIcons/pinterest.svg';
import FacebookIcon from '../../../resources/svgIcons/facebook.svg';


export default class NavBarBottom extends React.Component {

    constructor() {
      super();
      this.state = {
          french: window.location.pathname === "/fr" ? true : false,
      }
    }

    componentDidMount() {
        this.setState({
            french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
        })
    }

    render() {

        return <div className="navbar-bottom-container">
            <div className={`navbar-bottom-options`}>
                <div className={`navbar-bottom-column navbar-bottom-column-min-height`}>

                    <div className={`navbar-column-title`}>
                        {this.state.french ? "à propos" : "About us"}
                    </div>

                    <a href={this.state.french ? "/design-blog-main" : "/design-blog-main"}>
                        <div className={`navbar-column-buttons`}>
                            {this.state.french ? "Blog" : "Design Blog"}
                        </div>
                    </a>

                    <a href={this.state.french ? "/customer-reviews" : "/customer-reviews"}>
                        <div className={`navbar-column-buttons`}>
                            {this.state.french ? "Publier une évaluation" : "Post a Review"}
                        </div>
                    </a>

                    <a href={this.state.french ? "/customer-reviews" : "/customer-reviews"}>
                        <div className={`navbar-column-buttons`}>
                            {this.state.french ? "Lisez nos avis" : "Read Our Reviews"}
                        </div>
                    </a>

                    <a href={this.state.french ? "/gallery-fr" : "/gallery"}>
                        <div className={`navbar-column-buttons`}>
                            {this.state.french ? "Galérie de photos des clients" : "Customer Photo Gallery"}
                        </div>
                    </a>










                    {/*<a href={this.state.french ? "/inspiration-fr" : "/inspiration"}>*/}
                    {/*    <div className={`navbar-column-buttons`}>*/}
                    {/*        {this.state.french ? "Inspiration" : "Inspiration"}*/}
                    {/*    </div>*/}
                    {/*</a>*/}




                    {/*<a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"}>*/}
                    {/*    <div className={`navbar-column-buttons`}>*/}
                    {/*        {this.state.french ? "Studio de conception" : "Design Studio"}*/}
                    {/*    </div>*/}
                    {/*</a>*/}





                    {/*<a href={"https://www.etsy.com/ca/shop/CustommatCA"}>*/}
                    {/*    <div className={`navbar-column-buttons`}>*/}
                    {/*        {this.state.french ? "Magasin Etsy" : "Etsy Store"}*/}
                    {/*    </div>*/}
                    {/*</a>*/}

                    <a href={this.state.french ? "/sitemap-fr" : "/sitemap"}>
                        <div className={`navbar-column-buttons`}>
                            {this.state.french ? "Sitemap" : "Site Map"}
                        </div>
                    </a>


                </div>

                <div className={`navbar-bottom-column navbar-bottom-column-min-height`}>

                    <div className={`navbar-column-title`}>
                        {this.state.french ? "Assistance" : "Support"}
                    </div>

                    <a href={this.state.french ? "/design-guide-fr" : "/design-guide"}>
                        <div className={`navbar-column-buttons`}>
                            {this.state.french ? "Guide" : "Design Guide"}
                        </div>
                    </a>

                    <a href={this.state.french ? "/colour-guide-fr" : "/colour-guide"}>
                        <div className={`navbar-column-buttons`}>
                            {this.state.french ? "Couleur" : `${this.props.location === "us" ? "Color Guide" : "Colour Guide"}`}
                        </div>
                    </a>

                    <a href={this.state.french ? "/faqs-fr" : "/faqs"}>
                        <div className={`navbar-column-buttons`}>
                            {this.state.french ? "FAQ" : "FAQ"}
                        </div>
                    </a>

                    <a href={this.state.french ? "/how-it-works-fr" : "/how-it-works"}>
                        <div className={`navbar-column-buttons`}>
                            {this.state.french ? "Comment cela fonctionne" : "How it works"}
                        </div>
                    </a>

                    <a href={this.state.french ? "/returns-fr" : "/returns"}>
                        <div className={`navbar-column-buttons`}>
                            {this.state.french ? "Retours" : "Returns"}
                        </div>
                    </a>

                    <a href={this.state.french ? "/policies-fr" : "/policies"}>
                        <div className={`navbar-column-buttons`}>
                            {this.state.french ? "Politiques" : "Policies"}
                        </div>
                    </a>

                    <a href={this.state.french ? "/shipping-info-fr" : "/shipping-info"}>
                        <div className={`navbar-column-buttons`}>
                            {this.state.french ? "Informations sur les achats" : "Shipping Info"}
                        </div>
                    </a>

                    <a href={this.state.french ? "/pricing-and-discounts-fr" : "/pricing-and-discounts"}>
                        <div className={`navbar-column-buttons`}>
                            {this.state.french ? "Prix et Remises" : "Pricing & Discounts"}
                        </div>
                    </a>
                </div>

                <div className={`navbar-bottom-column navbar-bottom-column-min-height`}>

                    <div className={`navbar-column-title`}>
                        {this.state.french ? "CONNECTEZ" : "Connect with us"}
                    </div>

                    <a href={"https://www.instagram.com/custommat.ca/"}>
                        <div className={`navbar-column-buttons`}>
                            Instagram
                        </div>
                    </a>

                    <a href={"https://www.facebook.com/custommat.ca"}>
                        <div className={`navbar-column-buttons`}>
                            Facebook
                        </div>
                    </a>

                    <a href="https://www.pinterest.ca/custommatcanada">
                        <div className={`navbar-column-buttons`}>
                            Pinterest
                        </div>
                    </a>

                    <a href={"https://www.etsy.com/ca/shop/CustommatCA"}>
                        <div className={`navbar-column-buttons`}>
                            Etsy
                        </div>
                    </a>


                </div>

                <div className={`navbar-bottom-column`}>

                    <div className={`navbar-column-title`}>
                        {this.state.french ? "CONTACT" : "CONTACT"}
                    </div>
                    <div className={`navbar-bottom-column-email`}>{this.state.french ? "hi@CustomMat.com" : "hi@CustomMat.com"}</div>
                    <div className={`navbar-bottom-column-email`}>{this.state.french ? "SMS @ 1•604•345•8428 (Anglais)" : "Text @ 1•604•345•8428"}</div>
                    <div className={`navbar-bottom-column-email`}>{this.state.french ? "Boîte vocale @ 1•226•828•1705 (Anglais)" : "Voice Mail @ 1•226•828•1705"}</div>

                    <div className={"navbar-column-title-divider"}>

                    </div>


                </div>
            </div>


            <div className={`navbar-bottom-company-info-section`}>

                <div className={`navbar-bottom-company-logo mobile-no-display`}>
                    <img src={logoSmallV2} alt={this.state.french ? "Logo du tapis personnalisé" : "Custom Mat's logo."}/>
                </div>

                <div className={"navbar-bottom-happiness-guarantee-banner mobile-no-display"}>
                    {this.state.french ?
                        "Bonheur garanti à 100 %: Si vous n’êtes pas satisfait de votre commande, faites-le nous savoir et nous corrigerons la situation."
                        : "100% Happiness Guaranteed: If you’re not happy with your order, let us know and we’ll make it right."}
                </div>

                <div className={`navbar-bottom-company-address-container`}>

                    {/*{this.state.french ? "Construite et exploitée au Canada " : "Built and Operated in Canada "}*/}
                    {/*<img className={"navbar-bottom-leaf-vector"} src={leafVector}*/}
                    {/*     alt={this.state.french ? "Custom Mat est une entreprise canadienne basée à Toronto." : "Custom Mat is a Canadian company based in Toronto."}*/}
                    {/*/><br/>*/}
                    Custom Matboards & Frames © {new Date().getFullYear()}<br/>
                    CAN: 70 Wynford Dr, North York, ON, M3C2H0 (BOX 542)<br/>
                    USA: 9860 Niagara Falls Blvd, Niagara Falls, NY, 14304 (BOX 490)<br/>

                    <br/>
                </div>
            </div>

        </div>
    }
}