import React from 'react';
import {Carousel} from "react-responsive-carousel";
import photo1 from "../../../resources/images/blog/photoSet1/IMG_4253.JPG";
import photo2 from "../../../resources/images/blog/photoset2/photo1.jpg"
import photo3 from "../../../resources/images/blog/pressedFlowerPhotoSet/photo1.jpg";
import photo4 from "../../../resources/images/blog/oversizedMatRamsborgFrames/IMG_3419.JPG";
import photo5 from "../../../resources/images/gallery/10-articleTen/gallery-wall-stock-photo.png";
import image6 from "../../../resources/images/gallery/12-articleTwelve/photo1.jpg";
import image7 from "../../../resources/images/blog/jerseys/3.jpeg";
import photo8 from "../../../resources/images/blog/ikeaSeries/oversized-mat-photo-frame-3.jpg";
import photo9 from "../../../resources/images/blog/matboard/Capture.PNG";
import photo10 from "../../../resources/images/blog/frameMat/frame-mat-photo 1.PNG";
import photo11 from "../../../resources/images/blog/frameMat/Capture.PNG";
import photo12 from "../../../resources/images/blog/frameMat/matCutter.PNG";
import photo13 from "../../../resources/images/blog/howAndWhereToFindMatboards/Capture.PNG";
import photo14 from "../../../resources/images/blog/howAndWhereToFindMatboards/Capture5.PNG";
import photo15 from "../../../resources/images/blog/diploma/Background Photo.png";
import photo16 from "../../../resources/images/gallery/1-articleOne/reduced/3-2.png";
import photo17 from "../../../resources/images/blog/howAndWhereToFindMatboards/Capture3.PNG";
import photo18 from "../../../resources/images/blog/vintageFrames/IMG_5124.JPG";
import photo19 from "../../../resources/images/blog/blackAndWhitePhotoFraming/kayejcatapang.jpg";
import photo20 from "../../../resources/images/blog/socialLandingPg1/socialLandingPhoto1.png"
import photo21 from "../../../resources/images/blog/socialLandingPg1/socialLandingPhoto1.png"
import photo22 from "../../../resources/images/gallery/floatMounting/floatMountingReddit.png";
import photo23 from "../../../resources/images/gallery/matboards/Colors.jpg";
import photo24 from "../../../resources/images/gallery/13-chooseRightMatboard/IGPhoto1.jpg";
import photo25 from "../../../resources/images/gallery/circular/photo0.jpg";
import photo26 from "../../../resources/images/gallery/customFraming/photo1.jpg";
import photo27 from "../../../resources/images/gallery/matboards/uncutMatPhoto.png";
import photo28 from "../../../resources/images/backingMats/BackingRectangle2.jpg";
import photo29 from "../../../resources/images/matboardBlogPage/bevelCut.png";
import photo30 from "../../../resources/images/galleryWall/coverPhoto2.jpg";


import {Helmet} from "react-helmet";
import LazyLoad from 'react-lazyload';

export default class DesignBlogMain extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      content: [
        {
          head: "Home Art Gallery Wall Design Inspirations -- Top 14",
          subhead: "Our design team has done some research and came up with a wall decor shortlist. We hope we can be a source of your home decor inspiration.",
          photo: photo21,
          url: "https://www.custommat.ca/home-art-gallery-wall-design-inspirations",
        },
        {
          head: "We now ship to France, Belgium, Switzerland, and Luxembourg",
          subhead: "Custom Mat expédie désormais des passe-partouts 100 % personnalisés en France, Belgique, Suisse et Luxembourg. Livraison gratuite disponible pour la plupart des tailles.",
          photo: "https://plus.unsplash.com/premium_photo-1661919210043-fd847a58522d?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cGFyaXN8ZW58MHx8MHx8fDA%3D",
          url: "/passe-partout-sur-mesure-avec-livraison-en-ligne-en-France-belqique-suisse-luxembourg",
        },
        {
          head: "We now ship to UK & Ireland",
          subhead: "100% Custom Matboard Delivered to UK and Ireland now. Free Shipping Available for most of the sizes.",
          photo: "https://t4.ftcdn.net/jpg/01/08/47/29/360_F_108472954_A3Y42CvYf3GLzKKb5CJjVxuoal8TLE9M.jpg",
          url: "/custom-matboard-free-shipping-to-uk-and-ireland",
        },
        {
          head: "We now ship to Germany, Austria, and Switzerland",
          subhead: "100 % maßgefertigte Passepartouts jetzt mit Lieferung nach Deutschland, Österreich und Schweiz. Kostenloser Versand für die meisten Größen verfügbar.",
          photo: "https://cdn.kimkim.com/files/a/images/f15477c71a55027051b08639efb78c84aa8dd9a4/big-6e4b704fc898675fcce39530202760da.jpg",
          url: "/massgefertigtes-passepartout-lieferung-deutschland-osterreich-schweiz",
        },
        {
          head: "We now ship to Spain",
          subhead: "Passepartout 100 % a medida ahora con entrega en España. Envío gratuito disponible para la mayoría de los tamaños.",
          photo: "https://media.istockphoto.com/id/670888198/photo/view-of-the-sea-from-a-height-pe%C3%B1%C3%ADscola-castell%C3%B3n-spain-beautiful-view-of-the-sea-and-the-bay.jpg?s=612x612&w=0&k=20&c=VHW6qqIbLYIdB9Ajb9m8fDrArjjUPywffgn55zUs3JU=",
          url: "/pasepartout-personalizado-envío-gratis-españa",
        },
        {
          head: "We now ship to Italy",
          subhead: "Passepartout 100% su misura ora con consegna in Italia. Spedizione gratuita disponibile per la maggior parte delle dimensioni.",
          photo: "https://www.planetware.com/wpimages/2020/02/italy-in-pictures-beautiful-places-to-photograph-amalfi-coast.jpg",
          url: "/passepartout-su-misura-spedizione-gratuita-italia",
        },
        {
          head: "We now ship to the Netherlands & Belgium",
          subhead: "100% maatwerk passepartout nu met levering in Nederland en België. Gratis verzending beschikbaar voor de meeste formaten.",
          photo: "https://www.planetware.com/wpimages/2019/10/netherlands-in-pictures-most-beautiful-places-amsterdam.jpg",
          url: "/passepartout-op-maat-gratis-verzending-nederland-belgie",
        },

        {
          head: "Mastering the Art of Affordable Gallery Wall Creation",
          subhead: "Learn how to create an impressive gallery wall without breaking the bank. Discover the magic of well-designed gallery walls that transform your space, banish awkward blank walls, and create a sense of unity with cherished family photos.",
          photo: "https://www.custommat.ca/static/media/inspiration.home.mtl-IG.a887c80d.PNG",
          url: "https://www.custommat.ca/gallery-wall",
        },
        {
          head: "Here's How You Can Save HUGE on Picture Framing",
          subhead: "It's much easier than you think. Fit your artwork in any picture frame for less than $40 per piece. You can achieve the same visual effect as custom framing -- which costs over $200 per piece.",
          photo: photo20,
          url: "https://www.custommat.ca/frame-mat",
        },
        {
          head: "Getting A Right Size Frames & Mat Boards",
          subhead: "You can order your standard sized frames from your favorite retail stores or Amazon. You can order your customized matboards from us and get them delivered to your door. It’s much easier than you think.",
          photo: photo10,
          url: "https://www.custommat.ca/How-To-Get-My-Artwork-Framed-Perfectly-In-Budget",
        },
        {
          head: "Save Huge on Photo Frames",
          subhead: "I purchased a glorious set of picture frames and saved huge with a quick trick. The frames I gathered cost significantly less for the quality because they did not have matboard.",
          photo: photo1,
          url: "https://www.custommat.ca/save-huge-on-photo-frames-with-passe-partout-photo-mats",
        },
        {
          head: "Looking or a Wholesale Matboard Supplier in Canada & the US?",
          subhead: "You got the right one right here. We are one of Canada’s biggest art suppliers specializing in matboards and picture framing supplies. We specialize in picture frame matboards.",
          photo: photo11,
          url: "https://www.custommat.ca/wholesale-matboard-supplier",
        },
        {
          head: "Looking for a Matboard Cutter?",
          subhead: "Professional pieces deserve professional elevation. Your average scrapbooker or hobby artist may see the convenience in this tiny stationary tool, however, when considering material cost vs chance of the error it may be safer to trust experts.",
          photo: photo12,
          url: "https://www.custommat.ca/matboard-cutter",
        },
        {
          head: "Where & How to Order Your Custom Matboards",
          subhead: "We live in a digital age where we can virtually order everything online and your package shows up at your door.",
          photo: photo13,
          url: "https://www.custommat.ca/how-and-where-to-order-matboards",
        },
        {
          head: "Home Office that Promotes Productivity",
          subhead: "How does one go about creating the perfect, modern and productive home office? And where to start?",
          photo: photo2,
          url: "https://www.custommat.ca/creating-home-office",
        },
        {
          head: "Pressed Leaves Frame DIY",
          subhead: "I purchased a glorious set of picture frames and saved huge with a quick trick. The frames I gathered cost significantly less for the quality because they did not have matboard.",
          photo: photo3,
          url: "https://www.custommat.ca/pressed-flower-diy-framing",
        },
        {
          head: "Offset Oversized Mat Ramsborg Frames",
          subhead: "We all love a good #ikeahack, right? So today I’m showing you how to create a wedding photo gallery wall with IKEA Ramsborg frames.",
          photo: photo4,
          url: "https://www.custommat.ca/oversized-mat-frames-with-ikea-ramsborg",
        },
        {
          head: "Creating a Gallery Wall on a Budget",
          subhead: "Let's create a gallery wall on a budget. Total cost per picture was about $50 for a high-end designer look.",
          photo: photo5,
          url: "https://www.custommat.ca/diy-gallery-wall",
        },
        {
          head: "SQUARE FAMILY PHOTO FRAMES",
          subhead: "This DIY gift is an incredibly easy, sentimental, and very affordable.",
          photo: image6,
          url: "https://www.custommat.ca/diy-oversized-mat-amp-square-family-photo-frames",
        },
        {
          head: "Jersey Framing & Shadow Box Sports Memorabilia",
          subhead: "Enhance memorabilia with frames and custom cut photo mats to create preserved stylish pieces.",
          photo: image7,
          url: "https://www.custommat.ca/jersey-sports-shadow-box-memorabilia-matting",
        },
        {
          head: "Ultimate IKEA Picture Frame Mat Board Sizing Guide",
          subhead: "Looking to complete your gallery wall? Want to upgade your wall decor? Want to finally frame up your family photos for your living room?",
          photo: photo8,
          url: "https://www.custommat.ca/ikea-frame-matboard-sizing-guide",
        },
        {
          head: "What is a Matboard?",
          subhead: "Matboards are frequently used by framers and professional galleries to bridge the gap between the frame and the artwork. It provides a transitional space between your artwork and picture frame moulding.",
          photo: photo9,
          url: "https://www.custommat.ca/matboard",
        },
        {
          head: "How To Find A Right Picture Frame For Your Artwork",
          subhead: "It’s much easier than it seems. Tens of thousands of our customers have approached tackling their own framing DIYs with pleasing professional results. It’s so simple.",
          photo: photo14,
          url: "https://www.custommat.ca/how-to-find-a-right-frame-for-your-artwork",
        },
        {
          head: "Budget Friendly Diploma Framing & More",
          subhead: "Let’s talk about how to get your personalized degree frames in a budget friendly way.",
          photo: photo15,
          url: "https://www.custommat.ca/diploma-frame-matboard-and-more",
        },
        {
          head: "Stylish and Affordable Ways to Frame your University Degrees",
          subhead: "You can even add a personal touch by having your school name and/or logo, your name, or even your favorite quote engraved on your matboard.",
          photo: photo16,
          url: "https://www.custommat.ca/stylish-and-affordable-ways-to-frame-your-diploma",
        },

        {
          head: "How to Frame Art on a Budget: Tips for Saving Money on Custom Framing",
          subhead: "Dressing up your walls doesn't have to be expensive. In fact, with a little creativity and some thrifty shopping, you can save money on custom framing.",
          photo: photo17,
          url: "https://www.custommat.ca/how-to-frame-your-artwork-on-a-budget",
        },

        {
          head: "Vintage Gallery Wall With Thrift Shop Frames",
          subhead: " You can get an amazing looking space without breaking a sweat or spending too much money by following my advice on where to start and what you need!",
          photo: photo18,
          url: "https://www.custommat.ca/gallery-wall-with-repurposed-gallery-wall-frames",
        },
        {
          head: "Black and White Photography & Framing",
          subhead: "We're all about that black and white! One of our favorite things to do lately is take photos in shades like these, which create such an elegant look for your gallery wall. ",
          photo: photo19,
          url: "https://www.custommat.ca/gallery-wall-with-repurposed-gallery-wall-frames",
        },
        {
          head: "How To Find Quality Uncut Matboards For Your Art Projects",
          subhead: "If you’re an artist or photographer, chances are you’ve been in the market for uncut matboards at some point in your career. You were probably hunting for matboards from art supply stores to cut your own mat, or use them as backers.",
          photo: photo27,
          url: "https://www.custommat.ca/where-to-get-uncut-matboards",
        },
        {
          head: "Float Mounting / Mounting / Float Mounting Art",
          subhead: "Float mounting or mounting artwork may initially seem like a daunting task that requires custom framing expertise and expert mounting methods. However, it's much simpler than you might expect. With the right materials and guidance, you can easily accomplish it at home.",
          photo: photo22,
          url: "https://www.custommat.ca/float-mounting-matboard",
        },

        {
          head: "How Are The Matboards Made?",
          subhead: "Matboards, also known as matting or mounting boards, are commonly used in the picture framing industry to enhance and protect artworks, photographs, or other items. Matboards are made through a manufacturing process that involves several steps. Here's a general overview of how matboards are typically produced.",
          photo: photo23,
          url: "https://www.custommat.ca/how-are-matboards-made",
        },

        {
          head: "How To Choose The Right Mat Board For Your Picture.",
          subhead: "Choosing the right picture mat (also called photo mats or mat board) for your project is key as it can greatly impact the overall presentation and aesthetic appeal of your project. Here are 5 simple steps to help you decide which picture mat is perfect for your project.",
          photo: photo24,
          url: "https://www.custommat.ca/how-to-choose-right-matboard",
        },

        {
          head: "How To Best Frame Circular Artworks, Art Prints Or Photographs",
          subhead: "Circle and oval frames are most used for displaying photographs (matte photos), art prints, canvas prints and mirrors. They offer a unique and visually appealing alternative to traditional rectangular or square picture frames. Here are some specific uses for circle and oval picture frames.",
          photo: photo25,
          url: "https://www.custommat.ca/how-to-frame-circular-oval-artwork",
        },
        {
          head: "What Is The Most Economic Way To Get Custom Framing With The Quickest Turnaround?",
          subhead: "Discover cost-effective picture framing alternatives with Custom Matboards and standard frames at CustomMat.ca. Learn about the benefits of custom matting and standard frames for your artwork",
          photo: photo26,
          url: "https://www.custommat.ca/economic-way-to-custom-picture-framing",
        },
        {
          head: "Creative TV Room Decor Ideas: 5 Stunning Ways to Enhance Your TV Wall Space",
          subhead: "Discover 5 creative ideas to transform your TV wall space and make your TV room a visually appealing and functional space. Learn how to use floating shelves, create a gallery wall, add wallpaper or an accent wall, and manage cables for a tidy look.",
          photo: "https://www.custommat.ca/static/media/5.396ddad1.jpeg",
          url: "https://www.custommat.ca/five-ideas-wall-behind-your-tv",
        },
        {
          head: "8 Creative Ways to Display Your Wedding Photos at Home | Custom Matboards & Gallery Frames",
          subhead: "Discover 8 unique and budget-friendly ideas to beautifully display your wedding photos at home. Learn how to create a stunning gallery wall using standard picture frames and custom matboards.",
          photo: "https://www.custommat.ca/static/media/weddingSignaturePhotos.9322216a.png",
          url: "https://www.custommat.ca/diy-wedding-photo-frames",
        },
        {
          head: "Unveiling The Role Of Backing Boards In Art: A Comprehensive Guide",
          subhead: "Discover the significance of backing boards in the world of art. Explore how these sturdy craft paper layers support and present your artwork, ensuring a safe journey for your masterpieces.",
          photo: photo28,
          url: "https://www.custommat.ca/backing-mat",
        },
          {
              head: "Enhance Your Artwork Presentation: Matboard Types, Double Matting, And Show Kits",
              subhead: "Explore the world of matboards and elevate your artwork presentation. Learn about matboard types, double matting, and the Show Kit. Find out how to choose the right matting for your frames.",
              photo: photo29,
              url: "https://www.custommat.ca/matboards",
          },
          {
              head: "Mastering the Art of Affordable Gallery Wall Creation",
              subhead: "Learn how to create an impressive gallery wall without breaking the bank. Discover the magic of well-designed gallery walls that transform your space, banish awkward blank walls, and create a sense of unity with cherished family photos.",
              photo: photo30,
              url: "https://www.custommat.ca/gallery-wall",
          },



      ]
    }
  };

  componentDidMount() {

  }

  fetchImage(img, subhead) {
    return <LazyLoad>
      <img src={img} className={"blog-carousel-photo"} alt={subhead}/>
    </LazyLoad>
  }

  fetchArticlesCarousel() {
    return this.state.content.map((blog, key) => {
      return  <div className={"carousel-image-item-container"}>
        {this.fetchImage(blog.photo, blog.subhead)}
        <div  className={"carousel-subject"}>
          <div className={"carousel-subject-head"}>
            {blog.head}
          </div>
          <div className={"carousel-subject-sub"}>
            {blog.subhead}
          </div>
          <a className={"carousel-read-more-button"} href={blog.url}>
            READ MORE
          </a>
        </div>
      </div>
    })
  }

  fetchArticlesList() {
    return this.state.content.reverse().map((blog, key) => {
      return <div className={"blog-app-listing-col-left"}>
        <div className={"blog-app-listing-item-container"}>
          {this.fetchImage(blog.photo, blog.subhead)}
          <div className={"blog-listing-text-section"}>
            <div className={"blog-app-listing-subject-head"}>
              {blog.head}
            </div>
            <div className={"blog-app-listing-subject-sub"}>
              {blog.subhead}
            </div>
            <a className={"blog-app-listing-read-more-button"} href={blog.url}>
              READ MORE
            </a>
          </div>
        </div>
      </div>
    })
  }

  render() {
    return <div className={"blog-app-container"}>

      <Helmet>
        <title>CustomMat.ca - Design Blog</title>
        <meta name="description" content="We cut custom mat boards for your picture frames. Explore ways to save on picture framing."/>
      </Helmet>

      <Carousel showArrows={true} showStatus={false} showThumbs={false} infiniteLoop={true} swipeable={false}>
        {this.fetchArticlesCarousel()}
      </Carousel>

      <div className={"blog-app-listing"}>
        {this.fetchArticlesList()}
      </div>

      <div>
        <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
      </div>
    </div>
  }
}