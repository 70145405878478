import React from "react";
import SignaturePad from 'react-signature-canvas';
import "../../../../../stylesheets/selectMatApp/drawingAppStyle.css"
import Tooltip from "@material-ui/core/Tooltip";
import closeIcon from "../../../../../resources/svgIcons/close-icon.svg";
import customDesignPhoto2 from "../../../../../resources/images/selectMat/customDesignStep2Desktop.PNG";
import customDesignPhoto3 from "../../../../../resources/images/selectMat/customDesignStep3Desktop.PNG";
import customDesignPhoto1 from "../../../../../resources/images/selectMat/customDesignStep1Desktop.png";
import complexDesign1 from "../../../../../resources/images/selectMat/customDesignExampleSmall1.png";
import complexDesign2 from "../../../../../resources/images/selectMat/customDesignExampleSmall2.jpg";
import complexDesign4 from "../../../../../resources/images/selectMat/customDesignExampleSmall4.png";
import customDesignPhoto2Mobile from "../../../../../resources/images/selectMat/customDesignStep2Mobile.PNG";
import customDesignPhoto3Mobile from "../../../../../resources/images/selectMat/customDesignStep3Mobile.PNG";

const DrawingAppMain = ({parentSetStateCallBack, showDrawingPadExamplesModal, canvasDrawing, canvasDrawingInProgress, fetchLanguage, french}) => {

    const sigCanvas = React.createRef();

    const canvasSave = () => {
        //IMPORTANT -- please don't add anything here otherwsie it's gonna interfere with .current

        if (sigCanvas && sigCanvas.current)
        {
            var imageFile = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
            parentSetStateCallBack("canvasDrawingInProgress", false);
            parentSetStateCallBack("canvasDrawing", imageFile);
        }
    };

    const clearCanvas = () => {
        if (sigCanvas && sigCanvas.current) {
            sigCanvas.current.clear();
            parentSetStateCallBack("canvasDrawing", null);
        }
    };

    const undoLastStroke = () => {
        if (sigCanvas && sigCanvas.current) {
            const data = sigCanvas.current.toData();
            if (data) {
                data.pop();
                sigCanvas.current.fromData(data);
            }
        }
    };

    const showExampleClicked = () => {
        parentSetStateCallBack("showDrawingPadExamplesModal", !showDrawingPadExamplesModal);
    };

    const canvasFileSavingIndicator = () => {
        parentSetStateCallBack("canvasDrawingInProgress", true);
    }

    return <div className={"drawing-app-main"}>

        <div className={showDrawingPadExamplesModal ? "showing-drawing-pad-example" : "no-display"}>

            <div className={"showing-drawing-pad-example-modal"}>

                <div className={"photo-cropping-tool-cover-topic-header"}>
                    Custom Design Examples
                    <div className={`close-add-image-modal-button-container`}>
                        <Tooltip title={"Close the matboard size selection modal"} placement={"top"}>
                            <img className={`close-add-image-modal-button`} alt={"Close this modal."}
                                 src={closeIcon} onClick={showExampleClicked}
                            />
                        </Tooltip>
                    </div>
                </div>

                <div className={"drawing-pad-example-image-container"}>

                    <div className={"drawing-app-directions"}>
                        Got a unique matboard design? No problem! We can create any matboard design you are looking for!<br/>
                    </div>
                    <div>
                        <div className={"drawing-pad-example-small-image-section-container-left"}>
                            <img className={"drawing-pad-example-small-image-section"} src={complexDesign1} alt={"Complex design example 1"} />
                            <img className={"drawing-pad-example-small-image-section"} src={complexDesign4} alt={"Complex design example 2"}/>

                        </div>
                        <div className={"drawing-pad-example-small-image-section-container-left"}>
                            <img className={"drawing-pad-example-small-image-section"} src={complexDesign2} alt={"Complex design example 3"}/>
                        </div>
                    </div>



                    <div className={"drawing-app-directions"}>
                        <b>How to submit your custom design:</b><br/>
                        Let's create and submit unique vinyl frame like below. Our prices are based on your matboard size. No extra charges for custom design.
                    </div>
                    <img className={"drawing-pad-example-image-section"} src={customDesignPhoto1} alt={"Complex design example 4"} />
                </div>

                <div className={"drawing-pad-example-image-container"}>
                    <div className={"drawing-app-directions"}>
                        <b>1. Select your mat size & colour on the highlighted section.</b><br/>
                    </div>
                    <img className={"drawing-pad-example-image-section mobile-no-display"} src={customDesignPhoto2} alt={"Create custom design from your desktop"}/>
                    <img className={"drawing-pad-example-image-section mobile-display-only"} src={customDesignPhoto2Mobile} alt={"Create custom design from your mobile phone"}/>
                    <div className={"drawing-app-directions"}>
                        Let's draw design on the drawing pad (left). Choose the size and colour of the matboard on the right.<br/>
                        <i>It's important to choose right size & colour. Then, we won't have to follow up with you to confirm you order.</i>
                    </div>

                </div>

                <div className={"drawing-pad-example-image-container"}>
                    <div className={"drawing-app-directions"}>
                        <b>2. Draw your design and leave us notes for the dimension.</b><br/>
                    </div>
                    <img className={"drawing-pad-example-image-section mobile-no-display"} src={customDesignPhoto3} alt={"Create custom design from your desktop"}/>
                    <img className={"drawing-pad-example-image-section mobile-display-only"} src={customDesignPhoto3Mobile} alt={"Create custom design from your mobile phone"}/>
                    <div className={"drawing-app-directions"}>
                        Let write a quick note for our production staff on the "Additional Note" section:<br/>
                        <div style={{paddingLeft: "3vw"}}>
                            "I would like to place my LP cover and the LP together on your plush black frame.<br/>
                            1. Size of the matboard: 28 x 20 inches.<br/>
                            2. Matboard Colour: Snow White.<br/>
                            3. Size of A: 12.25 x 12.25 (inch).<br/>
                            4. Size of B: 12 x 12 (inch) circle.<br/>
                            5. Overlap between A and B: 2 (inch).<br/>
                            Please centralize both A and B."
                        </div>
                        <i>Our production team will read your note before producing your order.</i>
                    </div>
                </div>

                <div className={"drawing-pad-example-image-container"}>
                    <div className={"drawing-app-directions"}>
                        <b>3. Proceed with "Add to Cart" button.</b><br/>
                        If you have any questions, please send us email at hi@custommat.com or 226-828-1705. We typically respond within an hour.
                    </div>
                </div>



                <div className="crop-icon-container"s
                     id="showing-drawing-pad-example-closing-modal-icon"
                     title="Close this section and check out."
                     onClick={showExampleClicked}>
                    Got it
                </div>
            </div>
        </div>

        <div className="frame-size-desc-title">
            {/*<div className={"full-width float-left frame-size-desc-title-header-margin"} style={{color: "#72696A"}}>Customize Your Design</div>*/}
            <div className={"full-width frame-size-desc-options"}>
                <a href={french ? "/custom-design-guide-fr" : "/custom-design-guide"}>
                    <div className={"drawing-board-direction-font flex-box float-left frame-size-desc-title-header-margin"}>
                        {fetchLanguage("Guide de Conception Personnalisée", "Custom Design Guide")}
                    </div>
                </a>


            </div>



        </div>

        <SignaturePad
            ref={sigCanvas}
            penColor='black'
            dotSize={0.5}
            canvasProps={{
                className: "signatureCanvas",
            }}
            onEnd={canvasSave}
            onBegin={canvasFileSavingIndicator}
        />

        <div className={"flex-box float-right frame-size-desc-title-header-margin custom-design-padding-box"}>
            <button className={"drawing-app-main-buttons"} style={{color: "#E08C7A"}} onClick={clearCanvas}>
                Delete
            </button>

            <button className={"drawing-app-main-buttons"} onClick={undoLastStroke}>
                Undo
            </button>
        </div>

        <div className={"drawing-app-saving-status"}>
            {canvasDrawingInProgress ?
                <span style = {{color: "#F59276"}}>Saving...</span>
                :
                <span style = {{color: "#1A91DA"}}>Saved</span>
            }
        </div>
    </div>
};

export default DrawingAppMain;