import React from "react";
import landingPageArrowLeft from "../../../resources/logo/landing-page-arrow-left.svg";
import designGuidePhoto1 from "../../../resources/images/howItWorks/design-guide-photo-1.PNG";
import designGuidePhoto2 from "../../../resources/images/howItWorks/design-guide-photo-2.PNG";
import smoothWhiteFrame from "../../../resources/matColours/smoothWhite.PNG";
import chinaWhiteFrame from "../../../resources/matColours/chinaWhite.jpg";
import boneFrame from "../../../resources/matColours/bone.jpg";
import ecruFrame from "../../../resources/matColours/ecru.jpg";
import avillionFrame from "../../../resources/matColours/avillion.PNG";
import seafoamFrame from "../../../resources/matColours/seafoam.jpg";
import flagstoneFrame from "../../../resources/matColours/flagstone.jpg";
import strawFrame from "../../../resources/matColours/straw.jpg";
import silverFrame from "../../../resources/matColours/silver.jpg";
import goldFrame from "../../../resources/matColours/gold.png";
import cricketGreen from "../../../resources/matColours/cricketGreen.jpg";
import fire from "../../../resources/matColours/fire.jpg";
import red from "../../../resources/matColours/red.jpg";
import royalBlue from "../../../resources/matColours/royalBlue.jpg";
import navyBlue from "../../../resources/matColours/navyBlue.jpg";
import wine from "../../../resources/matColours/wine.jpg";
import chocolate from "../../../resources/matColours/chocolate.jpg";
import black from "../../../resources/matColours/black.jpg";
import icePink from "../../../resources/matColours/icePink.jpg";
import perisimmon from "../../../resources/matColours/perisimmon.jpg";
import purple from "../../../resources/matColours/purple.jpg";
import cirrus from "../../../resources/matColours/cirrus.jpg";
import flax from "../../../resources/matColours/flax.jpg";
import powderBlue from "../../../resources/matColours/powderBlue.jpg";
import peach from "../../../resources/matColours/peach.jpg";
import lavender from "../../../resources/matColours/lavender.jpg";
import lemon from "../../../resources/matColours/lemon.jpg";
import parchment from "../../../resources/matColours/parchment.jpg";
import blueGrey from "../../../resources/matColours/blueGrey.jpg";
import shamrock from "../../../resources/matColours/shamrock.jpg";
import slate from "../../../resources/matColours/slate.jpg";
import darkGrey from "../../../resources/matColours/darkGrey.jpg";
import azure from "../../../resources/matColours/azure.jpg";
import customGuidePhoto1 from "../../../resources/images/howItWorks/customDesignPhoto1.png";
import customGuidePhoto2 from "../../../resources/images/howItWorks/customDesignPhoto2.PNG";
import customGuidePhoto3 from "../../../resources/images/howItWorks/customDesignPhoto3.png";
import customGuidePhoto4 from "../../../resources/images/howItWorks/customDesignPhoto4.png";
import customGuidePhoto5 from "../../../resources/images/howItWorks/customDesignPhoto5.png";
import customGuidePhoto6 from "../../../resources/images/howItWorks/customDesignPhoto6.png";
import customGuidePhoto7 from "../../../resources/images/howItWorks/customDesignPhoto7.png";
import LazyLoad from 'react-lazyload';

export default class DesignGuide extends React.Component {

    constructor() {
        super();
        this.state = {
            penDrawing: false,
            french: false,
            colourScheme: {
                smoothWhite: {colour: smoothWhiteFrame, name: "Smooth White (#1)", nameFr: "Blanc Lisse (#1)", desc: "Smooth, bright white (Bestseller). Slightly cool white with a smooth, even tone."},
                avillion: {colour: avillionFrame, name: "Avillion (#7)", nameFr: "Avillion (#7)", desc: "Bright, cool white with a subtle texture with flecks in it. Gives a handmade-paper feel."},
                chinaWhite: {colour: chinaWhiteFrame, name: "China White (#2)", nameFr: "Blanc de Chine (#2)", desc: "An off-white with a very slight yellow tint. Closest to mats come with IKEA Frames."},
                bone: {colour: boneFrame, name: "Bone (#3)", nameFr: "Os (#3)", desc: "Soft yellow-white. Light, warm, creamy off-white or ivory."},
                parchment: {colour: parchment, name: "Parchment (#28)", nameFr: "Parchment (#28)", desc: "A soft, mottled cream with a subtle warm undertone. Slightly textured."},
                ecru: {colour: ecruFrame, name: "Ecru (#5)", nameFr: "Écru (#5)", desc: "A soft, warm, light cream with a subtle yellow undertone."},
                seafoam: {colour: seafoamFrame, name: "Seafoam (#4)", nameFr: "Écume de Mer (#4)", desc: "A medium, warm beige with a subtle gray undertone and a smooth finish."},
                flax: {colour: flax, name: "Flax (#23)", nameFr: "Lin (#23)", desc: "A medium, warm brown with a smooth, even tone and a slightly reddish undertone."},
                chocolate: {colour: chocolate, name: "Chocolate (#14)", nameFr: "Chocolat (#14)", desc: "Rich, medium-dark brown: Downham Mocha is known for being a warm, mid-tone brown."},


                cirrus: {colour: cirrus, name: "Cirrus (#22)", nameFr: "Cirrus (#22)", desc: "Cool and airy: It evokes a sense of coolness and airiness."},
                azure: {colour: azure, name: "Azure (#33)", nameFr: "Azure (#33)", desc: "Bright, clear blue: Azure is known for its vibrant and distinct blue hue."},
                powderBlue: {colour: powderBlue, name: "Powder Blue (#24)", nameFr: "Bleu Poudre (#24)", desc: "Light, soft blue with a subtle gray undertone. Pale blue with a muted appearance."},
                royalBlue: {colour: royalBlue, name: "Royal Blue (#8)", nameFr: "Bleu Royal (#8)", desc: "Medium-dark, muted blue with a subtle gray undertone."},
                navyBlue: {colour: navyBlue, name: "Navy Blue (#9)", nameFr: "Bleu Marine (#9)", desc: "Dark, muted blue-gray with a slightly cool undertone."},
                purple: {colour: purple, name: "Purple (#21)", nameFr: "Violet (#21)", desc: "Rich, dark purple with a slightly cool undertone."},
                shamrock: {colour: shamrock, name: "Shamrock (#30)", nameFr: "Shamrock (#30)", desc: "Medium-dark, slightly muted green with a cool undertone."},
                cricketGreen: {colour: cricketGreen, name: "Cricket Green (#10)", nameFr: "Vert Grillon (#10)", desc: "Dark, muted green-gray with a cool undertone."},
                lemon: {colour: lemon, name: "Lemon (#27)", nameFr: "Lemon (#27)", desc: "Bright, warm yellow with a slightly muted or soft quality."},
                straw: {colour: strawFrame, name: "Straw (#17)", nameFr: "Paille (#17)", desc: "Medium, warm yellow with a distinct orange undertone."},
                gold: {colour: goldFrame, name: "Gold Shiny Foil (#13)", nameFr: "Or Feuille (#13)", desc: "Gold with a metallic effect. Great for double matting diplomas."},
                silver: {colour: silverFrame, name: "Silver Shiny Foil (#18)", nameFr: "Argent Feuille (#18)", desc: "Silver with a metallic effect. Great for double matting diplomas." },
                blueGray: {colour: blueGrey, name: "Blue Gray (#29)", nameFr: "Blue Gray (#29)", desc: "Medium, muted blue-gray with a cool, neutral undertone."},
                flagstone: {colour: flagstoneFrame, name: "Flagstone (#6)", nameFr: "Dalle (#6)", desc: "Medium-dark, muted gray with a subtle green undertone."},
                darkGray: {colour: darkGrey, name: "Dark Gray (#32)", nameFr: "Dark Gray (#32)", desc: "Dark, muted brown-gray with a cool, neutral undertone"},
                slate: {colour: slate, name: "Slate (#31)", nameFr: "Slate (#31)", desc: "Very dark, muted olive green with a cool, neutral undertone."},
                black: {colour: black, name: "Black (#15)", nameFr: "Noir (#15)", desc: "Black with a subtle warm gray undertone."},
                icePink: {colour: icePink, name: "Ice Pink (#19)", nameFr: "Rose Glacé (#19)", desc: "Light, soft pink with a warm undertone."},
                peach: {colour: peach, name: "Peach (#25)", nameFr: "Pêche (#25)", desc: "Medium-light, warm pink with a distinct peach or coral undertone."},
                lavender: {colour: lavender, name: "Lavender (#26)", nameFr: "Lavande (#26)", desc: "Medium, muted dusty rose with a cool, slightly gray undertone."},
                wine: {colour: wine, name: "Wine (#16)", nameFr: "Vin (#16)", desc: "Medium-dark, muted reddish-brown with a cool, slightly gray undertone."},
                perisimmon: {colour: perisimmon, name: "Persimmon (#20)", nameFr: "Persimmon (#20)", desc: "Bright, medium-light orange with a warm undertone."},
                fire: {colour: fire, name: "Fire (#11)", nameFr: "Feu (#11)", desc: "Bright red with a warm, slightly orange undertone."},
                red: {colour: red, name: "Red (#12)", nameFr: "Rouge (#12)", desc: "Medium-dark red with warm orange undertone."},
            },
            fetchMobileDropdown: false,
        };
    };

    componentDidMount() {
        this.setState({
            french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
        })
    }

    fetchMatSizeTap() {
        if (this.props.tab === "matboardSize") {
            return <div className={"design-guide-content-container"}>
                <div className={"design-guide-content-container-topic"}>
                    {this.state.french ? "Comment mesurer la taille du passe-partout" : "How to measure the mat size"}
                </div>

                <div className={"design-guide-content"}>

                    <iframe className={"design-guide-illustration-video"} src="https://www.youtube.com/embed/t-YQxHYTkUQ" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>

                    {/*<LazyLoad>*/}
                    {/*    <img*/}
                    {/*        className={"design-guide-image"}*/}
                    {/*        src={designGuidePhoto1}*/}
                    {/*        alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}*/}
                    {/*    />*/}
                    {/*</LazyLoad>*/}

                    {/*<div className={"design-guide-desc"}>*/}
                    {/*    {this.state.french ? "Si vous avez acheté un cadre au détail, il se peut que les mesures soient indiquées sur l’emballage." : "If you bought a retail frame, it may have the measurements you need on the packaging."}*/}
                    {/*    <br/><br/>*/}
                    {/*    {this.state.french ? "Si vous ne disposez pas d’informations ou si vous souhaitez confirmer vos mesures, retournez le cadre et mesurez la hauteur et la largeur du panneau arrière." : "If you don't have any information or you want to confirm your measurements, flip the frame over and measure the height and width of the backing board."}*/}
                    {/*    <br/><br/>*/}
                    {/*    {this.state.french ? "Il s’agit de la taille du passe-partout dont vous avez besoin." : "This is the size of the matboard you need."}*/}
                    {/*</div>*/}
                </div>
            </div>
        } else if (this.props.tab === "windowSize") {
            return <div className={"design-guide-content-container"}>
                <div className={"design-guide-content-container-topic"}>
                    {this.state.french ? "Comment mesurer vos fenêtres d’œuvres" : "How to measure your artwork windows"}

                </div>

                <div className={"design-guide-content"}>

                    <iframe className={"design-guide-illustration-video"} src="https://www.youtube.com/embed/t-YQxHYTkUQ?start=22" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    {/*<img*/}
                    {/*    className={"design-guide-image"}*/}
                    {/*    src={designGuidePhoto2}*/}
                    {/*    alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}*/}

                    {/*/>*/}
                    {/*<div className={"design-guide-desc"}>*/}
                    {/*    {this.state.french ? "La fenêtre de l’œuvre est une ouverture coupée dans le passe-partout qui expose l’illustration en dessous." : "If artwork window is an opening cut in the mat that exposes the art below."}*/}
                    {/*    <br/><br/>*/}
                    {/*    {this.state.french ? "La fenêtre doit être plus petite que votre illustration, de sorte que l’ouverture masque les bordures de l’œuvre." : "The window must be smaller than your artwork, so the opening hides the edges of the work."}*/}
                    {/*    <br/><br/>*/}
                    {/*    {this.state.french ? "Pour mesurer la taille de la fenêtre de l’œuvre, mesurez la longueur et la largeur de votre illustration, puis soustrayez un quart de pouce de chaque mesure." : "To measure the artwork window size, measure the length and width of your art and substract a quarter of an inch (0.25 inch) from each measurement."}*/}
                    {/*    <br/><br/>*/}
                    {/*    {this.state.french ? "Exemple – image 5,0 x 7,0 moins 0,25 sur la longueur et la largeur = 4,75 x 6,75 pouces." : "Example -- 5.0 x 7.0 image minus 0.25 on the length and width = 4.75 x 6.75 inches."}*/}
                    {/*    <br/><br/>*/}
                    {/*    {this.state.french ? "Il s’agit de la taille de fenêtre de votre œuvre." : "This is the size of your artwork window."}*/}
                    {/*</div>*/}
                </div>
            </div>
        } else if (this.props.tab === "customDesign") {
            return <div className={"design-guide-content-instruction-container"}>
                <div className={"design-guide-content-instruction"}>
                    {this.state.french ? "Vous avez un design unique de passe-partout? Pas de problème!" : "Got a unique matboard design? No problem!"}
                    <br/>
                    {this.state.french ? "Nous pouvons créer n’importe quel design de passe-partout que vous recherchez!" : "We can create any matboard design you are looking for!"}
                </div>
                <div className={"design-guide-photo-container"}>
                    <LazyLoad>
                        <img src={customGuidePhoto1} className={"design-guide-photo-1"}
                             alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}

                        />
                    </LazyLoad>
                    <LazyLoad>
                        <img src={customGuidePhoto2} className={"design-guide-photo-2"}
                             alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}

                        />
                    </LazyLoad>
                </div>

                <div className={"design-guide-photo-container"}>
                    <LazyLoad>
                        <img src={customGuidePhoto3} className={"design-guide-photo-3"}
                             alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}

                        />
                    </LazyLoad>
                    <LazyLoad>
                        <img src={customGuidePhoto4} className={"design-guide-photo-4"}
                             alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                        />
                    </LazyLoad>
                </div>

                <div className={"design-guide-photo-container"}>
                    <div className={"design-guide-photo-container-title"}>
                        {this.state.french ? "ÉTAPE PAR ÉTAPE" : "Step by step"}
                    </div>
                    <div className={"design-guide-photo-container-subtitle"}>
                        {this.state.french ? "Créons LE cadre en vinyle unique vu ci-dessus. Nos prix sont basés sur la taille de votre passe-partout." :
                            "Let's create the unique vinyl frame seen above. Our prices are"}
                        {this.state.french ? " Aucun frais supplémentaires pour UN design personnalisé." :
                            " based on your matboard size. No extra charge for a custom design."}
                    </div>

                    <div className={"design-guide-step-by-step-container"}>
                        <div className={"design-guide-step-by-step-container-left"}>

                            <div className={"design-guide-step-by-step-container-left-topic"}>
                                {this.state.french ? "1. Esquissez-le sur l’outil de dessin" : "1. Sketch it out on the Drawing Tool"}
                            </div>

                            <div className={"design-guide-step-by-step-container-left-desc"}>
                                {this.state.french ? "Esquissons le design sur la planche à dessin. " : "Let's sketch out the design on the drawing pad. "}
                                {this.state.french ? "Vous pouvez inclure tous les détails que vous souhaitez!" : "You can include any details you'd like!"}
                            </div>

                        </div>
                        <img src={customGuidePhoto5} className={"design-guide-photo-5"}
                             alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                        />
                    </div>

                    <div className={"design-guide-step-by-step-container"}>

                        <div className={"design-guide-step-by-step-container-right"}>

                            <div className={"design-guide-step-by-step-container-left-topic"}>
                                {this.state.french ? "2. Saisir la taille et la couleur du passe-partout" : "2. Input Matboard Size and Colour"}
                                <br/>
                            </div>

                            <div className={"design-guide-step-by-step-container-left-desc"}>
                                {this.state.french ? "Il est important de saisir " : "It's important to enter right "}
                                <span><b>{this.state.french ? "la taille et la couleur de passe-partout appropriées" : `Matboard Size & Matboard ${this.props.location === "us" ? "Color" : "Colour"}`}</b></span>.
                                {this.state.french ? " Nous n’aurons pas à vous contacter pour confirmer votre commande, nous veillons à ce que tout se passe bien!" : " We won't have to follow up with you to confirm your order, make sure everything go smoothly!"}
                            </div>

                        </div>
                        <img src={customGuidePhoto6} className={"design-guide-photo-6"}
                             alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                        />


                    </div>

                    <div className={"design-guide-step-by-step-container"}>

                        <div className={"design-guide-step-by-step-container-left"}>
                            <div className={"design-guide-step-by-step-container-left-topic"}>
                                {this.state.french ? "3. Laissez-nous une note" : "3. Leave us a Note"}

                            </div>

                            <div className={"design-guide-step-by-step-container-left-desc"}>
                                {this.state.french ?
                                    "Écrivez une note rapide à l’intention de notre personnel de production dans " :
                                    "Let's write a quick note for our production staff on "}
                                <b>{this.state.french ? "la section Note supplémentaire. " : "the Additional Notes section. "}</b>
                                {this.state.french ?
                                    "Donnez le plus de détails que possible notre équipe de production lira attentivement votre note avant de produire votre commande." :
                                    "Give as much detail as possible. Our production team will carefully read your note before producing your order. "}
                                —<br/>
                                {this.state.french ? "Ajoutez ensuite au sac, et votre esquisse et les instructions détaillées seront enregistrées." : "Then Add to Bag, and your sketch and detailed instructions will be saved."}
                            </div>
                        </div>

                        <img src={customGuidePhoto7} className={"design-guide-photo-7"}
                             alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                        />

                    </div>





                </div>

            </div>
        }
    }

    fetchColourListing() {
        if (this.props.tab === "matboardColour") {
            var colourArray = Object.keys(this.state.colourScheme);

            return colourArray.map((colourID, key) => {
                return <div className={"design-guide-colour-box"}>
                    <img className={"design-guide-colour-box-color"} style={{width: "80%"}} src={this.state.colourScheme[colourID].colour}
                         alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                    />
                    <div className={"design-guide-colour-box-color-name"}>
                        {this.state.french ? this.state.colourScheme[colourID].nameFr : this.state.colourScheme[colourID].name}
                    </div>
                    <div className={"design-guide-colour-box-color-description"} style={{textTransform: "none", textAlign: "left", width: "90%", margin: "10px 5% 20px 5%"}}>
                        { this.state.colourScheme[colourID].desc}
                    </div>
                </div>
            })
        }
    }

    fetchTopicHeader() {
        if (this.props.tab === "matboardSize") {
            return <div className={"design-guide-button"}>
                {this.state.french ? "GUIDE DE CONCEPTION" : "Design Guide"}
            </div>
        } else if (this.props.tab === "matboardColour") {
            return <div className={"design-guide-button"}>
                {this.state.french ? "GUIDE DE Couleur" : "Colour Guide"}
            </div>
        } else if (this.props.tab === "windowSize") {
            return <div className={"design-guide-button"}>
                {this.state.french ? "GUIDE DE CONCEPTION" : "Design Guide"}
            </div>
        } else if (this.props.tab === "customDesign") {
            return <div className={"design-guide-button"}>
                {this.state.french ? "GUIDE DE CONCEPTION" : "Design Guide"}
            </div>
        }

    }

    render() {
        return <div className={"design-guide-container"}>
            <a href={this.state.french ? "customize-matboards-fr" : "customize-matboards"}>
                <div className={"back-to-my-design-button"}>
                    <img className={"back-to-my-design-button-icon"} src={landingPageArrowLeft}
                         alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                    />
                    {this.state.french ? "retour à ma conception" : "BACK TO MY DESIGN"}
                </div>
            </a>


            {this.fetchTopicHeader()}

            <div className={"mobile-no-display"}>
                <a className={"design-guide-section-button"}
                   href={this.state.french ? "design-guide-fr" : "design-guide"}
                >
                    <div className={this.props.tab === "matboardSize" ? "design-guide-section-button-selected" : "design-guide-section-unselected"}>
                        {this.state.french ? "PASSE-PARTOUT" : "Matbaord Size"}
                    </div>
                </a>

                <a className={"design-guide-section-button"}
                   href={this.state.french ? "colour-guide-fr" : "colour-guide"}
                >
                    <div className={this.props.tab === "matboardColour" ? "design-guide-section-button-selected" : "design-guide-section-unselected"}>
                        {this.state.french ? "COULEUR" : `Matboard ${this.props.location === "us" ? "Color" : "Colour"}`}

                    </div>
                </a>
                <a className={"design-guide-section-button"}
                   href={this.state.french ? "window-size-fr" : "window-size"}
                >
                    <div className={this.props.tab === "windowSize" ? "design-guide-section-button-selected" : "design-guide-section-unselected"}>
                        {this.state.french ? "TAILLE DE LA FENÊTRE" : "Window Size"}

                    </div>
                </a>
                <a className={"design-guide-section-button"}
                   href={this.state.french ? "custom-design-guide-fr" : "custom-design-guide"}
                >
                    <div className={this.props.tab === "customDesign" ? "design-guide-section-button-selected" : "design-guide-section-unselected"}>
                        {this.state.french ? "PERSONNALISÉE" : "Custom Design"}

                    </div>
                </a>
            </div>

            <div className={this.state.fetchMobileDropdown ? "design-guide-section-containe-type-2" : "no-display"}>

                <a
                   href={this.state.french ? "design-guide-fr" : "design-guide"}
                >
                    <div className={"design-guide-section-button-type-2"}>
                        {this.state.french ? "PASSE-PARTOUT" : "Matboard Size"}
                    </div>

                </a>





                <a
                   href={this.state.french ? "colour-guide-fr" : "colour-guide"}
                >
                    <div className={"design-guide-section-button-type-2"}>
                        {this.state.french ? "COULEUR DU PASSE-PARTOUT" : `Matboard ${this.props.location === "us" ? "Color" : "Colour"}`}
                    </div>
                </a>


                <a
                   href={this.state.french ? "window-size-fr" : "window-size"}
                >
                    <div className={"design-guide-section-button-type-2"}>
                        {this.state.french ? "TAILLE DE LA FENÊTRE" : "Window Size"}
                    </div>
                </a>


                <a
                   href={this.state.french ? "custom-design-guide-fr" : "custom-design-guide"}
                >
                    <div className={"design-guide-section-button-type-2"}>
                        {this.state.french ? "CONCEPTION PERSONNALISÉE" : "Custom Design"}
                    </div>
                </a>











            </div>

            <div className={this.state.fetchMobileDropdown ?  "no-display" : "mobile-display-only design-guide-selection-button-container"} onClick={() => {this.setState({fetchMobileDropdown: !this.state.fetchMobileDropdown})}}>
                <div className={this.props.tab === "matboardSize" ? "design-guide-section-button-selected" : "no-display"}>
                    <div className={"design-guide-section-button"}
                    >
                    {this.state.french ? "PASSE-PARTOUT" : "Matboard Size"}
                    </div>
                    <img className={"design-guide-mobile-dropdown-icon"} src={landingPageArrowLeft} style={{transform: "rotate(270deg)", marginLeft: "5px"}}
                         alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}

                    />
                </div>

                <div className={this.props.tab === "matboardColour" ? "design-guide-section-button-selected" : "no-display"}>

                    <div className={"design-guide-section-button"}
                    >
                    {this.state.french ? "COULEUR DU PASSE-PARTOUT" : `Matboard ${this.props.location === "us" ? "Color" : "Colour"}`}
                    </div>
                    <img className={"design-guide-mobile-dropdown-icon"}
                         src={landingPageArrowLeft} style={{transform: "rotate(270deg)", marginLeft: "5px"}}
                         onClick={() => {this.setState({fetchMobileDropdown: !this.state.fetchMobileDropdown})}}
                         alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                    />

                </div>



                <div className={this.props.tab === "windowSize" ? "design-guide-section-button-selected" : "no-display"}>
                    <div className={"design-guide-section-button"}
                    >
                    {this.state.french ? "TAILLE DE LA FENÊTRE" : "Window Size"}
                    </div>
                    <img className={"design-guide-mobile-dropdown-icon"}
                         src={landingPageArrowLeft} style={{transform: "rotate(270deg)", marginLeft: "5px"}}
                         onClick={() => {this.setState({fetchMobileDropdown: !this.state.fetchMobileDropdown})}}
                         alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}
                    />

                </div>

                <div className={this.props.tab === "customDesign" ? "design-guide-section-button-selected" : "no-display"}>
                    <div className={"design-guide-section-button"}
                    >
                    {this.state.french ? "CONCEPTION PERSONNALISÉE" : "Custom Design"}
                    </div>
                    <img className={"design-guide-mobile-dropdown-icon"}
                         src={landingPageArrowLeft} style={{transform: "rotate(270deg)", marginLeft: "5px"}}
                         onClick={() => {this.setState({fetchMobileDropdown: !this.state.fetchMobileDropdown})}}
                         alt={this.state.french ? "Ceci est une image illustrative du contenu de cette page." : "This is an illustrative image for the content on this page."}

                    />

                </div>

            </div>

            {this.fetchMatSizeTap()}

            <div className={this.props.tab === "matboardColour" ? "design-guide-colour-container" : "no-display"}>
                {this.fetchColourListing()}
            </div>

            <a href={"https://www.youtube.com/watch?v=t-YQxHYTkUQ"}>
                <div className={"design-guide-content-container-walkthrough-button"}>
                    {this.state.french ? "Cliquez ici pour regarder la vidéo pour une présentation complète." : "Click to watch the video for full walkthrough."}
                </div>
            </a>



            <div className={"design-guide-inquiry"}>
                {this.state.french ? "" : "If you have any questions, please don't hesiate to contact us at"}
                <br/>
                <span className={"design-guide-inquiry-underline"}>
                    {this.state.french ? "" : "hi@custommat.com "}
                </span>
                <span className={"design-guide-inquiry-underline"}>
                    {this.state.french ? "" : "| 226-828-1705"}
                </span>
                <br/>
                {this.state.french ? "" : "We typically respond within an hour."}

            </div>

        </div>


    }
}