import React from "react";
import LazyLoad from 'react-lazyload';
import explainerVideo1 from "../../../resources/video/explainerVideo1.mp4";
import explainerVideo2 from "../../../resources/video/explainerVideo2.mp4";
import explainerGif1 from "../../../resources/video/explainerGif1.gif";
import explainerGif2 from "../../../resources/video/explainerGif2.gif";



export default class Explainer extends React.Component {

    constructor() {
        super();
        this.state = {
            french: false,
        }
    }

    componentDidMount() {
        this.setState({
            french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
        })
    }

    fetchEnglish() {
        return <div className={"what-is-mat-page-v2"}>
            <div className={this.props.titleNoDisplay ? "no-display" : "what-is-mat-header-v2 how-it-works-page-leader"}
                 id={"what-is-mat-header-v2-moblet"}
            >
                HOW IT WORKS
            </div>

            <div className={"how-it-works-steps-container-v2"}
                 id={"how-it-works-steps-container-v2-moblet"}>
                <div className={"landing-page-standard-right-v2 mobile-and-tablet-display-only"}
                     id={"how-it-works-video1"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} id={"how-it-works-image1"} src={explainerGif1}></img>
                    </LazyLoad>
                </div>

                <div className={"landing-page-standard-left-v2"}>
                    <div className={"how-it-works-header-left"}>
                        Ordering yours  <br/>
                        in 3 simple steps
                    </div>

                    <div className={"how-it-works-subheader-left"} id={"how-it-works-substeps-1"}>
                        1. Enter the overall matboard size you want. <br/> <br className={"mobile-and-tablet-display-only"}/>
                        2. Specify the interior cutout dimensions. <br/> <br className={"mobile-and-tablet-display-only"}/>
                        3. Pick  your matboard colors. <br/>
                    </div>

                    <a href={this.state.french ? "/customize-matboards-fr" : "/customize-matboards"} id={"how-it-works-page-button"}>
                        <div className={"landing-page-button-standard-v2"}
                             id={"explainer-start-designing-button"}>
                            START DESIGNING
                        </div>
                    </a>
                </div>

                <div className={"landing-page-standard-right-v2 mobile-no-display tablet-no-display"}>
                    <LazyLoad>
                        <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                               src={explainerVideo1}></video>
                    </LazyLoad>
                </div>
            </div>

            <div className={"how-it-works-steps-container-v2"}>

                <div className={"landing-page-standard-right-v2 mobile-and-tablet-display-only"}
                     id={"how-it-works-video2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} id={"how-it-works-image2"} src={explainerGif2}></img>
                    </LazyLoad>
                </div>

                <div className={"how-it-works-step2-desc-v2 mobile-and-tablet-display-only"}>
                    <div className={"how-it-works-header-right"} id={"how-it-works-substeps-3"}>
                        Cut and shipped
                        <br/>
                        within 48 hours
                    </div>

                    <div className={"how-it-works-subheader-right"}>
                        After we precision cut your custom mat, we package it with care and ship it to you within 48 hours.
                        <br/><br/>
                        <u>
                            ✦100% Happiness Guaranteed.
                        </u>
                        <br/><br/>
                    </div>

                    <div className={"read-our-review-section"}>
                        <a href={"/customer-reviews"}>
                            <u>Read Our Glowing Reviews</u>
                        </a>
                    </div>
                </div>

                <div className={"landing-page-standard-left-v2 mobile-no-display tablet-no-display"}>
                    <LazyLoad>
                        <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                               src={explainerVideo2}></video>
                    </LazyLoad>
                </div>


                <div className={"how-it-works-step2-desc-v2 mobile-no-display tablet-no-display"}>
                    <div className={"how-it-works-header-right"}>
                        Produced and shipped within 48 hours.
                    </div>

                    <div className={"how-it-works-subheader-right"}>
                        Your custom matboard will be precisely cut and shipped within 48 hours for quick delivery.
                        <br/>
                        <br/>
                        ✦ 100% Satisfaction Guaranteed.
                        <br/><br/>

                        <a href={"/customer-reviews"}>
                            Read Our Glowing Customer Reviews
                        </a>


                    </div>
                </div>
            </div>

        </div>
    }

    fetchFrench() {
        return <div className={"what-is-mat-page-v2"}>
            <div className={this.props.titleNoDisplay ? "no-display" : "what-is-mat-header-v2 how-it-works-page-leader"}
                 id={"what-is-mat-header-v2-moblet"}
            >
                Comment ça Marche
            </div>

            <div className={"how-it-works-steps-container-v2"}
                 id={"how-it-works-steps-container-v2-moblet"}>
                <div className={"landing-page-standard-right-v2 mobile-and-tablet-display-only"}
                     id={"how-it-works-video1"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} id={"how-it-works-image1"} src={explainerGif1}></img>
                    </LazyLoad>
                </div>

                <div className={"landing-page-standard-left-v2"}>
                    <div className={"how-it-works-header-left"}>
                        Pour commander <br/>
                        votre passe-partout
                    </div>

                    <div className={"how-it-works-subheader-left"} id={"how-it-works-substeps-1"}>
                        1. Indiquez la taille totale de votre passe-partout.<br/>
                        2. Précisez les dimensions de la découpe intérieure.<br/> <br className={"mobile-and-tablet-display-only"}/>
                        3. Choisissez la couleur de votre passe-partout. <br/>
                    </div>

                    <a href={"https://fr.custommat.com"} id={"how-it-works-page-button"}>
                        <div className={"landing-page-button-standard-v2"}
                             id={"explainer-start-designing-button"}>
                            Je crée
                        </div>
                    </a>
                </div>

                <div className={"landing-page-standard-right-v2 mobile-no-display tablet-no-display"}>
                    <LazyLoad>
                        <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                               src={explainerVideo1}></video>
                    </LazyLoad>
                </div>
            </div>

            <div className={"how-it-works-steps-container-v2"}>

                <div className={"landing-page-standard-right-v2 mobile-and-tablet-display-only"}
                     id={"how-it-works-video2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} id={"how-it-works-image2"} src={explainerGif2}></img>
                    </LazyLoad>
                </div>

                <div className={"how-it-works-step2-desc-v2 mobile-and-tablet-display-only"}>
                    <div className={"how-it-works-header-right"} id={"how-it-works-substeps-3"}>
                        Expédié
                        <br/>
                        dans les 48 heures
                    </div>

                    <div className={"how-it-works-subheader-right"}>
                        Après avoir coupé avec précision votre passe-partout personnalisé, nous l’emballons avec soin et nous vous l’expédions dans les 48 heures.                         <br/><br/>
                        <u>
                            ✦Bonheur garanti à 100 %
                        </u>
                        <br/><br/>
                    </div>

                    <div className={"read-our-review-section"}>
                        <a href={"/customer-reviews"}>
                            Lisez nos avis clients élogieux
                        </a>
                    </div>
                </div>



                <div className={"landing-page-standard-left-v2 mobile-no-display tablet-no-display"}>
                    <LazyLoad>
                        <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                               src={explainerVideo2}></video>
                    </LazyLoad>
                </div>


                <div className={"how-it-works-step2-desc-v2 mobile-no-display tablet-no-display"}>
                    <div className={"how-it-works-header-right"}>
                        Produits et expédiés sous 48 heures
                    </div>

                    <div className={"how-it-works-subheader-right"}>
                        Votre passe-partout personnalisé sera découpé avec précision, emballé avec soin, et expédié vers vous en seulement 48 heures.
                        <br/>
                        <br/>
                        ✦ Bonheur garanti à 100 %
                        <br/><br/>

                        <a href={"/customer-reviews"}>
                            Lisez nos avis clients élogieux
                        </a>


                    </div>
                </div>
            </div>

        </div>
    }


    fetchGerman() {
        return <div className={"what-is-mat-page-v2"}>
            <div className={this.props.titleNoDisplay ? "no-display" : "what-is-mat-header-v2 how-it-works-page-leader"}
                 id={"what-is-mat-header-v2-moblet"}
            >
                Wie funktioniert es
            </div>

            <div className={"how-it-works-steps-container-v2"}
                 id={"how-it-works-steps-container-v2-moblet"}>
                <div className={"landing-page-standard-right-v2 mobile-and-tablet-display-only"}
                     id={"how-it-works-video1"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} id={"how-it-works-image1"} src={explainerGif1}></img>
                    </LazyLoad>
                </div>

                <div className={"landing-page-standard-left-v2"}>
                    <div className={"how-it-works-header-left"}>
                        Um Ihr Passepartout <br/>
                        zu bestellen
                    </div>

                    <div className={"how-it-works-subheader-left"} id={"how-it-works-substeps-1"}>
                        1. Geben Sie die Gesamtgröße Ihres Passepartouts an.<br/>
                        2. Geben Sie die Abmessungen des inneren Ausschnitts an.<br/> <br className={"mobile-and-tablet-display-only"}/>
                        3. Wählen Sie die Farbe Ihres Passepartouts.<br/>
                    </div>

                    <a href={"https://de.custommat.com"} id={"how-it-works-page-button"}>
                        <div className={"landing-page-button-standard-v2"}
                             id={"explainer-start-designing-button"}>
                            Anpassen
                        </div>
                    </a>
                </div>

                <div className={"landing-page-standard-right-v2 mobile-no-display tablet-no-display"}>
                    <LazyLoad>
                        <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                               src={explainerVideo1}></video>
                    </LazyLoad>
                </div>
            </div>

            <div className={"how-it-works-steps-container-v2"}>

                <div className={"landing-page-standard-right-v2 mobile-and-tablet-display-only"}
                     id={"how-it-works-video2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} id={"how-it-works-image2"} src={explainerGif2}></img>
                    </LazyLoad>
                </div>

                <div className={"how-it-works-step2-desc-v2 mobile-and-tablet-display-only"}>
                    <div className={"how-it-works-header-right"} id={"how-it-works-substeps-3"}>
                        Versand innerhalb
                        <br/>
                        von 48 Stunden
                    </div>

                    <div className={"how-it-works-subheader-right"}>
                        Nachdem wir Ihr maßgefertigtes Passepartout präzise zugeschnitten haben, verpacken wir es sorgfältig und versenden es innerhalb von 48 Stunden.                  <br/><br/>
                        <u>
                            ✦ 100 % Zufriedenheit garantiert
                        </u>
                        <br/><br/>
                    </div>

                    <div className={"read-our-review-section"}>
                        <a href={"/customer-reviews"}>
                            Lesen Sie unsere Lobenden Kundenbewertungen
                        </a>
                    </div>
                </div>



                <div className={"landing-page-standard-left-v2 mobile-no-display tablet-no-display"}>
                    <LazyLoad>
                        <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                               src={explainerVideo2}></video>
                    </LazyLoad>
                </div>


                <div className={"how-it-works-step2-desc-v2 mobile-no-display tablet-no-display"}>
                    <div className={"how-it-works-header-right"}>
                        Produkte werden innerhalb von 48 Stunden versandt
                    </div>

                    <div className={"how-it-works-subheader-right"}>
                        Ihr maßgefertigtes Passepartout wird präzise zugeschnitten, sorgfältig verpackt und innerhalb von nur 48 Stunden an Sie versendet.
                        <br/>
                        <br/>
                        ✦ 100 % Zufriedenheit garantiert
                        <br/><br/>

                        <a href={"/customer-reviews"}>
                            Lesen Sie unsere Kundenbewertungen
                        </a>
                    </div>
                </div>
            </div>

        </div>
    }

    fetchSpanish() {
        return <div className={"what-is-mat-page-v2"}>
            <div className={this.props.titleNoDisplay ? "no-display" : "what-is-mat-header-v2 how-it-works-page-leader"}
                 id={"what-is-mat-header-v2-moblet"}>
                Cómo funciona
            </div>

            <div className={"how-it-works-steps-container-v2"}
                 id={"how-it-works-steps-container-v2-moblet"}>
                <div className={"landing-page-standard-right-v2 mobile-and-tablet-display-only"}
                     id={"how-it-works-video1"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} id={"how-it-works-image1"} src={explainerGif1}></img>
                    </LazyLoad>
                </div>

                <div className={"landing-page-standard-left-v2"}>
                    <div className={"how-it-works-header-left"}>
                        Para ordenar su Passepartout <br/>
                        personaliza tu pedido
                    </div>

                    <div className={"how-it-works-subheader-left"} id={"how-it-works-substeps-1"}>
                        1. Indique el tamaño total de su passepartout.<br/>
                        2. Indique las dimensiones del corte interior.<br/> <br className={"mobile-and-tablet-display-only"}/>
                        3. Elija el color de su passepartout.<br/>
                    </div>

                    <a href={"https://es.custommat.com"} id={"how-it-works-page-button"}>
                        <div className={"landing-page-button-standard-v2"}
                             id={"explainer-start-designing-button"}>
                            Personalizar
                        </div>
                    </a>
                </div>

                <div className={"landing-page-standard-right-v2 mobile-no-display tablet-no-display"}>
                    <LazyLoad>
                        <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                               src={explainerVideo1}></video>
                    </LazyLoad>
                </div>
            </div>

            <div className={"how-it-works-steps-container-v2"}>

                <div className={"landing-page-standard-right-v2 mobile-and-tablet-display-only"}
                     id={"how-it-works-video2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} id={"how-it-works-image2"} src={explainerGif2}></img>
                    </LazyLoad>
                </div>

                <div className={"how-it-works-step2-desc-v2 mobile-and-tablet-display-only"}>
                    <div className={"how-it-works-header-right"} id={"how-it-works-substeps-3"}>
                        Envío dentro de
                        <br/>
                        48 horas
                    </div>

                    <div className={"how-it-works-subheader-right"}>
                        Después de cortar con precisión su passepartout a medida, lo empaquetamos cuidadosamente y lo enviamos dentro de las 48 horas.                  <br/><br/>
                        <u>
                            ✦ 100 % satisfacción garantizada
                        </u>
                        <br/><br/>
                    </div>

                    <div className={"read-our-review-section"}>
                        <a href={"/customer-reviews"}>
                            Lea nuestras Reseñas de Clientes Elogiosas
                        </a>
                    </div>
                </div>

                <div className={"landing-page-standard-left-v2 mobile-no-display tablet-no-display"}>
                    <LazyLoad>
                        <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                               src={explainerVideo2}></video>
                    </LazyLoad>
                </div>

                <div className={"how-it-works-step2-desc-v2 mobile-no-display tablet-no-display"}>
                    <div className={"how-it-works-header-right"}>
                        Los productos se envían dentro de las 48 horas
                    </div>

                    <div className={"how-it-works-subheader-right"}>
                        Su passepartout a medida será cortado con precisión, empaquetado cuidadosamente y enviado a usted en tan solo 48 horas.
                        <br/>
                        <br/>
                        ✦ 100 % satisfacción garantizada
                        <br/><br/>

                        <a href={"/customer-reviews"}>
                            Lea nuestras reseñas de clientes
                        </a>
                    </div>
                </div>
            </div>

        </div>
    }

    fetchItalian() {
        return <div className={"what-is-mat-page-v2"}>
            <div className={this.props.titleNoDisplay ? "no-display" : "what-is-mat-header-v2 how-it-works-page-leader"}
                 id={"what-is-mat-header-v2-moblet"}>
                Come funziona
            </div>

            <div className={"how-it-works-steps-container-v2"}
                 id={"how-it-works-steps-container-v2-moblet"}>
                <div className={"landing-page-standard-right-v2 mobile-and-tablet-display-only"}
                     id={"how-it-works-video1"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} id={"how-it-works-image1"} src={explainerGif1}></img>
                    </LazyLoad>
                </div>

                <div className={"landing-page-standard-left-v2"}>
                    <div className={"how-it-works-header-left"}>
                        Per ordinare il tuo Passepartout <br/>
                        personalizza il tuo ordine
                    </div>

                    <div className={"how-it-works-subheader-left"} id={"how-it-works-substeps-1"}>
                        1. Indica la dimensione totale del tuo passepartout.<br/>
                        2. Indica le dimensioni del taglio interno.<br/> <br className={"mobile-and-tablet-display-only"}/>
                        3. Scegli il colore del tuo passepartout.<br/>
                    </div>

                    <a href={"https://it.custommat.com"} id={"how-it-works-page-button"}>
                        <div className={"landing-page-button-standard-v2"}
                             id={"explainer-start-designing-button"}>
                            Personalizza
                        </div>
                    </a>
                </div>

                <div className={"landing-page-standard-right-v2 mobile-no-display tablet-no-display"}>
                    <LazyLoad>
                        <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                               src={explainerVideo1}></video>
                    </LazyLoad>
                </div>
            </div>

            <div className={"how-it-works-steps-container-v2"}>

                <div className={"landing-page-standard-right-v2 mobile-and-tablet-display-only"}
                     id={"how-it-works-video2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} id={"how-it-works-image2"} src={explainerGif2}></img>
                    </LazyLoad>
                </div>

                <div className={"how-it-works-step2-desc-v2 mobile-and-tablet-display-only"}>
                    <div className={"how-it-works-header-right"} id={"how-it-works-substeps-3"}>
                        Spedizione entro
                        <br/>
                        48 ore
                    </div>

                    <div className={"how-it-works-subheader-right"}>
                        Dopo aver tagliato con precisione il tuo passepartout su misura, lo imballiamo con cura e lo spediamo entro 48 ore.                  <br/><br/>
                        <u>
                            ✦ Soddisfazione garantita al 100 %
                        </u>
                        <br/><br/>
                    </div>

                    <div className={"read-our-review-section"}>
                        <a href={"/customer-reviews"}>
                            Leggi le nostre recensioni di clienti soddisfatti
                        </a>
                    </div>
                </div>

                <div className={"landing-page-standard-left-v2 mobile-no-display tablet-no-display"}>
                    <LazyLoad>
                        <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                               src={explainerVideo2}></video>
                    </LazyLoad>
                </div>

                <div className={"how-it-works-step2-desc-v2 mobile-no-display tablet-no-display"}>
                    <div className={"how-it-works-header-right"}>
                        I prodotti vengono spediti entro 48 ore
                    </div>

                    <div className={"how-it-works-subheader-right"}>
                        Il tuo passepartout su misura sarà tagliato con precisione, imballato con cura e spedito a te in sole 48 ore.
                        <br/>
                        <br/>
                        ✦ Soddisfazione garantita al 100 %
                        <br/><br/>

                        <a href={"/customer-reviews"}>
                            Leggi le nostre recensioni dei clienti
                        </a>
                    </div>
                </div>
            </div>

        </div>
    }

    fetchDutch() {
        return <div className={"what-is-mat-page-v2"}>
            <div className={this.props.titleNoDisplay ? "no-display" : "what-is-mat-header-v2 how-it-works-page-leader"}
                 id={"what-is-mat-header-v2-moblet"}>
                Hoe het werkt
            </div>

            <div className={"how-it-works-steps-container-v2"}
                 id={"how-it-works-steps-container-v2-moblet"}>
                <div className={"landing-page-standard-right-v2 mobile-and-tablet-display-only"}
                     id={"how-it-works-video1"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} id={"how-it-works-image1"} src={explainerGif1}></img>
                    </LazyLoad>
                </div>

                <div className={"landing-page-standard-left-v2"}>
                    <div className={"how-it-works-header-left"}>
                        Om je Passepartout te bestellen <br/>
                        pas je bestelling aan
                    </div>

                    <div className={"how-it-works-subheader-left"} id={"how-it-works-substeps-1"}>
                        1. Geef de totale afmeting van je passepartout aan.<br/>
                        2. Geef de afmetingen van de uitsnede aan.<br/> <br className={"mobile-and-tablet-display-only"}/>
                        3. Kies de kleur van je passepartout.<br/>
                    </div>

                    <a href={"https://nl.custommat.com"} id={"how-it-works-page-button"}>
                        <div className={"landing-page-button-standard-v2"}
                             id={"explainer-start-designing-button"}>
                            Pas aan
                        </div>
                    </a>
                </div>

                <div className={"landing-page-standard-right-v2 mobile-no-display tablet-no-display"}>
                    <LazyLoad>
                        <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                               src={explainerVideo1}></video>
                    </LazyLoad>
                </div>
            </div>

            <div className={"how-it-works-steps-container-v2"}>

                <div className={"landing-page-standard-right-v2 mobile-and-tablet-display-only"}
                     id={"how-it-works-video2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} id={"how-it-works-image2"} src={explainerGif2}></img>
                    </LazyLoad>
                </div>

                <div className={"how-it-works-step2-desc-v2 mobile-and-tablet-display-only"}>
                    <div className={"how-it-works-header-right"} id={"how-it-works-substeps-3"}>
                        Verzending binnen
                        <br/>
                        48 uur
                    </div>

                    <div className={"how-it-works-subheader-right"}>
                        Nadat we je op maat gemaakte passepartout nauwkeurig hebben gesneden, verpakken we het zorgvuldig en verzenden het binnen 48 uur.                  <br/><br/>
                        <u>
                            ✦ 100% Tevredenheid gegarandeerd
                        </u>
                        <br/><br/>
                    </div>

                    <div className={"read-our-review-section"}>
                        <a href={"/customer-reviews"}>
                            Lees onze recensies van tevreden klanten
                        </a>
                    </div>
                </div>

                <div className={"landing-page-standard-left-v2 mobile-no-display tablet-no-display"}>
                    <LazyLoad>
                        <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                               src={explainerVideo2}></video>
                    </LazyLoad>
                </div>

                <div className={"how-it-works-step2-desc-v2 mobile-no-display tablet-no-display"}>
                    <div className={"how-it-works-header-right"}>
                        Producten worden verzonden binnen 48 uur
                    </div>

                    <div className={"how-it-works-subheader-right"}>
                        Je op maat gemaakte passepartout wordt nauwkeurig gesneden, zorgvuldig verpakt en binnen 48 uur naar je verzonden.
                        <br/>
                        <br/>
                        ✦ 100% Tevredenheid gegarandeerd
                        <br/><br/>

                        <a href={"/customer-reviews"}>
                            Lees onze klantbeoordelingen
                        </a>
                    </div>
                </div>
            </div>

        </div>
    }





    render() {
        if (this.state.french || this.props.country === "France") {
            return this.fetchFrench()
        } else if (this.props.country === "Germany") {
            return this.fetchGerman()
        } else if (this.props.country === "Spain") {
            return this.fetchSpanish()
        } else if (this.props.country === "Italy") {
            return this.fetchItalian()
        } else if (this.props.country === "Netherlands") {
            return this.fetchDutch()
        }

        else {
            return this.fetchEnglish();
        }
    }
}