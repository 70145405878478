import React from 'react';
import photo1 from "../../../resources/images/blog/ikeaSeries/oversized-mat-photo-frame-3.jpg";
import photo2 from "../../../resources/images/blog/oversizedMatRamsborgFrames/IMG_3419.JPG";
import photo3 from "../../../resources/images/blog/photoset2/photo1.jpg";
import photo4 from "../../../resources/images/blog/photoset2/photo8.png";
import photo5 from "../../../resources/images/blog/photoset2/photo9.jpg";



import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";
import backgorundPhoto from "../../../resources/images/blog/oversizedMatRamsborgFrames/IMG_3419.JPG";

export default class IkeaFrameMat extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>Custom Matboards for IKEA Frames</title>
                <meta name="description" content="We are here to custom tailor your matboards to your art and your IKEA frames. Here at CustomMat.ca, we take pride in our expertise in creating custom matboards"/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Custom mat boards for<br/> {this.props.keyword} {this.props.unit} IKEA {this.props.frame} frames
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                IKEA Hack
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Danielle M<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-content-photo"}/>
            </LazyLoad>


            <div className={"blog-article-content"}>
                Did you purchase a {this.props.keyword} {this.props.unit} IKEA {this.props.frame} frame but your artwork looks a bit odd?

                <br/><br/>

                Maybe not as streamlined or elevated as you hoped? Consider visual realignment using a custom cut matboard.

                <br/><br/>

                Perhaps the image fit fine but the colours of the framing feel dull? Do you desire a more suitable coloured {this.props.keyword} {this.props.unit} matboard for your IKEA {this.props.frame} Frames to elevate custom room décor? Why not consider a custom mat color option for full presentation results?

                <br/><br/>

                Your need a {this.props.keyword} {this.props.unit} custom matboard for your {this.props.keyword} {this.props.unit} IKEA {this.props.frame} frame.

                <br/><br/>

                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <br/><br/>

                That’s what we do. Here at Custom-Mat.com, we take pride in our expertise in creating custom matboards. We are here to custom tailor your matboards to your art and your {this.props.keyword} {this.props.unit} IKEA {this.props.frame} Frames. Do you notice the difference between going with an IKEA's default mat vs. getting a customized matboard?

                <br/><br/>

                <LazyLoad>
                    <img src={photo5} className={"blog-content-photo"}/>
                </LazyLoad>
                <div className={"blog-article-photo-description"}>
                    Bottom Left: With our 100% custom matboard<br/>
                    Bottom Right: IKEA's default matboard
                </div>

                Your art will look great in any IKEA {this.props.frame} frame with our custom and pre-cut standard sized matboards. Our 4-ply, high quality, acid-free (acid-neutralized) matboards come in 26 colour options — which include multiple texture finishes such as matte, specked, and foiled options.


                <br/><br/>

                <LazyLoad>
                    <img src={photo2} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                These high-quality materials are sourced from our suppliers in Canada, the United States, and the United Kingdom. Next, they are expertly crafted to suit your frames in our workshop.

                <br/><br/>

                If your artwork, photo, or prints are standard sizes, quickly purchase our standard-sized, pre-cut matboards. They come in Smooth White mat (which is standard white colour), China White mat (an off-white coloured matboard, closest to the IKEA {this.props.frame} frame’s default matboards), and Black mat. These pre-cut budget friendly, everyday options are guaranteed to fit any standard IKEA {this.props.frame} frame without any compromise on quality compared to our custom cut options.



                <br/><br/>

                <div>
                    <b>100% Customizable Dimensions</b>
                </div>

                <br/>

                If your artwork sizes are unique or nonstandard? No problem. We have options to customize your matboard opening to fit any artwork within your {this.props.keyword} {this.props.unit} IKEA {this.props.frame} frames. You can choose any custom size mat, design, or shape for the matboard opening. Custom matting is an excellent alternative to custom framing.

                <br/><br/>

                <LazyLoad>
                    <img src={photo4} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                We have customized hundreds of thousands of picture frame matboards for our customers all over the world. They have been used for art exhibition’s gallery frames and home décor gallery walls. We can custom fit and elevate diplomas, awards and certificates. We offer custom calligraphy personalization options as well as paper etching.

                <br/><br/>

                <LazyLoad>
                    <img src={photo3} className={"blog-content-photo"}/>
                </LazyLoad>

                <br/><br/>

                Our company has been tested by years of experience and customer reviews. We have produced hundreds of thousands of custom matboards for 16x20 IKEA picture frames alone. Our acid-neutralized, high-quality mats are perfect for your decorating needs. Elevate and streamline your space beautifully by controlling the eye without going over your interior design budget.

                <br/><br/>

                We make both single mat and double mat options. You can also do custom designs, including round and multiple openings. All our matboard opening edges are bevelled in 45 degrees like the popular matboards that we see around us. This provides visual depth and centres the framed image. Our white core when exposed through the bevelling accentuates the highlighted dimensions of the final product

                <br/><br/>

                <b>Artist Presentation Kit</b>

                <br/><br/>

                Are you an artist looking to present your artwork? We can also make your order as a presentation kit with backing mats and acrylic sleeves to make an artist package.

                <br/><br/>

                Backer mats come in handy. Adhere or mount your artwork on top of backing mats to prevent photo depression or any unintended visual effect on your framing and end presentation.

                <br/><br/>

                <b>Other Tools and Supplies for Picture Framing</b>

                <br/><br/>

                In addition, the custom cut matboards we do offer many other tools and supplies for your picture framing needs. You get to have your custom cut mat boards delivered to your door. We ship across all of Canada and the United States. Our shipping is free.

                <br/><br/>

                Don’t forget to lookout for our seasonal coupon codes!

                <br/><br/>

                If you have any questions, please email us at hi@custommat.com


                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>


            </div>


            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>




        </div>
    }
}