import React from "react";
import CheckoutV2 from "../comp-payments/checkoutV2";
import "@babel/polyfill";
import ShoppingSummary from "./shoppingSummary";
import connectToDB from "../database/connectDB";

export default class PaymentCheckoutV2 extends React.Component {


  constructor() {
    super();
    this.state = {
      addressBookDBUpdated: false,
    };

  };

  componentDidMount() {
    this.submitInfoToGtag('add_payment_info');

    if (this.props.preDiscountPrice === 0) {
      //if the prices not updated, then update the function calculator.
      this.props.updatePrices();
    }

    //if we don't do this, it will call too many times
    if (this.state.customerEmail) {
      //means data is loaded so do nothing
      return null
    } else {
      //means data is NOT loaded so load data
      this.loadLocalStorage()
    }
  };


  postDataToCustomerAddressBook() {
    //once the address validation gets completed, we send the address to firebase and local storage
    var content = `${this.state.customerEmail},${this.state.shippingAddressRecipientName},${this.state.shippingAddressStreet},${this.state.shippingAddressSuite === "undefined" ? "" : this.state.shippingAddressSuite},${this.state.shippingAddressCity},${this.state.shippingAddressProvince},${this.state.shippingAddressPostalCode},${this.state.country},${this.props.calculateItemSubtotal().toFixed(2)}`
    connectToDB("postAddress","customer-address", `${this.state.orderConfirmationNumber}`, content);
    this.setState({addressBookDBUpdated: true});
  }

  loadLocalStorage() {

    var items = { ...localStorage };
    var prices = this.props.updatePrices()

    if (items.customerEmail) {
      this.setState({
        country: items.country,
        customerEmail: items.customerEmail,
        customerPhoneNumber: items.customerPhoneNumber,
        orderConfirmationNumber:items.orderConfirmationNumber,
        shippingAddressCity: items.shippingAddressCity,
        shippingAddressPostalCode: items.shippingAddressPostalCode,
        shippingAddressProvince: items.shippingAddressProvince,
        shippingAddressRecipientName: items.shippingAddressRecipientName,
        shippingAddressStreet: items.shippingAddressStreet,
        shippingAddressSuite: items.shippingAddressSuite,
        currency: this.props.currency,
        preDiscountPrice: prices.preDiscountPrice,
        itemsSubtotal: prices.itemsSubtotal,
        shippingCharges: prices.shippingCharges,
        taxes: prices.taxes,
        grandTotal: prices.grandTotal,
      }, () => this.checkIfReturnToPaymentPageNeeded());
    } else {
      window.location.href="/"
    }
  }


  checkIfReturnToPaymentPageNeeded() {
    //if there's no shipment info data or empty cart, then return to the appropriate page.
    if (isNaN(this.state.grandTotal) || this.shippingAddressStreet === null) {
      //if no price or address info -- then previous page
      window.location.href="/payments"
    } else if (this.state.grandTotal < 1) {
      //if no order there, then return to order page
      window.location.href="/customize-matboards"
    } else {
      this.postDataToCustomerAddressBook();
      return null
    }
  }

  submitInfoToGtag(tag) {
    window.gtag('event', tag, {
      'send_to': 'AW-732793253/RjgUCLrI2qcBEKWTtt0C',
      'value': parseFloat(this.props.calculateItemSubtotal()),
      'currency': "CAD",
      'transaction_id': ''
    })
  }

  fetchPaymentSectionHeader(text) {
    return <div className={"payment-section-container-header"}>
      {text}
    </div>
  }


  fetchAddressForConfirmationEmail() {
    return `${this.state.shippingAddressStreet} ${this.state.shippingAddressSuite}, ${this.state.shippingAddressCity}, ${this.state.shippingAddressProvince}, ${this.state.shippingAddressPostalCode}`;
  };

  fetchCheckoutSection() {

    return <div className={"delivery-payments-section-shipping-payments-container"}  id={"shopping-checkout-container"}>

      <div className={"payment-section-dividers"}>
        {<div className={"checkout-button"}>
          <CheckoutV2
              customer={this.state.customerEmail}
              description={`${this.state.shippingAddressProvince}-${this.state.customerEmail}`}
              currency={this.props.currency}
              amount={this.props.fxConversion(this.state.grandTotal)}
              email={this.state.customerEmail}
          />

          <div className={`payment-processor-description`}>
            {this.state.french ? "Votre paiement est traité en toute sécurité grâce au chiffrement de pointe de Stripe." : "Your payment is securely processed through Stripe's industry-leading encryption."}
            <br/><br/>
            {this.state.french ? "En finalisant votre achat, vous acceptez nos Conditions d'utilisation et notre Politique de confidentialité." : "By completing your purchase, you agree to our Terms of Service and Privacy Policy."}
          </div>


        </div>}
      </div>

    </div>
  };

  showShoppingSummary() {
    return <ShoppingSummary
        forPaymentApp={true}

        shoppingCartItems={this.props.shoppingCartItems}

        //shippingInfo
        pickUpOrShipping={this.state.pickUpOrShipping}
        shippingAddressRecipientName={this.state.shippingAddressRecipientName}
        customerEmail={this.state.customerEmail}
        customerPhoneNumber={this.state.customerPhoneNumber}
        shippingAddressStreet={this.state.shippingAddressStreet}
        shippingAddressSuite={this.state.shippingAddressSuite}
        shippingAddressCity={this.state.shippingAddressCity}
        shippingAddressPostalCode={this.state.shippingAddressPostalCode}
        shippingAddressProvince={this.state.shippingAddressProvince}
        country={this.state.country}

        //callback Functions
        fxConversion={this.props.fxConversion}
        updateItemList={this.updateItemList}
        parentSetStateCallback={this.props.parentSetStateCallback}
        getTotalItemCount={this.props.getTotalItemCount}
        getDiscountMultiplier={this.props.getDiscountMultiplier}
        discountRate={this.props.discountRate}

        //payment amounts
        currency={this.props.currency}
        preDiscountPrice={this.state.preDiscountPrice}
        itemsSubtotal={this.state.itemsSubtotal}
        shippingCharges={this.state.shippingCharges}
        taxes={this.state.taxes}
        grandTotal={this.state.grandTotal}
    />
  }


  applyCoupon() {
    localStorage.setItem("promoCodeApplied", true);
    this.props.parentSetStateCallback("discountRate", this.props.promoDecimals);
    this.props.updatePrices();
  }


  render() {
    return <div className={`payments-container`}>

      <div className={this.props.onGoingPromotion ? "delivery-payments-section-shipping-payments-container" : "no-display"}>
        <div className={"summary-review-button-left"}>
          Ship to
        </div>

        <div className={"shipping-summary-amount"}>
          {this.state.shippingAddressRecipientName}<br/>
          {this.state.shippingAddressStreet} {this.state.shippingAddressSuite}<br/>
          {this.state.shippingAddressCity}, {this.state.shippingAddressProvince}, {this.state.shippingAddressPostalCode}<br/>
        </div>
      </div>

      <div className={this.props.onGoingPromotion ? "delivery-payments-section-shipping-payments-container" : "no-display"}>
        <div className={"summary-review-button-left"}>
          Promotions
        </div>

        <div className={"promotions-button"} style={{float: "right", textAlign: "center"}} onClick={() => {this.applyCoupon()} }>
          <div className={this.props.discountRate === 0 ? "null" : "no-display"}>Apply {this.props.promotionRate}<br/>Discount Coupon</div>
          <div className={this.props.discountRate === 0 ? "no-display" : "null"}>Coupon Applied</div>
        </div>
      </div>

      {this.showShoppingSummary(this.state.preDiscountPrice, this.state.itemsSubtotal, this.state.shippingCharges, this.state.taxes, this.state.grandTotal)}

      {this.state.grandTotal > 0 ? this.fetchCheckoutSection() : null}

    </div>
  }

}