import React from "react";
import landingPageArrowLeft from "../../../resources/logo/landing-page-arrow-left.svg";
import landingPageReviewStars from "../../../resources/logo/landing-page-star.svg";
import landingPageArrowRight from "../../../resources/logo/landing-page-arrow-right.svg";
import img1 from "../../../resources/images/customers/1.jpg";
import img2 from "../../../resources/images/customers/2.jpg";
import img3 from "../../../resources/images/customers/3.jpg";
import img4 from "../../../resources/images/customers/4.jpg";
import img5 from "../../../resources/images/customers/5.jpg";
import img6 from "../../../resources/images/customers/6.jpg";
import img7 from "../../../resources/images/customers/7.jpg";
import img8 from "../../../resources/images/customers/8.jpg";
import img9 from "../../../resources/images/customers/9.jpg";
import img10 from "../../../resources/images/customers/10.jpg";
import img11 from "../../../resources/images/customers/11.jpg";
import img12 from "../../../resources/images/customers/12.jpg";
import img13 from "../../../resources/images/customers/13.jpg";
import LazyLoad from 'react-lazyload';

export default class ReviewComponent extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            starClicked: false,
            carouselNumber: 0,
            ourComment: {
                improve: "Sorry for our mistake. Please contact us at hi@custommat.com. We will come up with solution. We will issue you a refund or redo the work.",
                good: "Thank you very much for your feedback!",
                excellent: "Awesome! Happy to see our satisfied customers! :)",
            },
            featuredReviews: [
                {
                    img: img1,
                    reviewer: "Kelsey Simone",
                    review: "100% Satisfied. I could not be happier with my order from Custom Mat - the order process was very easy, shipping was extremely quick, and the mats came exactly as ordered. My framed pictures look better than I hoped for! ",
                    reviewFr: "100 % satisfait. Je ne pouvais pas être plus heureux avec ma commande de Custom Mat - le processus de commande a été très facile, l’expédition a été extrêmement rapide, et les passe-partout sont arrivés exactement comme commandés. Mes photos encadrées ont l’air mieux que ce que j’espérais!",
                    reviewDe: "100 % zufrieden. Ich könnte nicht glücklicher mit meiner Bestellung von Custom Mat sein – der Bestellprozess war sehr einfach, der Versand war extrem schnell, und die Passepartouts kamen genau wie bestellt an. Meine gerahmten Fotos sehen besser aus, als ich erwartet habe!",
                    reviewEs: "100 % satisfecho. No podría estar más feliz con mi pedido de Custom Mat: el proceso de compra fue muy fácil, el envío fue extremadamente rápido, y los passepartouts llegaron exactamente como los pedí. ¡Mis fotos enmarcadas se ven mejor de lo que esperaba!",
                    reviewIt: "100 % soddisfatto. Non potrei essere più felice con il mio ordine da Custom Mat – il processo di ordinazione è stato molto facile, la spedizione è stata estremamente rapida e i passepartout sono arrivati esattamente come ordinato. Le mie foto incorniciate sembrano meglio di quanto mi aspettassi!",
                    reviewNl: "100 % tevreden. Ik kan niet gelukkiger zijn met mijn bestelling bij Custom Mat – het bestelproces was heel eenvoudig, de verzending was extreem snel en de passepartouts kwamen precies zoals besteld. Mijn ingelijste foto's zien er beter uit dan ik had verwacht!",

                },
                {
                    img: img2,
                    reviewer: "Stacey Harvie",
                    review: "Excellent customer service! Wasn't even a full week from the time I placed my order to the time I received it (that includes cross country shipping). Custom matting was cut perfectly and works great. Thanks so much!",
                    reviewFr: "Excellent service à la clientèle! Même pas une semaine complète ne s’est écoulée entre le moment où j’ai passé ma commande et le moment où je l’ai reçue (incluant l’expédition de ON à AB). Le passe-partout personnalisé a été parfaitement coupé et fonctionne parfaitement. Merci beaucoup!",
                    reviewDe: "Hervorragender Kundenservice! Es verging noch nicht einmal eine ganze Woche zwischen dem Zeitpunkt, an dem ich meine Bestellung aufgegeben habe, und dem Moment, als ich sie erhielt (einschließlich des Versands von ON nach AB). Das maßgefertigte Passepartout wurde perfekt zugeschnitten und funktioniert einwandfrei. Vielen Dank!",
                    reviewEs: "¡Servicio al cliente excelente! Ni siquiera pasó una semana entre el momento en que hice mi pedido y cuando lo recibí (incluido el envío de ON a AB). El passepartout a medida fue cortado perfectamente y funciona a la perfección. ¡Muchas gracias!",
                    reviewIt: "Servizio clienti eccellente! Non è passata nemmeno una settimana tra il momento in cui ho effettuato l'ordine e quando l'ho ricevuto (inclusa la spedizione da ON a AB). Il passepartout su misura è stato tagliato perfettamente e funziona alla perfezione. Grazie mille!",
                    reviewNl: "Uitstekende klantenservice! Er is nog geen week verstreken tussen het moment dat ik mijn bestelling plaatste en het moment dat ik het ontving (inclusief de verzending van ON naar AB). Het op maat gemaakte passepartout was perfect gesneden en werkt perfect. Heel erg bedankt!",
                },
                {
                    img: img3,
                    reviewer: "Daniel Weedmark",
                    review: "I have about 10 mats ordered from you guys and they are all perfect! Price is amazing, quality is on point and your turnaround time is unmatched with other companies I’ve tried. Thanks for all the hard work you do!",
                    reviewFr: "J’ai environ 10 passe-partout commandés de vous et ils sont tous parfaits! Le prix est incroyable, la qualité est parfaite et votre temps de réponse est sans égal comparativement à d’autres entreprises que j’ai essayées. Merci pour tout le travail que vous faites!",
                    reviewDe: "Ich habe etwa 10 Passepartouts bei Ihnen bestellt und sie sind alle perfekt! Der Preis ist unglaublich, die Qualität ist perfekt und Ihre Reaktionszeit ist unübertroffen im Vergleich zu anderen Unternehmen, die ich ausprobiert habe. Vielen Dank für die ganze Arbeit, die Sie leisten!",
                    reviewEs: "He pedido alrededor de 10 passepartouts y todos son perfectos. ¡El precio es increíble, la calidad es perfecta y su tiempo de respuesta es inigualable en comparación con otras empresas que he probado! ¡Gracias por todo el trabajo que hacen!",
                    reviewIt: "Ho ordinato circa 10 passepartout da voi e sono tutti perfetti! Il prezzo è incredibile, la qualità è perfetta e i vostri tempi di risposta sono incomparabili rispetto ad altre aziende che ho provato. Grazie per tutto il lavoro che fate!",
                    reviewNl: "Ik heb ongeveer 10 passepartouts bij jullie besteld en ze zijn allemaal perfect! De prijs is ongelooflijk, de kwaliteit is perfect en jullie reactietijd is ongeëvenaard in vergelijking met andere bedrijven die ik heb geprobeerd. Bedankt voor al het werk dat jullie doen!",

                },
                {
                    img: img4,
                    reviewer: "Sarah Donovan",
                    review: "Excellent customer service. I goofed and they fixed it with no extra charge. Everything was delivered across the country in perfect condition and quickly. A great Canadian company.",
                    reviewFr: "Excellent service à la clientèle. J’ai fait une erreur et ils l’ont réparée sans frais supplémentaires. Tout a été livré dans tout le pays en parfait état et rapidement. Une excellente entreprise canadienne. ",
                    reviewDe: "Hervorragender Kundenservice. Ich habe einen Fehler gemacht und sie haben ihn ohne zusätzliche Kosten behoben. Alles wurde landesweit schnell und in einwandfreiem Zustand geliefert. Ein ausgezeichnetes kanadisches Unternehmen.",
                    reviewEs: "Excelente servicio al cliente. Cometí un error y lo corrigieron sin costo adicional. Todo fue entregado rápidamente y en perfectas condiciones a nivel nacional. Una excelente empresa canadiense.",
                    reviewIt: "Eccellente servizio clienti. Ho commesso un errore e l'hanno corretto senza costi aggiuntivi. Tutto è stato consegnato rapidamente e in perfette condizioni a livello nazionale. Un'ottima azienda canadese.",
                    reviewNl: "Uitstekende klantenservice. Ik maakte een fout en ze hebben het kosteloos gecorrigeerd. Alles werd snel en in perfecte staat op nationaal niveau geleverd. Een uitstekend Canadees bedrijf.",
                },
                {
                    img: img5,
                    reviewer: "Lucinda Nigren",
                    review: "Great company! I felt the mats they sent were a great finish and quality. When I made a mistake in size they were able to help me out right away. The mat really improved the look of the art work too. If I need more mats I will be back.",
                    reviewFr: "Excellente entreprise! J’ai trouvé que les passe-partout qu’ils ont envoyés étaient d’une excellente finition et de qualité. Quand j’ai fait une erreur de taille, ils ont pu m’aider immédiatement. Le passe-partout a également amélioré l’aspect de l’œuvre d’art. Si j’ai besoin de plus de passe-partout, je reviendrai",
                    reviewDe: "Ausgezeichnetes Unternehmen! Ich fand, dass die Passepartouts, die sie geschickt haben, eine ausgezeichnete Verarbeitung und Qualität hatten. Als ich einen Größenfehler gemacht habe, konnten sie mir sofort helfen. Das Passepartout hat auch das Aussehen des Kunstwerks verbessert. Wenn ich mehr Passepartouts brauche, werde ich wiederkommen.",
                    reviewEs: "¡Excelente empresa! Encontré que los passepartouts que enviaron tenían una excelente fabricación y calidad. Cuando cometí un error con el tamaño, pudieron ayudarme de inmediato. El passepartout también mejoró la apariencia de la obra de arte. Si necesito más passepartouts, volveré.",
                    reviewIt: "Ottima azienda! Ho trovato che i passepartout che hanno inviato fossero di eccellente fattura e qualità. Quando ho commesso un errore con la misura, sono riusciti ad aiutarmi immediatamente. Il passepartout ha anche migliorato l'aspetto dell'opera d'arte. Se avrò bisogno di altri passepartout, tornerò.",
                    reviewNl: "Uitstekend bedrijf! Ik vond dat de passepartouts die ze stuurden een uitstekende afwerking en kwaliteit hadden. Toen ik een fout maakte met de maat, konden ze me onmiddellijk helpen. Het passepartout verbeterde ook de uitstraling van het kunstwerk. Als ik meer passepartouts nodig heb, kom ik zeker terug.",


                },
                {
                    img: img6,
                    reviewer: "Alicia Silva",
                    review: "I've ordered ~10 mats now and each one has showed up very quickly, and they have all been exactly as ordered. Exceptional service, especially during the pandemic. Thank you!",
                    reviewFr: "J’ai commandé environ 10 passe-partout maintenant et chacun d’eux a été livré très rapidement, et ils ont tous été exactement comme commandés. Un service exceptionnel, en particulier pendant la pandémie. Merci!",
                    reviewDe: "Ich habe jetzt etwa 10 Passepartouts bestellt und jedes wurde sehr schnell geliefert, und sie waren alle genau wie bestellt. Ein außergewöhnlicher Service, besonders während der Pandemie. Vielen Dank!",
                    reviewEs: "He pedido alrededor de 10 passepartouts y cada uno fue entregado muy rápidamente, y todos fueron exactamente como los pedí. Un servicio excepcional, especialmente durante la pandemia. ¡Muchas gracias!",
                    reviewIt: "Ho ordinato circa 10 passepartout e ciascuno è stato consegnato molto rapidamente, e tutti erano esattamente come li avevo richiesti. Un servizio eccezionale, specialmente durante la pandemia. Grazie mille!",
                    reviewNl: "Ik heb ongeveer 10 passepartouts besteld en elk werd heel snel geleverd, en ze waren precies zoals ik had gevraagd. Een uitstekende service, vooral tijdens de pandemie. Heel erg bedankt!",


                },
                {
                    img: img7,
                    reviewer: "Dotti Lister",
                    review: "Very easy to order on their site! Shipped within two days, perfect fit & good quality.",
                    reviewFr: "Très facile de commander sur leur site! Livré en deux jours, ajustement parfait et bonne qualité.",
                    reviewDe: "Sehr einfach, auf ihrer Website zu bestellen! In zwei Tagen geliefert, perfekte Passform und gute Qualität.",
                    reviewEs: "¡Muy fácil hacer el pedido en su sitio web! Entregado en dos días, ajuste perfecto y buena calidad.",
                    reviewIt: "Molto facile fare l'ordine sul loro sito web! Consegnato in due giorni, perfetto come misura e buona qualità.",
                    reviewNl: "Heel makkelijk om de bestelling te plaatsen op hun website! Geleverd binnen twee dagen, perfecte pasvorm en goede kwaliteit.",
                },
                {
                    img: img8,
                    reviewer: "Makai Thompson",
                    review: "My matboards were perfect! Just what I was expecting. Equally as great as the mats themselves was the customer service :)",
                    reviewFr: "Mes passe-partout étaient parfaits! Tout ce à quoi je m’attendais. Le service à la clientèle était tout aussi bien que les passe-partout eux-mêmes :)",
                    reviewDe: "Meine Passepartouts waren perfekt! Alles, was ich erwartet habe. Der Kundenservice war genauso gut wie die Passepartouts selbst :)",
                    reviewEs: "¡Mis passepartouts fueron perfectos! Todo lo que esperaba. El servicio al cliente fue tan bueno como los propios passepartouts :)",
                    reviewIt: "I miei passepartout erano perfetti! Tutto quello che mi aspettavo. Il servizio clienti è stato tanto buono quanto i passepartout stessi :)",
                    reviewNl: "Mijn passepartouts waren perfect! Alles wat ik had verwacht. De klantenservice was net zo goed als de passepartouts zelf :)",
                },
                {
                    img: img9,
                    reviewer: "Jennifer Kim",
                    review: "Excellent customer service! I was afraid to order online. I called an my fears were put to rest. I ordered mats, one with bottom weighted matting. It came exactly I needed it. Thank you so much for the wonderful service.",
                    reviewFr: "Excellent service à la clientèle! J’avais peur de commander en ligne. J’ai appelé et mes craintes ont été mises de côté. J’ai commandé des passe-partout, dont un avec passe-partout lesté sur le bas. C’était exactement ce dont j’avais besoin. Merci beaucoup pour le merveilleux service.",
                    reviewDe: "Hervorragender Kundenservice! Ich hatte Angst, online zu bestellen. Ich habe angerufen und meine Bedenken wurden ausgeräumt. Ich habe Passepartouts bestellt, darunter eines mit beschwertem Passepartout am unteren Rand. Es war genau das, was ich brauchte. Vielen Dank für den wunderbaren Service.",
                    reviewEs: "¡Excelente servicio al cliente! Tenía miedo de hacer un pedido en línea. Llamé y se resolvieron mis inquietudes. Pedí passepartouts, incluido uno con passepartout pesado en la parte inferior. Fue exactamente lo que necesitaba. Gracias por el maravilloso servicio.",
                    reviewIt: "Ottimo servizio clienti! Avevo paura di fare un ordine online. Ho chiamato e le mie preoccupazioni sono state risolte. Ho ordinato dei passepartout, incluso uno con passepartout pesante nella parte inferiore. Era esattamente ciò di cui avevo bisogno. Grazie per il meraviglioso servizio.",
                    reviewNl: "Uitstekende klantenservice! Ik was bang om een bestelling online te plaatsen. Ik belde en mijn zorgen werden opgelost. Ik bestelde passepartouts, inclusief een met een zwaar passepartout aan de onderkant. Het was precies wat ik nodig had. Bedankt voor de geweldige service.",

                },

                {
                    img: img10,
                    reviewer: "Jo Anne MacMilan",
                    review: "Just want to say thank you Custom Mat for helping me out! The folks at Custom Mat helped me out and I received my mat yesterday. It was absolutely perfect! The price was affordable and the quality was exceptional.",
                    reviewFr: "Je veux simplement vous remercier Custom Mat pour m’avoir aidé! Les gens chez Custom Mat m’ont aidé et j’ai reçu mon passe-partout hier. C’était absolument parfait! Le prix était abordable et la qualité exceptionnelle.",
                    reviewDe: "Ich möchte mich einfach bei Custom Mat bedanken, dass sie mir geholfen haben! Die Leute bei Custom Mat haben mir geholfen und ich habe mein Passepartout gestern erhalten. Es war absolut perfekt! Der Preis war erschwinglich und die Qualität außergewöhnlich.",
                    reviewEs: "¡Solo quiero agradecer a Custom Mat por haberme ayudado! Las personas de Custom Mat me ayudaron y recibí mi passepartout ayer. ¡Fue absolutamente perfecto! El precio fue asequible y la calidad excepcional.",
                    reviewIt: "Voglio solo ringraziare Custom Mat per avermi aiutato! Le persone di Custom Mat mi hanno aiutato e ho ricevuto il mio passepartout ieri. Era assolutamente perfetto! Il prezzo era accessibile e la qualità eccezionale.",
                    reviewNl: "Ik wil Custom Mat gewoon bedanken voor hun hulp! De mensen van Custom Mat hebben me geholpen en ik ontving mijn passepartout gisteren. Het was absoluut perfect! De prijs was betaalbaar en de kwaliteit uitzonderlijk.",
                },
                {
                    img: img11,
                    reviewer: "Colleen Wishart",
                    review: "This company is fantastic. Not only do they offer beautiful custom mats which arrived quickly and were really good quality, but my mats didn’t fit and they replaced them for free and without any hassle. I will be ordering all of my mats from them from now on.",
                    reviewFr: "Cette entreprise est fantastique. Non seulement ils offrent de beaux passe-partout personnalisés qui sont arrivés rapidement et étaient vraiment de bonne qualité, mais mes passe-partout n’étaient pas bien ajustés et ils les ont remplacés gratuitement et sans tracas. Je vais maintenant commander tous mes passe-partout d’eux.",
                    reviewDe: "Dieses Unternehmen ist fantastisch. Sie bieten nicht nur wunderschöne, maßgefertigte Passepartouts an, die schnell geliefert wurden und wirklich von guter Qualität waren, sondern meine Passepartouts passten nicht richtig und sie haben sie kostenlos und ohne Probleme ersetzt. Ich werde jetzt alle meine Passepartouts bei ihnen bestellen.",
                    reviewEs: "Esta empresa es fantástica. No solo ofrecen passepartouts personalizados y hermosos, que fueron entregados rápidamente y realmente de buena calidad, sino que mis passepartouts no encajaban correctamente y los reemplazaron sin costo adicional y sin problemas. Ahora pediré todos mis passepartouts con ellos.",
                    reviewIt: "Questa azienda è fantastica. Non solo offrono passepartouts personalizzati e bellissimi, che sono stati consegnati rapidamente e di ottima qualità, ma i miei passepartouts non si adattavano correttamente e li hanno sostituiti senza costi aggiuntivi e senza problemi. Ora ordinerò tutti i miei passepartouts da loro.",
                    reviewNl: "Dit bedrijf is fantastisch. Ze bieden niet alleen mooie, op maat gemaakte passepartouts aan, die snel werden geleverd en van goede kwaliteit waren, maar mijn passepartouts pasten niet goed en ze hebben ze zonder extra kosten en zonder problemen vervangen. Nu ga ik al mijn passepartouts bij hen bestellen.",

                },
                {
                    img: img12,
                    reviewer: "Al Thatcher",
                    review: "Quality mats and great service! I needed matting to frame two pieces of needlework. CustomMat cut the mats perfectly and delivered within a few days. The colour selection on one was not pleasing (my blunder). CustomMat replaced it right away ... no fuss at all. This is great service.",
                    reviewFr: "Des passe-partout de qualité et un excellent service! J’avais besoin de passe-partout pour encadrer deux pièces de broderies. CustomMat a parfaitement coupé les passe-partout et les a livrés en quelques jours. La sélection de couleurs sur l’une d’elles n’était pas agréable (mon erreur). CustomMat l’a remplacé tout de suite... pas de souci du tout. C’est un excellent service.",
                    reviewDe: "Qualitativ hochwertige Passepartouts und ein ausgezeichneter Service! Ich brauchte Passepartouts, um zwei Stickereien zu rahmen. CustomMat hat die Passepartouts perfekt zugeschnitten und in wenigen Tagen geliefert. Die Farbauswahl für eines davon war nicht angenehm (mein Fehler). CustomMat hat es sofort ersetzt... kein Problem. Ein hervorragender Service.",
                    reviewEs: "¡Passepartouts de alta calidad y un servicio excelente! Necesitaba passepartouts para enmarcar dos bordados. CustomMat cortó los passepartouts perfectamente y los entregó en unos pocos días. La selección de color para uno de ellos no era la correcta (mi error). CustomMat lo reemplazó de inmediato... ningún problema. Un servicio excelente.",
                    reviewIt: "Passepartout di alta qualità e un servizio eccellente! Avevo bisogno di passepartout per incorniciare due ricami. CustomMat ha tagliato i passepartout perfettamente e li ha consegnati in pochi giorni. La selezione del colore per uno di essi non era corretta (colpa mia). CustomMat lo ha sostituito immediatamente... nessun problema. Un servizio eccellente.",
                    reviewNl: "Passepartouts van hoge kwaliteit en uitstekende service! Ik had passepartouts nodig om twee borduursels in te lijsten. CustomMat snede de passepartouts perfect en leverde ze binnen een paar dagen. De kleurkeuze voor één van hen was niet correct (mijn fout). CustomMat verving het onmiddellijk... geen probleem. Uitstekende service.",
                },
                {
                    img: img13,
                    reviewer: "Jamie Campbell",
                    review: "The customer service I have received from Custom Mat has been mindblowing! The communication between us while designing a custom matte for my signed jersey shadow box was second to none. They have truly gone out of their way to ensure 100% customer satisfaction.",
                    reviewFr: "Le service client que j’ai reçu de Custom Mat a été époustouflant! La communication entre nous pendant la conception de mon passe-partout personnalisé pour l’encadrement de mon gilet signé était sans égal. Ils ont vraiment fait tout le nécessaire pour garantir une satisfaction client de 100 %.",
                    reviewDe: "Der Kundenservice, den ich von Custom Mat erhalten habe, war beeindruckend! Die Kommunikation zwischen uns während der Gestaltung meines maßgefertigten Passepartouts für den Rahmen meiner signierten Weste war einzigartig. Sie haben wirklich alles getan, um eine 100 % Kundenzufriedenheit zu gewährleisten.",
                    reviewEs: "¡El servicio al cliente que recibí de Custom Mat fue impresionante! La comunicación entre nosotros durante el diseño de mi passepartout a medida para el marco de mi chaleco firmado fue única. Realmente hicieron todo lo posible para garantizar una satisfacción del cliente del 100%.",
                    reviewIt: "Il servizio clienti che ho ricevuto da Custom Mat è stato impressionante! La comunicazione tra di noi durante la progettazione del mio passepartout su misura per la cornice del mio gilet firmato è stata unica. Hanno davvero fatto tutto il possibile per garantire una soddisfazione del cliente al 100%.",
                    reviewNl: "De klantenservice die ik van Custom Mat ontving was indrukwekkend! De communicatie tussen ons tijdens het ontwerp van mijn op maat gemaakte passepartout voor het frame van mijn gesigneerde vest was uniek. Ze hebben echt alles gedaan om 100% klanttevredenheid te garanderen.",
                },

            ]
        };
    }

    reviewCarouselClicked(leftOrRight) {
        if (leftOrRight === "left") {
            if (this.state.carouselNumber === 0) {
                this.setState({carouselNumber: 12})
            } else {
                this.setState({carouselNumber: this.state.carouselNumber - 1})
            }
        } else {
            if (this.state.carouselNumber === 12) {
                this.setState({carouselNumber: 0})
            } else {
                this.setState({carouselNumber: this.state.carouselNumber + 1})
            }
        }
    }

    fetchEnglish() {
        return <div className={"landing-page-review-container-v2"} id={"landing-page-review-container-v2-moblet"}>

            <div className={"landing-page-left-arrow-v2"} onClick={()=> this.reviewCarouselClicked("left")}>
                <img className={"landing-page-arrow"} src={landingPageArrowLeft} alt={"Click to view more reviews."} />
            </div>

            <div className={"landing-page-review-v2"}>
                <LazyLoad>
                    <img className={"customer-review-image"} src={this.state.featuredReviews[this.state.carouselNumber].img}/>
                </LazyLoad>
                <div className={"landing-page-reviewer-name-v2"}>
                    {this.state.featuredReviews[this.state.carouselNumber].reviewer}
                </div>
                <div className={"landing-page-review-content-v2"}>
                    {`"${this.state.featuredReviews[this.state.carouselNumber].review}"` }
                </div>
                <div className={"landing-page-review-stars-v2"}>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars}
                         alt={this.props.french ? "Cote d'évaluation moyenne de CustomMat.ca : 4,9/5,0" : "CustomMat.ca Average Review Rating: 4.9/5.0"}
                    />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"CustomMat.ca Average Review Rating: 4.9/5.0"}/>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"CustomMat.ca Average Review Rating: 4.9/5.0"}/>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"CustomMat.ca Average Review Rating: 4.9/5.0"}/>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"CustomMat.ca Average Review Rating: 4.9/5.0"}/>
                </div>

                <a href={"/customer-reviews"} className={"landing-page-total-review-count-v2"}>
                    {"Browse over 4,000 5-Star Reviews"}
                </a>
            </div>

            <div className={"landing-page-right-arrow-v2"} onClick={()=> this.reviewCarouselClicked("right")}>
                <img className={"landing-page-arrow"} src={landingPageArrowRight} alt={"Click to view more reviews."} />
            </div>


            <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>


        </div>
    }

    fetchFrench() {
        return <div className={"landing-page-review-container-v2"} id={"landing-page-review-container-v2-moblet"}>

            <div className={"landing-page-left-arrow-v2"} onClick={()=> this.reviewCarouselClicked("left")}>
                <img className={"landing-page-arrow"} src={landingPageArrowLeft} alt="Cliquez pour voir plus d'avis."/>
            </div>

            <div className={"landing-page-review-v2"}>
                <LazyLoad>
                    <img className={"customer-review-image"} src={this.state.featuredReviews[this.state.carouselNumber].img}/>
                </LazyLoad>
                <div className={"landing-page-reviewer-name-v2"}>
                    {this.state.featuredReviews[this.state.carouselNumber].reviewer}
                </div>
                <div className={"landing-page-review-content-v2"}>
                    {`"${this.state.featuredReviews[this.state.carouselNumber].reviewFr}"`}
                </div>
                <div className={"landing-page-review-stars-v2"}>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars}
                         alt={"CustomMat.ca Average Review Rating: 4.9/5.0. Over 5,000 Five Star Reviews"}
                    />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Note moyenne des avis de CustomMat.ca : 4.9/5.0"}/>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Note moyenne des avis de CustomMat.ca : 4.9/5.0"}/>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Note moyenne des avis de CustomMat.ca : 4.9/5.0"}/>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Note moyenne des avis de CustomMat.ca : 4.9/5.0"}/>
                </div>

                <a href={"/customer-reviews"} className={"landing-page-total-review-count-v2"}>
                   Parcourez plus de 5 000 avis 5 étoiles
                </a>
            </div>

            <div className={"landing-page-right-arrow-v2"} onClick={()=> this.reviewCarouselClicked("right")}>
                <img className={"landing-page-arrow"} src={landingPageArrowRight} alt="Cliquez pour voir plus d'avis"/>
            </div>


            <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>


        </div>

    }

    fetchGerman() {
        return <div className={"landing-page-review-container-v2"} id={"landing-page-review-container-v2-moblet"}>

            <div className={"landing-page-left-arrow-v2"} onClick={()=> this.reviewCarouselClicked("left")}>
                <img className={"landing-page-arrow"} src={landingPageArrowLeft} alt="Klicken Sie hier, um weitere Bewertungen zu sehen."/>
            </div>

            <div className={"landing-page-review-v2"}>
                <LazyLoad>
                    <img className={"customer-review-image"} src={this.state.featuredReviews[this.state.carouselNumber].img}/>
                </LazyLoad>
                <div className={"landing-page-reviewer-name-v2"}>
                    {this.state.featuredReviews[this.state.carouselNumber].reviewer}
                </div>
                <div className={"landing-page-review-content-v2"}>
                    {`"${this.state.featuredReviews[this.state.carouselNumber].reviewDe}"`}
                </div>
                <div className={"landing-page-review-stars-v2"}>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars}
                         alt={"CustomMat.ca Average Review Rating: 4.9/5.0. Over 5,000 Five Star Reviews"}
                    />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Durchschnittliche Bewertung der Bewertungen von CustomMat.ca: 4,9/5,0"}/>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Durchschnittliche Bewertung der Bewertungen von CustomMat.ca: 4,9/5,0"}/>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Durchschnittliche Bewertung der Bewertungen von CustomMat.ca: 4,9/5,0"}/>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Durchschnittliche Bewertung der Bewertungen von CustomMat.ca: 4,9/5,0"}/>
                </div>

                <a href={"/customer-reviews"} className={"landing-page-total-review-count-v2"}>
                    Durchstöbern Sie über 5.000 5-Sterne-Bewertungen
                </a>
            </div>

            <div className={"landing-page-right-arrow-v2"} onClick={()=> this.reviewCarouselClicked("right")}>
                <img className={"landing-page-arrow"} src={landingPageArrowRight} alt="Klicken Sie hier, um mehr Bewertungen zu sehen"/>
            </div>


            <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>


        </div>

    }

    fetchSpainish() {
        return <div className={"landing-page-review-container-v2"} id={"landing-page-review-container-v2-moblet"}>

            <div className={"landing-page-left-arrow-v2"} onClick={()=> this.reviewCarouselClicked("left")}>
                <img className={"landing-page-arrow"} src={landingPageArrowLeft} alt="Haga clic aquí para ver más reseñas." />
            </div>

            <div className={"landing-page-review-v2"}>
                <LazyLoad>
                    <img className={"customer-review-image"} src={this.state.featuredReviews[this.state.carouselNumber].img}/>
                </LazyLoad>
                <div className={"landing-page-reviewer-name-v2"}>
                    {this.state.featuredReviews[this.state.carouselNumber].reviewer}
                </div>
                <div className={"landing-page-review-content-v2"}>
                    {`"${this.state.featuredReviews[this.state.carouselNumber].reviewEs}"`}
                </div>
                <div className={"landing-page-review-stars-v2"}>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars}
                         alt={"Calificación promedio de reseñas de CustomMat.ca: 4.9/5.0. Más de 5,000 reseñas de cinco estrellas"}
                    />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Calificación promedio de reseñas de CustomMat.ca: 4.9/5.0"} />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Calificación promedio de reseñas de CustomMat.ca: 4.9/5.0"} />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Calificación promedio de reseñas de CustomMat.ca: 4.9/5.0"} />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Calificación promedio de reseñas de CustomMat.ca: 4.9/5.0"} />
                </div>

                <a href={"/customer-reviews"} className={"landing-page-total-review-count-v2"}>
                    Explora más de 5,000 reseñas de 5 estrellas
                </a>
            </div>

            <div className={"landing-page-right-arrow-v2"} onClick={()=> this.reviewCarouselClicked("right")}>
                <img className={"landing-page-arrow"} src={landingPageArrowRight} alt="Haga clic aquí para ver más reseñas" />
            </div>


            <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>


        </div>

    }

    fetchItalian() {
        return <div className={"landing-page-review-container-v2"} id={"landing-page-review-container-v2-moblet"}>

            <div className={"landing-page-left-arrow-v2"} onClick={()=> this.reviewCarouselClicked("left")}>
                <img className={"landing-page-arrow"} src={landingPageArrowLeft} alt="Clicca qui per vedere altre recensioni." />
            </div>

            <div className={"landing-page-review-v2"}>
                <LazyLoad>
                    <img className={"customer-review-image"} src={this.state.featuredReviews[this.state.carouselNumber].img}/>
                </LazyLoad>
                <div className={"landing-page-reviewer-name-v2"}>
                    {this.state.featuredReviews[this.state.carouselNumber].reviewer}
                </div>
                <div className={"landing-page-review-content-v2"}>
                    {`"${this.state.featuredReviews[this.state.carouselNumber].reviewIt}"`}
                </div>
                <div className={"landing-page-review-stars-v2"}>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars}
                         alt={"Valutazione media delle recensioni di CustomMat.ca: 4.9/5.0. Oltre 5.000 recensioni a 5 stelle"} />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Valutazione media delle recensioni di CustomMat.ca: 4.9/5.0"} />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Valutazione media delle recensioni di CustomMat.ca: 4.9/5.0"} />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Valutazione media delle recensioni di CustomMat.ca: 4.9/5.0"} />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Valutazione media delle recensioni di CustomMat.ca: 4.9/5.0"} />
                </div>

                <a href={"/customer-reviews"} className={"landing-page-total-review-count-v2"}>
                    Esplora oltre 5.000 recensioni a 5 stelle
                </a>
            </div>

            <div className={"landing-page-right-arrow-v2"} onClick={()=> this.reviewCarouselClicked("right")}>
                <img className={"landing-page-arrow"} src={landingPageArrowRight} alt="Clicca qui per vedere altre recensioni" />
            </div>

            <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>

        </div>
    }

    fetchDutch() {
        return <div className={"landing-page-review-container-v2"} id={"landing-page-review-container-v2-moblet"}>

            <div className={"landing-page-left-arrow-v2"} onClick={()=> this.reviewCarouselClicked("left")}>
                <img className={"landing-page-arrow"} src={landingPageArrowLeft} alt="Klik hier om meer beoordelingen te bekijken." />
            </div>

            <div className={"landing-page-review-v2"}>
                <LazyLoad>
                    <img className={"customer-review-image"} src={this.state.featuredReviews[this.state.carouselNumber].img}/>
                </LazyLoad>
                <div className={"landing-page-reviewer-name-v2"}>
                    {this.state.featuredReviews[this.state.carouselNumber].reviewer}
                </div>
                <div className={"landing-page-review-content-v2"}>
                    {`"${this.state.featuredReviews[this.state.carouselNumber].reviewNl}"`}
                </div>
                <div className={"landing-page-review-stars-v2"}>
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars}
                         alt={"Gemiddelde beoordeling van CustomMat.ca reviews: 4.9/5.0. Meer dan 5.000 5-sterren beoordelingen"} />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Gemiddelde beoordeling van CustomMat.ca reviews: 4.9/5.0"} />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Gemiddelde beoordeling van CustomMat.ca reviews: 4.9/5.0"} />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Gemiddelde beoordeling van CustomMat.ca reviews: 4.9/5.0"} />
                    <img className={"landing-page-review-stars"} src={landingPageReviewStars} alt={"Gemiddelde beoordeling van CustomMat.ca reviews: 4.9/5.0"} />
                </div>

                <a href={"/customer-reviews"} className={"landing-page-total-review-count-v2"}>
                    Ontdek meer dan 5.000 5-sterren beoordelingen
                </a>
            </div>

            <div className={"landing-page-right-arrow-v2"} onClick={()=> this.reviewCarouselClicked("right")}>
                <img className={"landing-page-arrow"} src={landingPageArrowRight} alt="Klik hier om meer beoordelingen te bekijken" />
            </div>

            <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>

        </div>
    }




    render() {
        if (this.props.country === "France") {
            return this.fetchFrench();
        } else if (this.props.country === "Germany") {
            return this.fetchGerman();
        } else if (this.props.country === "Spain") {
            return this.fetchSpainish();
        } else if (this.props.country === "Italy") {
            return this.fetchItalian();
        } else if (this.props.country === "Netherlands") {
            return this.fetchDutch();
        }

        else {
            return this.fetchEnglish()
        }
    }
}