import React from "react";
import {productDB} from "../comp-pricing/productDB";
import Tooltip from "@material-ui/core/Tooltip";
import closeIcon from "../../../resources/svgIcons/close-icon.svg";
import displayInputFields from "../app-matsize-rendering/components/displayInputFields";
import editShoppingCartIcon from "../../../resources/svgIcons/shopping-bag-edit.svg";
import logoForSample from "../../../resources/logo/comp-logo-v2.svg";

export default class FetchItemList extends React.Component {

    getProductTypeName(prodCode, canvasDrawing) {
        if (canvasDrawing) {
            return this.props.fetchLanguage("Passe-partout sur Mesure","Custom Designed Matboard")
        } else if (prodCode === "matboardOnly") {
            return this.props.fetchLanguage("Passe-partout Personnalisé","Custom Matboard")
        } else if (prodCode === "precutMat") {
            return this.props.fetchLanguage("Passepartout de taille standard","Standard Sized Matboard")
        } else if (prodCode === "backingMat") {
            return this.props.fetchLanguage("Support / Montage Passe-partout","Backing / Mounting Matboard")
        } else if (prodCode === "certMat") {
            return this.props.fetchLanguage("Passepartout de certificat","Certificate Matboard")
        } else if (prodCode === "sampleRequest") {
            return this.props.fetchLanguage("Échantillons de passe-partouts","Matboard Samples")
        } else if (prodCode === "polaroidFrame") {
            return "Polaroid Frame"
        } else {
            return "Frame + Custom Mat"
        }
    };
    
    fetchSecondMatDetails(item) {
        //here needs to show the secondary matboard details
        if (item.secondMatColour !== "none" && item.secondMatColour !== "not selected") {
            return <div className={"cart-slider-second-mat"}>
                <div className={"cart-slider-second-mat-item-details"}>
                    <div className={"cart-slider-second-mat-subtitle"}>
                        {this.props.fetchLanguage("Passe-partout Double", "Double Mat")}<br/>
                        {productDB.matboards[item.secondMatColour].color}
                    </div>
                </div>

                <div className={"cart-slider-second-mat-item-price"}>
                    <div className={"cart-slider-shopped-item-total"}>
                        +${parseFloat(this.props.fxConversion(item.secondMatPrice * this.props.getDiscountMultiplier())).toFixed(2)}
                    </div>
                </div>
            </div>
        }
    };

    fetchTextOptions(item) {
        if (item.textAddition) {
            return <div className={"cart-slider-second-mat"}>
                <div className={"cart-slider-second-mat-item-details"}>
                    <div className={"cart-slider-second-mat-subtitle"}>
                        {this.props.fetchLanguage("Texte ajouté", "Add Text")}<br/>
                    </div>
                </div>

                <div className={"cart-slider-second-mat-item-price"}>
                    <div className={"cart-slider-shopped-item-total"}>
                        +${parseFloat(this.props.fxConversion(item.textAdditionPrice * this.props.getDiscountMultiplier())).toFixed(2)}
                    </div>
                </div>
            </div>
        }
    }

    fetchLogoOptions(item) {
        if (item.logoAdd) {
            return <div className={"cart-slider-second-mat"}>
                <div className={"cart-slider-second-mat-item-details"}>
                    <div className={"cart-slider-second-mat-subtitle"}>
                        {this.props.fetchLanguage("Ajouter un logo", "Add Logo")}<br/>
                    </div>
                </div>

                <div className={"cart-slider-second-mat-item-price"}>
                    <div className={"cart-slider-shopped-item-total"}>
                        +${parseFloat(this.props.fxConversion(item.logoAdditionPrice * this.props.getDiscountMultiplier())).toFixed(2)}
                    </div>
                </div>
            </div>
        }
    }



    fetchBackingMatDetails(item) {
        if (item.backingMatboard) {
            return <div className={"cart-slider-second-mat"}>
                    <div className={"cart-slider-second-mat-item-details"}>
                        <div className={"cart-slider-second-mat-subtitle"}>
                            {this.props.fetchLanguage("Dos du Passe-partout", "Backing Mat")}
                        </div>
                        {/*<div className={"cart-slider-shopped-item-subtitle"}>*/}
                        {/*    Same size*/}
                        {/*</div>*/}
                        {/*<div className={"cart-slider-shopped-item-subtitle"}>*/}
                        {/*    No window opening*/}
                        {/*</div>*/}
                    </div>

                    <div className={"cart-slider-second-mat-item-price"}>
                        {/*<div className={"cart-slider-shopped-item-total"} style={this.props.getDiscountMultiplier() !== 1 ? {textDecoration: "line-through", color: "#F59276"} : {}}>*/}
                        {/*    + ${parseFloat(item.secondMatPrice).toFixed(2)}*/}
                        {/*</div>*/}
                        <div className={"cart-slider-shopped-item-total"}>
                            +${parseFloat(this.props.fxConversion(item.secondMatPrice * this.props.getDiscountMultiplier())).toFixed(2)}
                        </div>
                    </div>
            </div>
        }
    }

    fetchWindowDetails(item) {
        if (item.canvasDrawing) {
            return <div className={"custom-mat-design-diagram"}>
                <img src={item.canvasDrawing} alt={this.props.fetchLanguage("Détails de dessin personnalisés.", "Custom drawing details.")}/>
            </div>
        }
    };

    fetchNoteDetails(item) {
        if (item.additionalNoteContent !== "" && item.additionalNoteContent !== undefined) {
            return <div className={"fetch-note-details-container"}>
                <div className="cart-slider-shopped-item-title">
                    {this.props.fetchLanguage("Notes Supplémentaires", "Additional Notes")}
                </div>
                <div className={"fetch-note-details-container-item-detail"}>{item.additionalNoteContent}</div>
            </div>
        } else {
            return null
        }
    };

    fetchOffCenterWindowDetails(item) {
        return <div className={item.window.rowCount * item.window.columnCount === 1 ? null : "no-display"}>
            <div className={((item.window.window1.marginTop !== item.window.window1.marginBottom) || (item.window.window1.marginLeft !== item.window.window1.marginRight)) ? "display" : "no-display"}>
                <div className={"cart-slider-shopped-item-title"}>Off-centre Window</div>
                <div className={"cart-slider-shopped-item-subtitle"}>
                    {this.props.fetchLanguage("H", "T")}<span>: </span>{item.window.window1.marginTop}<span> </span>
                    {this.props.fetchLanguage("B", "B")}<span>: </span>{item.window.window1.marginBottom}<span> </span>
                    {this.props.fetchLanguage("G", "L")}<span>: </span>{item.window.window1.marginLeft}<span> </span>
                    {this.props.fetchLanguage("D", "R")}<span>: </span>{item.window.window1.marginRight}<span> </span>
                    {this.props.fetchLanguage("PO", "IN")}

                </div>
            </div>
        </div>
    };

    fetchAdditionalMatHeader(item) {
        if ((item.secondMatColour !== "none" && item.secondMatColour !== "not selected") || item.backingMatboard) {
            return <div className={"cart-slider-second-mat"}>
                <div className={"cart-slider-shopped-item-subheading"}>
                    {this.props.fetchLanguage("Passe-partouts Supplémentaires", "Additional Option")}
                </div>
            </div>
        }
    }
    //
    // fetchAdditionalMatOrderDetails(item) {
    //     return <div style={{width: "100%", overflow: "auto"}}>
    //
    // cart-slider-shopped-item-quantity-button
    //
    //     </div>
    // };

    fetchForms() {
        if (this.props.shoppingCartItems.length > 1) {
            // do nothing
        } else {
            if (this.props.french) {
                return <div style={{textTransform: "none"}}>
                    Si vous avez seulement besoin des échantillons, {<a href="https://docs.google.com/forms/d/1iLljfvNlrLclrYXPgLtki7PDhvSjNUX2DJYFrZpuXjI" target={"_blank"}><u>cliquez ici.</u></a>}
                </div>
            } else {
                return <div style={{textTransform: "none"}}>
                    If you only need the samples, {<a href="https://docs.google.com/forms/d/1iLljfvNlrLclrYXPgLtki7PDhvSjNUX2DJYFrZpuXjI" target={"_blank"}><u>please click here.</u></a>}
                </div>
            }
        }
    }

    render() {

        let confirmationPage = (window.location.pathname === "/confirmation" || window.location.pathname === "/confirmation-fr" || this.props.disallowQuantityAdjust) ?  true : false;

        if (this.props.shoppingCartItems) {

            return this.props.shoppingCartItems.map((item, key) => {

                var productType = this.getProductTypeName(item.prodType, item.canvasDrawing);

                var price = parseFloat(item.price * this.props.getDiscountMultiplier())
                var priceCurrencyAdjusted = this.props.fxConversion(price);


                if (item.prodType === "matboardOnly") {

                    var matOrientation;

                    var windowOrientation;

                    var matWidth = parseFloat(item.frameWidth)

                    var matHeight = parseFloat(item.frameHeight)

                    var windowWidth = parseFloat(item.window.window1.width);

                    var windowHeight = parseFloat(item.window.window1.height);

                    var sameOrientation = true;

                    if(Number.isInteger(item.orderQuantity) === false) {
                        this.props.updateItemList(key, 1)
                    }


                    if (matWidth > matHeight) {
                        matOrientation = "landscape";
                    } else if (matWidth === matHeight) {
                        matOrientation = "square";
                    } else if (matWidth < matHeight) {
                        matOrientation = "portrait";
                    }

                    if (windowWidth > windowHeight) {
                        windowOrientation = "landscape";
                    } else if (windowWidth === windowHeight) {
                        windowOrientation = "square";
                    } else if (windowWidth < windowHeight) {
                        windowOrientation = "portrait";
                    }

                    if (matOrientation !== windowOrientation && matOrientation !=="square" && windowOrientation !== "square") {
                        sameOrientation = false;
                    }

                    return <div className={"cart-slider-shopped-item-container"} key={`cart-shopped-item-${key}`}>
                        <div className={"cart-slider-shopped-item-cover"}>
                            <div style={{width: "100%", overflow: "auto"}}>
                                <img className={"cart-slider-shopped-item-left"}
                                     src={productDB.matboards[item.matColour].sample}
                                     alt={`${productDB.matboards[item.matColour].color} colour`}
                                />
                                <img className={item.canvasDrawing ? "custom-design-matboard-sign" : "no-display"} src={editShoppingCartIcon} alt={"Remove this item from the cart."}/>


                                <div className={"cart-slider-shopped-item-center"}>
                                    <div className={"cart-slider-shopped-item-center-center"}>
                                        <div className={"navbar-column-title-checkout-section"}>
                                            {productType}
                                        </div>
                                        <div className={"cart-slider-shopped-item-title"}>
                                            {this.props.fetchLanguage("Taille du Passe-partout", "Matboard Size")}

                                        </div>
                                        <div className={"cart-slider-shopped-item-subtitle"}>
                                            {item.frameWidth} x {item.frameHeight} {this.props.fetchLanguage("PO", "IN")}
                                        </div>
                                        <div className={"cart-slider-shopped-item-title"}>
                                            {this.props.fetchLanguage("Couleur", `${this.props.location === "us" ? "Color" : "Colour"}`)}
                                        </div>
                                        <div className={"cart-slider-shopped-item-subtitle"}>
                                            {this.props.french ? productDB.matboards[item.matColour].colorFr : productDB.matboards[item.matColour].color}
                                        </div>
                                    </div>

                                    <div className={"cart-slider-shopped-item-right"}>
                                        <div className={"cart-slider-shopped-item-total"}>
                                            ${parseFloat(priceCurrencyAdjusted).toFixed(2)}
                                        </div>
                                    </div>

                                </div>

                                <div className={"cart-slider-second-mat-container"}>
                                    <div>
                                        {this.fetchAdditionalMatHeader(item)}
                                        {this.fetchSecondMatDetails(item)}
                                        {this.fetchBackingMatDetails(item)}
                                        {this.fetchTextOptions(item)}
                                        {this.fetchLogoOptions(item)}

                                        <div className={item.canvasDrawing ? "no-display" : "cart-slider-shopped-item-title"}>
                                            {this.props.fetchLanguage("Taille de Fenêtre", "Window Size")}
                                        </div>

                                        <div className={item.canvasDrawing ? "no-display" : "cart-slider-shopped-item-subtitle"}>
                                            {item.window.window1.width} x {item.window.window1.height} {this.props.fetchLanguage("PO", "IN")}
                                        </div>

                                        <div className={sameOrientation ? "no-display" : "cart-slider-shopped-item-title"} style={{color: "#B33A3A", textTransform: "none"}}>
                                            Please note your matboard will be <u>{matOrientation}</u>, and the window will be <u>{windowOrientation}</u>.
                                        </div>

                                        {this.fetchOffCenterWindowDetails(item)}

                                        {this.fetchWindowDetails(item)}

                                        <div className={item.isWindowOval ? "cart-slider-shopped-item-subtitle" : "no-display"}>
                                            {this.props.fetchLanguage("Fenêtres Arrondies", "Round Windows")}

                                        </div>
                                        <div className={item.window.rowCount * item.window.columnCount < 2 ? "no-display" : "cart-slider-shopped-item-subtitle"}>
                                            {item.window.rowCount * item.window.columnCount} {this.props.fetchLanguage("Fenêtres", "Windows")}
                                        </div>

                                        {this.fetchNoteDetails(item)}

                                        {/*<div className={item.canvasDrawing ? "cart-slider-shopped-item-subtitle" : "no-display"}>*/}
                                        {/*    Custom Design (Free)*/}
                                        {/*</div>*/}
                                    </div>
                                </div>

                            </div>

                            <div className={"cart-slider-item-controller"}>
                                <div className={"cart-slider-shopped-item-quantity"}>

                                    <div className={confirmationPage ? "no-display" : "cart-slider-shopped-item-quantity-button"} onClick={() => this.props.updateItemList(key, "add")}>+</div>
                                    <div className={confirmationPage ? "no-display"  : "select-window-multiple-window-dimensions"}>
                                        {/*{item.orderQuantity}*/}
                                        {displayInputFields("", item.orderQuantity, (event) => this.props.updateItemList(key, event.target.value))}
                                    </div>
                                    <div className={confirmationPage ? "select-window-multiple-window-dimensions" : "no-display"} style={{marginLeft: "0"}}>
                                        <div className={"cart-slider-shopped-item-title"}>{this.props.french ? "Quantité" : "Quantity"}</div>
                                        <div className={"cart-slider-shopped-item-subtitle"}>{item.orderQuantity}</div>
                                    </div>
                                    <div className={confirmationPage ? "no-display" : "cart-slider-shopped-item-quantity-button"} id={"cart-slider-shopped-item-quantity-button-right"} onClick={() => this.props.updateItemList(key, "reduce")}>-</div>

                                </div>

                                <div className={confirmationPage ? "no-display" : "cart-slider-shopped-item-delete"} onClick={() => this.props.updateItemList(key, "remove")}>
                                    <Tooltip title={"Remove this item from the cart."} placement={"top"}>
                                        <img className={`close-add-image-modal-button`} src={closeIcon} alt={"Remove this item from the cart."}/>
                                    </Tooltip>
                                </div>

                                {/*<div className={confirmationPage ? "no-display"  : "cart-slider-shopped-item-delete"} onClick={() => this.props.updateItemList(key, "remove")}>*/}
                                {/*    <Tooltip title={"Edit shopping cart icon."} placement={"top"}>*/}
                                {/*        <img className={`close-add-image-modal-button`} src={editShoppingCartIcon} alt={"Remove this item from the cart."}/>*/}
                                {/*    </Tooltip>*/}
                                {/*</div>*/}

                            </div>
                        </div>
                    </div>
                }

                else if (item.prodType === "precutMat") {
                    return <div className={"cart-slider-shopped-item-container"} key={`cart-shopped-item-${key}`}>
                        <div className={"cart-slider-shopped-item-cover"}>
                            <div style={{width: "100%", overflow: "auto"}}>
                                <img className={"cart-slider-shopped-item-left"}
                                     src={productDB.matboards[item.matColour].sample}
                                     alt={`${productDB.matboards[item.matColour].color} colour`}
                                />
                                <img className={item.canvasDrawing ? "custom-design-matboard-sign" : "no-display"} src={editShoppingCartIcon} alt={"Remove this item from the cart."}/>


                                <div className={"cart-slider-shopped-item-center"}>
                                    <div className={"cart-slider-shopped-item-center-center"}>
                                        <div className={"navbar-column-title-checkout-section"}>
                                            {productType}
                                        </div>
                                        <div className={"cart-slider-shopped-item-title"}>
                                            {this.props.fetchLanguage("Taille du Passe-partout", "Matboard Size")}

                                        </div>
                                        <div className={"cart-slider-shopped-item-subtitle"}>
                                            {item.frameWidth} x {item.frameHeight} {this.props.fetchLanguage("PO", "IN")}
                                        </div>
                                        <div className={"cart-slider-shopped-item-title"}>
                                            {this.props.fetchLanguage("Couleur", `${this.props.location === "us" ? "Color" : "Colour"}`)}

                                        </div>
                                        <div className={"cart-slider-shopped-item-subtitle"}>
                                            {this.props.french ? productDB.matboards[item.matColour].colorFr : productDB.matboards[item.matColour].color}
                                        </div>
                                    </div>

                                    <div className={"cart-slider-shopped-item-right"}>
                                        <div className={"cart-slider-shopped-item-total"}>
                                            ${parseFloat(priceCurrencyAdjusted).toFixed(2)}
                                        </div>
                                    </div>

                                </div>

                                <div className={"cart-slider-second-mat-container"}>
                                    <div>
                                        {this.fetchAdditionalMatHeader(item)}
                                        {this.fetchSecondMatDetails(item)}
                                        {this.fetchBackingMatDetails(item)}

                                        <div className={item.canvasDrawing ? "no-display" : "cart-slider-shopped-item-title"}>
                                            {this.props.fetchLanguage("Taille de Fenêtre", "Window Size")}
                                        </div>
                                        <div className={item.canvasDrawing ? "no-display" : "cart-slider-shopped-item-subtitle"}>
                                            {item.window.window1.width} x {item.window.window1.height} {this.props.fetchLanguage("PO", "IN")}
                                        </div>

                                        {this.fetchOffCenterWindowDetails(item)}

                                        {this.fetchWindowDetails(item)}

                                        <div className={item.isWindowOval ? "cart-slider-shopped-item-subtitle" : "no-display"}>
                                            {this.props.fetchLanguage("Fenêtres Arrondies", "Round Windows")}

                                        </div>
                                        <div className={item.window.rowCount * item.window.columnCount < 2 ? "no-display" : "cart-slider-shopped-item-subtitle"}>
                                            {item.window.rowCount * item.window.columnCount} {this.props.fetchLanguage("Fenêtres", "Windows")}
                                        </div>

                                        {this.fetchNoteDetails(item)}

                                        {/*<div className={item.canvasDrawing ? "cart-slider-shopped-item-subtitle" : "no-display"}>*/}
                                        {/*    Custom Design (Free)*/}
                                        {/*</div>*/}
                                    </div>
                                </div>

                            </div>

                            <div className={"cart-slider-item-controller"}>
                                <div className={"cart-slider-shopped-item-quantity"}>

                                    <div className={confirmationPage ? "no-display" : "cart-slider-shopped-item-quantity-button"} onClick={() => this.props.updateItemList(key, "add")}>+</div>
                                    <div className={confirmationPage ? "no-display"  : "select-window-multiple-window-dimensions"}>
                                        {/*{item.orderQuantity}*/}
                                        {displayInputFields("", item.orderQuantity, (event) => this.props.updateItemList(key, event.target.value))}
                                    </div>
                                    <div className={confirmationPage ? "select-window-multiple-window-dimensions" : "no-display"} style={{marginLeft: "0"}}>
                                        <div className={"cart-slider-shopped-item-title"}>{this.props.french ? "Quantité" : "Quantity"}</div>
                                        <div className={"cart-slider-shopped-item-subtitle"}>{item.orderQuantity}</div>
                                    </div>
                                    <div className={confirmationPage ? "no-display" : "cart-slider-shopped-item-quantity-button"} id={"cart-slider-shopped-item-quantity-button-right"} onClick={() => this.props.updateItemList(key, "reduce")}>-</div>

                                </div>

                                <div className={confirmationPage ? "no-display" : "cart-slider-shopped-item-delete"} onClick={() => this.props.updateItemList(key, "remove")}>
                                    <Tooltip title={"Remove this item from the cart."} placement={"top"}>
                                        <img className={`close-add-image-modal-button`} src={closeIcon} alt={"Remove this item from the cart."}/>
                                    </Tooltip>
                                </div>

                                {/*<div className={confirmationPage ? "no-display"  : "cart-slider-shopped-item-delete"} onClick={() => this.props.updateItemList(key, "remove")}>*/}
                                {/*    <Tooltip title={"Edit shopping cart icon."} placement={"top"}>*/}
                                {/*        <img className={`close-add-image-modal-button`} src={editShoppingCartIcon} alt={"Remove this item from the cart."}/>*/}
                                {/*    </Tooltip>*/}
                                {/*</div>*/}

                            </div>
                        </div>
                    </div>
                }

                else if (item.prodType === "certMat") {
                    return <div className={"cart-slider-shopped-item-container"} key={`cart-shopped-item-${key}`}>
                        <div className={"cart-slider-shopped-item-cover"}>
                            <div style={{width: "100%", overflow: "auto"}}>
                                <img className={"cart-slider-shopped-item-left"}
                                     src={productDB.matboards[item.matColour].sample}
                                     alt={`${productDB.matboards[item.matColour].color} colour`}
                                />
                                <img className={item.canvasDrawing ? "custom-design-matboard-sign" : "no-display"} src={editShoppingCartIcon} alt={"Remove this item from the cart."}/>


                                <div className={"cart-slider-shopped-item-center"}>
                                    <div className={"cart-slider-shopped-item-center-center"}>
                                        <div className={"navbar-column-title-checkout-section"}>
                                            {productType}
                                        </div>
                                        <div className={"cart-slider-shopped-item-title"}>
                                            {this.props.fetchLanguage("Taille du Passe-partout", "Matboard Size")}

                                        </div>
                                        <div className={"cart-slider-shopped-item-subtitle"}>
                                            {item.frameWidth} x {item.frameHeight} {this.props.fetchLanguage("PO", "IN")}
                                        </div>
                                        <div className={"cart-slider-shopped-item-title"}>
                                            {this.props.fetchLanguage("Couleur", `${this.props.location === "us" ? "Color" : "Colour"}`)}

                                        </div>
                                        <div className={"cart-slider-shopped-item-subtitle"}>
                                            {productDB.matboards[item.matColour].color}
                                        </div>
                                    </div>

                                    <div className={"cart-slider-shopped-item-right"}>
                                        <div className={"cart-slider-shopped-item-total"}>
                                            ${parseFloat(priceCurrencyAdjusted).toFixed(2)}
                                        </div>
                                    </div>

                                </div>

                                <div className={"cart-slider-second-mat-container"}>
                                    <div>
                                        {this.fetchAdditionalMatHeader(item)}
                                        {this.fetchSecondMatDetails(item)}
                                        {this.fetchBackingMatDetails(item)}
                                        {this.fetchTextOptions(item)}
                                        {this.fetchLogoOptions(item)}

                                        <div className={item.canvasDrawing ? "no-display" : "cart-slider-shopped-item-title"}>
                                            {this.props.fetchLanguage("Taille de Fenêtre", "Window Size")}

                                        </div>
                                        <div className={item.canvasDrawing ? "no-display" : "cart-slider-shopped-item-subtitle"}>
                                            {item.window.window1.width} x {item.window.window1.height} {this.props.fetchLanguage("PO", "IN")}
                                        </div>

                                        {this.fetchOffCenterWindowDetails(item)}

                                        {this.fetchWindowDetails(item)}

                                        <div className={item.isWindowOval ? "cart-slider-shopped-item-subtitle" : "no-display"}>
                                            {this.props.fetchLanguage("Fenêtres Arrondies", "Round Windows")}

                                        </div>
                                        <div className={item.window.rowCount * item.window.columnCount < 2 ? "no-display" : "cart-slider-shopped-item-subtitle"}>
                                            {item.window.rowCount * item.window.columnCount} {this.props.fetchLanguage("Fenêtres", "Windows")}
                                        </div>

                                        {this.fetchNoteDetails(item)}

                                        {/*<div className={item.canvasDrawing ? "cart-slider-shopped-item-subtitle" : "no-display"}>*/}
                                        {/*    Custom Design (Free)*/}
                                        {/*</div>*/}
                                    </div>
                                </div>

                            </div>

                            <div className={"cart-slider-item-controller"}>
                                <div className={"cart-slider-shopped-item-quantity"}>

                                    <div className={confirmationPage ? "no-display" : "cart-slider-shopped-item-quantity-button"} onClick={() => this.props.updateItemList(key, "add")}>+</div>
                                    <div className={confirmationPage ? "no-display"  : "select-window-multiple-window-dimensions"}>
                                        {displayInputFields("", item.orderQuantity, (event) => this.props.updateItemList(key, event.target.value))}
                                    </div>
                                    <div className={confirmationPage ? "select-window-multiple-window-dimensions" : "no-display"} style={{marginLeft: "0"}}>
                                        <div className={"cart-slider-shopped-item-title"}>{this.props.french ? "Quantité" : "Quantity"}</div>
                                        <div className={"cart-slider-shopped-item-subtitle"}>{item.orderQuantity}</div>
                                    </div>
                                    <div className={confirmationPage ? "no-display" : "cart-slider-shopped-item-quantity-button"} id={"cart-slider-shopped-item-quantity-button-right"} onClick={() => this.props.updateItemList(key, "reduce")}>-</div>

                                </div>

                                <div className={confirmationPage ? "no-display" : "cart-slider-shopped-item-delete"} onClick={() => this.props.updateItemList(key, "remove")}>
                                    <Tooltip title={"Remove this item from the cart."} placement={"top"}>
                                        <img className={`close-add-image-modal-button`} src={closeIcon} alt={"Remove this item from the cart."}/>
                                    </Tooltip>
                                </div>

                                {/*<div className={confirmationPage ? "no-display"  : "cart-slider-shopped-item-delete"} onClick={() => this.props.updateItemList(key, "remove")}>*/}
                                {/*    <Tooltip title={"Edit shopping cart icon."} placement={"top"}>*/}
                                {/*        <img className={`close-add-image-modal-button`} src={editShoppingCartIcon} alt={"Remove this item from the cart."}/>*/}
                                {/*    </Tooltip>*/}
                                {/*</div>*/}

                            </div>
                        </div>
                    </div>
                }

                else if (item.prodType === "backingMat") {
                    return <div className={"cart-slider-shopped-item-container"} key={`cart-shopped-item-${key}`}>
                        <div className={"cart-slider-shopped-item-cover"}>
                            <div style={{width: "100%", overflow: "auto"}}>
                                <img className={"cart-slider-shopped-item-left"}
                                     src={productDB.matboards[item.matColour].sample}
                                     alt={`${productDB.matboards[item.matColour].color} colour`}
                                />
                                <img className={item.canvasDrawing ? "custom-design-matboard-sign" : "no-display"} src={editShoppingCartIcon} alt={"Remove this item from the cart."}/>


                                <div className={"cart-slider-shopped-item-center"}>
                                    <div className={"cart-slider-shopped-item-center-center"}>
                                        <div className={"navbar-column-title-checkout-section"}>
                                            {productType}
                                        </div>
                                        <div className={"cart-slider-shopped-item-title"}>
                                            {this.props.fetchLanguage("Taille du Passe-partout", "Matboard Size")}

                                        </div>
                                        <div className={"cart-slider-shopped-item-subtitle"}>
                                            {item.frameWidth} x {item.frameHeight} {this.props.fetchLanguage("PO", "IN")}
                                        </div>
                                        <div className={"cart-slider-shopped-item-title"}>
                                            {this.props.fetchLanguage("Couleur", `${this.props.location === "us" ? "Color" : "Colour"}`)}

                                        </div>
                                        <div className={"cart-slider-shopped-item-subtitle"}>
                                            {productDB.matboards[item.matColour].color}
                                        </div>
                                    </div>

                                    <div className={"cart-slider-shopped-item-right"}>
                                        <div className={"cart-slider-shopped-item-total"}>
                                            ${parseFloat(priceCurrencyAdjusted).toFixed(2)}
                                        </div>
                                    </div>

                                </div>

                                {/*<div className={"cart-slider-second-mat-container"}>*/}
                                {/*    <div>*/}
                                {/*        {this.fetchAdditionalMatHeader(item)}*/}
                                {/*        {this.fetchSecondMatDetails(item)}*/}
                                {/*        {this.fetchBackingMatDetails(item)}*/}

                                {/*        <div className={item.canvasDrawing ? "no-display" : "cart-slider-shopped-item-title"}>*/}
                                {/*            {this.props.fetchLanguage("Taille de Fenêtre", "Window Size")}*/}

                                {/*        </div>*/}
                                {/*        <div className={item.canvasDrawing ? "no-display" : "cart-slider-shopped-item-subtitle"}>*/}
                                {/*            {item.window.window1.width} x {item.window.window1.height} {this.props.fetchLanguage("PO", "IN")}*/}
                                {/*        </div>*/}

                                {/*        {this.fetchOffCenterWindowDetails(item)}*/}

                                {/*        {this.fetchWindowDetails(item)}*/}

                                {/*        <div className={item.isWindowOval ? "cart-slider-shopped-item-subtitle" : "no-display"}>*/}
                                {/*            {this.props.fetchLanguage("Fenêtres Arrondies", "Round Windows")}*/}

                                {/*        </div>*/}
                                {/*        <div className={item.window.rowCount * item.window.columnCount < 2 ? "no-display" : "cart-slider-shopped-item-subtitle"}>*/}
                                {/*            {item.window.rowCount * item.window.columnCount} {this.props.fetchLanguage("Fenêtres", "Windows")}*/}
                                {/*        </div>*/}

                                {/*        {this.fetchNoteDetails(item)}*/}

                                {/*        /!*<div className={item.canvasDrawing ? "cart-slider-shopped-item-subtitle" : "no-display"}>*!/*/}
                                {/*        /!*    Custom Design (Free)*!/*/}
                                {/*        /!*</div>*!/*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                            </div>

                            <div className={"cart-slider-item-controller"}>
                                <div className={"cart-slider-shopped-item-quantity"}>

                                    <div className={confirmationPage ? "no-display" : "cart-slider-shopped-item-quantity-button"} onClick={() => this.props.updateItemList(key, "add")}>+</div>
                                    <div className={confirmationPage ? "no-display"  : "select-window-multiple-window-dimensions"}>
                                        {displayInputFields("", item.orderQuantity, (event) => this.props.updateItemList(key, event.target.value))}
                                    </div>
                                    <div className={confirmationPage ? "select-window-multiple-window-dimensions" : "no-display"} style={{marginLeft: "0"}}>
                                        <div className={"cart-slider-shopped-item-title"}>{this.props.french ? "Quantité" : "Quantity"}</div>
                                        <div className={"cart-slider-shopped-item-subtitle"}>{item.orderQuantity}</div>
                                    </div>
                                    <div className={confirmationPage ? "no-display" : "cart-slider-shopped-item-quantity-button"} id={"cart-slider-shopped-item-quantity-button-right"} onClick={() => this.props.updateItemList(key, "reduce")}>-</div>

                                </div>

                                <div className={confirmationPage ? "no-display" : "cart-slider-shopped-item-delete"} onClick={() => this.props.updateItemList(key, "remove")}>
                                    <Tooltip title={"Remove this item from the cart."} placement={"top"}>
                                        <img className={`close-add-image-modal-button`} src={closeIcon} alt={"Remove this item from the cart."}/>
                                    </Tooltip>
                                </div>

                                {/*<div className={confirmationPage ? "no-display"  : "cart-slider-shopped-item-delete"} onClick={() => this.props.updateItemList(key, "remove")}>*/}
                                {/*    <Tooltip title={"Edit shopping cart icon."} placement={"top"}>*/}
                                {/*        <img className={`close-add-image-modal-button`} src={editShoppingCartIcon} alt={"Remove this item from the cart."}/>*/}
                                {/*    </Tooltip>*/}
                                {/*</div>*/}

                            </div>
                        </div>
                    </div>
                }

                else if (item.prodType === "sampleRequest") {
                    return <div className={"cart-slider-shopped-item-container"} key={`cart-shopped-item-${key}`}>
                        <div className={"cart-slider-shopped-item-cover"}>
                            <img className={"cart-slider-shopped-item-left"}
                                 style={{border: "none"}}
                                 src={"https://www.custommat.ca/static/media/bevelCut.f4f308ab.png"}
                                 alt={`Colour palette of our matboards`}
                            />
                            <div className={"cart-slider-shopped-item-center"}>
                                <div className={"cart-slider-shopped-item-center-center"}>
                                    <div className={"navbar-column-title-checkout-section"}>
                                        {this.props.fetchLanguage("Échantillons de passe-partouts", "Matboard Samples")}
                                    </div>

                                    <div className={"cart-slider-shopped-item-title"}>
                                        {this.props.fetchLanguage("Toutes nos 26 couleurs", `${this.props.location === "us" ? "All our our 26 colors" : "All our our 26 colours"}`)}<br/><br/>
                                        {this.fetchForms()}
                                    </div>

                                </div>

                                <div className={"cart-slider-shopped-item-right"}>
                                    <div className={"cart-slider-shopped-item-total"}>
                                        {this.props.fetchLanguage("Gratuit", "Free")}
                                    </div>
                                </div>

                            </div>

                            <div className={"cart-slider-shopped-item-delete"} onClick={() => this.props.updateItemList(key, "remove")}>
                                <Tooltip title={"Remove this item from the cart."} placement={"top"}>
                                    <img className={`close-add-image-modal-button`} src={closeIcon} alt={"Remove this item from the cart."}/>
                                </Tooltip>
                            </div>

                        </div>



                    </div>
                }
                else {
                    return <div className={"cart-slider-shopped-item-container"} key={`cart-shopped-item-${key}`}>
                        <div className={"cart-slider-shopped-item-cover"}>
                            <div style={{width: "100%", overflow: "auto"}}>
                                <img className={"cart-slider-shopped-item-left"} alt={"This is how product looks."} src={productDB.frame.frameLists[item.frameType].png}/>
                                <div className={"cart-slider-shopped-item-center"}>
                                    <div className={"navbar-column-title-checkout-section"}>
                                        {productType}
                                    </div>
                                    <div className={"cart-slider-shopped-item-title"}>
                                        {productDB[item.prodType].frameLists[item.frameType].productTitle}
                                    </div>
                                    <div className={"cart-slider-shopped-item-title"}>
                                        Frame
                                    </div>
                                    <div className={"cart-slider-shopped-item-subtitle"}>
                                        {item.frameWidth} x {item.frameHeight} IN
                                    </div>
                                    <div className={"cart-slider-shopped-item-subtitle"}>
                                        <img className={"matboard-selected-colour"} alt={"This is how matboard product looks."} src={productDB.matboards[item.matColour].img}/>
                                    </div>

                                    <div className={item.canvasDrawing ? "no-display" : null}>
                                        <div className={"cart-slider-shopped-item-title"}>
                                            Window
                                        </div>
                                        <div className={"cart-slider-shopped-item-subtitle"}>
                                            {item.window.window1.width} x {item.window.window1.height} in
                                        </div>
                                        <div className={"cart-slider-shopped-item-subtitle"}>
                                            {item.window.rowCount * item.window.columnCount} Windows
                                        </div>
                                        <div className={item.isWindowOval ? "cart-slider-shopped-item-subtitle" : "no-display"}>
                                            Circle / Oval Window
                                        </div>
                                    </div>

                                </div>
                                <div className={"cart-slider-shopped-item-right"}>

                                    <div className={"cart-slider-shopped-item-total"}>
                                        ${parseFloat(this.props.fxConversion(item.price * this.props.getDiscountMultiplier())).toFixed(2)}
                                    </div>


                                    <div className={"cart-slider-shopped-item-delete"} onClick={() => this.props.updateItemList(key, "remove")}>
                                        <Tooltip title={"Remove this item from the cart."} placement={"top"}>
                                            <img className={`close-add-image-modal-button`} alt={"Remove this item from the cart."} src={closeIcon}/>
                                        </Tooltip>
                                    </div>
                                    <div className={"cart-slider-shopped-item-quantity"}>
                                        <span className={confirmationPage ? "no-display" : "display"} onClick={() => this.props.updateItemList(key, "reduce")}>-</span>
                                        {item.orderQuantity}
                                        <span className={confirmationPage ? "no-display" : "display"} onClick={() => this.props.updateItemList(key, "add")} >+</span>
                                    </div>


                                </div>
                            </div>
                            {/*{this.fetchAdditionalMatOrderDetails(item)}*/}
                        </div>
                    </div>
                }
            });
        } else {
            return null
        }
    }
}