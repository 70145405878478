import React from 'react';
import photo1 from "../../../resources/images/blog/frameMat/Capture.PNG"
import photo2 from "../../../resources/images/blog/matboard/Capture.PNG";
import photo3 from "../../../resources/images/blog/photoset2/photo1.jpg";
import photo4 from "../../../resources/images/blog/photoset2/photo8.png";
import photo5 from "../../../resources/images/blog/photoset2/photo9.jpg";



import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";
import backgorundPhoto from "../../../resources/images/blog/oversizedMatRamsborgFrames/IMG_3419.JPG";

export default class WholesaleMatboardSupplier extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>Looking or a Wholesale Matboard Supplier in Canada & the United States?</title>
                <meta name="description" content="We are one of Canada’s biggest art suppliers specializing in matboards and picture framing supplies. We specialize in picture frame matboards."/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Looking or a Wholesale Matboard Supplier in Canada & the United States?
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Wholesale
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Felix H<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-content-photo"}/>
            </LazyLoad>


            <div className={"blog-article-content"}>
                Wholesale Matboard Supplier in Canada and the United States

                <br/><br/>

                Are you an artist looking for a high quality, wholesale matboard distributor? You got the right one right here. We are one of Canada’s biggest art suppliers specializing in matboards and picture framing supplies. We specialize in picture frame matboards.

                <br/><br/>

                The best part is, ordering from us is as easy as ordering from Amazon. You place an order from our website, and we get them produced and shipped with in 2 business days. You will get them within a week anywhere in Canada and the United States. There’s no minimum order quantity, and we do have a very competitive quantity discount.
                <span> </span><a href="/pricing-and-discounts">Click here to learn more about our retail and wholesale discounted matboard prices.</a>

                <br/><br/>

                With our direct factory to your home business model, we supply matboard for your custom frames at a wholesale price. In addition to the custom size matboards, we also do the backing boards, and other art craft paper supplies related to the photo frames, including moulding, mounting boards, foam boards, matboard products, backing papers, and paper crafts.

                <br/><br/>

                We offer both pre-cut matboards, uncut matboards, and custom mats for your artwork presentation needs. We have 4 ply, acid-free (acid neutralized), white core matboards to meet your archival and conservation needs.

                <br/><br/>

                All our matboards come in 45-degree industry standard bevel cuts. We can also do various other bevel angles per your request. Our standard, acid-free (acid neutralized matboards) come with 45-degree white core bevel cuts. Per your request, we can also do bevel cuts ranging from 45 - 90 degrees. Black core, solid core, foiled, and suede mats are also available at your request.

                <br/><br/>

                Our high quality custom matboards are perfect fit for your photo frames. With our <a href={"/customize-matboards"}>custom sized photo matboards</a>  and <a href={"/precut-mats"}>standard sized matboards,</a> you can skip picture framers and frame your artworks at a fraction of the cost.
                <span> </span>
                <a href={"/diy-gallery-wall"}>Click here to learn more about how to find a perfect picture frame for your artwork at a fraction of cost.</a>

                <br/><br/>
                Are you an artist preparing for the exhibitions?

                Check out artwork presentation kit. With our quality mat, backing mat (can be foam board or solid matboard), and sleeves, you can crate an artist presentation package for your clients.

                It’s a very nice way for your client to feel valued when you send your artwork or photo prints in a presentation kit.

                Our artist clients have received positive feedback from their customers. It’s a quick and easy way to increase the perceived value of your artwork.

                Elegant packaging does make a difference.

                <br/><br/>

                Here at CustomMat.ca, we take pride in our high-quality products.

                We are very proud to say we provide quality mat and paper crafts at the frame industries’ competitive prices.

                In addition to that, we do have the 100% customer satisfaction policy. If you are not happy with your order, let us know and we will make it right.

                We are very proud to say that tens of thousands of customers who have received our mats have been happy with our products.

                <br/><br/>

                Please feel free to reach out to us at hi@custommat.com if you have any questions. We are here to help.


                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"}/>
                    <img src={photo2} className={"blog-content-photo"}/>
                    <img src={photo3} className={"blog-content-photo"}/>
                    <img src={photo4} className={"blog-content-photo"}/>
                    <img src={photo5} className={"blog-content-photo"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>



            </div>

            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>



        </div>
    }
}