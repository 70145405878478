import React from "react";
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';

export default class StarReview extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            starClicked: false,
            ourComment: {
                improve: "Sorry for our mistake. Please contact us at hi@custommat.com. We will come up with solution. We will issue you a refund or redo the work.",
                good: "Thank you very much for your feedback!",
                excellent: "Awesome! Happy to see our satisfied customers! :)",
            }
        };
    }

    render() {
        return <div style={{marginBottom: "5.55vh"}}>
            <Box component="fieldset" mb={3} borderColor="transparent" style={{width: "12rem", marginLeft: "calc(50% - 8.5rem)", marginBottom: "0"}}>
                <Rating
                    name="customized-empty"
                    precision={1}
                    value={this.props.stars}
                    style={{width: "auto", fontSize: "3rem"}}
                    onChange={(event, newStarValue) => {

                        if (this.state.starClicked === false) {
                            this.setState({starClicked: true})
                        }

                        if (newStarValue !== this.props.stars) {
                            // this.setState({starValue: newStarValue});
                            this.props.parentSetState("stars", newStarValue);
                        }

                        if (newStarValue === null) {
                            // this.setState({starValue: this.state.starValue});
                            this.props.parentSetState("stars", this.props.stars);
                        }
                    }}
                />
            </Box>
            {/*<div style={{textAlign: "center", color: "rgb(118,118,118)"}}>*/}
            {/*    {this.state.starClicked && this.props.stars === 5 ? this.state.ourComment.excellent : ""}*/}
            {/*    {this.state.starClicked && this.props.stars === 4 ? this.state.ourComment.good: ""}*/}
            {/*    {this.state.starClicked && this.props.stars <= 3 && this.props.stars > 0 ? this.state.ourComment.improve: ""}*/}
            {/*</div>*/}
        </div>
    }
}