import React from 'react';
import displayInputFields from "./displayInputFields";
import arrowUp from "../../../../resources/svgIcons/select-mat-arrow-up.svg";
import arrowDown from "../../../../resources/svgIcons/select-mat-arrow-down.svg";
import warningIcon from "../../../../resources/svgIcons/warning-icon.svg";
import Select from "@material-ui/core/Select";
import ideaIcon from "../../../../resources/svgIcons/lightbulb-regular.svg";

export default class ChangeProductRendering extends React.Component {

    constructor() {
        super();
        this.state = {
            openBackingMatModal: false,
            roundMatModal: false,
            openWindowSizeModal: true,
            addWriting: false,
            windowWidthCm: 6,
            windowHeightCm: 6,
        }
    }

    showBackingMatInfo() {
        return <div className={"backing-mat-instructions"}>
            {this.props.fetchLanguage("1. Votre tapis de support aura la même taille que la taille du passe-partout ci-dessus.", "1. Your backing mat will be same size as the matboard size above.")}<br/>
            {this.props.fetchLanguage("2. Nos tapis de support sont disponibles en blanc lisse. Si vous souhaitez changer de couleur, veuillez nous en informer dans la section \"Notes supplémentaires\" ci-dessous.",
               `Our backing mats come in smooth white. If you want to change ${this.props.location === "us" ? " colors " : " colours "}, please let us know on the "Additional Notes" section below.`)}<br/>
        </div>
    }

    showBackingMatOption() {
        return <div className={"select-mat-row"} id={"double-mat-float-right-2"}>

            <div className={"select-matboard-window-caption-arrow add-margin-buffer-5px select-box-outside"}
                 onClick={() => this.setState({openBackingMatModal: !this.state.openBackingMatModal},
                     (event)=>{this.props.parentSetStateCallBack("backingMatboard", !this.props.backingMatboard)})}
            >
                <div className={this.props.backingMatboard ? "box-selected" : "box-unselected"}></div>
            </div>

            <div className={"select-mat-row-divider"} id={"backing-mat-price-and-colour-container"}>
                <div>
                    <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"backing-mat-price-and-colour-container-2"}
                         onClick={() => this.setState({openBackingMatModal: !this.state.openBackingMatModal},
                             (event)=>{this.props.parentSetStateCallBack("backingMatboard", !this.props.backingMatboard)})}
                    >
                        <span>{this.props.fetchLanguage("Dos du passe-partout", "Backing mat")}</span>
                        <span> </span>
                        <div className={this.state.openBackingMatModal ? "display second-mat-price" : "mobile-display-only second-mat-price"} id={"backing-mat-pricing-mobile"}>+${this.props.backingMatboardPrice.toFixed(2)}</div>
                    </div>

                    <div className="select-matboard-window-caption-selected-colour-text" id={"backing-mat-prices-mobile"}>
                        <div className={"matboard-colour-name matboard-colour-name-desc"}>
                            <span className={this.state.openBackingMatModal || (this.props.backingMatboard) ? "no-display" : "mobile-no-display"}>+${this.props.backingMatboardPrice.toFixed(2)}</span>
                            {/*{(this.props.backingMatboard) ? "Added" : null}*/}
                        </div>

                    </div>
                </div>

                <div style={{width: "100%", overflow: "auto"}}>
                    <img className={"select-mat-guidance-icon mobile-no-display"} src={ideaIcon}
                         alt={this.props.french ? "Veuillez consulter le guide pour plus de détails sur les couleurs" : "Please see guide for colour details."}
                    />
                    <div className={"select-mat-guidance-desc-long"}>
                        <span className={this.props.backingMatboard ? "no-display" : ""}>

                            {this.props.fetchLanguage("Le tapis de support est utilisé pour maintenir votre œuvre d'art. Vous aurez besoin d'un tapis de support si votre œuvre est trop fine ou trop transparente.", "Backing mat is used to hold your artwork. You will need backing mat if your artwork is too thin or too transparent.")}
                        </span>
                        {(this.props.backingMatboard) ? this.showBackingMatInfo() : null}
                    </div>
                </div>



                {/*{(this.props.designFor === "matboardOnly")  ? <FormControlLabel control={<ColoredCheckBox checked={this.props.backingMatboard} onChange={(event)=>{this.props.parentSetStateCallBack("backingMatboard", !this.props.backingMatboard)}}/>} label={`Backing mat (+$${this.props.backingMatboardPrice})`}/> : null}*/}


            </div>



        </div>
    }

    showRoundMatOption() {
        return <div className={"select-mat-row"} id={"select-mat-row-round-option"}>

            <div className={"select-matboard-window-caption-arrow add-margin-buffer-5px select-box-outside"}
                 onClick={() => this.setState({roundMatModal: !this.state.roundMatModal}, (event)=>{this.props.parentSetStateCallBack("isWindowOval", !this.props.isWindowOval)} )}
            >
                <div>
                    <div className={this.props.isWindowOval ? "box-selected" : "box-unselected"}></div>
                </div>
            </div>

            <div className={"select-mat-row-divider"} id={"window-size-round-window-container"}>
                <div className="select-matboard-window-caption-text matboard-colour-name-desc"
                     onClick={() => this.setState({roundMatModal: !this.state.roundMatModal}, (event)=>{this.props.parentSetStateCallBack("isWindowOval", !this.props.isWindowOval)} )}
                >
                    <span>{this.props.fetchLanguage("Fenêtres Arrondies", "Rounded Windows")}</span>
                </div>
            </div>

        </div>
    }

    showAddTextOption() {
        return <div className={"select-mat-row"} id={"select-mat-row-round-option"}>

            <div className={"select-matboard-window-caption-arrow add-margin-buffer-5px select-box-outside"}
                 onClick={() => this.setState({addWriting: !this.state.addWriting}, (event)=>{this.props.parentSetStateCallBack("addWriting", !this.props.addWriting)} )}>
                <div>
                    <div className={this.props.addWriting ? "box-selected" : "box-unselected"}></div>
                </div>
            </div>

            <div className={"select-mat-row-divider"} id={"window-size-round-window-container"}>
                <div className="select-matboard-window-caption-text matboard-colour-name-desc">
                    <span>{this.props.fetchLanguage("Ajouter du texte", "Add Writing")}</span>
                </div>
            </div>

        </div>
    }

    multipleWindowOptionClicked() {
        if (this.props.windowCount > 1) {
            this.props.parentSetStateCallBack("windowCount", 1);
            this.props.updateWindowOpeningCallBack("column", 1);
            this.props.updateWindowOpeningCallBack("row", 1);
        } else {
            this.props.parentSetStateCallBack("windowCount", 2);
        }
    }

    showMultipleWindowOption() {
        return <div className={"select-mat-row"} id={"window-size-multiple-window-option"}>

            <div className={"select-matboard-window-caption-arrow add-margin-buffer-5px select-box-outside"}
                 onClick={() => {this.props.parentSetStateCallBack("showMultipleWindowSelectionModal", !this.props.showMultipleWindowSelectionModal); this.multipleWindowOptionClicked()}}>
                 <div className={this.props.windowCount > 1 ? "box-selected" : "box-unselected"}></div>
            </div>

            <div className={"select-mat-row-divider"} id={"window-size-multiple-window-text"}>

                <div className="select-matboard-window-caption-text matboard-colour-name-desc"
                     onClick={() => {this.props.parentSetStateCallBack("showMultipleWindowSelectionModal", !this.props.showMultipleWindowSelectionModal); this.multipleWindowOptionClicked()}}
                >
                    <span>{this.props.fetchLanguage("Plusieurs Fenêtres", "Multiple Windows")}</span>
                </div>

                <div className="select-matboard-window-caption-selected-colour-text">
                    <div className={"matboard-colour-name matboard-colour-name-desc"}>
                        {/*<span className={this.state.openBackingMatModal || (this.props.backingMatboard) ? "no-display" : "display"}>+${this.props.backingMatboardPrice}</span>*/}
                        {(this.props.windowCount > 1) ? `${this.props.windowCount} Windows` : null}
                    </div>

                </div>


                <div className={this.props.showMultipleWindowSelectionModal ? "multiple-window-opening-modal" : "no-display"}>

                    <div className="select-mat-window-quantity" style={{display: "inline"}}>
                        <div className="select-mat-input-field-direction">{this.props.fetchLanguage("# de fenêtres", "# of Windows")}</div>
                        {displayInputFields("", this.props.windowCount, (event) => this.props.parentSetStateCallBack("windowCount", event.target.value), "outlined")}
                    </div>

                        <div className="select-mat-window-quantity-column">
                            <div className="select-mat-input-field-direction">{this.props.fetchLanguage("colonne", "column")}</div>
                            {displayInputFields("", this.props.columnCount, (event) => this.props.updateWindowOpeningCallBack("column", event.target.value), "outlined")}
                        </div>

                        <div className={"select-mat-window-quantity-row-column-middle"}>
                            x
                        </div>

                        <div className="select-mat-window-quantity-row">
                            <div className="select-mat-input-field-direction">{this.props.fetchLanguage("ligne", "row")}</div>
                            {displayInputFields("", this.props.rowCount, (event) => this.props.updateWindowOpeningCallBack("row", event.target.value), "outlined")}
                        </div>



                </div>


            </div>





        </div>
    }

    fetchUnitConversionDirection(widthFraction, heightFraction) {

        if (widthFraction !== "None" || heightFraction !== "None") {
            return <div className="select-mat-input-field-direction-long">
                {this.props.fetchLanguage(`Vos dimensions seront converties en ${widthFraction} x ${heightFraction} à la caisse.`, `Your dimensions will be converted to ${widthFraction} x ${heightFraction} at the checkout.`)}
            </div>
        }

    }

    updateCmValues(prop, value) {

        prop === "multipleWindowHeight" ? this.setState({windowHeightCm: value}) : this.setState({windowWidthCm: value})
        this.props.parentSetStateCallBack(prop, (value/2.54).toFixed(2))
    }

    showWindowSizeOption() {
        return <div className={"select-mat-row"}>

            <img className={"select-matboard-window-caption-arrow add-margin-buffer-5px"}
                 id={"window-options-container-mobile"}
                 onClick={() => {this.setState({openWindowSizeModal: !this.state.openWindowSizeModal})}}
                 alt={this.props.fetchLanguage("Développez pour plus d'options.", "Expand for more options.")}
                 src={this.state.openWindowSizeModal ? arrowUp : arrowDown}/>

            <div className={"select-mat-row-divider"}>

                <div className="select-matboard-window-caption-text matboard-colour-name-desc" id={"select-matboard-window-sizing-mobile"}
                     onClick={() => {this.setState({openWindowSizeModal: !this.state.openWindowSizeModal})}}
                >
                    <span>{this.props.fetchLanguage("Options de Fenêtre","Window Options")}</span>
                </div>

                <div className="select-matboard-window-caption-selected-colour-text">
                    <div className={"matboard-colour-name matboard-colour-name-desc"} id={"matboard-window-size-mobile"}>
                        {/*/!*<span className={this.state.openBackingMatModal || (this.props.backingMatboard) ? "no-display" : "display"}>+${this.props.backingMatboardPrice}</span>*!/*/}
                        {/*{(this.props.windowCount > 1) ? `${this.props.windowCount} Windows` : null}*/}
                        {this.props.multipleWindowWidth} <span> x </span> {this.props.multipleWindowHeight} {this.props.fetchLanguage("PO","IN")}
                    </div>
                </div>


                <div className={this.state.openWindowSizeModal ? "multiple-window-artwork-size-selection-panel" : "no-display"}>

                    <div className={"select-mat-sub-row"}>
                        <img className={"select-mat-guidance-icon"} src={warningIcon}
                            alt={this.props.fetchLanguage("Pour mesurer la taille de la fenêtre de l’œuvre, mesurez la longueur et la largeur de votre illustration, puis soustrayez un quart de pouce de chaque mesure.", "Please make sure that this is 0.25 inch smaller than your artwork.")}
                        />
                        <div className={"select-mat-guidance-desc"}>
                            {this.props.matboardWindowValueType === "centimeters" ? "0.6 cm" : this.props.fetchLanguage("0,25 po", "0.25 inch")}
                            <b>{this.props.fetchLanguage(" plus petit ", " smaller ")}</b>
                            {this.props.fetchLanguage("que votre œuvre d’art", "than your artwork")}
                        </div>
                        <div className={"select-mat-guidance-button"}>
                            <a href={this.props.fetchLanguage("window-size-fr", "window-size")}>
                                Guide
                            </a>
                        </div>
                    </div>

                    {/*For Decimal Values HERE*/}

                    <div className={"select-mat-sub-row"}>
                        <div
                            className={this.props.matboardWindowValueType === "decimals" ? "value-type-selector value-type-selected" : "value-type-selector"}
                            id={"matsize-decimals"}
                            onClick={() => {this.props.parentSetStateCallBack("matboardWindowValueType", "decimals")}}
                        >
                            {this.props.fetchLanguage("Décimales", "Decimals (in)")}

                        </div>
                        <div className={"value-type-selector"}> | </div>
                        <div
                            className={this.props.matboardWindowValueType === "fractions" ? "value-type-selector value-type-selected" : "value-type-selector"}
                            id={"matsize-fractions"}
                            onClick={() => {this.props.parentSetStateCallBack("matboardWindowValueType", "fractions")}}
                        >
                            {this.props.fetchLanguage("Fractions", "Fractions (in)")}
                        </div>
                        <div className={"value-type-selector"}> | </div>
                        <div
                            className={this.props.matboardWindowValueType === "standard" ? "value-type-selector value-type-selected" : "value-type-selector"}
                            id={"matsize-popular"}
                            onClick={() => {this.props.parentSetStateCallBack("matboardWindowValueType", "standard")}}
                        >
                            {this.props.fetchLanguage("Tailles Populaires", "Popular Sizes (in)")}
                        </div>
                        <div className={"value-type-selector"}> | </div>
                        <div
                            className={this.props.matboardWindowValueType === "centimeters" ? "value-type-selector value-type-selected" : "value-type-selector"}
                            id={"matsize-popular"}
                            onClick={() => {this.props.parentSetStateCallBack("matboardWindowValueType", "centimeters")}}
                        >
                           Centimeters (cm)
                        </div>
                    </div>

                    {/*For fractional Values HERE*/}

                    <div className={this.props.matboardWindowValueType === "decimals" ? "select-mat-sub-row" : "no-display"}>
                        <div className="select-window-multiple-window-dimensions">
                            <div className="select-mat-input-field-direction">
                                {this.props.fetchLanguage("largeur","width")}
                            </div>
                            {displayInputFields("", this.props.multipleWindowWidth, (event) => this.props.parentSetStateCallBack("multipleWindowWidth", event.target.value), "outlined")}
                        </div>

                        <div className={"select-mat-window-quantity-row-column-transition"}>
                            x
                        </div>

                        <div className="select-window-multiple-window-dimensions">
                            <div className="select-mat-input-field-direction">{this.props.fetchLanguage("hauteur","height")}</div>
                            {displayInputFields("", this.props.multipleWindowHeight, (event) => this.props.parentSetStateCallBack("multipleWindowHeight", event.target.value), "outlined")}
                        </div>
                        <div className="select-mat-input-field-direction-long">
                            {this.props.fetchLanguage("Mesures partielles à l’aide de décimales (c’est-à-dire 19 ¾ -> 19,75)", "Partial measurements using decimals (ie: 19.75).")}
                        </div>
                    </div>

                    {/*For standard Values HERE*/}
                    <div className={this.props.matboardWindowValueType === "fractions" ? "select-mat-sub-row" : "no-display"}>
                        <div className="select-window-multiple-window-dimensions matboard-size-frations">
                            <div className={"select-mat-input-field-direction"}>
                                {this.props.fetchLanguage("largeur", "width")}
                            </div>
                            {displayInputFields("", this.props.windowFractionWidthValueWholeNumber,
                                (event) => this.props.setWindowSizeFraction(event.target.value, this.props.windowFractionWidthValueSelected, this.props.windowFractionHeightValueWholeNumber, this.props.windowFractionHeightValueSelected), "outlined")}
                            <Select
                                native
                                value={this.props.windowFractionWidthValueSelected}
                                onChange={(evt) => {this.props.setWindowSizeFraction(this.props.windowFractionWidthValueWholeNumber, evt.target.value, this.props.windowFractionHeightValueWholeNumber, this.props.windowFractionHeightValueSelected)}}
                                variant={"outlined"}
                            >
                                {
                                    Object.keys(this.props.fractionalObject).map((fraction, key) => {
                                        return <option  value={fraction} key={`select-province-state-${key}`} >
                                            {fraction}
                                        </option >
                                    })
                                }
                            </Select>
                        </div>

                        <div className={"select-mat-window-quantity-row-column-transition"}>
                            x
                        </div>

                        <div className="select-window-multiple-window-dimensions matboard-size-frations">

                            <div className={"select-mat-input-field-direction"}>
                                {this.props.fetchLanguage("Hauteur", "Height")}
                            </div>
                            {displayInputFields("", this.props.windowFractionHeightValueWholeNumber,
                                (event) => this.props.setWindowSizeFraction(this.props.windowFractionWidthValueWholeNumber, this.props.windowFractionWidthValueSelected, event.target.value, this.props.windowFractionHeightValueSelected), "outlined")}
                            <Select
                                native
                                className={"window-size-fractional-input"}
                                value={this.props.windowFractionHeightValueSelected}
                                onChange={(evt) => {this.props.setWindowSizeFraction(this.props.windowFractionWidthValueWholeNumber, this.props.windowFractionWidthValueSelected, this.props.windowFractionHeightValueWholeNumber, evt.target.value)}}
                                variant={"outlined"}
                            >
                                {
                                    Object.keys(this.props.fractionalObject).map((fraction, key) => {
                                        return <option  value={fraction} key={`select-province-state-${key}`} >
                                            {fraction}
                                        </option >
                                    })
                                }
                            </Select>
                        </div>

                        {this.fetchUnitConversionDirection(this.props.multipleWindowWidth, this.props.multipleWindowHeight)}


                    </div>

                    {/*For cm values here*/}

                    <div className={this.props.matboardWindowValueType === "centimeters" ? "select-mat-sub-row" : "no-display"}>
                        <div className="select-window-multiple-window-dimensions">
                            <div className="select-mat-input-field-direction">
                                {this.props.fetchLanguage("largeur","width")}
                            </div>
                            {displayInputFields("", this.state.windowWidthCm, (event) => this.updateCmValues("multipleWindowWidth", event.target.value), "outlined")}
                        </div>

                        <div className={"select-mat-window-quantity-row-column-transition"}>
                            x
                        </div>

                        <div className="select-window-multiple-window-dimensions">
                            <div className="select-mat-input-field-direction">{this.props.fetchLanguage("hauteur","height")}</div>
                            {displayInputFields("", this.state.windowHeightCm, (event) => this.updateCmValues("multipleWindowHeight", event.target.value), "outlined")}
                        </div>
                        <div className="select-mat-input-field-direction-long">
                            At checkout, dimensions are shown in <u>inches</u>.<br/>
                            <b>
                                {(parseFloat(this.state.windowWidthCm)).toFixed(1)} x {(parseFloat(this.state.windowHeightCm)).toFixed(1)} cm is equivalent to  {(parseFloat(this.props.multipleWindowWidth)).toFixed(2)} x {parseFloat(this.props.multipleWindowHeight).toFixed(2)} in inches.<br/><br/>
                            </b>
                        </div>
                    </div>







                    <div className={this.props.matboardWindowValueType === "standard" ? "select-mat-sub-row" : "no-display"}>
                        <div className="matboard-size-popular-values-input">
                            <div className={"select-mat-input-field-direction"}>
                                {this.props.fetchLanguage("taille de fenêtre", "window size")}
                            </div>
                            <Select
                                native
                                className={"matboard-size-popular-values-input"}
                                onChange={(event) => {this.props.parentSetStateCallBack("multipleWindowWidth", this.props.standardWindowSizes[event.target.value].width); this.props.parentSetStateCallBack("multipleWindowHeight", this.props.standardWindowSizes[event.target.value].height)}}
                                variant={"outlined"}
                            >
                                {
                                    Object.keys(this.props.standardWindowSizes).map((matSize, key) => {
                                        if (this.props.frameWidth > this.props.standardWindowSizes[matSize].width + 2 && this.props.frameHeight > this.props.standardWindowSizes[matSize].height + 2) {
                                            return <option value={matSize} key={`select-province-state-${key}`} >
                                                {this.props.standardWindowSizes[matSize].width} ({this.props.fetchLanguage("L", "W")}) x {this.props.standardWindowSizes[matSize].height} (H) - {this.props.fetchLanguage(this.props.standardWindowSizes[matSize].descFr, this.props.standardWindowSizes[matSize].desc)}
                                            </option >
                                        }
                                    })
                                }
                            </Select>
                        </div>
                    </div>

                    <div className="select-mat-input-field-direction-long">
                        {this.props.matboardWindowValueType === "centimeters" ? 'Make sure the window size is 0.6cm smaller than your artwork. Otherwise, the artwork will fall through the opening. Please note that we cut the opening exactly as you tell us to do so.' : this.props.fetchLanguage("Assurez-vous que la taille de la fenêtre est inférieure à celle de votre œuvre d’art. Sinon, l’œuvre d’art tombera par l’ouverture. Veuillez noter que nous coupons l’ouverture exactement selon vos instructions.", 'Make sure the window size is 0.25" smaller than your artwork. Otherwise, the artwork will fall through the opening. Please note that we cut the opening exactly as you tell us to do so.')}
                    </div>


                </div>


            </div>





        </div>
    }

  render() {
        return <div className={`select-mat-product-change`}>
          <div className={`select-mat-window-quantity-container`}>

              {this.showBackingMatOption()}

              {this.showWindowSizeOption()}

              <div className={this.props.designFor === "polaroidFrame" ? "no-display" : "display form-control-label"}>
                  {this.showRoundMatOption()}
                  {this.showMultipleWindowOption()}
                  {/*{this.showAddTextOption()}*/}
              </div>

          </div>
        </div>
  }
}