import React from 'react';
import '../../../stylesheets/review/review.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
// import logoColor from './logo-color.png';
import StarReview from "./starReview";
import connectToDB from "../database/connectDB";


export default class AppReview extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      approved: true,
      reviewDate: {
      },
      email: "",
      firstName: "",
      review: "",
      stars: 5,
      reviewPosted: false,
      googleIndep: false,
    };

    this.parentSetState = this.parentSetState.bind(this);
  }

  parentSetState(stateField, stateValue) {
    this.setState({[stateField]: stateValue})
  }

  componentDidMount() {

    var today = new Date();
    var todayYear = today.getFullYear();
    var todayMonth = today.getMonth()+1;
    var todayDate = today.getDate();

    this.setState({
      reviewDate: {
        year: todayYear,
        month: todayMonth,
        date: todayDate,
      }
    });

  }

  clickSubmitReview() {
    this.setState({reviewPosted: true});
    connectToDB("w","user-review", new Date().getTime(), this.state);
  }

  render() {

    return <div className="review-container" >

      {/*<div className={"top-header-container"}>*/}
      {/*  <img className={"logo-size"} src={logoColor}/>*/}
      {/*</div>*/}

      <div className={"review-section"}>

        <div className={this.state.reviewPosted ? "no-display" : "display"}>
          <div className={"review-section-header"}>
            Your Feedback
          </div>

          <div className={"review-section-directions"}>
            Thank you very much for taking your time to give us feedback. This will take 2 minutes. <br className={"tablet-no-display"}/>
            If there are any concerns, please send us an email to hi@custommat.com. <br className={"tablet-no-display"}/>
            We will work with you until you are fully satisfied.
          </div>

          <StarReview stars={this.state.stars} parentSetState={this.parentSetState}/>

          <form noValidate autoComplete="off" >
            <TextField
                fullWidth={true}
                id="standard-basic"
                value={this.state.firstName}
                onChange={(evt) => this.setState({firstName: evt.target.value})}
                label="Your Name (i.e. Jenna L.)" />
          </form>
          <form noValidate autoComplete="off" className={"review-form-margin"}>
            <TextField
                fullWidth={true}
                id="standard-basic"
                label="Your Email"
                value={this.state.email}
                onChange={(evt) => this.setState({email: evt.target.value})}
            />
          </form>
          <FormHelperText id="component-helper-text" >
            Your email will be used to verify your purchase. We will never share your email.
          </FormHelperText>
          <div className={"review-field-margin"}>
            <TextField
                id="standard-textarea"
                label="Your Feedback"
                multiline
                fullWidth={true}
                rows={10}
                variant="outlined"
                value={this.state.review}
                onChange={(evt) => this.setState({review: evt.target.value})}
            />
            <FormHelperText id="component-helper-text">
              For an accurate representation, we only post reviews from our customers.<br className={"mobile-no-display"}/>
              It takes about 48 hours for your review to be moderated and verified.
            </FormHelperText>
          </div>

          <div className={"review-field-submit-button-container"}>
            <Button className={this.state.reviewPosted ? "no-display" : "display"}
                    onClick={() => this.clickSubmitReview()} variant="contained"
                    fullWidth={true}>Submit Review
            </Button>
          </div>
        </div>

        <div className={this.state.reviewPosted ? "display" : "no-display"} style={{textAlign: "center", lineHeight: 2}}>
          Thank you. Your review has been sent for review.<br/>
          We will verify and post your review within 24 hours.
        </div>

      </div>

    </div>
  }
}