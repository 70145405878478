import React from "react";
import LazyLoad from 'react-lazyload';
import whatIsAMat2 from '../../../resources/images/landingPage/whatIsAMatNew.png';
import whatIsAMatGif from '../../../resources/images/landingPage/whatIsMatGif.gif';


export default class WhatIsMatboard extends React.Component {

    constructor() {
        super();
        this.state = {
            french: false,
        }
    }

    componentDidMount() {
        this.setState({
            french: (window.location.pathname.slice(-2) === "fr" || this.props.language === "fr") ? true : false
        })
    }

    fetchEnglish() {
        return <div className={"what-is-mat-page-v2"}>
            <div style={{width: "100%", overflow: "auto"}}>
                <div className={"landing-page-standard-left-v2"}>
                    <h1 className={"what-is-mat-header-v2"}>
                        What is a <br/>
                        matboard
                    </h1>

                    <h2 className={"what-is-mat-desc-v2"}>
                        {this.state.french ? "Matboard – Une couche dense de papier d'art spécialisé qui se place entre le verre ou l'acrylique de votre cadre et votre œuvre d'art." :
                            "Matboard – A dense layer of specialty art paper that sits between your frame’s glass / acrylic and your artwork."}
                    </h2>

                    <div className={"what-is-mat-line-break-v2"}>
                        {this.state.french ? "✦ Cadres non inclus." : "✦ Frames not included."}
                    </div>
                </div>

                <div className={"landing-page-standard-right-v2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2-mobile what-is-mat-image-v2"} src={whatIsAMatGif}></img>
                    </LazyLoad>
                </div>
            </div>

            <div style={{width: "100%", overflow: "auto"}}>
                <div className={"landing-page-standard-left-v2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} src={whatIsAMat2}/>
                    </LazyLoad>
                </div>

                <div className={"landing-page-standard-right-v2"}>
                    <h1 className={"what-is-mat-header-v2"}>
                        {this.state.french ? "VOTRE CADRE" : "Your Frame +"} <br/>
                        {this.state.french ? "Nos Passe-Partout" : "Our Matboard"}
                    </h1>

                    <h2 className={"what-is-mat-desc-v2"}>
                        <div>
                            Create the <b>custom framing</b> look at a <b>fraction of cost</b> by combining our custom matboards with your frames.
                            <div className={"what-is-mat-header-v2-transition"}>
                            </div>
                            Upgrade your art display affordably by pairing our custom matboards with frames from popular retailers like Amazon and IKEA.
                        </div>
                    </h2>

                </div>
            </div>
        </div>

    }

    fetchFrench() {
        return <div className={"what-is-mat-page-v2"}>
            <div style={{width: "100%", overflow: "auto"}}>
                <div className={"landing-page-standard-left-v2"}>
                    <h1 className={"what-is-mat-header-v2"}>
                        Qu’est-ce qu’un<br/>
                        passe-partout?
                    </h1>

                    <h2 className={"what-is-mat-desc-v2"}>
                        Matboard – Une couche dense de papier d'art spécialisé qui se place entre le verre ou l'acrylique de votre cadre et votre œuvre d'art.
                    </h2>

                    <div className={"what-is-mat-line-break-v2"}>
                        ✦ Cadres non inclus.
                    </div>
                </div>

                <div className={"landing-page-standard-right-v2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2-mobile what-is-mat-image-v2"} src={whatIsAMatGif}></img>
                    </LazyLoad>
                </div>
            </div>

            <div style={{width: "100%", overflow: "auto"}}>
                <div className={"landing-page-standard-left-v2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} src={whatIsAMat2}/>
                    </LazyLoad>
                </div>

                <div className={"landing-page-standard-right-v2"}>
                    <h1 className={"what-is-mat-header-v2"}>
                        VOTRE CADRE <br/>
                        Nos Passe-Partout
                    </h1>

                    <h2 className={"what-is-mat-desc-v2"}>
                        <div>
                            Obtenez l'apparence d'un <b>passe-partout personnalisé</b> à une <b>fraction du coût</b> en combinant nos tapis sur mesure avec vos cadres.
                            <br/><br/>
                            Améliorez l'affichage de votre art de manière abordable en mariant nos tapis sur mesure avec des cadres de détaillants populaires tels qu'Amazon et IKEA.
                        </div>
                    </h2>

                </div>
            </div>
        </div>

    }

    fetchGerman() {
        return <div className={"what-is-mat-page-v2"}>
            <div>
                <div className={"landing-page-standard-left-v2"}>
                    <h1 className={"what-is-mat-header-v2"}>
                        Was ist ein <br/>
                        Passepartout
                    </h1>

                    <h2 className={"what-is-mat-desc-v2"}>
                        Passepartout – Eine dichte Schicht aus Spezialkunstpapier, die zwischen dem Glas / Acrylglas Ihres Rahmens und Ihrem Kunstwerk liegt.
                    </h2>

                    <div className={"what-is-mat-line-break-v2"}>
                        ✦ Bilderrahmen nicht enthalten.
                    </div>
                </div>

                <div className={"landing-page-standard-right-v2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2-mobile what-is-mat-image-v2"} src={whatIsAMatGif}></img>
                    </LazyLoad>
                </div>
            </div>

            <div style={{width: "100%", overflow: "auto"}}>
                <div className={"landing-page-standard-left-v2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} src={whatIsAMat2}/>
                    </LazyLoad>
                </div>

                <div className={"landing-page-standard-right-v2"}>
                    <h1 className={"what-is-mat-header-v2"}>
                        Ihr Rahmen <br/>
                        Unser Passepartout
                    </h1>

                    <h2 className={"what-is-mat-desc-v2"}>
                        <div>
                            Erschaffen Sie den maßgeschneiderten Rahmenlook zu einem Bruchteil der Kosten, indem Sie unsere maßgefertigten Passepartouts mit Ihren Rahmen kombinieren.
                            <div className={"what-is-mat-header-v2-transition"}>
                            </div>
                            Verbessern Sie Ihre Kunstpräsentation kostengünstig, indem Sie unsere maßgefertigten Passepartouts mit Rahmen von beliebten Einzelhändlern wie Amazon und IKEA kombinieren.
                        </div>
                    </h2>

                </div>
            </div>
        </div>

    }

    fetchSpanish() {
        return <div className={"what-is-mat-page-v2"}>
            <div>
                <div className={"landing-page-standard-left-v2"}>
                    <h1 className={"what-is-mat-header-v2"}>
                        ¿Qué es un <br/>
                        Passepartout?
                    </h1>

                    <h2 className={"what-is-mat-desc-v2"}>
                        Passepartout: una capa densa de papel artístico especial que se encuentra entre el vidrio / acrílico de tu marco y tu obra de arte.
                    </h2>

                    <div className={"what-is-mat-line-break-v2"}>
                        ✦ Marco de fotos no incluido.
                    </div>
                </div>

                <div className={"landing-page-standard-right-v2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2-mobile what-is-mat-image-v2"} src={whatIsAMatGif}></img>
                    </LazyLoad>
                </div>
            </div>

            <div style={{width: "100%", overflow: "auto"}}>
                <div className={"landing-page-standard-left-v2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} src={whatIsAMat2}/>
                    </LazyLoad>
                </div>

                <div className={"landing-page-standard-right-v2"}>
                    <h1 className={"what-is-mat-header-v2"}>
                        Su marco <br/>
                        Nuestro Passepartout
                    </h1>

                    <h2 className={"what-is-mat-desc-v2"}>
                        <div>
                            Cree el aspecto de marco personalizado a una fracción del costo combinando nuestros passepartouts a medida con sus marcos.
                            <div className={"what-is-mat-header-v2-transition"}>
                            </div>
                            Mejore la presentación de su arte de manera económica combinando nuestros passepartouts a medida con marcos de minoristas populares como Amazon e IKEA.
                        </div>
                    </h2>

                </div>
            </div>
        </div>

    }

    fetchItalian() {
        return <div className={"what-is-mat-page-v2"}>
            <div>
                <div className={"landing-page-standard-left-v2"}>
                    <h1 className={"what-is-mat-header-v2"}>
                        Cos'è un <br/>
                        Passepartout?
                    </h1>

                    <h2 className={"what-is-mat-desc-v2"}>
                        Passepartout: uno strato denso di carta artistica speciale che si trova tra il vetro/acrilico della tua cornice e la tua opera d'arte.
                    </h2>

                    <div className={"what-is-mat-line-break-v2"}>
                        ✦ Cornice foto non inclusa.
                    </div>
                </div>

                <div className={"landing-page-standard-right-v2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2-mobile what-is-mat-image-v2"} src={whatIsAMatGif}></img>
                    </LazyLoad>
                </div>
            </div>

            <div style={{width: "100%", overflow: "auto"}}>
                <div className={"landing-page-standard-left-v2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} src={whatIsAMat2}/>
                    </LazyLoad>
                </div>

                <div className={"landing-page-standard-right-v2"}>
                    <h1 className={"what-is-mat-header-v2"}>
                        La tua cornice <br/>
                        Il nostro Passepartout
                    </h1>

                    <h2 className={"what-is-mat-desc-v2"}>
                        <div>
                            Crea l'aspetto di una cornice personalizzata a una frazione del costo combinando i nostri passepartout su misura con le tue cornici.
                            <div className={"what-is-mat-header-v2-transition"}>
                            </div>
                            Migliora la presentazione della tua arte in modo economico combinando i nostri passepartout su misura con cornici da rivenditori popolari come Amazon e IKEA.
                        </div>
                    </h2>

                </div>
            </div>
        </div>
    }

    fetchDutch() {
        return <div className={"what-is-mat-page-v2"}>
            <div>
                <div className={"landing-page-standard-left-v2"}>
                    <h1 className={"what-is-mat-header-v2"}>
                        Wat is een <br/>
                        Passepartout?
                    </h1>

                    <h2 className={"what-is-mat-desc-v2"}>
                        Passepartout: een dikke laag speciaal artistiek papier die zich bevindt tussen het glas/acryl van je lijst en je kunstwerk.
                    </h2>

                    <div className={"what-is-mat-line-break-v2"}>
                        ✦ Foto lijst niet inbegrepen.
                    </div>
                </div>

                <div className={"landing-page-standard-right-v2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2-mobile what-is-mat-image-v2"} src={whatIsAMatGif}></img>
                    </LazyLoad>
                </div>
            </div>

            <div style={{width: "100%", overflow: "auto"}}>
                <div className={"landing-page-standard-left-v2"}>
                    <LazyLoad>
                        <img className={"what-is-mat-image-v2"} src={whatIsAMat2}/>
                    </LazyLoad>
                </div>

                <div className={"landing-page-standard-right-v2"}>
                    <h1 className={"what-is-mat-header-v2"}>
                        Jouw lijst <br/>
                        Ons Passepartout
                    </h1>

                    <h2 className={"what-is-mat-desc-v2"}>
                        <div>
                            Creëer de uitstraling van een op maat gemaakte lijst voor een fractie van de prijs door onze op maat gemaakte passepartouts te combineren met je bestaande lijsten.
                            <div className={"what-is-mat-header-v2-transition"}>
                            </div>
                            Verbeter de presentatie van je kunst op een voordelige manier door onze op maat gemaakte passepartouts te combineren met lijsten van populaire retailers zoals Amazon en IKEA.
                        </div>
                    </h2>

                </div>
            </div>
        </div>
    }



    render() {
        if (this.state.french || this.props.country === "France") {
            return this.fetchFrench()
        } else if (this.props.country === "Germany") {
            return this.fetchGerman()
        } else if (this.props.country === "Spain") {
            return this.fetchSpanish()
        } else if (this.props.country === "Italy") {
            return this.fetchItalian()
        } else if (this.props.country === "Netherlands") {
            return this.fetchDutch()
        }
        else {
            return this.fetchEnglish()
        }

    }
}