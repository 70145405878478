import React from 'react';
import photo1 from "../../../resources/images/blog/diploma/Background Photo.png"
import photo2 from "../../../resources/images/blog/diploma/Double Mat.png";
import photo3 from "../../../resources/images/blog/diploma/Single Mat.png";
import photo4 from "../../../resources/images/blog/diploma/Double Mat with Writing and Logo.png";
import photo5 from "../../../resources/images/blog/diploma/Single Mat with Logo.png";
import photo6 from "../../../resources/images/blog/diploma/Double Matting No Writing.png";
import photo7 from "../../../resources/images/blog/diploma/Single Matting with Multiple Opening.png";
import univ from "../../../resources/images/blog/diploma/univ-edited.jpg";
import western from "../../../resources/images/blog/diploma/western.jpg";
import photo8 from "../../../resources/images/blog/frameMat/frame-mat-photo 1.PNG";
import blogArticle1Regular32 from "../../../resources/images/gallery/1-articleOne/reduced/3-2.png";

import LazyLoad from "react-lazyload";
import {Helmet} from "react-helmet";



export default class DiplomaMatboardAndMore extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>Budget Friendly Diploma Framing & More</title>
                <meta name="description" content="High quality graduation frames cost over $200 dollars easily at the picture framing shops. It doesn’t have to be that way. Let’s talk about how to get your personalized degree frames in a budget friendly way."/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Budget Friendly Diploma Framing & More
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                Diplomas & Certificates
            </div>

            <div className={"carousel-subject-subhead-type3"}>
                Written by Daniel S<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-carousel-photo"} alt={"Illustration of Canadian University Diplomas – Computer Graphic Generated"}/>
            </LazyLoad>

            <div className={"blog-article-content"}>

                <b>Looking for a budget friendly diploma or certificate frame?</b>

                <br/><br/>

                It’s the high school and college graduation season. Looking for a university frame to present your hard work? Just got your serious professional degrees — like CPA or RN — through your hard work? Maybe, you are looking for a graduation gift for your loved one’s accomplishment? You are at the right place.


                <br/><br/>

                High-quality graduation frames cost over $200 dollars easily at the picture framing shops. It doesn’t have to be that way. Let’s talk about how to get your personalized degree frames in a budget-friendly way.


                <br/><br/>

                <LazyLoad>
                    <video className={"what-is-mat-image-v2"} loop="true" autoPlay="autoplay" controls muted
                           src="https://s3.ca-central-1.amazonaws.com/custommat.landing.v2/0_What-is-a-matboard_1920x1290_v1+(1).mp4"></video>
                </LazyLoad>

                <br/>

                How do you frame your diploma economically? <b>Here’s how we can save money on your bachelor’s or master's degree frames:</b>

                <ol>
                    <li>
                        <b>Get a standard-sized picture frame.</b>
                        <br/>
                        You can get them easily from Amazon or retail stores — like Costco, IKEA, Target, Walmart, or Homesense.
                    </li>
                    <br/>
                    <li>
                        <b>Get a custom mat board.</b>
                        <br/>
                        You can customize it with your school color, school name, and logo.
                    </li>
                    <br/>
                    <li>
                        <b>Put them together at home.</b>
                        <br/>
                        It takes only 5 minutes.
                    </li>
                </ol>
                <br/>

                <LazyLoad>
                    <img src={blogArticle1Regular32} className={"blog-content-photo"} alt={"CustomMat.ca customer who purchased a bright yellow matboard for her New School Graduate degree. "}/>
                </LazyLoad>

                <br/><br/>

                Combining standard-sized picture frames with our <b>custom-designed degree matboards</b>, you can get a custom diploma frame for around $30. Your certificate frames can be either metal or wood frames — frame types don’t matter. It’s up to your preference.

                <br/><br/>

                <b>Personalization</b>

                <br/><br/>

                Want something more than just a mat board? Maybe you want a little extra. Want to have your <b>university color</b>?

                Want a <b>shiny gold</b> mat around your diploma? How about <b>school name and/or your name written</b> in shiny gold or silver letters?

                What about your <b>School Logo</b>? Maybe have <b>several openings</b> to combine all your previous degrees into one large, elegant university frame?

                You can also include your <b>graduation photos</b>. No problem — we can do all that. We have done thousands across the United States and Canada.

                <br/><br/>

                At picture framing stores, customized college degree frames hover over $200. Some going over $300 per frame. However, you can do all of this for about $20-60 dollars with us — including the picture frame and the matboard.


                <br/><br/>

                <b>Here’s how you do it:</b>
                <br/><br/>

                This may seem overwhelming — but <b>it’s much easier than you think</b>.

                <br/><br/>

                <ol>
                    <li>
                        <b>Google your diploma size. Or, measure your own diploma size.</b>
                    </li>
                    <br/>
                    <li>
                        <b>Order a frame that is bigger than your diploma.</b><br/>
                        It would be ideal if you could make your frame 2-6 inches bigger than your diploma on each side.
                        <i>It would be ideal if you could make your frame 2-6 inches bigger than your diploma on each side. i.e. If your diploma is 13.5 x 10.5 inch, 18 x 14 inch or 20 x 16-inch frame would be a good choice.</i>
                    </li>
                    <br/>
                    <li>
                        <b>Order custom matboards from us. </b><br/>
                        <i>
                            When you place your order, your matboard size should be the same as your frame size. Your mat board window opening (cut-out) size should be 0.25 inches smaller on each side. i.e. If your diploma is 13.5x10.5 inches, your opening (cut-out) should be 13.25 x 10.25 inches
                        </i>
                        <b> <a href={"/design-guide"}>Click here for more info on measurements.</a></b>
                    </li>
                    <br/>
                    <li>
                        <b>Put them together.</b> It takes about ten minutes.<br/>
                    </li>
                </ol>

                <br/><br/>

                <b>Here are some recommendations for the college diploma frame:</b>
                <br/><br/>

                <ul>
                    <li>
                        Option 1: Double matting with university color with highlighting.
                    </li>
                    <br/>
                    <li>
                        Option 2: Single matting with university color.
                    </li>
                    <br/>
                    <li>
                        Option 3: Single or Double matting with School Name.
                    </li>
                    <br/>
                    <li>
                        Option 4: Single or Double matting with Multiple Openings Diploma Frames.
                    </li>
                    <br/>
                    <li>
                        Option 5: Single or Double matting with Writing and Multiple Openings.
                    </li>
                </ul>

                <br/><br/>

                <LazyLoad>
                    <img src={photo2} className={"blog-content-photo-small"} alt={"Illustration of McGill University Diploma frame matboard."}/>
                </LazyLoad>
                <br/>

                <LazyLoad>
                    <img src={univ} className={"blog-content-photo"} alt={"Custom matboard for diploma and certificate frames – Product illustration."}/>
                </LazyLoad>
                <br/>

                <b>Type 1: Double matting with your university color:</b>

                <br/><br/>

                It’s the most popular option. The most popular option is your school color with gold second-tier matting to highlight your degree. If your school has two colors, you can use the second color instead of gold.

                <br/><br/>

                Overall, you want to have your university pride through your school color. But we don’t want to take too much attention away from your degree.


                <br/><br/>

                <LazyLoad>
                    <img src={photo3} className={"blog-content-photo-small"} alt={"Illustration of McGill University Degree frame matboard."}/>
                </LazyLoad>
                <br/>

                <LazyLoad>
                    <img src={blogArticle1Regular32} className={"blog-content-photo"} alt={"Graduate degree cutomzied matboard."}/>
                </LazyLoad>
                <br/>

                <b>Type 2: Single matting with university color:</b>

                <br/><br/>

                Do you have a degree in something creative — creative writing or film studies? Maybe you have played sports? Do you want your diploma frame to take the attention of your office guests?

                <br/><br/>

                You can make your diploma frames pop with an accented matboard. They can be your university color, or they can be just your favorite colors. When you do that, your college diploma frame will draw attention. You maybe want to talk about your college football experience with your guests.

                <br/><br/>

                <LazyLoad>
                    <img src={photo5} className={"blog-content-photo-small"} alt={"University of Toronto custom matboard -- Double mating illustration"}/>
                </LazyLoad>
                <br/>

                <LazyLoad>
                    <img src={photo4} className={"blog-content-photo-small"} alt={"University of Toronto custom matboard -- Single mating illustration"}/>
                </LazyLoad>
                <br/>

                <LazyLoad>
                    <img src={western} className={"blog-content-photo-small"} alt={"Western Univeristy Actual customer."}/>
                </LazyLoad>
                <br/>


                <b>Type 3: Single or Double matting with School Name Writing (Optional Logo)</b>

                <br/><br/>

                Want to get <b>shiny writing</b> on your mat board?
                Want your <b>university logo</b>?
                Want to highlight <b>your name and/or degrees</b> (like Alexa Roe JD or John Doe MD)? Do you want them to write in classic, university style, Latin calligraphy, or modern fonts? We can do all that at $10 dollars extra.

                <br/><br/>

                <LazyLoad>
                    <img src={photo7} className={"blog-content-photo-small"} alt={"You can choose to have a single or double matting around your diploma. This will create different openings where people can see your diploma."}/>
                </LazyLoad>
                <br/>

                <b>Type 4: Single or Double Matting with Multiple Openings Diploma Frames</b>

                <br/><br/>

                Looking for a high school graduation frame for your children? Maybe it can be cute to have their grad photos or medallions next to their degrees. That will be memorable when you look at them in a couple of years.

                <br/><br/>

                Did you get your master’s or doctor’s degrees? Want to combine with your bachelor’s degree one large double diploma frame? Want to add your graduation tassel? No worries — we can do all this very easily.

                <br/><br/>

                <LazyLoad>
                    <img src={photo6} className={"blog-content-photo-small"} alt={"There are two types of matting - single or double. Single matting has one layer of protection for your picture, while double matting has two."}/>
                </LazyLoad>
                <br/>

                <b>Type 5: Single or Double Matting with Writing and Multiple Openings</b>

                <br/><br/>

                You can mix and match all the options between 1-4. You can have many openings with writing. Or you can be even more creative. Whatever you can think of, we can usually do it all.

                <br/><br/>

                Combining standard-sized picture frames (or photo frames) with our customized frames can complete truly customized diploma frames for your degrees and certificates. It would be a nice summary of your campus scene and a nice graduation gift to celebrate their hard work.

                <br/><br/>

                <b>Beyond Diplomas</b>

                <br/><br/>

                <LazyLoad>
                    <img src={photo8} className={"blog-content-photo"} alt={"There are so many fun ideas. Gallery wall for your wedding, family, kids, pets, and the list goes on and on. Here are some photos from our actual customers. Check out our Customer Photo Gallery here."}/>
                </LazyLoad>
                <br/>


                There are so many fun ideas. Gallery wall for your wedding, family, kids, pets, and the list goes on and on.

                Here are some photos from our actual customers. Check out our <b><a href={"/inspiration"}>Customer Photo Gallery</a></b> here.

                <br/><br/>

                Here are some instructions on how to create beautiful gallery walls at a fraction of traditional custom framing costs.

                <ol>
                    <li><a href={"https://www.custommat.ca/ikea-frame-matboard-sizing-guide"}>IKEA Ribba Picture Frame Gallery Wall</a></li>
                    <li><a href={"https://www.custommat.ca/creating-home-office"}>Home Office Gallery Wall</a></li>
                    <li><a href={"https://www.custommat.ca/save-huge-on-photo-frames-with-passe-partout-photo-mats"}>Bedside Table Gallery Wall</a></li>
                    <li><a href={"https://www.custommat.ca/oversized-mat-frames-with-ikea-ramsborg"}>Wedding Gallery Wall</a></li>
                    <li><a href={"https://www.custommat.ca/jersey-sports-shadow-box-memorabilia-matting"}>Sports Room Gallery Wall</a></li>
                    <li><a href={"https://www.custommat.ca/diy-oversized-mat-amp-square-family-photo-frames"}>Square Family Photo Frames for Gift</a></li>
                    <li><a href={"https://www.kendrafoundit.com/blog/diy-eclectic-vintage-gallery-wall "}>Recycling your old frames</a></li>
                </ol>

                We do have a very generous return policy. 100% Happiness Guaranteed. If you’re not happy with your order, let us know and we’ll make it right. We take pride in our workmanship.

                <br/><br/>

                Please feel free to reach out to us at hi@custommat.com if you have any questions. We are here to help.


                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                <LazyLoad>
                    <img src={photo1} className={"blog-content-photo"} alt={"Illustration of Canadian University Diplomas – Computer Graphic Generated"}/>
                    <img src={photo2} className={"blog-content-photo"} alt={"Illustration of McGill University Diploma frame matboard."}/>
                    <img src={photo3} className={"blog-content-photo"} alt={"Illustration of McGill University Degree frame matboard."}/>
                    <img src={photo4} className={"blog-content-photo"} alt={"University of Toronto custom matboard -- Single mating illustration"}/>
                    <img src={photo5} className={"blog-content-photo"} alt={"University of Toronto custom matboard -- Double mating illustration"}/>
                </LazyLoad>

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>



            </div>

            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>



        </div>
    }
}