export const cadUsdRate = 0.8;

export const cadGbpRate = 0.65;

export const cadEurRate = 0.75;

export const cadAudRate = 1.2;

export const cadNzdRate = 1.3;


