import React from 'react';
import photo1 from "../../../resources/images/blog/photoSet1/IMG_4253.JPG";
import photo2 from "../../../resources/images/blog/photoSet1/opposite wall frame.JPG";
import photo3 from "../../../resources/images/blog/photoSet1/diy overszied mat frames.JPG";
import photo4 from "../../../resources/images/blog/photoSet1/ordering-matboards.png";
import photo5 from "../../../resources/images/blog/photoSet1/IMG_4344.JPG";
import photo6 from "../../../resources/images/blog/photoSet1/IMG_4418.JPG";
import photo7 from "../../../resources/images/blog/photoSet1/IMG_4401.JPG";
import photo8 from "../../../resources/images/blog/photoSet1/IMG_4398.JPG";
import photo9 from "../../../resources/images/blog/photoSet1/IMG_4305.JPG";
import galleryPhoto1 from "../../../resources/images/blog/photoSet1/gallery/Large Square Frames with Oversized Mat_0001.jpg";
import galleryPhoto2 from "../../../resources/images/blog/photoSet1/gallery/Large Square Frames with Oversized Mat_0002.jpg";
import galleryPhoto3 from "../../../resources/images/blog/photoSet1/gallery/Large Square Frames with Oversized Mat_0003.jpg";
import galleryPhoto4 from "../../../resources/images/blog/photoSet1/gallery/Large Square Frames with Oversized Mat_0004.jpg";
import galleryPhoto5 from "../../../resources/images/blog/photoSet1/gallery/Large Square Frames with Oversized Mat_0005.jpg";
import galleryPhoto6 from "../../../resources/images/blog/photoSet1/gallery/Large Square Frames with Oversized Mat_0006.jpg";
import galleryPhoto7 from "../../../resources/images/blog/photoSet1/gallery/Large Square Frames with Oversized Mat_0007.jpg";
import galleryPhoto8 from "../../../resources/images/blog/photoSet1/gallery/Large Square Frames with Oversized Mat_0008.jpg";
import galleryPhoto9 from "../../../resources/images/blog/photoSet1/gallery/Large Square Frames with Oversized Mat_0009.jpg";
import {Helmet} from "react-helmet";
import LazyLoad from "react-lazyload";

export default class DesignBlogMain extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    };

    componentDidMount() {

    }

    fetchImage(img) {
        return <LazyLoad>
            <img src={img} className={"blog-content-photo"}/>
        </LazyLoad>
    }

    render() {
        return <div className={"blog-app-container"}>

            <Helmet>
                <title>Save huge on photo frames with passe-partout! (photo mats)</title>
                <meta name="description" content="I purchased a glorious set of picture frames and saved huge with a quick trick. The frames I gathered cost significantly less for the quality because they did not have matboard."/>
            </Helmet>

            <div className={"carousel-subject-head"} style={{textAlign: "center"}}>
                Save huge on photo frames<br/> with passe-partout! (photo mats)
            </div>

            <div className={"carousel-subject-subhead-type2"}>
                ART ON BUDGET
            </div>
            <div className={"carousel-subject-subhead-type3"}>
                Written by Danielle M<br/>Custom Mat Project Blogger
            </div>

            <LazyLoad>
                <img src={photo1} className={"blog-carousel-photo"}/>
            </LazyLoad>

            <div className={"blog-article-content"}>
                I purchased a glorious set of picture frames and saved huge with a quick trick. The frames I gathered cost significantly less for the quality because they did not have matboard.
                <br/><br/>
                {this.fetchImage(photo2)}
                <div className={"blog-article-photo-description"}>
                    Opposite Wall 20x20 inch frame from Amazon.com / Amazon.ca.
                </div>
                {this.fetchImage(photo3)}
                <div className={"blog-article-photo-description"}>
                    20x20 inch frame and custom ordered matboard (passepartout)
                </div>
                My frames were of standard size and quite lovely. But as often happens, the photo frames were much bigger than the photo prints that I intended to put in.

                To bridge the gap between the artwork and the frame, I decided to source bulk mat from a custom mat shop. I decided to use Custom Matboard (CustomMat.com). This company sourced in Toronto, Canada offered really great bundled packages for quality mat boards.
                <br/><br/>

                What is matboard (also known as passepartout)? Matboard is a a durable paper based layer that is positioned between the frame and artwork. Matboard has an opening size that can be standard or custom cut. Pre-cut mats and custom cut mat boards are available for regular sized and custom frames.

                <br/><br/>

                {this.fetchImage(photo4)}
                <div className={"blog-article-photo-description"}>
                    Ordering 20x20 inch customized matboard / passe-partout for 11 x 9 inch photo
                </div>

                Of course they offer custom size options in the features as well, but I just needed high quality picture frame mats. They offered a variety of pre-cut mat colour options.
                The mat size was available at a steal of $49 CAD (or $40 USD) for FOUR 20x20 inch matboards. Their advertised product was a crisp 4 ply that is acid free to preserve my prints at archival and display quality.
                <br/><br/>

                {this.fetchImage(photo5)}
                {this.fetchImage(photo6)}
                {this.fetchImage(photo7)}

                The difference a mat cutter workshop delivered was substantial. This paper has a beautiful white core. The opening revealed just a hint of white that looked almost carved into the board. This bevelling detail and colour highlight just heightened the entire product. Since a variety of colour and texture options existed. I ordered some white mat and my uncut backing board to match I also purchased some lavender pre cut gallery mats for my larger frames just as a budget friendly room upgrade.

                <br/><br/>

                {this.fetchImage(photo8)}
                {this.fetchImage(photo9)}

                When my mats arrived my DIY felt like a custom design from a professional designer. I brought a bargain find of a frame set to its true potential. Elevating my purchase with this quick additional purchase made me feel like I just visited a framing store. This is a must try for anyone with a standard sized set of frames.

                <br/><br/>

                The website listed can actually source any shape for any size frame, or any project that requires housing our beloved images. I'm going to call back and order a crescent cut mat board to create a photo for my nephews nursery. I plan to take advantage of their calligraphy expert and have his name and date of birth inscribed on the mat, here are some examples below of what picture mats or passepartout can do.

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>

                <div className={"carousel-subject-subhead-type2"}>
                    Photo Gallery
                </div>

                {this.fetchImage(galleryPhoto1)}
                {this.fetchImage(galleryPhoto2)}
                {this.fetchImage(galleryPhoto3)}
                {this.fetchImage(galleryPhoto4)}
                {this.fetchImage(galleryPhoto5)}
                {this.fetchImage(galleryPhoto6)}
                {this.fetchImage(galleryPhoto7)}
                {this.fetchImage(galleryPhoto8)}
                {this.fetchImage(galleryPhoto9)}

                <div className={"visit-custom-mat-button"}>
                    <a href={"/"} className={"carousel-read-more-button"}>
                        Visit Custom Mat
                    </a>
                </div>


            </div>

            <div>
                <div className="elfsight-app-7688ab8e-8d1b-4130-9172-7c7f3e0d45b4"></div>
            </div>







        </div>
    }
}