import React from 'react';
import rotateFrame from "../../../../resources/svgIcons/rotate-icon.svg";
import displayInputFields from "./displayInputFields";
import warningIcon from "../../../../resources/svgIcons/warning-icon.svg";
import arrowUp from "../../../../resources/svgIcons/select-mat-arrow-up.svg";
import arrowDown from "../../../../resources/svgIcons/select-mat-arrow-down.svg";
import Select from "@material-ui/core/Select";


export default class ShowProductDescription extends React.Component {

  constructor() {
    super();
    this.state = {
      openMatSizeModal: true,
      openFractionModal: false,
      frameWidthCm: 50.8,
      frameHeightCm: 40.6,

    }
  };

  rotateFrameClicked() {
    this.props.rotateFrame(this.props.frameHeight, this.props.frameWidth);

    if (this.props.designFor === "matboardOnly") {
      this.props.polaroidWindowRendering(this.props.frameHeight, this.props.frameWidth);
    }

  };

  setPictureFrameSize(frameWidth, frameHeight) {
    if (this.props.matboardSizeValueType === "centimeters" && frameWidth < 100 && frameHeight < 100) {
      this.setState({frameWidthCm: frameWidth, frameHeightCm: frameHeight})
      this.props.setStateFrameRendering((frameWidth/2.54).toFixed(2), (frameHeight/2.54).toFixed(2));
    } else if ((frameWidth > 32 && frameHeight > 32) || frameWidth > 40 || frameHeight > 40) {
      window.alert("Sorry. We cannot deliver matboard larger than 40 x 32 inches due to our shipping restrictions.")
    } else {
      this.props.setStateFrameRendering(frameWidth, frameHeight);
    }
  };

  fetchFrameTitle(designForValue) {
    if (designForValue === "matboardOnly") {
      return "Custom Matboard"
    } else if (designForValue === "frame") {
      return `Customized Frame + Mat` ;
    } else {
      return "Customized Polaroid Frame"
    }
  };

  fractionalValues(widthOrHeight) {
    if (widthOrHeight === "width") {
      return <div>

      </div>
    } else if (widthOrHeight === "height") {
      return <div>
        <Select
            native
            value={this.props.fractionHeightValueSelected}
            onChange={(evt) => {this.setState({fractionHeightValueSelected: evt.target.value})}}
            variant={"outlined"}
        >
          {
            Object.keys(this.props.fractionalObject).map((fraction, key) => {
              return <option  value={fraction} key={`select-province-state-${key}`} >
                {fraction}
              </option >
            })
          }
        </Select>
      </div>
    }


  }

  fetchUnitConversionDirection(widthFraction, heightFraction) {

    if (widthFraction !== "None" || heightFraction !== "None") {
      return <div className="select-mat-input-field-direction-long">

        {this.props.fetchLanguage(`Vos dimensions seront converties avec une partie apparente de ${this.props.frameWidth} x ${this.props.frameHeight} pouce à la caisse.`, `Your dimensions will be converted to ${this.props.frameWidth} x ${this.props.frameHeight} inch reveal at the checkout.`)}

      </div>
    }

  }

  render() {
      return <div className={`select-mat-product-description`}>

      <div className="frame-size-desc-subtitle">
        <div className={this.props.designFor === "matboardOnly" ? "no-display" : "frame-size-desc-dimensions"}>
          Frame Size - {this.props.frameWidth} x {this.props.frameHeight} {this.props.frameMeasuringUnit} |
        </div>
        <div className={this.props.designFor === "matboardOnly" ? "no-display" : "frame-size-desc-button"} onClick={() => {this.rotateFrameClicked()}}>
          <img src={rotateFrame} className={"product-rendering-subcontrol-button-image"}
            alt={this.props.fetchLanguage("Cliquez pour faire pivoter le cadre.", "Click to rotate the frame.")}
          />
          <div className={"product-rendering-subcontrol-button-image-text"}>Rotate Frame</div>
        </div>
      </div>

      <div className={this.props.designFor === "matboardOnly" ? "matboard-only-frame-size-selector" : "no-display"}>

        <div className={"select-matboard-subdivider mobile-no-display"}>
          <div className={"select-mat-container-row"}>
            {this.props.fetchLanguage("Unité", "Unit")}:
            <span> </span>
            <span className={this.props.selectedUnit === "in" ? "bold" : ""} style={{cursor: "pointer"}} onClick={()=>this.props.changeUnit("in")}>{this.props.fetchLanguage("Pouce", "Inch")}</span>
            <span> | </span>
            <span className={this.props.selectedUnit === "cm" ? "bold" : ""} style={{cursor: "pointer"}} onClick={()=>this.props.changeUnit("cm")}> {this.props.fetchLanguage("CM", "CM")}</span>
          </div>
        </div>

        <div className={"select-mat-row"}>
          <img className={"select-matboard-window-caption-arrow"}
               onClick={() => this.setState({openMatSizeModal: !this.state.openMatSizeModal})}
               src={this.state.openMatSizeModal ? arrowUp : arrowDown}
              alt={"Click to open matsize modal"}
          />

          <div className={"select-matboard-subdivider mobile-display-only"} id={"select-matboard-unit-mobile"}>
            <div className={"select-mat-container-row"} id={"select-matboard-unit-mobile"}>
              {this.props.fetchLanguage("Unité", "Unit")}: <span><b>{this.props.fetchLanguage("po", "in")}</b></span>
            </div>
          </div>

          <div className={"select-mat-row-divider mobile-add-row-bottom"}>
            <div className="select-matboard-window-caption-text">
              {this.props.fetchLanguage("Taille du passe-partout", "Matboard Size")}

            </div>
            <div className="select-matboard-window-caption-selected-colour-text">
              {this.props.frameWidth} x {this.props.frameHeight} {this.props.fetchLanguage("po", "in")}
            </div>
          </div>

        </div>

        <div className={this.state.openMatSizeModal ? "select-mat-size-option-toggle" : "no-display"}>
          <div className={"select-mat-sub-row"}>
            <img className={"select-mat-guidance-icon"} src={warningIcon} alt= {this.props.fetchLanguage("Veuillez vous assurer que les renseignements sont exacts", "Please make sure this is correct")}/>
            <div className={"select-mat-guidance-desc"}>
              {this.props.fetchLanguage("Veuillez vous assurer que les renseignements sont exacts", "Please make sure this is correct")}

            </div>
            <div className={"select-mat-guidance-button"}>
              <a href={this.props.fetchLanguage("design-guide-fr", "design-guide")}>
                Guide
              </a>
            </div>
          </div>

          {/*Unit Selector*/}

          <div className={"select-mat-sub-row"}>
            <div
                className={this.props.matboardSizeValueType === "decimals" ? "value-type-selector value-type-selected" : "value-type-selector"}
                id={"matsize-decimals"}
                onClick={() => {this.props.parentSetStateCallBack("matboardSizeValueType", "decimals")}}
            >
              {this.props.fetchLanguage("Décimales", "Decimals (in)")}
            </div>
            <div className={"value-type-selector"}> | </div>
            <div
                className={this.props.matboardSizeValueType === "fractions" ? "value-type-selector value-type-selected" : "value-type-selector"}
                id={"matsize-fractions"}
                onClick={() => {this.props.parentSetStateCallBack("matboardSizeValueType", "fractions"); this.props.setPictureFrameSizeFraction(20, "1/4", 16, "1/4");}}
            >
              {this.props.fetchLanguage("Fractions", "Fractions (in)")}
            </div>
            <div className={"value-type-selector"}> | </div>
            <div
                className={this.props.matboardSizeValueType === "standard" ? "value-type-selector value-type-selected" : "value-type-selector"}
                id={"matsize-popular"}
                onClick={() => {this.props.parentSetStateCallBack("matboardSizeValueType", "standard")}}
            >
              {this.props.fetchLanguage("Tailles Populaires", "Popular Sizes (in)")}
            </div>
            <div className={"value-type-selector"}> | </div>
            <div
                className={this.props.matboardSizeValueType === "centimeters" ? "value-type-selector value-type-selected" : "value-type-selector"}
                id={"matsize-popular"}
                onClick={() => {this.props.parentSetStateCallBack("matboardSizeValueType", "centimeters")}}
            >
              {this.props.fetchLanguage("Centimeters", "Centimeters (cm)")}
            </div>
          </div>

          {/*For Decimal Values HERE*/}

          <div className={this.props.matboardSizeValueType === "decimals" ? "select-mat-sub-row" : "no-display"}>
            <div className="select-window-multiple-window-dimensions">
              <div className={"select-mat-input-field-direction"}>
                {this.props.fetchLanguage("largeur", "width")}
              </div>
              {displayInputFields("", this.props.frameWidth, (event) => this.setPictureFrameSize(event.target.value, this.props.frameHeight), "outlined")}
            </div>

            <div className={"select-mat-window-quantity-row-column-transition"}>
              x
            </div>

            <div className="select-window-multiple-window-dimensions">
              <div className={"select-mat-input-field-direction"}>
                {this.props.fetchLanguage("Hauteur", "height")}
              </div>
              {displayInputFields("", this.props.frameHeight, (event) => this.setPictureFrameSize(this.props.frameWidth, event.target.value), "outlined")}
            </div>

            <div className="select-mat-input-field-direction-long">
              {this.props.fetchLanguage("Mesures partielles à l’aide de décimales (c’est-à-dire 19 ¾ -> 19,75)", "Partial measurements using decimals (ie: 19.75).")}
            </div>
          </div>

          {/*For fractional Values HERE*/}

          <div className={this.props.matboardSizeValueType === "fractions" ? "select-mat-sub-row" : "no-display"}>
            <div className="select-window-multiple-window-dimensions matboard-size-frations">
              <div className={"select-mat-input-field-direction"}>{this.props.fetchLanguage("largeur", "width")}</div>
              {displayInputFields("", this.props.fractionWidthValueWholeNumber,
                  (event) => this.props.setPictureFrameSizeFraction(event.target.value, this.props.fractionWidthValueSelected, this.props.fractionHeightValueWholeNumber, this.props.fractionHeightValueSelected), "outlined")}
              <Select
                  native
                  value={this.props.fractionWidthValueSelected}
                  onChange={(evt) => {this.props.setPictureFrameSizeFraction(this.props.fractionWidthValueWholeNumber, evt.target.value, this.props.fractionHeightValueWholeNumber, this.props.fractionHeightValueSelected)}}
                  variant={"outlined"}
              >
                {
                  Object.keys(this.props.fractionalObject).map((fraction, key) => {
                    return <option  value={fraction} key={`select-province-state-${key}`} >
                      {fraction}
                    </option >
                  })
                }
              </Select>
            </div>

            <div className={"select-mat-window-quantity-row-column-transition"}>
              x
            </div>

            <div className="select-window-multiple-window-dimensions matboard-size-frations">
              <div className={"select-mat-input-field-direction"}>{this.props.fetchLanguage("Hauteur", "Height")}</div>
              {displayInputFields("", this.props.fractionHeightValueWholeNumber,
                  (event) => this.props.setPictureFrameSizeFraction(this.props.fractionWidthValueWholeNumber, this.props.fractionWidthValueSelected, event.target.value, this.props.fractionHeightValueSelected), "outlined")}
              <Select
                  native
                  className={"window-size-fractional-input"}
                  value={this.props.fractionHeightValueSelected}
                  onChange={(evt) => {this.props.setPictureFrameSizeFraction(this.props.fractionWidthValueWholeNumber, this.props.fractionWidthValueSelected, this.props.fractionHeightValueWholeNumber, evt.target.value)}}
                  variant={"outlined"}
              >
                {
                  Object.keys(this.props.fractionalObject).map((fraction, key) => {
                    return <option  value={fraction} key={`select-province-state-${key}`} >
                      {fraction}
                    </option >
                  })
                }
              </Select>
            </div>

            {this.fetchUnitConversionDirection(this.props.fractionWidthValueSelected, this.props.fractionHeightValueSelected)}


          </div>

          {/*For standard Values HERE*/}

          <div className={this.props.matboardSizeValueType === "standard" ? "select-mat-sub-row" : "no-display"}>
            <div className="matboard-size-popular-values-input">
              <div className={"select-mat-input-field-direction"}>
                {this.props.fetchLanguage("taille du passe-partout", "Matboard Size")}

              </div>
              <Select
                  native
                  className={"matboard-size-popular-values-input"}
                  value={this.props.setFrameStandardSizeRendering[this.props.selectedStandardMatboardSize]}
                  onChange={(event) => {this.setPictureFrameSize(this.props.standardMatSizes[event.target.value].width, this.props.standardMatSizes[event.target.value].height)}}
                  variant={"outlined"}
              >
                {
                  Object.keys(this.props.standardMatSizes).map((matSize, key) => {
                    return <option value={matSize} key={`select-province-state-${key}`} >
                      {this.props.standardMatSizes[matSize].width} ({this.props.fetchLanguage("Largeur", "Width")}) x {this.props.standardMatSizes[matSize].height} ({this.props.fetchLanguage("Hauteur", "Height")})
                    </option >
                  })
                }
              </Select>
            </div>
          </div>

          {/*For cm Values HERE*/}

          <div className={this.props.matboardSizeValueType === "centimeters" ? "select-mat-sub-row" : "no-display"}>
            <div className="select-window-multiple-window-dimensions">
              <div className={"select-mat-input-field-direction"}>
                {this.props.fetchLanguage("largeur", "width")}
              </div>
              {displayInputFields("", this.state.frameWidthCm, (event) => this.setPictureFrameSize(event.target.value, this.state.frameHeightCm), "outlined")}
            </div>

            <div className={"select-mat-window-quantity-row-column-transition"}>
              x
            </div>

            <div className="select-window-multiple-window-dimensions">
              <div className={"select-mat-input-field-direction"}>
                {this.props.fetchLanguage("Hauteur", "height")}
              </div>
              {displayInputFields("", this.state.frameHeightCm, (event) => this.setPictureFrameSize(this.state.frameWidthCm, event.target.value), "outlined")}
            </div>

            <div className="select-mat-input-field-direction-long">
              At checkout, dimensions are shown in <u>inches</u>.<br/>
              <b>
                {(parseFloat(this.props.frameWidth) * 2.54).toFixed(1)} x {(parseFloat(this.props.frameHeight) * 2.54).toFixed(1)} cm is equivalent to  {(parseFloat(this.props.frameWidth)).toFixed(2)} x {parseFloat(this.props.frameHeight).toFixed(2)} in inches.
              </b>
            </div>
          </div>


        </div>

      </div>

      <div className={this.props.designFor === "matboardOnly" ? "no-display" : "frame-size-desc-price"}>
        ${this.props.framePrice}
      </div>
    </div>
  }
}