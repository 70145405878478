import React from "react";
import landingPageArrowLeft from "../../../resources/logo/landing-page-arrow-left.svg";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import recPhotoNo1 from "../../../resources/precut-mat-options/18x24/4x6no1.jpg";
import recPhotoNo2 from "../../../resources/precut-mat-options/18x24/4x6no2.jpg";
import recPhotoNo3 from "../../../resources/precut-mat-options/18x24/4x6no3.jpg";

import sqPhotoNo1 from "../../../resources/precut-mat-options/20x20/4x4no1.jpg";
import sqPhotoNo2 from "../../../resources/precut-mat-options/20x20/4x4no2.jpg";
import sqPhotoNo3 from "../../../resources/precut-mat-options/20x20/4x4no3.jpg";

import colourOptions1 from "../../../resources/precut-mat-options/colourOptions01-min.JPG";
import colourOptions2 from "../../../resources/precut-mat-options/colourOptions2-min.JPG";
import colourOptions3 from "../../../resources/precut-mat-options/colourOptions3-min.JPG";
import colourOptions4 from "../../../resources/precut-mat-options/colourOptions4.JPG";
import colourOptions5 from "../../../resources/precut-mat-options/colourOptions5.PNG";
import colourOptions6 from "../../../resources/precut-mat-options/colourOptions6.JPG";
import colourOptions7 from "../../../resources/precut-mat-options/colourOptions7.JPG";
import colourOptions8 from "../../../resources/precut-mat-options/colourOptions8.JPG";
import colourOptions9 from "../../../resources/precut-mat-options/colourOptions9.JPG";
import colourOptions10 from "../../../resources/precut-mat-options/colourOptions10.JPG";

import {Helmet} from "react-helmet";
import WhatIsMatboard from "../page-howItWorks/whatIsMatboard";
import InstagramLoader from "../page-landing/instagramLoader";
import ReviewComponent from "../app-review/reviewComponent";
import LandingPageReviewStars from "../page-landing/landingPageReviewStars";
import LandingPageFAQ from "../page-landing/landingPageFAQ";
import SatisfactionTransition from "../page-landing/satisfactionTransition";
import {productDB} from "../comp-pricing/productDB";
import Tooltip from "@material-ui/core/Tooltip";
import LazyLoad from "react-lazyload";

export default class ShopByFrameSizeLineItem extends React.Component {

    constructor() {
        super();
        this.state = {

        };
    };

    componentDidMount() {
        // windowaddEventListener("scroll", this.setState({showNotificationBanner: false})
        this.setState({
            french: (window.location.pathname.slice(-2) === "fr") ? true : false
        })
    };

    fetchMatboardColours(primaryOrSecondaryMat) {
        var matboardList = Object.keys(productDB.matboards);

        return matboardList.map((mat, key) => {
            return <div className={productDB.matboards[mat].active ? "matboard-selected-colour-container" : "no-display"} key={`matboard-selected-colour-container-${key}`}>
                <Tooltip title={productDB.matboards[mat].color} placement={"top"}>
                    <img alt={`Selected matboard colour is -- ${productDB.matboards[mat].color}`}
                         className={ mat === this.state[primaryOrSecondaryMat] ? "matboard-colour matboard-colour-selected" : "matboard-colour"}
                         src={productDB.matboards[mat].img}
                    />
                </Tooltip>

            </div>
        })
    };

    fetchContent() {
        var isThisPageSquare = this.props.matWidth === this.props.matHeight ? true : false;

        if (this.props.french) {
            return <div className={`precut-mat-container`}>

                <div className={"precut-container-left"}>

                    <Carousel>
                        <div className={"carousel-image-item"}>
                            <img src={isThisPageSquare ? sqPhotoNo1 : recPhotoNo1} className={"precut-container-left-photo"}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={isThisPageSquare ? sqPhotoNo2 : recPhotoNo2} className={"precut-container-left-photo"}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={isThisPageSquare ? sqPhotoNo3 : recPhotoNo3} className={"precut-container-left-photo"}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions1}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions2}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions3}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions4}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions5}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions6}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions7}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions8}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions9}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions10}/>
                        </div>

                    </Carousel>

                </div>

                <div className={"precut-container-right"}>

                    <div style={{width: "auto", overflow: "auto", paddingBottom: "16px"}}>

                    </div>

                    <h1 className={"precut-size-header"}>
                        PASSE-PARTOUT DE {this.props.matWidth} x {this.props.matHeight} POUCES POUR <br/>
                        CADRE PHOTO DE {this.props.matWidth} x {this.props.matHeight} POUCES<br/>
                        AVEC DÉCOUPE DE FENÊTRE PERSONNALISÉE
                    </h1>

                    <div className={"select-mat-row-divider mobile-add-row-bottom"} style={{width: "100%", overflow: "auto"}}>
                        <div className={"select-matboard-window-caption-text matboard-colour-name-desc"} style={{width: "auto"}}>
                            TAILLE DU PASSE-PARTOUT
                        </div>

                        <div className={"select-matboard-window-caption-selected-colour-text"}>
                            {this.props.matWidth} x {this.props.matHeight} pouces
                        </div>
                    </div>

                    <div className={"select-mat-row-divider mobile-add-row-bottom"} style={{width: "100%", overflow: "auto"}}>
                        <div className={"select-matboard-window-caption-text"} style={{width: "auto"}}>
                            TAILLE DE LA FENÊTRE
                        </div>

                        <div className={"select-matboard-window-caption-selected-colour-text"}>
                            Taille personnalisée
                        </div>
                    </div>



                    <div className={"select-mat-row-divider mobile-add-row-bottom"} style={{width: "100%", marginLeft: "0"}}>
                        <div className={"select-mat-row-divider mobile-add-row-bottom"} style={{width: "100%", marginLeft: "0"}}>
                            <div className="select-matboard-window-caption-text matboard-colour-name-desc">
                                COULEUR DU PASSE-PARTOUT
                            </div>
                            <div className={"select-matboard-window-caption-selected-colour-text"}>
                                26 Couleurs
                            </div>
                        </div>

                        <div className={"select-mat-size-option-toggle"} style={{width: "100%", marginLeft: "0", marginTop: "26px"}}>
                            {this.fetchMatboardColours()}
                        </div>
                    </div>

                    <div style={{width: "100%", overflow: "auto"}}>
                        <a className="add-to-bag-button"
                           style={{width: "auto", marginTop: "24px"}}
                           href={`/customize-matboards-fr?mWidth=${this.props.matWidth}&mHeight=${this.props.matHeight}&wWidth=${this.props.matWidth * 0.5}&wHeight=${this.props.matHeight * 0.5}&mTop=${this.props.matWidth * 0.25}&mLeft=${this.props.matWidth * 0.25}&mRight=${this.props.matHeight * 0.25}&mBottom=${this.props.matHeight * 0.25}&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=true`}>
                            Passe-partout Personnalisé
                        </a>


                        <div className={"select-mat-row-divider mobile-add-row-bottom"} style={{width: "100%", overflow: "auto", margin: "24px 0 0 0"}}>
                            <a className={"select-mat-request-samples"} href={"/shop-by-frame-size-fr"} style={{margin: "24px 0" }}>
                                Vous souhaitez un passe-partout de taille différente
                            </a>
                        </div>
                    </div>

                </div>


                <div style={{width: "100%", overflow: "auto", lineHeight: 2}}>
                    <div className={"product-desc-dividers"}>

                        <div className="product-desc-dividers-header">
                            <b>Politique de Retour</b>
                            <br/><br/>
                        </div>
                        100% de bonheur garanti. Si vous n'êtes pas satisfait de votre commande, veuillez nous en informer. Contactez-nous à l'adresse hi@custommat.com, et nous rectifierons la situation. Nous avons une politique de retours et de remboursements facile.

                        <div className="product-desc-dividers-header">
                            Production et Livraison
                            <br/><br/>
                        </div>
                        Nous livrons nos passe-partout de taille personnalisée jusqu'à votre porte partout au Canada et aux États-Unis.
                        <br/>


                        <div className="product-desc-dividers-header">
                            Toutes nos commandes sont expédiées depuis:<br/>
                        </div>

                        <ul>
                            <li>
                                Canada : Entrepôt du sud de l'Ontario
                            </li>
                            <li>
                                États-Unis : Entrepôt du nord de l'État de New York
                            </li>
                        </ul>

                        Tous nos passe-partout sont produits au Canada, aux États-Unis et au Royaume-Uni.

                        <br/>

                        <div className="product-desc-dividers-header">
                            Temps de Production et de Livraison<br/>
                        </div>

                        <ul>
                            <li>
                                De la commande à la production : 1 à 2 jours ouvrables
                            </li>
                            <li>
                                Délai de livraison : 1 à 6 jours ouvrables
                            </li>
                        </ul>

                        <div className="product-desc-dividers-header">
                            Estimations de livraison<br/><br/>
                        </div>
                        Canada
                        <ul>
                            <li>
                                ON, QC : 2 à 5 jours
                            </li>
                            <li>
                                AB, SK, MB : 3 à 7 jours
                            </li>
                            <li>
                                BC : 5 à 8 jours
                            </li>
                            <li>
                                NB, NL, NS, PE : 4 à 8 jours
                            </li>
                            <li>
                                Territoires du Nord : 7 à 12 jours
                            </li>
                        </ul>

                        <br/>
                        États-Unis<br/>
                        <ul>
                            <li>
                                Côte Est et Midwest : 3 à 6 jours
                            </li>
                            <li>
                                Nord-Ouest : 4 à 7 jours
                            </li>
                            <li>
                                Sud-Est et Sud-Ouest : 4 à 7 jours
                            </li>
                            <li>
                                Alaska, Hawaï, Porto Rico : 7 à 14 jours
                            </li>
                        </ul>

                        <div className="product-desc-dividers-header">
                            Quantité Minimale de Commande (MOQ)
                            <br/><br/>
                        </div>

                        Il n'y a pas de quantité minimale de commande. La livraison est gratuite pour les commandes de plus de 49 $ ou un tarif fixe de 9,95 $ pour les commandes de moins.


                        <div className="product-desc-dividers-header">
                            Détails du Produit:<br/>
                        </div>

                        <ul>
                            <li>
                                Taille du passe-partout: {this.props.matWidth} x {this.props.matHeight} pouces
                            </li>
                            <li>
                                Standard 4 plis (1/16 de pouce) d'épaisseur
                            </li>
                            <li>
                                Biseau blanc de 45 degrés avec un cœur blanc net
                            </li>
                            <li>
                                Sans acide (surface tamponnée pour neutraliser l'acidité) pour répondre à vos besoins de préservation
                            </li>
                            <li>
                                Résistant à la décoloration et à l'écoulement de l'encre
                            </li>
                            <li>
                                Fabriqué et expédié depuis le Canada
                            </li>
                            <br/>
                        </ul>


                        <div className="product-desc-dividers-header">
                            <b>Des Questions?</b>
                            <br/><br/>
                        </div>
                        Si vous avez des questions, veuillez nous envoyer un e-mail à hi@CustomMat.com ou nous envoyer un SMS au 604•345•8428.
                    </div>
                    <div className={"product-desc-dividers"}>
                        <div className="product-desc-dividers-header">
                            Résumé du Produit:<br/><br/>
                        </div>
                            Passe-partout photo avec cœur blanc de {this.props.matWidth} x {this.props.matHeight} pouces, conçu pour s'adapter aux cadres photo de {this.props.matWidth} x {this.props.matHeight} pouces de taille standard. Du blanc lisse (blanc éclatant) au noir, nous avons plus de 20 couleurs de passe-partout parmi lesquelles choisir.
                        <br/><br/>
                        Si vous n'êtes pas sûr de la taille de votre cadre, veuillez mesurer la largeur et la hauteur de la plaque de support de votre cadre. Vous pouvez également ouvrir le cadre et mesurer la largeur et la hauteur totales de votre acrylique, de votre verre ou de votre plexiglas. Cela devrait être la taille de votre passe-partout.
                        <br/><br/>
                        <a href="/design-guide-fr"><u>Voici le lien vers le guide des tailles.</u></a>
                        <br/><br/>
                        Tous nos passe-partout photo de {this.props.matWidth} x {this.props.matHeight} pouces sont livrés avec des fenêtres découpées en biais à 45 degrés. Nos tailles de fenêtres sont personnalisables pour s'adapter parfaitement à votre œuvre.
                        <br/><br/>
                        Tous nos passe-partout sont de couleurs mates et sobres, à l'exception des couleurs dorée et argentée. Nos passe-partout dorés et argentés sont feuilletés pour leur donner un aspect brillant, similaire à ce que l'on trouve dans les cadres sur mesure pour les certificats et les diplômes.
                        <br/><br/>
                        Des remises quantitatives sont disponibles pour votre commande. Les remises seront automatiquement appliquées lorsque vous commandez 2+, 4+ et 10+ unités. Si vous avez besoin de plus de 50 feuilles, veuillez nous contacter pour obtenir un devis. Nous livrons partout au Canada et aux États-Unis.
                        <br/><br/>

                        <div className="product-desc-dividers-header">
                            FAQ
                            <br/><br/>
                        </div>

                        <b>Des options de double passe-partout sont-elles disponibles?</b>
                        <br/><br/>
                        Oui. Une fois que vous procédez à la personnalisation de votre passe-partout, vous pouvez ajouter l'option de double passe-partout.
                        <br/><br/>

                        <b>Puis-je commander un kit de présentation ou un kit d'exposition?</b>
                        <br/><br/>
                        Oui. Cliquez ici pour commander un kit d'exposition.<br/><br/>

                        Veuillez noter que nos kits d'exposition comprennent:<br/>
                        <ol>
                            <li>
                                Un passe-partout découpé sur mesure ou un passe-partout prédécoupé de taille standard,
                            </li>
                            <li>
                                Un passe-partout non découpé (passe-partout sans découpe, à utiliser comme passe-partout de support ou de montage), et
                            </li>
                            <li>
                                Des sacs transparents ou des pochettes en acrylique avec des fermetures.
                            </li>
                        </ol>

                        <b>Vendez-vous des panneaux en mousse?</b>
                        <br/><br/>
                        Non. Nous ne proposons pas de panneaux en mousse. Au lieu de panneaux en mousse, vous pouvez utiliser notre passe-partout non découpé (passe-partout blanc) pour vos besoins de montage.
                        <br/><br/>


                        <b>Plus de questions ?</b>
                        <br/><br/>
                        Veuillez <a href={"faq-fr"}><u>cliquer ici</u></a> pour d'autres questions.




                    </div>
                </div>
            </div>
        } else {
            return             <div className={`precut-mat-container`}>

                <div className={"precut-container-left"}>

                    <Carousel>
                        <div className={"carousel-image-item"}>
                            <img src={isThisPageSquare ? sqPhotoNo1 : recPhotoNo1} className={"precut-container-left-photo"}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={isThisPageSquare ? sqPhotoNo2 : recPhotoNo2} className={"precut-container-left-photo"}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={isThisPageSquare ? sqPhotoNo3 : recPhotoNo3} className={"precut-container-left-photo"}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions1}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions2}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions3}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions4}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions5}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions6}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions7}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions8}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions9}/>
                        </div>
                        <div className={"carousel-image-item"}>
                            <img src={colourOptions10}/>
                        </div>

                    </Carousel>

                </div>

                <div className={"precut-container-right"}>

                    <div style={{width: "auto", overflow: "auto", paddingBottom: "16px"}}>

                    </div>

                    <h1 className={"precut-size-header"}>
                        {this.props.matWidth} x {this.props.matHeight} inch Matboard for <br/>
                        {this.props.matWidth} x {this.props.matHeight} inch Picture Frame<br/>
                        With Custom Window Cutout
                    </h1>

                    <div className={"select-mat-row-divider mobile-add-row-bottom"} style={{width: "100%", overflow: "auto"}}>
                        <div className={"select-matboard-window-caption-text matboard-colour-name-desc"} style={{width: "auto"}}>
                            Matboard Size
                        </div>

                        <div className={"select-matboard-window-caption-selected-colour-text"}>
                            {this.props.matWidth} x {this.props.matHeight} inch
                        </div>
                    </div>

                    <div className={"select-mat-row-divider mobile-add-row-bottom"} style={{width: "100%", overflow: "auto"}}>
                        <div className={"select-matboard-window-caption-text"} style={{width: "auto"}}>
                            Window size
                        </div>

                        <div className={"select-matboard-window-caption-selected-colour-text"}>
                            Custom Size
                        </div>
                    </div>



                    <div className={"select-mat-row-divider mobile-add-row-bottom"} style={{width: "100%", marginLeft: "0"}}>
                        <div className={"select-mat-row-divider mobile-add-row-bottom"} style={{width: "100%", marginLeft: "0"}}>
                            <div className="select-matboard-window-caption-text matboard-colour-name-desc">
                                Matboard {this.props.location === "us" ? " Color" : " Colour"}
                            </div>
                            <div className={"select-matboard-window-caption-selected-colour-text"}>
                                26 {this.props.location === "us" ? " Colors" : " Colours"}
                            </div>
                        </div>

                        <div className={"select-mat-size-option-toggle"} style={{width: "100%", marginLeft: "0", marginTop: "26px"}}>
                            {this.fetchMatboardColours()}
                        </div>
                    </div>

                    <div style={{width: "100%", overflow: "auto"}}>
                        <a className="add-to-bag-button"
                           style={{width: "auto", marginTop: "24px"}}
                           href={`/customize-matboards?mWidth=${this.props.matWidth}&mHeight=${this.props.matHeight}&wWidth=${this.props.matWidth * 0.5}&wHeight=${this.props.matHeight * 0.5}&mTop=${this.props.matWidth * 0.25}&mLeft=${this.props.matWidth * 0.25}&mRight=${this.props.matHeight * 0.25}&mBottom=${this.props.matHeight * 0.25}&mColor=smoothWhite&dmColor=none&dmWidth=0&backing=false&oval=false&ctr=true`}>
                            Customize Matboard
                        </a>


                        <div className={"select-mat-row-divider mobile-add-row-bottom"} style={{width: "100%", overflow: "auto", margin: "24px 0 0 0"}}>
                            <a className={"select-mat-request-samples"} href={"/shop-by-frame-size"} style={{margin: "24px 0" }}>
                                Want a different size mat?
                            </a>
                        </div>
                    </div>

                </div>


                <div style={{width: "100%", overflow: "auto", lineHeight: 2}}>
                    <div className={"product-desc-dividers"}>

                        <div className="product-desc-dividers-header">
                            <b>Return Policy</b>
                            <br/><br/>
                        </div>
                        100% Happiness Guaranteed. If you are not happy with your order, please let us know. Contact us through hi@custommat.com and we will make it right. We have an easy returns & refund policy.



                        <div className="product-desc-dividers-header">
                            Production & Delivery
                            <br/><br/>
                        </div>
                        We deliver our custom size matboards to your door anywhere in Canada and the USA. <br/>


                        <div className="product-desc-dividers-header">
                            All our orders dispatch from<br/>
                        </div>

                        <ul>
                            <li>
                                Canada: Southern Ontario Warehouse
                            </li>
                            <li>
                                United States: Upstate New York Warehouse
                            </li>
                        </ul>

                        All our matboards are produced in Canada, the USA, and the UK.

                        <br/>

                        <div className="product-desc-dividers-header">
                            Production & Delivery Time<br/>
                        </div>

                        <ul>
                            <li>
                                From order to production: 1-2 business days
                            </li>
                            <li>
                                Delivery time: 1-6 business days
                            </li>
                        </ul>

                        <div className="product-desc-dividers-header">
                            Delivery Estimates<br/><br/>
                        </div>
                        Canada
                        <ul>
                            <li>
                                ON, QC: 2 - 5 days
                            </li>
                            <li>
                                AB, SK, MB: 3 - 7 days
                            </li>
                            <li>
                                BC: 5 - 8 days
                            </li>
                            <li>
                                NB, NL, NS, PE: 4 - 8 days
                            </li>
                            <li>
                                Northern Territories: 7 - 12 days
                            </li>
                        </ul>

                        <br/>
                        United States<br/>
                        <ul>
                            <li>
                                East Coast and Mid-West: 3 - 6 days
                            </li>
                            <li>
                                Northwest: 4 - 7 days
                            </li>
                            <li>
                                Southeast and Southwest: 4 - 7 days
                            </li>
                            <li>
                                Alaska, Hawaii, Puerto Rico: 7 - 14 days
                            </li>
                        </ul>

                        <div className="product-desc-dividers-header">
                            Minimum Order Quantity (MOQ)
                            <br/><br/>
                        </div>

                        There is no minimum order quantity. Free shipping across Canada & the USA.

                        <div className="product-desc-dividers-header">
                            Product Details:<br/>
                        </div>

                        <ul>
                            <li>
                                Matboard Size: {this.props.matWidth} x {this.props.matHeight} inch
                            </li>
                            <li>
                                Standard 4-ply (1/16 inch) thick
                            </li>
                            <li>
                                45 degree, crisp, white-core bevel
                            </li>
                            <li>
                                Acid-free (acid-neutralized) buffered surface to meet your preservation needs.
                            </li>
                            <li>
                                Fade and bleed resistant
                            </li>
                            <li>
                                Made and shipped from Canada
                            </li>
                            <br/>
                        </ul>


                        <div className="product-desc-dividers-header">
                            <b>Any Questions?</b>
                            <br/><br/>
                        </div>
                        If you have any questions, please email us hi@CustomMat.com or text us at 604•345•8428.
                    </div>
                    <div className={"product-desc-dividers"}>
                        <div className="product-desc-dividers-header">Product Summary:<br/><br/></div>
                        {this.props.matWidth} x {this.props.matHeight} inch white core photo mat board, designed to fit standard-sized {this.props.matWidth} x {this.props.matHeight} inch photo frames. From Smooth White (Bright White) mat color to Black, we have 20+ mat colors available for you to choose from.
                        <br/><br/>
                        If you are not sure about your frame size, please measure the width and height of your frame’s backing board. You can also open up the frame and measure the full width and height of your acrylic, glass, or plexiglass. That should be your matboard size.
                        <br/><br/>
                        <a href="design-guide"><u>Here's the link to sizing guide</u></a>
                        <br/><br/>
                        All our  {this.props.matWidth} x {this.props.matHeight} inch photo mats will come with 45-degree bevel-cut windows. Our window opening sizes are customizable to fit your artwork perfectly.
                        <br/><br/>
                        All our matboards come in clean, matte colors, except for gold and silver colors. Our gold and silver matboards are foiled to make them shiny—like the ones you see in custom frames for certificates and diplomas.
                        <br/><br/>
                        Quantity discounts are available for your order. Discounts will be reflected automatically when you order 2+, 4+, and 10+ units. If you need more than 50 sheets, please contact us for pricing. We ship anywhere in Canada and the United States.
                        <br/><br/>

                        <div className="product-desc-dividers-header">
                            FAQs
                            <br/><br/>
                        </div>

                        <b>Is Double Matting Options Available?</b>
                        <br/><br/>
                        Yes. Once you proceed to customize your matboard, you can add double matting option.
                        <br/><br/>

                        <b>Can I order presentation kit / show kit?</b>
                        <br/><br/>
                        Yes. Click here to order a show kit.<br/><br/>

                        Please note our show kits consist of<br/>
                        <ol>
                            <li>
                                Custom-cut matboard or standard-sized pre-cut matboard,
                            </li>
                            <li>
                                Uncut matboard (matboard without an opening to be used as backing/mounting matboard), and
                            </li>
                            <li>
                                Clear bags or acrylic sleeves with seals.
                            </li>
                        </ol>

                        <b>Do you sell foam boards?</b>
                        <br/><br/>
                        No. We don’t do foam boards. Instead of foamboards, you can use our uncut matboard (white mat) for your mounting needs.
                        <br/><br/>


                        <b>More Questions?</b>
                        <br/><br/>
                        Please <a href={"faq"}><u>click here</u></a> for other questions.




                    </div>
                </div>
            </div>
        }
    }

    render() {

        return <div>
            <Helmet>
                <title>
                    {this.state.french ?
                        `Passe-partout sur mesure ${this.state.selectedFrameSize} pouces pour cadres photo | 20+ couleurs disponibles` :
                        `Custom ${this.props.matWidth} x ${this.props.matHeight} Matboard for Picture Frames | 20+ Colors Available`
                    }
                </title>
                <meta name="description" content={
                    this.state.french ? `Découvrez notre politique de retour, nos délais de production et de livraison rapides pour les passe-partout sur mesure. Profitez de notre Garantie 100 % Satisfaction avec des retours et des remboursements faciles. Livraison à travers le Canada et les États-Unis. Aucune quantité minimale requise. Passe-partout premium de ${this.props.matWidth} x ${this.props.matHeight} pouces avec cœur blanc et différentes couleurs. Fenêtres personnalisables à biseau de 45 degrés. Rabais quantitatifs disponibles.` :
                        `Explore our return policy, fast production & delivery times for custom size matboards. Get 100% Happiness Guaranteed with easy returns & refunds. Shipping across Canada & USA. No minimum order. Premium ${this.props.matWidth} x ${this.props.matHeight} inch white core photo mat boards in various colors. Customizable 45-degree bevel-cut windows. Quantity discounts available.`
                } />
            </Helmet>

            {this.fetchContent()}



            <WhatIsMatboard/>

            <InstagramLoader
                french={this.state.french}
            />

            <ReviewComponent
                french={this.state.french}
            />

            <SatisfactionTransition
                french={this.state.french}
            />


            <LandingPageFAQ
                location = {this.props.location}
            />



        </div>
    }
}