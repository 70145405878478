import React from "react";
import RefundPolicy from "../page-disclosure/refunds";
import {marketList} from "../articleDB/articleCityNames";
import dropdownArrow from "../../../resources/svgIcons/chevron-up.svg";

export default class ShippingPage extends React.Component {

    constructor() {
        super();
        this.state = {
            bc: false,
            ab: false,
            sk: false,
            mb: false,
            on: false,
            qc: false,
            french: false,
        };
    };

    componentDidMount() {
        // windowaddEventListener("scroll", this.setState({showNotificationBanner: false})

        this.setState({
            french: (window.location.pathname.slice(-2) === "fr") ? true : false
        })
    };


    toggleModal(stateToToggle) {
        this.setState({[stateToToggle]: !this.state[stateToToggle]})
    }

    render() {
        return <div>
            <div className={`general-static-page-container`}>
                <div className={`general-static-page-container-heading`}>
                    Shipping Info
                </div>

                <div className={`general-static-page-container-topic-heading`}>
                    Canada, USA, & UK
                </div>

                <div className={`general-static-page-container-text`}>
                    <ul>
                        <li>
                            <h2>
                                We ship directly to your door anywhere in Canada, the USA, and the UK — free of charge!
                            </h2>
                        </li>
                        <li>
                            <b>Free shipping on <u>all</u> orders,</b> regardless of size or dimensions.
                        </li>
                        <li>
                            <b>We handle all tax-related matters,</b> including customs, VAT, import tariffs, and state/provincial sales taxes.
                        </li>
                        <li>
                            We deliver to Alaska, Hawaii, Puerto Rico, and overseas territories, including PO and APO boxes.
                        </li>
                        <li>
                            Canadian Deliveries: Canada Post, Purolator, and UniUni (2–5 business days).
                        </li>
                        <li>
                            U.S. Deliveries: DHL, UPS, and USPS (2–6 business days).
                        </li>
                        <li>
                            U.K. Deliveries: APC, POST NL, and Royal Mail (5–12 business days).
                        </li>
                        <i>Courier selection depends on package size, weight, and shipping address.</i>
                    </ul>
                </div>

                <div className={`general-static-page-container-topic-heading`}>
                    European Union, Norway, & Switzerland
                </div>

                <div className={`general-static-page-container-text`}>
                    <ul>
                        <li>
                            <h2>
                                We ship to EU nations, Norway, and Switzerland free of charge on eligible orders. Additional charges apply for larger items.
                            </h2>
                        </li>
                        <li>
                            <b>Free shipping on orders,</b> smaller than 24 inches.
                        </li>
                        <li>
                            Items larger than 24 inches (one side) incur ~$10 USD shipping.
                        </li>
                        <li>
                            Items larger than 28 inches (one side) incur ~$15 USD shipping.
                        </li>
                        <li>
                            <b>We handle all tax-related matters,</b> including customs, VAT, import tariffs, and state/provincial sales taxes.
                        </li>
                        <li>
                            Europe Deliveries: APC, POST NL, and National Carriers (6–15 business days).
                        </li>
                        <i>Courier selection depends on package size, weight, and shipping address.</i>

                        <li>
                            Eligible EU Nations for Delivery: Austria, Belgium, Bulgaria, Croatia, Cyprus, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Hungary, Ireland, Italy, Latvia,
                            Lithuania, Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Romania, Slovakia, Slovenia, Spain, Sweden, and Switzerland.

                        </li>
                    </ul>
                </div>

                <div className={`general-static-page-container-topic-heading`}>
                    Australia & New Zealand
                </div>

                <div className={`general-static-page-container-text`}>
                    <ul>
                        <li>
                            <h2s>
                                We ship to Australia and New Zealand free of charge on eligible orders. Additional charges apply for larger items.
                            </h2s>
                        </li>
                        <li>
                            We ship directly to your door anywhere in Canada, the USA, and the UK — free of charge!
                        </li>
                        <li>
                            <b>Free shipping on orders,</b> smaller than 24 inches.
                        </li>
                        <li>
                            Items larger than 24 inches (one side) incur ~$18 USD shipping.
                        </li>
                        <li>
                            Items larger than 28 inches (one side) incur ~25 USD shipping.
                        </li>
                        <li>
                            <b>We handle all tax-related matters,</b> including customs, VAT, import tariffs, and state/provincial sales taxes.
                        </li>
                        <li>
                            ANZ Deliveries: APC, POST NL, Australia Post, and Post NZ (8–20 business days).
                        </li>
                        <i>Courier selection depends on package size, weight, and shipping address.</i>
                    </ul>
                </div>

                <div className={`general-static-page-container-topic-heading`}>
                    Holiday Seasons, Sales Period, and Rural Areas
                </div>

                <div className={`general-static-page-container-text`}>
                    <ul>
                        <li>
                            Delays of 1-5 business days may occur during year-end holidays, sales periods, and for deliveries to remote regions.
                        </li>
                    </ul>
                </div>

                <div className={`general-static-page-container-topic-heading`}>
                    Returns Policy
                </div>

                <div className={`general-static-page-container-text`}>
                    <ul>
                        <li>
                            At Custom Mat, we take pride in our high-quality merchandise and exceptional customer care. We offer a generous return policy.
                        </li>
                        <li>
                            If there are any issues with your order, including sizing, color, or damage during transit, please email us at Fhi@CustomMat.com. We’ll either issue a refund or send you a replacement at no extra charge.
                        </li>
                        <li>
                            100% Happiness Guaranteed: If you're not completely satisfied with your order, let us know, and we’ll make it right.
                        </li>
                    </ul>
                </div>

                <div className={`user-policy-paragraph`}>

                    <div style={{paddingBottom: "6px"}}>
                        {this.state.french ? "Nous proposons une expédition plus rapide vers les zones suivantes :" : "We offer faster shipping to following areas:"}

                    </div>


                    <div className={"faster-shipping-area-expandable"}>
                        <div className={"faq-question"} onClick={() => this.toggleModal("bc")}>
                            <img src={dropdownArrow} className={this.state.bc ? "faq-question-close" : "faq-question-open"}
                                alt={this.state.french ? "Développez pour plus d'options." : "Expand for more options."}
                            />
                            <div className={"faq-question-right"}>
                                {this.state.french ? "Colombie-Britannique" : "British Columbia"}

                        </div>
                        </div>

                        <div className={this.state.bc ? "bc-shipping-area-container" : "no-display"}>
                            {
                                marketList.britishColumbia.neighbourList.map((neighbourhood) => {
                                    var neighbourhoodStringProcessed = neighbourhood.replace(' ', "");
                                    var cityStringProcessed = marketList.britishColumbia.cityName.replace(' ', "");
                                    return <div className={"city-name-container"} style={{"color": "#72696A", "marginLeft":  "1rem", "marginBottom": "0.5rem"}}>
                                        •
                                        <a href={`/custom-framing-matboard-${neighbourhoodStringProcessed}-${cityStringProcessed}`}
                                           alt={`Shipping information for ${neighbourhood}, ${marketList.britishColumbia.cityName}`}
                                           style={{"color": "#72696A"}}>{neighbourhood}, {marketList.britishColumbia.cityName}
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    <div className={"faster-shipping-area-expandable"}>
                        <div className={"faq-question"} onClick={() => this.toggleModal("ab")}>
                            <img src={dropdownArrow} className={this.state.ab ? "faq-question-close" : "faq-question-open"}
                                 alt={this.state.french ? "Développez pour plus d'options." : "Expand for more options."}
                            />
                            <div className={"faq-question-right"}>
                                Alberta
                            </div>
                        </div>

                        <div className={this.state.ab ? "bc-shipping-area-container" : "no-display"}>
                            {
                                marketList.alberta.neighbourList.map((neighbourhood) => {
                                    var neighbourhoodStringProcessed = neighbourhood.replace(' ', "");
                                    var cityStringProcessed = marketList.alberta.cityName.replace(' ', "");
                                    return <div className={"city-name-container"} style={{"color": "#72696A", "marginLeft":  "1rem", "marginBottom": "0.5rem"}}>
                                        •
                                        <a href={`/custom-framing-matboard-${neighbourhoodStringProcessed}-${cityStringProcessed}`}
                                           alt={`Shipping information for ${neighbourhood}, ${marketList.alberta.cityName}`}
                                           style={{"color": "#72696A"}}>{neighbourhood}, {marketList.alberta.cityName}
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    <div className={"faster-shipping-area-expandable"}>
                        <div className={"faq-question"} onClick={() => this.toggleModal("sk")}>
                            <img src={dropdownArrow} className={this.state.sk ? "faq-question-close" : "faq-question-open"}
                                 alt={this.state.french ? "Développez pour plus d'options." : "Expand for more options."}
                            />
                            <div className={"faq-question-right"}>
                                Saskatchewan
                            </div>
                        </div>

                        <div className={this.state.sk ? "bc-shipping-area-container" : "no-display"}>
                            {
                                marketList.saskatchewan.neighbourList.map((neighbourhood) => {
                                    var neighbourhoodStringProcessed = neighbourhood.replace(' ', "");
                                    var cityStringProcessed = marketList.saskatchewan.cityName.replace(' ', "");
                                    return <div className={"city-name-container"} style={{"color": "#72696A", "marginLeft":  "1rem", "marginBottom": "0.5rem"}}>
                                        •
                                        <a href={`/custom-framing-matboard-${neighbourhoodStringProcessed}-${cityStringProcessed}`}
                                           alt={`Shipping information for ${neighbourhood}, ${marketList.saskatchewan.cityName}`}
                                           style={{"color": "#72696A"}}>{neighbourhood}, {marketList.saskatchewan.cityName}
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    <div className={"faster-shipping-area-expandable"}>
                        <div className={"faq-question"} onClick={() => this.toggleModal("mb")}>
                            <img src={dropdownArrow} className={this.state.mb ? "faq-question-close" : "faq-question-open"}
                                 alt={this.state.french ? "Développez pour plus d'options." : "Expand for more options."}
                            />
                            <div className={"faq-question-right"}>
                                Manitoba
                            </div>
                        </div>

                        <div className={this.state.mb ? "bc-shipping-area-container" : "no-display"}>
                            {
                                marketList.manitoba.neighbourList.map((neighbourhood) => {
                                    var neighbourhoodStringProcessed = neighbourhood.replace(' ', "");
                                    var cityStringProcessed = marketList.manitoba.cityName.replace(' ', "");
                                    return <div className={"city-name-container"} style={{"color": "#72696A", "marginLeft":  "1rem", "marginBottom": "0.5rem"}}>
                                        •
                                        <a href={`/custom-framing-matboard-${neighbourhoodStringProcessed}-${cityStringProcessed}`}
                                           alt={`Shipping information for ${neighbourhood}, ${marketList.manitoba.cityName}`}
                                           style={{"color": "#72696A"}}>{neighbourhood}, {marketList.manitoba.cityName}
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    <div className={"faster-shipping-area-expandable"}>
                        <div className={"faq-question"} onClick={() => this.toggleModal("on")}>
                            <img src={dropdownArrow} className={this.state.on ? "faq-question-close" : "faq-question-open"}
                                 alt={this.state.french ? "Développez pour plus d'options." : "Expand for more options."}
                            />
                            <div className={"faq-question-right"}>
                                Ontario
                            </div>
                        </div>

                        <div className={this.state.on ? "bc-shipping-area-container" : "no-display"}>
                            {
                                marketList.ontario.neighbourList.map((neighbourhood) => {
                                    var neighbourhoodStringProcessed = neighbourhood.replace(' ', "");
                                    var cityStringProcessed = marketList.ontario.cityName.replace(' ', "");
                                    return <div className={"city-name-container"} style={{"color": "#72696A", "marginLeft":  "1rem", "marginBottom": "0.5rem"}}>
                                        •
                                        <a href={`/custom-framing-matboard-${neighbourhoodStringProcessed}-${cityStringProcessed}`}
                                           alt={`Shipping information for ${neighbourhood}, ${marketList.ontario.cityName}`}
                                           style={{"color": "#72696A"}}>{neighbourhood}, {marketList.ontario.cityName}
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    <div className={"faster-shipping-area-expandable"}>
                        <div className={"faq-question"} onClick={() => this.toggleModal("qc")}>
                            <img src={dropdownArrow} className={this.state.qc ? "faq-question-close" : "faq-question-open"}
                                 alt={this.state.french ? "Développez pour plus d'options." : "Expand for more options."}
                            />
                            <div className={"faq-question-right"}>
                                Québec
                            </div>
                        </div>

                        <div className={this.state.qc ? "bc-shipping-area-container" : "no-display"}>
                            {
                                marketList.quebec.neighbourList.map((neighbourhood) => {
                                    var neighbourhoodStringProcessed = neighbourhood.replace(' ', "");
                                    var cityStringProcessed = marketList.quebec.cityName.replace(' ', "");
                                    return <div className={"city-name-container"} style={{"color": "#72696A", "marginLeft":  "1rem", "marginBottom": "0.5rem"}}>
                                        •
                                        <a href={`/custom-framing-matboard-${neighbourhoodStringProcessed}-${cityStringProcessed}`}
                                           alt={`Shipping information for ${neighbourhood}, ${marketList.quebec.cityName}`}
                                           style={{"color": "#72696A"}}>{neighbourhood}, {marketList.quebec.cityName}
                                        </a>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                </div>
            </div>



            <div style={{marginTop: "-69px"}}>
                <RefundPolicy style={{width: "100%"}} french={this.state.french}/>
            </div>



        </div>
    }
}